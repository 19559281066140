import '@/components/Portal/style.scss';
import { FC } from 'react';
import { createPortal } from 'react-dom';
import { cn, CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('components-portal');

interface Props {
  className: string;
}

export const Portal: FC<Props> = ({ children, className }) =>
  createPortal(<div className={cn(scope, className)}>{children}</div>, document.body);
