import i18n from '@/i18next';
import { IauthenticationReducer } from '@/redux/auth/auth.interfaces';
import {
  AuthActionTypes,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SEND_FORGOT_PASSWORD_REQUEST,
  SEND_FORGOT_PASSWORD_SUCCESS,
  SEND_FORGOT_PASSWORD_FAILURE,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  REFRESH_TOKEN_REQUEST,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
} from '@/redux/auth/auth.types';

const defaultAuthState: IauthenticationReducer = {
  isAuthenticated: false,
  isLoading: false,
  error: {
    emailOrPassword: false,
    unexpectedError: false,
  },
  errorMessage: null,
  token: null,
  refreshToken: null,
  userId: null!,
};

export default (state = defaultAuthState, action: AuthActionTypes): IauthenticationReducer => {
  switch (action.type) {
    case REFRESH_TOKEN_REQUEST:
      return {
        ...state,
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refresh_token,
      };
    case REFRESH_TOKEN_FAILURE:
    case LOGOUT_SUCCESS:
      return defaultAuthState;
    case LOGOUT_REQUEST:
      return {
        ...state,
        ...defaultAuthState,
      };
    case SEND_FORGOT_PASSWORD_REQUEST:
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case SEND_FORGOT_PASSWORD_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
      };
    case LOGOUT_FAILURE:
    case SEND_FORGOT_PASSWORD_FAILURE:
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error.message,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        isAuthenticated: false,
        errorMessage: null,
        error: {
          emailOrPassword: false,
          unexpectedError: false,
        },
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        errorMessage: null,
        error: {
          emailOrPassword: false,
          unexpectedError: false,
        },
        ...action.payload,
      };

    /* eslint-disable no-case-declarations */
    // Because of the different structure of error object in this reducer and the error sent by backend
    // the case declatariotn is needed in this case
    case LOGIN_FAILURE:
      const email = action.error && !!action.error.email;
      const password = action.error && !!action.error.password;
      const blocked = action.error && !!action.error.blocked;
      const unexpectedError = action.error && !action.error.email && !action.error.password;
      let errorMessage = null;
      if (email || password) {
        errorMessage = i18n.t('login.wrongPasswordOrEmail');
      } else if (blocked) {
        errorMessage = i18n.t('login.blockedUser');
      } else {
        errorMessage = i18n.t('error.generalError');
      }
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        errorMessage,
        error: {
          emailOrPassword: email || password,
          unexpectedError,
        },
      };

    default:
      return state;
  }
};
