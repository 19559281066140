import '@/components/DatePicker/style.scss';
import { FC, ReactNode } from 'react';
import DatePicker from 'react-datepicker';
import { cn, CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('components-date-picker');

interface Props {
  selected?: Date;
  show?: boolean;
  value?: string;
  onClickOutside?(event: React.MouseEvent<HTMLDivElement>): void;
  onChange(date: Date | [Date, Date] | null): void;
  selectsRange?: boolean;
  startDate?: Date;
  endDate?: Date;
  withPortal?: boolean;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  shouldCloseOnSelect?: boolean;
  minDate?: Date;
  dateFormat?: string | string[];
  customInput?: ReactNode;
  isError?: boolean;
  inline?: boolean;
  isClearable?: boolean;
  calendarContainer?(props: { children: React.ReactNode[] }): React.ReactNode;
  openToDate?: Date;
  id?: string;
}

export const DatePickerComponent: FC<Props> = ({
  selected,
  show,
  onChange,
  isError,
  value,
  onClickOutside,
  children,
  selectsRange,
  startDate,
  endDate,
  withPortal,
  selectsStart,
  selectsEnd,
  shouldCloseOnSelect,
  minDate,
  dateFormat,
  customInput,
  inline = true,
  calendarContainer,
  isClearable,
  openToDate,
  id,
  ...Props
}) => {
  return (
    <div className={cn(scope, scope.and('hide'), scope.and(show && 'show'))} {...Props}>
      <div className={`${isError ? '-errorDropdown' : ''}`} id={id ? id : 'date-picker-calendar'}>
        <DatePicker
          customInput={customInput}
          selected={selected}
          onChange={onChange}
          onClickOutside={onClickOutside}
          inline={inline}
          value={value}
          popperPlacement="bottom-end"
          selectsRange={selectsRange}
          startDate={startDate}
          endDate={endDate}
          selectsStart={selectsStart}
          withPortal={withPortal}
          selectsEnd={selectsEnd}
          minDate={minDate}
          shouldCloseOnSelect={shouldCloseOnSelect}
          dateFormat={dateFormat}
          calendarContainer={calendarContainer}
          isClearable={isClearable}
          openToDate={openToDate}
        />
        {children}
      </div>
    </div>
  );
};
