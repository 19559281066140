import { AxiosError } from 'axios';
import { IDocumentUploadResponse } from '@/client/documentManagementInterfaces';
import { ISearchWorkFlowResponse } from '@/client/workflowManagementInterfaces';
import { IIsModified } from './processes.interfaces';

export const CREATE_PROCESS_REQUEST = 'CREATE_PROCESS_REQUEST';
export const CREATE_PROCESS_SUCCESS = 'CREATE_PROCESS_SUCCESS';
export const CREATE_PROCESS_FAILURE = 'CREATE_PROCESS_FAILURE';
export const SEARCH_PROCESS_REQUEST = 'SEARCH_PROCESS_REQUEST';
export const SEARCH_PROCESS_SUCCESS = 'SEARCH_PROCESS_SUCCESS';
export const SEARCH_PROCESS_FAILURE = 'SEARCH_PROCESS_FAILURE';
export const SET_PROCESS_SEARCH_WORDS = 'SET_PROCESS_SEARCH_WORDS';
export const DELETE_PROCESS_SEARCH_WORDS = 'DELETE_PROCESS_SEARCH_WORDS';

export const SET_COLLAPSE_PROCESS_ID = 'SET_COLLAPSE_PROCESS_ID';
export const SET_MEMORIZE_MILE_ID = 'SET_MEMORIZE_MILE_ID';
export const SET_PROCESS_SCROLLBAR_POSITION = 'SET_PROCESS_SCROLLBAR_POSITION';
export const IS_PROCESS_MODIFIED = 'IS_PROCESS_MODIFIED';

export interface ISetMemorizeMileId {
  type: typeof SET_MEMORIZE_MILE_ID;
  payload: number;
}

export interface ISetCollapseProcessId {
  type: typeof SET_COLLAPSE_PROCESS_ID;
  payload: number;
}

export interface ISetProcessScrollbarPositon {
  type: typeof SET_PROCESS_SCROLLBAR_POSITION;
  payload: number;
}

export interface ICreateProcessRequestAction {
  type: typeof CREATE_PROCESS_REQUEST;
}

export interface ICreateProcessSuccessAction {
  type: typeof CREATE_PROCESS_SUCCESS;
  payload: IDocumentUploadResponse;
}

export interface ICreateProcessFailureAction {
  type: typeof CREATE_PROCESS_FAILURE;
  error: AxiosError;
}

export interface ISearchProcessRequestAction {
  type: typeof SEARCH_PROCESS_REQUEST;
}

export interface ISearchProcessSuccessAtion {
  type: typeof SEARCH_PROCESS_SUCCESS;
  payload: ISearchWorkFlowResponse[];
}

export interface ISearchProcessFailureAtion {
  type: typeof SEARCH_PROCESS_FAILURE;
  error: AxiosError;
}

export interface ISetProcessSearchWordAction {
  type: typeof SET_PROCESS_SEARCH_WORDS;
  payload: string[];
}

export interface IDeleteProcessSearchWordAction {
  type: typeof DELETE_PROCESS_SEARCH_WORDS;
}
export interface IIsProcessModified {
  type: typeof IS_PROCESS_MODIFIED;
  payload: IIsModified;
}

export type ProcessesActionTypes =
  | ISetCollapseProcessId
  | ISetMemorizeMileId
  | ICreateProcessRequestAction
  | ICreateProcessSuccessAction
  | ICreateProcessFailureAction
  | ISearchProcessRequestAction
  | ISearchProcessSuccessAtion
  | ISearchProcessFailureAtion
  | ISetProcessSearchWordAction
  | ISetProcessScrollbarPositon
  | IDeleteProcessSearchWordAction
  | IIsProcessModified;
