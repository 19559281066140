/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import i18next from 'i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import { creatRepo } from '@/client_v2/db/utils/createRepo';
import { createWhereQuery } from '@/client_v2/db/utils/createWhereQuery';
import { BusinessArea } from '@/client_v2/rest/types/BusinessAreasRes';
import { Client } from '@/client_v2/rest/types/ClientsRes';
import { Comment } from '@/client_v2/rest/types/CommentsRes';
import { Company } from '@/client_v2/rest/types/CompaniesRes';
import { Document } from '@/client_v2/rest/types/DocumentsRes';
import { User } from '@/client_v2/rest/types/UsersRes';
import { WorkflowMile } from '@/client_v2/rest/types/WorkflowMilesRes';
import { Workflow } from '@/client_v2/rest/types/WorkflowsRes';
import { WorkflowStep } from '@/client_v2/rest/types/WorkflowStepsRes';
import { getBusinessAreasNiceName } from '@/resources/functions';
import { ActivityTypes, CommentTypes } from '@/resources/constants/shared';

export interface IGetBusinessAreaData {
  id: number;
  name: string;
  updatedAt: Date;
}

export interface IGetWorkflowModalData {
  businessAreaId: number;
  businessAreaName: string;
  clients: {
    clientId: number;
    clientName: string;
    companies: {
      companyId: number;
      companyName: string;
    }[];
  }[];
}

export interface IGetDashboardDataWorkflow {
  id: number;
  name: string;
  clientId: number;
  companyId: number;
  userId: number;
  workflowStatus: WorkflowMile['status'];
  activityType: Workflow['activity_type'];
  activityComment?: IGetDashboardComment;
  activityDocument?: IGetDashboardDocument;
  activityModifyUser?: {
    userId: number;
    name: string;
    updatedAt?: string;
  };
  statistic: IGetDashboardStatistic;
  modifyDate?: string;
}

export interface IGetDashboardComment {
  commentId: number;
  comment: string;
  updatedAt?: string;
}

export interface IGetDashboardDocument {
  documentId: number;
  path: string;
  name: string;
  updatedAt?: string;
}

export interface IGetDashboardStatistic {
  sum: number;
  statuses: {
    status: string;
    count: number;
  }[];
}

interface IGetDashboardQueryWeorkflow extends IGetDashboardDataWorkflow {
  miles?: {
    steps: {
      status: string;
      count: number;
    }[];
  }[];
}

interface IGetDasboardQuery {
  businessAreaName: string;
  workflows: IGetDashboardQueryWeorkflow[];
}

export interface IGetDasboardData {
  businessAreaId: number;
  businessAreaName: string;
  workflows: IGetDashboardDataWorkflow[];
}

export interface IDashboardDataArg {
  businessAreaIds: number[];
  selectedCompanyId: number | null;
  selectedClientId?: number | null;
}

export const BusinessAreaRepo = creatRepo<BusinessArea>({
  name: 'business_area',
  model: {
    'id:number': { pk: true },
    'name:string': {},
    'created_at:date': {},
    'updated_at:date': {},
    'deleted_at:date': {},
  },
})
  .queryName('get-business-area-data')
  .handler(async (table): Promise<IGetBusinessAreaData[]> => {
    const res = (await table()
      .query('select', ['id', 'name', 'updated_at AS updatedAt'])
      .exec()) as IGetBusinessAreaData[];
    return res;
  })
  .queryName('get-active-business-area-list')
  .handler(async (table) => {
    const res: IGetBusinessAreaData[] = (await table()
      .query('select', ['id', 'name'])
      .exec()) as IGetBusinessAreaData[];

    return getBusinessAreasNiceName(res);
  })
  .queryName('get-workflow-modal-data')
  .handler((table) => {
    return table()
      .query('select', ['id AS businessAreaId', 'name AS businessAreaName', 'clients'])
      .graph({
        key: 'clients',
        with: { table: 'client' },
        select: ['id AS clientId', 'name AS clientName', 'companies'],
        on: createWhereQuery<Client, { business_area: BusinessArea }>(
          ({ business_areas_ids }, { business_area }) =>
            business_areas_ids.includes(business_area.id),
        ),
        graph: {
          key: 'companies',
          with: { table: 'company' },
          select: ['id AS companyId', 'name AS companyName'],
          on: createWhereQuery<Company, { client: Client }>(({ id }, { client }) =>
            client.companies_ids.includes(id),
          ),
        },
      })
      .exec();
  })
  .queryName('get-dashboard-data')
  .handler(
    async (
      table,
      { businessAreaIds, selectedCompanyId, selectedClientId }: IDashboardDataArg = {
        businessAreaIds: [],
        selectedCompanyId: null,
        selectedClientId: null,
      },
    ) => {
      const res: IGetDasboardQuery[] = (await table()
        .query('select', ['id AS businessAreaId', 'name AS businessAreaName', 'workflows'])
        .graph({
          key: 'workflows',
          with: { table: 'workflow' },
          on: createWhereQuery<Workflow, { business_area: BusinessArea }>(
            ({ business_area_id, is_archived, company_id, client_id }, { business_area }) => {
              if (selectedClientId && client_id != selectedClientId) {
                return false;
              }

              if (selectedCompanyId && company_id != selectedCompanyId) {
                return false;
              }
              if (
                business_area_id === business_area.id &&
                !businessAreaIds.includes(business_area_id) &&
                !is_archived
              ) {
                return true;
              }
              return false;
            },
          ),
          select: [
            'id',
            'name',
            'client_id AS clientId',
            'company_id AS companyId',
            'user_id AS userId',
            'miles',
            'mile.status AS workflowStatus',
            'activity_type AS activityType',
            'activityComment',
            'activityDocument',
            'activityModifyUser',
          ],
          orderBy: ['created_at DESC'],
          graph: [
            {
              key: 'activityComment',
              single: true,
              with: { table: 'comment' },
              on: createWhereQuery<Comment, { workflow: Workflow }>(
                (comment, { workflow }) =>
                  comment.id === workflow.activity_comment_id &&
                  comment.commentable_type === CommentTypes.WORKFLOW_STEP,
              ),
              select: ['id AS commentId', 'comment', 'updated_at AS updatedAt'],
            },
            {
              key: 'activityDocument',
              single: true,
              with: { table: 'document' },
              on: createWhereQuery<Document, { workflow: Workflow }>(
                ({ id }, { workflow }) => id === workflow.activity_document_id,
              ),
              select: ['id AS documentId', 'path', 'name', 'updated_at AS updatedAt'],
            },
            {
              key: 'activityModifyUser',
              single: true,
              with: { table: 'user' },
              on: createWhereQuery<User, { workflow: Workflow }>(
                ({ id }, { workflow }) => id === workflow.activity_causer_id,
              ),
              select: ['id AS userId', 'name', 'updated_at AS updatedAt'],
            },
            {
              key: 'miles',
              with: { table: 'workflow_mile' },
              on: createWhereQuery<WorkflowMile, { workflow: Workflow }>(
                ({ workflow_id }, { workflow }) => workflow_id === workflow.id,
              ),
              graph: {
                key: 'steps',
                with: { table: 'workflow_step' },
                on: createWhereQuery<WorkflowStep, { workflow_mile: WorkflowMile }>(
                  ({ workflow_mile_id }, { workflow_mile }) =>
                    workflow_mile_id === workflow_mile.id,
                ),
                select: ['status', 'COUNT(*) AS count'],
                groupBy: ['status'],
              },
              select: ['steps'],
            },
          ],
        })
        .exec()) as IGetDasboardQuery[];

      return res.filter((row) => {
        row.workflows = row.workflows.filter((workflow) => {
          workflow.miles = workflow.miles!.filter((mile) => {
            mile.steps = mile.steps.filter((step) => step.count > 0);
            return mile.steps.length > 0;
          });

          if (workflow.miles!.length === 0) return false;
          workflow.statistic = {
            sum: 0,
            statuses: [],
          };

          const statusMap: { [key: string]: number } = {};

          workflow.miles.forEach((mile) =>
            mile.steps.forEach((step) => {
              workflow.statistic.sum += step.count;
              statusMap[step.status] = (statusMap[step.status] || 0) + step.count;
            }),
          );
          delete workflow.miles;

          workflow.statistic.statuses = Object.keys(statusMap).map((status) => ({
            status,
            count: statusMap[status],
          }));

          if (
            statusMap.done + (statusMap.overdue || 0) === workflow.statistic.sum &&
            workflow.statistic.statuses.some((stat) => stat.status === 'done')
          ) {
            workflow.workflowStatus = 'done';
          } else if (
            workflow.statistic.statuses.every(
              (stat) => stat.status === 'later' || stat.status === 'ready_to_start',
            )
          ) {
            workflow.workflowStatus = 'ready_to_start';
          } else if (workflow.statistic.statuses.every((stat) => stat.status === 'overdue')) {
            workflow.workflowStatus = 'overdue';
          } else {
            workflow.workflowStatus = 'in_progress';
          }

          if (
            workflow.activityType === ActivityTypes.SET_DONE ||
            workflow.activityType === ActivityTypes.UNSET_DONE ||
            workflow.activityType === ActivityTypes.DOCUMENT_REMOVED
          ) {
            workflow.modifyDate = workflow?.activityModifyUser?.updatedAt;
          }
          if (workflow.activityType === ActivityTypes.DOCUMENT_ADDED) {
            workflow.modifyDate = workflow?.activityDocument?.updatedAt;
          }
          if (
            workflow.activityType === ActivityTypes.ADD_COMMENT ||
            workflow.activityType === ActivityTypes.COMMENT_REMOVED
          ) {
            workflow.modifyDate = workflow?.activityComment?.updatedAt;
          }

          dayjs.locale(i18next.language);
          dayjs.extend(utc);
          dayjs.extend(relativeTime);
          const offset = new Date().getTimezoneOffset();

          workflow.modifyDate =
            workflow.modifyDate && dayjs.utc(workflow.modifyDate).add(-offset, 'minutes').fromNow();

          return true;
        });
        return row.workflows.length > 0;
      }) as IGetDasboardData[];
    },
  )
  .make();
