import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FileDrop } from 'react-file-drop';
import { AppState } from '@/redux/store';
import { convertFileListToFileArr } from '@/resources/utils';
import '@pathofdev/react-tag-input/build/index.css';
import DocumentUploadLayout from '@/layouts/DocumentUploadModalLayout';
import Button from '@/components/Button';
import Text from '@/components/Text';
import FileBadges from '@/screens/DocumentsScreen/components/FileBadges';
import { UnderlineInput } from '@/components/Input/UnderlineInput/UnderlineInput';
import Badge from '@/components/Badge';
import MultiselectDropdown from '@/components/MultiselectDropdown';
import { useClientsData } from '@/client_v2/hooks/useClientsData';
import { useBusinessAreaData } from '@/client_v2/hooks/useBusinesAreaData';
import { useCompanyData } from '@/client_v2/hooks/useCompanyData';
import { useUserData } from '@/client_v2/hooks/useUserData';
import { setError } from '@/redux/message/message.action';
import { useWorkflowData } from '@/client_v2/hooks/useWorkflowData';
import useAddComment from '@/hooks/useAddComment';
import { createOnError } from '@/redux/message/message.callback';
import { useDocumentModalData } from '@/client_v2/hooks/useDocumentModalData';
import RestClient from '@/client_v2/rest';
import { useAsyncCallback } from '@/hooks/useAsyncCallback';
import {
  IGetWorkflowData,
  IGetWorkflowMiles,
  IGetWorkflowSteps,
} from '@/client_v2/db/repositories/Workflow.repo';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import { IGetBusinessAreaData } from '@/client_v2/db/repositories/BusinessArea.repo';
import Icon from '@/components/Icon';
import { setDocumentScrollbarPosition } from '@/redux/documents/documents.actions';
import CheckBox from '@/components/CheckBox';
import Tooltip from '@/components/Tooltip';

interface Props {
  documentId?: number;
  close(): void;
  selectedWorkflowData?: {
    selectedProcessData: IGetWorkflowData;
    selectedMileData: IGetWorkflowMiles;
    selectedWorkflowStep: IGetWorkflowSteps;
  };
}

const useDocumentUploadModal = ({ documentId, close }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user } = useSelector((state: AppState) => state.user);
  const selectedCompanyId = useSelector((state: AppState) => state.user.selectedCompanyId);
  const selectedClientId = useSelector((state: AppState) => state.user.selectedClientId);
  const [isDragging, setIsDragging] = useState(false);
  const [isModifyLoaded, setIsModifyLoaded] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState<File[]>([]);

  const {
    comments: labelValues,
    setComments: setLabelValues,
    comment: tempLabel,
    setCommentInput: setTempLabel,
    addComment: addLabel,
    removeComment: removeLabel,
  } = useAddComment();

  const {
    comments: commentValues,
    setComments: setCommentValues,
    comment: tempComment,
    setCommentInput: setTempComment,
    addComment,
    removeComment,
  } = useAddComment();

  const [businessArea, setBusinessArea] = useState<{ value: number; name: string }>();
  const [selectedClient, setSelectedClient] = useState<{ value: number; name: string }>();
  const [selectedUser, setSelectedUser] = useState<{ value: number; name: string }>();
  const [selectedCompany, setSelectedCompany] = useState<{
    value: number;
    name: string;
    clientId: number;
  }>();
  const [selectedWorkflow, setSelectedWorkflow] = useState<{ value: number; name: string }>();
  const [selectedMile, setSelectedMile] = useState<{ value: number; name: string }>();
  const [workflowStep, setWorkflowStep] = useState<{ value: number; name: string }>();
  const [selectedAddressee, setSelectedAddressee] = useState<{ value: number; name: string }[]>();
  const [isPublic, setIsPublic] = useState<boolean>(false);

  const [modifiedDocumentLoading, modifiedDocument] = useDocumentModalData({
    renderOnUpdate: true,
    triggerOnce: documentId || true,
    onError: createOnError(dispatch),
  });

  const [clientsLoading, clients] = useClientsData({
    renderOnUpdate: true,
    triggerOnce: true,
    onError: (err) => dispatch(setError(err.toString())),
  });

  const [businessAreaLoading, businessAreas] = useBusinessAreaData({
    renderOnUpdate: true,
    triggerOnce: true,
    onError: (err) => dispatch(setError(err.toString())),
  });
  const [companyLoading, companies] = useCompanyData({
    triggerOnce: true,
    renderOnUpdate: true,
    onError: (err) => dispatch(setError(err.toString())),
  });
  const [userLoading, users] = useUserData({
    triggerOnce: true,
    renderOnUpdate: true,
    onError: (err) => dispatch(setError(err.toString())),
  });
  const [workflowLoading, workflows, workflowsTrigger] = useWorkflowData({
    triggerOnce: {
      businessAreaIds: [],
      sort: { field: 'endDate', direction: 'asc' },
      filters: {
        ...(selectedClient && {
          clientIds: [selectedClient.value],
        }),
        ...(selectedCompany && {
          companyIds: [selectedCompany.value],
        }),
      },
      ...(selectedCompanyId
        ? {
            selectedCompanyId: selectedCompanyId,
          }
        : {}),
      ...(selectedClientId
        ? {
            selectedClientId: selectedClientId,
          }
        : {}),
    },
    renderOnUpdate: true,
    onError: (err) => dispatch(setError(err.toString())),
  });

  const dividerText = t('upload.dividerText');
  const buttonText = modifiedDocument ? t('upload.modifyButtonText') : t('upload.buttonText');
  const uploadText = t('upload.uploadText');
  const chosenDocsText = t('upload.chosenDocs');
  const instructionText = t('upload.instructionText');
  const fileLimitText = t('upload.fileLimitText');
  const choseBusinessAreaText = t('upload.choseBusinessArea');
  const partnerDropDownText = t('upload.partnerDropDown');
  const accepterDropDownText = t('upload.accepterDropDown');
  const companyDropDownText = t('upload.companyDropDown');
  const tagInputPlaceholder = t('upload.tagInputPlaceholder');
  const noDocumentText = t('upload.noDocument');
  const labels = t('upload.labels');
  const comment = t('upload.comment');
  const publicText = t('upload.public');
  const publicTooltipText = t('upload.publicTooltip');

  const fileInputRef = useRef<HTMLInputElement>(null);

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    setSelectedDocuments([...selectedDocuments, ...convertFileListToFileArr(files!)]);
    setIsDragging(true);
  };

  const onFileSelectClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const filteredCompanies =
    companies &&
    selectedClient &&
    companies.companies.filter((companyData) => companyData.clientId === selectedClient.value);

  const accepterUserList =
    users &&
    users.filter((userData) => {
      if (
        userData.authority !== Authorities.Admin &&
        userData.authority !== Authorities.SuperAdmin
      ) {
        return false;
      } else {
        return true;
      }
    });

  const filteredUsers =
    users &&
    selectedClient &&
    users.filter((data) => {
      if (selectedCompany) {
        return (
          data.clients?.includes(selectedClient.value) &&
          data.id !== user?.id &&
          data.companies?.includes(selectedCompany.value)
        );
      }
      return data.clients?.includes(selectedClient.value) && data.id != user?.id;
    });

  const findWorkflow =
    workflows &&
    selectedWorkflow &&
    workflows.workflowData &&
    workflows.workflowData.find((workflow) => workflow.id === selectedWorkflow.value);
  const miles =
    findWorkflow &&
    findWorkflow.miles.map((mile) => {
      return { name: mile.mileName, id: mile.mileId, steps: mile.steps };
    });

  const steps =
    miles &&
    selectedMile &&
    miles
      .find((mile) => mile.id === selectedMile.value)!
      .steps.map((step) => {
        return { name: step.stepName, id: step.stepId };
      });

  const document = {
    files: selectedDocuments,
    data: {
      businessAreaId: businessArea?.value,
      ...(selectedUser && {
        approverUserId: selectedUser.value,
      }),
      ...(selectedAddressee && {
        addressee: {
          clientId: selectedClient?.value,
          companyId: selectedCompany?.value,
          userIds: selectedAddressee.map((item) => item.value),
        },
      }),
      ...(workflowStep && {
        workflowStepId: workflowStep,
      }),
      isPublic,
    },
    comments: commentValues,
    tags: labelValues,
  };

  const { files, comments, tags } = document;
  const { businessAreaId } = document.data;

  const isDocumentInvalid =
    (!files.length && !modifiedDocument) ||
    !businessAreaId ||
    (!workflowStep && !!selectedWorkflow) ||
    !!(selectedAddressee?.length && !selectedUser);

  useEffect(() => {
    workflowsTrigger({
      businessAreaIds: [],
      sort: { field: 'endDate', direction: 'asc' },
      filters: {
        ...(selectedClient &&
          selectedClient.value && {
            clientIds: [selectedClient.value],
          }),
        ...(selectedCompany &&
          selectedCompany.value && {
            companyIds: [selectedCompany.value],
          }),
      },
    });
  }, [selectedClient, selectedCompany, businessArea, workflowsTrigger, businessAreas]);

  useEffect(() => {
    if (modifiedDocumentLoading) return;
    if (!modifiedDocument) return;
    if (isModifyLoaded) return;

    setIsPublic(modifiedDocument.isPublic);
    setSelectedUser({
      name: modifiedDocument.approverName,
      value: modifiedDocument.approverId,
    });
    setSelectedCompany({
      name: modifiedDocument.companyName,
      value: modifiedDocument.companyId,
      clientId: modifiedDocument.clientId,
    });
    setSelectedClient({ name: modifiedDocument.clientName, value: modifiedDocument.clientId });
    setCommentValues(modifiedDocument.comments);
    setLabelValues(
      modifiedDocument.tags.map((item) => {
        return { commentId: item.tagId, comment: item.name };
      }),
    );
    setBusinessArea({
      name: t(`sidebarFilter.${modifiedDocument.businessAreaName}`),
      value: modifiedDocument.businessAreaId,
    });
    modifiedDocument.workflow_step.id &&
      setWorkflowStep({
        name: modifiedDocument.workflow_step.name,
        value: modifiedDocument.workflow_step.id,
      });
    setSelectedAddressee(
      modifiedDocument.addressees.map((user) => {
        return {
          name: user.name,
          value: user.id,
        };
      }),
    );
    const savedWorkflow =
      workflows &&
      workflows.workflowData &&
      workflows.workflowData.find((flow) =>
        flow.miles.find((mile) => mile.mileId === modifiedDocument.workflow_step.workflowMileId),
      );
    const savedMile =
      savedWorkflow &&
      savedWorkflow.miles &&
      savedWorkflow.miles.find(
        (mile) => mile.mileId === modifiedDocument.workflow_step.workflowMileId,
      );
    savedWorkflow && setSelectedWorkflow({ name: savedWorkflow.name, value: savedWorkflow.id });
    savedMile && setSelectedMile({ name: savedMile.mileName, value: savedMile.mileId });
    if (!modifiedDocument.workflow_step.id || (savedWorkflow && savedMile)) {
      setIsModifyLoaded(true);
    }

    setIsDragging(true);
  }, [
    modifiedDocumentLoading,
    modifiedDocument,
    setSelectedUser,
    setSelectedCompany,
    setSelectedClient,
    setLabelValues,
    setBusinessArea,
    setWorkflowStep,
    setSelectedWorkflow,
    setSelectedMile,
    setCommentValues,
    setIsModifyLoaded,
    workflows,
    isModifyLoaded,
    t,
  ]);

  const [sendDocument, sendLoading, res] = useAsyncCallback(
    async () => {
      dispatch(setDocumentScrollbarPosition(undefined));
      if (modifiedDocument) {
        await RestClient.Documents.patch(
          {
            id: modifiedDocument.id,
            businessAreaId: businessArea!.value,
            ...(selectedUser && {
              approverUserId: selectedUser!.value,
            }),
            ...(selectedAddressee && {
              addressee: {
                clientId: selectedClient!.value,
                companyId: selectedCompany!.value,
                userIds: selectedAddressee.map((user) => user.value),
              },
            }),
            ...(workflowStep && {
              workflowStepId: workflowStep!.value,
            }),
            ...(selectedClientId && {
              sourceClientId: selectedClientId,
            }),
            isPublic,
          },
          {
            userId: user!.id!,
            comments: commentValues
              .filter(
                (newComment) =>
                  !modifiedDocument.comments.some(
                    (oldComment) => newComment.comment === oldComment.comment,
                  ),
              )
              .map(({ comment }) => comment),
          },
          labelValues
            .filter(
              (newTag) => !modifiedDocument.tags.some((oldTag) => newTag.comment === oldTag.name),
            )
            .map(({ comment }) => comment),
          modifiedDocument.comments.filter(
            (oldComment) =>
              !commentValues.some((newComment) => oldComment.comment === newComment.comment),
          ),
          modifiedDocument.tags.filter(
            (oldTag) => !labelValues.some((newTag) => oldTag.name === newTag.comment),
          ),
        );
        close();
      } else {
        await RestClient.Documents.post(
          {
            files: files!,
            data: {
              businessAreaId: businessArea!.value,
              ...(selectedUser && {
                approverUserId: selectedUser!.value,
              }),
              ...(selectedAddressee && {
                addressee: {
                  clientId: selectedClient!.value,
                  companyId: selectedCompany?.value,
                  userIds: selectedAddressee.map((user) => user.value),
                },
              }),
              ...(selectedClientId && {
                sourceClientId: selectedClientId,
              }),
              ...(selectedCompanyId && {
                sourceCompanyId: selectedCompanyId,
              }),
              ...(workflowStep && {
                workflowStepId: workflowStep.value,
              }),
              ...(isPublic && {
                isPublic,
              }),
            },
          },
          {
            userId: user!.id!,
            comments: commentValues.map((item) => item.comment),
          },
          labelValues.map((item) => item.comment),
        );
        close();
      }
    },
    createOnError(dispatch),
    [
      modifiedDocument,
      files,
      businessArea,
      workflowStep,
      comments,
      tags,
      close,
      selectedUser,
      selectedClient,
      selectedCompany,
      selectedAddressee,
      isPublic,
    ],
  );

  useEffect(() => {
    if (res) {
      close();
    }
  }, [close, res]);

  return {
    addComment,
    removeComment,
    isDragging,
    dividerText,
    buttonText,
    uploadText,
    chosenDocsText,
    instructionText,
    fileLimitText,
    setIsDragging,
    choseBusinessAreaText,
    partnerDropDownText,
    accepterDropDownText,
    companyDropDownText,
    tagInputPlaceholder,
    dispatch,
    selectedDocuments,
    setSelectedDocuments,
    fileInputRef,
    onFileInputChange,
    onFileSelectClick,
    noDocumentText,
    selectedCompanyId,
    labels,
    comment,
    publicText,
    publicTooltipText,
    labelValues,
    setLabelValues,
    addLabel,
    removeLabel,
    tempLabel,
    setTempLabel,
    commentValues,
    setCommentValues,
    tempComment,
    setTempComment,
    document,
    setSelectedClient,
    selectedClient,
    setSelectedCompany,
    selectedCompany,
    setBusinessArea,
    businessArea,
    setSelectedUser,
    selectedUser,
    files,
    isDocumentInvalid,
    businessAreaId,
    clientsLoading,
    clients,
    businessAreas,
    companies,
    users,
    companyLoading,
    userLoading,
    businessAreaLoading,
    sendLoading,
    comments,
    tags,
    selectWorkflowText: t('upload.selectWorkflow'),
    optionalText: t('upload.optional'),
    selectMilestoneText: t('upload.selectMilestone'),
    selectStepText: t('upload.selectStep'),
    setWorkflowStep,
    workflowLoading,
    workflowStep,
    workflows,
    steps,
    miles,
    selectedMile,
    setSelectedMile,
    selectedWorkflow,
    setSelectedWorkflow,
    sendDocument,
    modifiedDocument,
    addresseeText: t('upload.addressee'),
    selectAddresseText: t('upload.selectAddresse'),
    selectedAddressee,
    setSelectedAddressee,
    addText: t('upload.add'),
    accepterUserList,
    filteredUsers,
    filteredCompanies,
    t,
    isPublic,
    setIsPublic,
    selectedClientId,
  };
};

const DocumentUploadModal: FC<Props> = ({ documentId, close, selectedWorkflowData }: Props) => {
  const {
    addComment,
    removeComment,
    isDragging,
    dividerText,
    buttonText,
    selectedCompanyId,
    uploadText,
    chosenDocsText,
    instructionText,
    fileLimitText,
    setIsDragging,
    choseBusinessAreaText,
    partnerDropDownText,
    accepterDropDownText,
    companyDropDownText,
    setSelectedDocuments,
    selectedDocuments,
    fileInputRef,
    onFileInputChange,
    onFileSelectClick,
    noDocumentText,
    labels,
    comment,
    publicText,
    publicTooltipText,
    labelValues,
    tempLabel,
    setTempLabel,
    commentValues,
    tempComment,
    setTempComment,
    addLabel,
    removeLabel,
    setSelectedClient,
    selectedClient,
    isDocumentInvalid,
    clients,
    businessAreas,
    setSelectedCompany,
    selectedCompany,
    setSelectedUser,
    selectedUser,
    setBusinessArea,
    businessArea,
    sendLoading,
    selectWorkflowText,
    optionalText,
    selectMilestoneText,
    selectStepText,
    setWorkflowStep,
    workflowStep,
    workflows,
    steps,
    setSelectedMile,
    miles,
    setSelectedWorkflow,
    sendDocument,
    modifiedDocument,
    selectedWorkflow,
    selectedMile,
    addresseeText,
    selectAddresseText,
    setSelectedAddressee,
    selectedAddressee,
    addText,
    accepterUserList,
    filteredUsers,
    filteredCompanies,
    t,
    isPublic,
    setIsPublic,
    selectedClientId,
  } = useDocumentUploadModal({ documentId, close, selectedWorkflowData });

  const filteredWorkflows =
    businessArea &&
    businessArea.value &&
    workflows &&
    workflows.workflowData.filter(
      (item) => item.businessAreaId === businessArea?.value && !item.isArchived,
    );

  const user = useSelector((state: AppState) => state.user.user);
  const isAdmin =
    user!.authority === Authorities.SuperAdmin || user!.authority === Authorities.Admin;
  const [filteredBusinessArea, setFilteredBusinessArea] = useState<IGetBusinessAreaData[]>();

  useEffect(() => {
    let selectedClient;
    if (selectedWorkflowData) {
      selectedClient = clients?.clients.find(
        (c) => c.id === selectedWorkflowData?.selectedProcessData?.client,
      );
    } else {
      if (selectedClientId) {
        selectedClient = clients?.clients.find((c) => selectedClientId === c.id);
      }
    }

    if (selectedClient) {
      setSelectedClient({ value: selectedClient?.id, name: selectedClient?.name });
    }
  }, [clients, selectedWorkflowData, setSelectedClient, selectedClientId]);

  useEffect(() => {
    if (filteredCompanies && !selectedCompany) {
      const selectedCompany = filteredCompanies?.find(
        (c) => c.id === selectedWorkflowData?.selectedProcessData?.companyId,
      );

      const usersCompany =
        filteredCompanies &&
        user &&
        filteredCompanies.find((company) => user.companies.includes(company.id));

      if (user && user.authority === 'user' && selectedCompanyId!) {
        setSelectedCompany({
          value: selectedCompanyId,
          name: usersCompany!.name,
          clientId: usersCompany!.clientId,
        });
      }

      if (selectedCompany) {
        setSelectedCompany({
          value: selectedCompany?.id,
          name: selectedCompany?.name,
          clientId: selectedCompany?.clientId,
        });
      }
    }
  }, [
    selectedCompanyId,
    filteredCompanies,
    selectedCompany,
    selectedWorkflowData?.selectedProcessData?.companyId,
    setSelectedCompany,
    user,
  ]);

  /*    If i am a SUPERADMIN
        i can only upload the document for those business areas
        that are available for the given client, if a client is selected, even without addressee  */

  useEffect(() => {
    if (user && selectedClient && clients && !selectedWorkflowData) {
      if (selectedClient) {
        const clientsBusinessAreas =
          clients &&
          clients.clients.find(({ id }) => selectedClient.value == id)?.activeBusinessAreas;
        const filteredBusinessAreas = businessAreas?.filter((item) =>
          clientsBusinessAreas?.includes(item.id),
        );
        setFilteredBusinessArea(filteredBusinessAreas);
        const isSelectedBAInsideTheClientsBA = filteredBusinessAreas.findIndex(
          (item) => businessArea?.value === item.id,
        );
        if (isSelectedBAInsideTheClientsBA == -1 && businessArea) setBusinessArea(undefined);
      } else {
        setFilteredBusinessArea(businessAreas);
      }
    }

    if (!selectedClient) {
      setFilteredBusinessArea(businessAreas);
    }
  }, [
    selectedAddressee,
    user,
    businessAreas,
    clients,
    selectedClient,
    setBusinessArea,
    businessArea,
    selectedWorkflowData,
  ]);

  useEffect(() => {
    if (user && user.authority !== Authorities.SuperAdmin) {
      const actualClient = clients?.clients.find((client) => user?.clients?.includes(client.id));
      setFilteredBusinessArea(
        businessAreas?.filter((filter) => actualClient?.activeBusinessAreas.includes(filter.id)),
      );
    } else {
      setFilteredBusinessArea(businessAreas);
    }
  }, [businessAreas, clients, user]);

  useEffect(() => {
    if (selectedWorkflowData?.selectedProcessData?.businessAreaId) {
      const selectedBusinessArea = filteredBusinessArea?.find(
        (b) => b.id === selectedWorkflowData?.selectedProcessData?.businessAreaId,
      );

      if (selectedBusinessArea) {
        setBusinessArea({ value: selectedBusinessArea?.id, name: selectedBusinessArea?.name });
      }
    }
  }, [
    filteredBusinessArea,
    selectedWorkflowData?.selectedProcessData?.businessAreaId,
    setBusinessArea,
  ]);

  useEffect(() => {
    if (selectedWorkflowData?.selectedProcessData?.id) {
      setSelectedWorkflow({
        value: selectedWorkflowData?.selectedProcessData?.id,
        name: selectedWorkflowData?.selectedProcessData.name,
      });
    }

    if (selectedWorkflowData?.selectedMileData?.mileId) {
      setSelectedMile({
        value: selectedWorkflowData?.selectedMileData?.mileId,
        name: selectedWorkflowData?.selectedMileData.mileName,
      });
    }

    if (selectedWorkflowData?.selectedWorkflowStep?.stepId) {
      setWorkflowStep({
        value: selectedWorkflowData?.selectedWorkflowStep?.stepId,
        name: selectedWorkflowData?.selectedWorkflowStep.stepName,
      });
    }
  }, [
    selectedWorkflowData?.selectedMileData?.mileId,
    selectedWorkflowData?.selectedMileData.mileName,
    selectedWorkflowData?.selectedProcessData?.id,
    selectedWorkflowData?.selectedProcessData.name,
    selectedWorkflowData?.selectedWorkflowStep?.stepId,
    selectedWorkflowData?.selectedWorkflowStep.stepName,
    setSelectedMile,
    setSelectedWorkflow,
    setWorkflowStep,
  ]);

  return (
    <DocumentUploadLayout
      hasFile={isDragging}
      dividerText={!modifiedDocument && <Text type="style10">{dividerText}</Text>}
      uploadButton={
        <Button
          icon={!documentId ? undefined : 'pencil'}
          size="medium"
          onClick={sendDocument}
          disabled={isDocumentInvalid}
          loadingSpinner={sendLoading}
          id="documentSubmitButton"
        >
          <Text type="style24" className="pl-md-5 pr-md-5 pl-0 pr-0 pt-1 pb-1">
            {buttonText}
          </Text>
        </Button>
      }
      addFilesButton={
        !modifiedDocument && (
          <>
            <Button
              size="large"
              className="d-flex flex-nowrap align-items-center bg-transparent"
              onClick={onFileSelectClick}
              id="documentFileUploadButton"
            >
              <Text type="style20" className="pl-0 pr-0 pt-1 pb-1">
                {uploadText}
              </Text>
              <Icon colorStyle="primary" type="plus" />
            </Button>
          </>
        )
      }
      dragArea={
        !modifiedDocument && (
          <FileDrop
            onDrop={(files) => {
              setIsDragging(true);
              setSelectedDocuments([...selectedDocuments, ...convertFileListToFileArr(files!)]);
            }}
            onDragOver={() => setIsDragging(true)}
          >
            <input type="file" id="file" ref={fileInputRef} hidden onChange={onFileInputChange} />
            <div className="d-flex justify-content-center flex-column">
              <div className="mb-2 d-flex justify-content-center">
                <Text type="style7">{instructionText}</Text>
              </div>
              <div className="d-flex justify-content-center">
                <Text type="style6">{fileLimitText}</Text>
              </div>
            </div>
          </FileDrop>
        )
      }
      uploadedDocsBadges={
        <FileBadges
          files={selectedDocuments}
          noDocumentText={modifiedDocument ? modifiedDocument.name : noDocumentText}
          onBadgeClick={setSelectedDocuments}
        />
      }
      chosenDocsText={<Text type="style8">{chosenDocsText}</Text>}
      labelBadgeInput={
        <>
          <UnderlineInput
            onChange={setTempLabel}
            value={tempLabel}
            onEnterKeyPressed={addLabel}
            titleText={labels}
          />
          {labelValues ? (
            <div className="d-flex flex-wrap">
              {labelValues.map((label, index: number) => (
                <div className=" m-1" key={`${index}-${label.comment}`} id={label.comment}>
                  <Badge
                    size="small"
                    content={label.comment}
                    colorStyle="lightBlue"
                    selfClosing
                    onClick={() => removeLabel(label)}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </>
      }
      addLabelButton={
        <Button
          onClick={() => {
            addLabel();
          }}
        >
          {addText}
        </Button>
      }
      commentBadgeInput={
        <>
          <UnderlineInput
            onChange={setTempComment}
            value={tempComment}
            onEnterKeyPressed={addComment}
            titleText={comment}
          />
          {commentValues ? (
            <div className="d-flex flex-wrap">
              {commentValues.map((comment, index: number) => (
                <div className=" m-1" key={`${index}-${comment.comment}`} id={comment.comment}>
                  <Badge
                    size="small"
                    content={comment.comment}
                    colorStyle="lightBlue"
                    selfClosing
                    onClick={() => removeComment(comment)}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </>
      }
      addCommentButton={<Button onClick={addComment}>{addText}</Button>}
      businessAreaDropdown={
        <MultiselectDropdown
          avoidHighlightFirstOption
          options={
            filteredBusinessArea?.map((item) => ({
              ...item,
              name: t(`sidebarFilter.${item.name}`),
            })) || []
          }
          title={choseBusinessAreaText}
          label={choseBusinessAreaText}
          singleSelect
          isDisabled={!!selectedWorkflowData}
          selectedOptions={businessArea?.value && [businessArea]}
          onSelectItem={(selectedBusinessArea) =>
            setBusinessArea({
              name: selectedBusinessArea[0].name,
              value: selectedBusinessArea[0].id,
            })
          }
          onRemoveItem={() => {
            setBusinessArea(undefined);
            setSelectedWorkflow(undefined);
            setSelectedMile(undefined);
            setWorkflowStep(undefined);
          }}
          required
          id="selectDocBusinessArea"
        />
      }
      addresseeText={<Text type="style8">{addresseeText}</Text>}
      addresseeDropdown={
        <MultiselectDropdown
          avoidHighlightFirstOption
          options={
            (filteredUsers &&
              filteredUsers.map((user) => {
                return {
                  name: user!.name,
                  value: user!.id,
                };
              })) ||
            []
          }
          title={selectAddresseText}
          label={selectAddresseText}
          maxSelection={5}
          showIcon
          selectedOptions={selectedAddressee && selectedAddressee.length && selectedAddressee}
          onSelectItem={(userList) => setSelectedAddressee(userList)}
          onRemoveItem={(selectedUser) => setSelectedAddressee(selectedUser)}
          closeOnSelect={true}
          id="documentAddresseeDropdown"
        />
      }
      juniorAccessibility={
        <>
          <Text className="float-left mr-2" type="style1">
            {publicText}
          </Text>
          <Tooltip bubbleContent={publicTooltipText} place="right">
            {/* Every user should be able to upload public documents at all authorization levels */}
            <CheckBox
              key={`public`}
              type={isPublic ? 'checked' : 'notChecked'}
              onClick={() => {
                isPublic ? setIsPublic(false) : setIsPublic(true);
              }}
              id={`publicCheck`}
            />
          </Tooltip>
        </>
      }
      partnerDropdown={
        <MultiselectDropdown
          avoidHighlightFirstOption
          options={(clients && clients.clients) || []}
          title={partnerDropDownText}
          label={partnerDropDownText}
          singleSelect
          isDisabled={
            !!selectedWorkflowData ||
            user!.authority === Authorities.ClientUser1 ||
            user!.authority === Authorities.ClientUser2
          }
          selectedOptions={selectedClient?.value && [selectedClient]}
          onSelectItem={(selectedClient) =>
            setSelectedClient({ name: selectedClient[0].name, value: selectedClient[0].id })
          }
          onRemoveItem={() => {
            setSelectedClient(undefined);
            setSelectedCompany(undefined);
            setSelectedAddressee(undefined);
          }}
          id="documentClientDropdown"
        />
      }
      accepterPersonDropdown={
        <MultiselectDropdown
          avoidHighlightFirstOption
          options={accepterUserList || []}
          title={accepterDropDownText}
          label={accepterDropDownText}
          required={!!selectedAddressee?.length}
          singleSelect
          selectedOptions={selectedUser?.value && [selectedUser]}
          onSelectItem={(selectedUser) =>
            setSelectedUser({
              name: selectedUser[0].name,
              value: selectedUser[0].id,
            })
          }
          onRemoveItem={() => setSelectedUser(undefined)}
          id="documentAccepterDropdown"
        />
      }
      companyDropdown={
        <MultiselectDropdown
          avoidHighlightFirstOption
          options={filteredCompanies || []}
          title={companyDropDownText}
          label={companyDropDownText}
          singleSelect
          selectedOptions={selectedCompany?.value && [selectedCompany]}
          onSelectItem={(selectedCompany) =>
            setSelectedCompany({
              name: selectedCompany[0].name,
              value: selectedCompany[0].id,
              clientId: selectedCompany[0].clientId,
            })
          }
          onRemoveItem={() => {
            setSelectedCompany(undefined);
            setSelectedAddressee(undefined);
          }}
          id="documentCompanyDropdown"
          isDisabled={!!selectedWorkflowData || (!isAdmin && !user?.companies.length)}
        />
      }
      workflowText={<Text type="style8">{selectWorkflowText}</Text>}
      workflowTextOptional={<Text type="style7">{optionalText}</Text>}
      workflowDropdown={
        <MultiselectDropdown
          avoidHighlightFirstOption
          options={filteredWorkflows || []}
          title={selectWorkflowText}
          label={selectWorkflowText}
          singleSelect
          isDisabled={!!selectedWorkflowData}
          selectedOptions={selectedWorkflow && !!selectedWorkflow.value && [selectedWorkflow]}
          onSelectItem={(selectedWorkflow) =>
            setSelectedWorkflow({ name: selectedWorkflow[0].name, value: selectedWorkflow[0].id })
          }
          onRemoveItem={() => {
            setSelectedWorkflow(undefined);
            setSelectedMile(undefined);
            setWorkflowStep(undefined);
          }}
          maxSelection={1}
          id="documentWorkflowDropdown"
        />
      }
      milestoneDropdown={
        <MultiselectDropdown
          avoidHighlightFirstOption
          options={miles && selectedWorkflow ? miles : []}
          title={selectMilestoneText}
          label={selectMilestoneText}
          singleSelect
          isDisabled={!!selectedWorkflowData}
          selectedOptions={selectedMile && selectedMile.value && [selectedMile]}
          onSelectItem={(selectedMile) =>
            setSelectedMile({ name: selectedMile[0].name, value: selectedMile[0].id })
          }
          onRemoveItem={() => {
            setSelectedMile(undefined);
            setWorkflowStep(undefined);
          }}
          maxSelection={1}
          id="documentMileDropdown"
        />
      }
      stepDropdown={
        <MultiselectDropdown
          avoidHighlightFirstOption
          options={steps && !!selectedMile ? steps : []}
          title={selectStepText}
          label={selectStepText}
          singleSelect
          isDisabled={!!selectedWorkflowData}
          selectedOptions={workflowStep && workflowStep.value && [workflowStep]}
          onSelectItem={(selectedStep) =>
            setWorkflowStep({ name: selectedStep[0].name, value: selectedStep[0].id })
          }
          onRemoveItem={() => setWorkflowStep(undefined)}
          maxSelection={1}
          id="documentStepDropdown"
        />
      }
    />
  );
};

export default DocumentUploadModal;
