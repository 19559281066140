export const API_TAGS = 'api/tags';
export const API_TAGS_$ID = 'api/tags/$id';
export const API_TAGS_POLL_$TIMESTAMP = 'api/tags/poll/$timestamp';
export const API_DOCUMENT_$DOCUMENT_ID_TAGS = 'api/documents/$documentId/tags';
export const API_DOCUMENT_$DOCUMENT_ID_TAGS_$TAGID = 'api/documents/$documentId/tags/$tagId';
export const API_TAGS_SEARCH = 'api/tags/search';

export const DISABLE_PAGINATIONS_QUERY = 'pagination_disabled';

export const $ID_PARAM = '$id';
export const $TAG_ID_PARAM = '$tagId';
export const $TIMESTAMP_PARAM = '$timestamp';
export const $DOCUMENT_ID_PARAM = '$documentId';
