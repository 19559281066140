import { FC, ReactNode } from 'react';
import { CSVLink } from 'react-csv';
import Button from '@/components/Button';
import { ButtonProps } from '@/components/Button/Button.view';

interface IHeaders {
  label: string;
  key: string;
}

export interface Props extends ButtonProps {
  data: string;
  headers?: IHeaders[];
  fileName?: string;
  children?: ReactNode;
}

export const CSVDownloadButton: FC<Props> = ({
  data,
  headers,
  children,
  fileName,
  ...buttonProps
}) => {
  return (
    <CSVLink data={data} target="_blank" headers={headers && headers} filename={fileName}>
      <Button {...buttonProps}>{children}</Button>
    </CSVLink>
  );
};
