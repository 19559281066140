/* eslint-dsiable @typescript-eslint/no-explicit-any */
import { axiosFormDataInstance } from '@/client_v2/rest/axios/axiosInstance';
import { PostDocument } from '@/client_v2/rest/types/PostDocument';
import { postComments } from '@/client_v2/rest/Comments/postComments';
import { API_DOCUMENTS } from '@/client_v2/rest/Documents/URLS';
import { DocumentPoll } from '@/client_v2/polls/Document.poll';
import { postTags } from '@/client_v2/rest/Tags/postTags';
import { WorkflowStepPoll } from '@/client_v2/polls/WorkflowStep.poll';
import { WorkflowPoll } from '@/client_v2/polls/Workflow.poll';

export const postDocument = async (
  { files, data }: PostDocument,
  newComments?: { comments: string[]; userId: number },
  tags?: string[],
) => {
  const formData = new FormData();
  const fileNames = files.map((file) => {
    formData.set(file.name, file);
    return file.name;
  });
  formData.set('files', JSON.stringify(fileNames));
  formData.set('data', JSON.stringify(data));
  const res = await axiosFormDataInstance.post<{ data: { id: number }[] }>(API_DOCUMENTS, formData);

  if (newComments && newComments.comments && newComments.comments.length) {
    await Promise.all(
      res.data.data.map(({ id }) =>
        postComments({
          entityId: id,
          entityType: 'documents',
          comments: newComments,
        }),
      ),
    );
  }

  if (tags && tags.length) {
    await Promise.all(
      res.data.data.map(({ id }) =>
        postTags({
          documentId: id,
          tags,
          clientId: data.sourceClientId,
        }),
      ),
    );
  }
  await DocumentPoll.trigger();

  if (data.workflowStepId) {
    await WorkflowStepPoll.trigger();
    await WorkflowPoll.trigger();
  }

  return res;
};
