import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CircleImage from '@/components/CircleImage';
import Icon from '@/components/Icon';
import KondorLogo from '@/components/KondorLogo';
import Link from '@/components/Link';
import HeaderLayout from '@/layouts/HeaderLayout';
import URLS from '@/resources/constants/URLS';
import { AppState } from '@/redux/store';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import { logout } from '@/redux/auth/auth.actions';
import { BASE_URL } from '@/client/urls';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import { useHeaderData } from '@/client_v2/hooks/useHeaderData';
import { setError } from '@/redux/message/message.action';
import { LogoTypes } from '@/components/KondorLogo/KondorLogo.view';
import Notification from '@/components/Notification';
import { DocumentDirection, DocumentStatuses } from '@/resources/constants/shared';
import { useDocumentData } from '@/client_v2/hooks/useDocumentData';

const useHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state: AppState) => state.user);
  const isAdmin =
    user.user?.authority == Authorities.Admin || user.user?.authority == Authorities.SuperAdmin;
  const dispatch = useDispatch();

  const { isLoading: authLoading } = useSelector((state: AppState) => state.auth);
  const selectedCompanyId = useSelector((state: AppState) => state.user.selectedCompanyId);

  const processesText = t('nav.processes');
  const dashboardText = t('nav.dashboard');
  const documentsText = t('nav.documents');
  const accountSettingsText = t('nav.accountSettings');

  const onClickLogo = useCallback(() => navigate(URLS.root.dashboard.toString()), [navigate]);
  const onClickDashboard = useCallback(() => navigate(URLS.root.dashboard.toString()), [navigate]);
  const onClickProcesses = useCallback(() => navigate(URLS.root.processes.toString()), [navigate]);
  const onClickDocuments = useCallback(() => navigate(URLS.root.documents.toString()), [navigate]);
  const onClickAccountSettings = useCallback(() => {
    navigate(URLS.root.accountSettings.toString());
  }, [navigate]);

  const onLogOut = async () => {
    dispatch(logout());
  };

  const monogram = (
    user &&
    user.user! &&
    user.user!.name &&
    user
      .user!.name.split('  ')
      .map((c) => c[0])
      .join('')
  )?.toUpperCase();

  const [, headerData] = useHeaderData({
    triggerOnce: selectedCompanyId || 0,
    renderOnUpdate: true,
    onError: (err) => dispatch(setError(err.toString())),
  });

  const [, data] = useDocumentData({
    triggerOnce: {
      businessAreaIds: [],
      sort: { field: 'companyName', direction: 'asc' },
      filters: { statuses: [DocumentStatuses.IS_WAITING_FOR_APPROVAL] },
      currentUserId: user?.user?.id,
      selectedCompanyId: null,
      selectedClientId: null,
    },
    renderOnUpdate: true,
    onError: (err) => dispatch(setError(err.toString())),
  });

  const [, downloadedDocData] = useDocumentData({
    triggerOnce: {
      businessAreaIds: [],
      sort: { field: 'companyName', direction: 'asc' },
      filters: { direction: [DocumentDirection.RECEIVED] },
      currentUserId: user?.user?.id,
      selectedCompanyId: null,
      selectedClientId: null,
    },
    renderOnUpdate: true,
    onError: (err) => dispatch(setError(err.toString())),
  });

  const unreads = data?.filter(
    (doc) => doc.approverId === user?.user?.id || user?.user?.authority == Authorities.SuperAdmin,
  ).length;
  const clientHasNotOpened = downloadedDocData?.filter(
    (document) =>
      user &&
      user.user &&
      document.downloadedBy !== user.user.id &&
      document.targetUsers.includes(user.user.id),
  ).length;

  return {
    isLoading: user.isLoading,
    userName: user.user! && user.user!.name,
    profileImg: user.user! && user.user.profile_image_url,
    monogram,
    processesText,
    dashboardText,
    documentsText,
    accountSettingsText,
    onClickLogo,
    onClickProcesses,
    onClickDocuments,
    onClickAccountSettings,
    onClickDashboard,
    onLogOut,
    authLoading,
    companyImage: headerData?.profileImageUrl,
    companyName: headerData?.name,
    isAdmin,
    unreads,
    clientHasNotOpened,
  };
};

const Header: FC = () => {
  const {
    profileImg,
    authLoading,
    monogram,
    userName,
    accountSettingsText,
    documentsText,
    processesText,
    dashboardText,
    onClickLogo,
    onLogOut,
    onClickProcesses,
    onClickDocuments,
    onClickDashboard,
    onClickAccountSettings,
    isLoading,
    companyImage,
    companyName,
    isAdmin,
    unreads,
    clientHasNotOpened,
  } = useHeader();
  return (
    <HeaderLayout
      appLogo={<KondorLogo onClick={onClickLogo} />}
      companyLogo={
        <KondorLogo
          onClick={onClickLogo}
          companyImage={companyImage}
          companyName={companyName}
          type={LogoTypes.COMPANY}
        />
      }
      userImage={
        <LoadingPlaceholder showSpinner={false} height="32px" width="32px" isLoading={isLoading}>
          {profileImg ? (
            <CircleImage image={BASE_URL + profileImg} />
          ) : (
            <CircleImage image="" smallText={monogram} />
          )}
        </LoadingPlaceholder>
      }
      userName={
        <LoadingPlaceholder showSpinner={false} height="32px" width="32px" isLoading={isLoading}>
          <Link defaultType="style23">{userName}</Link>
        </LoadingPlaceholder>
      }
      navLinks={
        <>
          <Link onClick={onClickDashboard} defaultType="style24" onHoverType="style24">
            {dashboardText}
          </Link>
          <Link
            onClick={onClickProcesses}
            defaultType="style24"
            onHoverType="style24"
            id="processLink"
          >
            {processesText}
          </Link>
          <div className="d-flex flex-row-reverse">
            {isAdmin && (!!unreads || !!clientHasNotOpened) && (
              <Notification unread={unreads + clientHasNotOpened} />
            )}
            {!isAdmin && !!clientHasNotOpened && <Notification unread={clientHasNotOpened} />}
            <Link
              onClick={onClickDocuments}
              defaultType="style24"
              onHoverType="style24"
              id="documentsLink"
            >
              {documentsText}
            </Link>
          </div>
          {isAdmin && (
            <Link
              onClick={onClickAccountSettings}
              defaultType="style24"
              onHoverType="style24"
              id="accountSettingsLink"
            >
              {accountSettingsText}
            </Link>
          )}
        </>
      }
      logout={
        <LoadingPlaceholder showSpinner={true} width="25px" height="25px" isLoading={authLoading}>
          <Icon onClick={onLogOut} type="logOut" colorStyle="white" />
        </LoadingPlaceholder>
      }
    />
  );
};

export default Header;
