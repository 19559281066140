import { FC } from 'react';
import Badge from '@/components/Badge';
import Text from '@/components/Text';

export interface Props {
  files: File[];
  noDocumentText: string;
  onBadgeClick: (files: File[]) => void;
}

const FileBadges: FC<Props> = ({ files, noDocumentText, onBadgeClick }) => (
  <>
    {(files &&
      files.length &&
      files.map((file: File, index: number) => (
        <div className="mr-2 mb-2" key={index} id={file.name}>
          <Badge
            size="small"
            content={file.name}
            colorStyle="lightBlue"
            selfClosing
            onClick={() => onBadgeClick(files.filter((item) => item.name !== file.name))}
          />
        </div>
      ))) || <Text type="style7">{noDocumentText}</Text>}
  </>
);

export default FileBadges;
