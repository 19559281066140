import * as Endpoints from '@/client/endpoints';
import * as Interfaces from '@/client/workflowManagementInterfaces';
import axiosInstance from '@/client_v2/rest/axios/axiosInstance';

//Workflow management calls
export class WorkflowManagement {
  workflowCountSteps = async (
    workflowId: number,
  ): Promise<Interfaces.IWorkflowCountStepStatusResponse> => {
    const response = await axiosInstance.put(
      Endpoints.WORKFLOW_COUNT_STEPS_STATUS.replace('{workflow}', workflowId.toString()),
    );
    return response.data;
  };

  workflowCountAllSteps = async (): Promise<Interfaces.IWorkflowCountStepStatusResponse> => {
    const response = await axiosInstance.get(Endpoints.WORKFLOW_COUNT_STEPS);
    return response.data;
  };

  searchfetchWorkflows = async (
    searchbody: Interfaces.ISearchWorkflowRequest,
  ): Promise<Interfaces.ISearchWorkflowResponse> => {
    const response = await axiosInstance.post(Endpoints.SEARCH_WORKFLOW, searchbody);
    return response.data;
  };

  createWorkflow = async (
    worfklow: Interfaces.ICreateWorkflowRequest,
  ): Promise<Interfaces.ICreateWorkflowResponse> => {
    const response = await axiosInstance.post(Endpoints.CREATE_WORKFLOW, worfklow);
    return response.data;
  };

  fetchWorkflow = async (workflowId: number): Promise<Interfaces.IFetchWorkflowResponse> => {
    const response = await axiosInstance.get(
      Endpoints.FETCH_WORKFLOW.replace('{workflow}', workflowId.toString()),
    );
    return response.data;
  };

  updatePutWorkflow = async (
    workflowId: number,
    workflow: Interfaces.IUpdateWorkflowRequest,
  ): Promise<Interfaces.IUpdateWorkflowResponse> => {
    const response = await axiosInstance.put(
      Endpoints.UPDATE_WORKFLOW.replace('{workflow}', workflowId.toString()),
      workflow,
    );
    return response.data;
  };
  updatePatchWorkflow = async (
    workflowId: number,
    workflow: Interfaces.IUpdateWorkflowRequest,
  ): Promise<Interfaces.IUpdateWorkflowResponse> => {
    const response = await axiosInstance.patch(
      Endpoints.UPDATE_WORKFLOW.replace('{workflow}', workflowId.toString()),
      workflow,
    );
    return response.data;
  };

  deleteWorkflow = async (workflowId: number): Promise<Interfaces.IUpdateWorkflowResponse> => {
    const response = await axiosInstance.delete(
      Endpoints.DELETE_WORKFLOW.replace('{workflow}', workflowId.toString()),
    );
    return response.data;
  };

  restoreWorkflow = async (workflowId: number): Promise<Interfaces.IRestoreWorkflowResponse> => {
    const response = await axiosInstance.post(
      Endpoints.RESTORE_WORKFLOW.replace('{workflow}', workflowId.toString()),
    );
    return response.data;
  };

  searchWorkflowMiles = async (
    workflowId: number,
  ): Promise<Interfaces.IRestoreWorkflowResponse> => {
    const response = await axiosInstance.post(
      Endpoints.WORKFLOW_MILES_SEARCH.replace('{workflow}', workflowId.toString()),
    );
    return response.data;
  };

  createWorkflowMile = async (
    workflowId: number,
    mile: Interfaces.ICreateWorkflowMileRequest,
  ): Promise<Interfaces.ICreateWorkflowMileResponse> => {
    const response = await axiosInstance.post(
      Endpoints.CREATE_WORKFLOW_MILE.replace('{workflow}', workflowId.toString()),
      mile,
    );
    return response.data;
  };

  //ilyen kérdőjeleseknél ok ha csak így replacelem?
  updatePutWorkflowMile = async (
    workflowId: number,
    mileId: number,
    mile: Interfaces.IUpdateWorkflowMileRequest,
  ): Promise<Interfaces.IUpdateWorkflowMileResponse> => {
    const response = await axiosInstance.put(
      Endpoints.UPDATE_WORKFLOW_MILE.replace('{workflow}', workflowId.toString()).replace(
        '{mile?}',
        mileId.toString(),
      ),
      mile,
    );
    return response.data;
  };

  updatePatchWorkflowMile = async (
    workflowId: number,
    mileId: number,
    mile: Interfaces.IUpdateWorkflowMileRequest,
  ): Promise<Interfaces.IUpdateWorkflowMileResponse> => {
    const response = await axiosInstance.patch(
      Endpoints.UPDATE_WORKFLOW_MILE.replace('{workflow}', workflowId.toString()).replace(
        '{mile?}',
        mileId.toString(),
      ),
      mile,
    );
    return response.data;
  };

  deleteWorkflowMile = async (
    workflowId: number,
    mileId: number,
  ): Promise<Interfaces.IDeleteWorkflowMileResponse> => {
    const response = await axiosInstance.delete(
      Endpoints.DELETE_WORKFLOW_MILE.replace('{workflow}', workflowId.toString()).replace(
        '{mile?}',
        mileId.toString(),
      ),
    );
    return response.data;
  };

  searchWorkflowStep = async (mileId: number): Promise<Interfaces.ISearchWorkflowResponseStep> => {
    const response = await axiosInstance.post(
      Endpoints.SEARCH_WORKFLOW_STEPS.replace('{mile}', mileId.toString()),
    );
    return response.data;
  };

  fetchWorkflowStep = async (mileId: number): Promise<Interfaces.ISearchWorkflowResponseStep> => {
    const response = await axiosInstance.get(
      Endpoints.SEARCH_WORKFLOW_STEPS.replace('{mile}', mileId.toString()),
    );
    return response.data;
  };

  createWorkflowStep = async (
    mileId: number,
    step: Interfaces.ICreateWorkflowStepRequest,
  ): Promise<Interfaces.ICreateWorkflowStepResponse> => {
    const response = await axiosInstance.post(
      Endpoints.CREATE_WORKFLOW_STEP.replace('{mile}', mileId.toString()),
      step,
    );
    return response.data;
  };

  updatePutWorkflowStep = async (
    mileId: number,
    stepId: number,
    step: Interfaces.IUpdateWorkflowStepRequest,
  ): Promise<Interfaces.IUpdateWorkflowStepResponse> => {
    const response = await axiosInstance.put(
      Endpoints.UPDATE_WORKFLOW_STEP.replace('{mile}', mileId.toString()).replace(
        '{step?}',
        stepId.toString(),
      ),
      step,
    );
    return response.data;
  };

  updatePatchWorkflowStep = async (
    mileId: number,
    stepId: number,
    step: Interfaces.IUpdateWorkflowStepRequest,
  ): Promise<Interfaces.IUpdateWorkflowStepResponse> => {
    const response = await axiosInstance.patch(
      Endpoints.UPDATE_WORKFLOW_STEP.replace('{mile}', mileId.toString()).replace(
        '{step?}',
        stepId.toString(),
      ),
      step,
    );
    return response.data;
  };

  deletePatchWorkflowStep = async (
    mileId: number,
    stepId: number,
  ): Promise<Interfaces.IUpdateWorkflowStepResponse> => {
    const response = await axiosInstance.delete(
      Endpoints.DELETE_WORKFLOW_STEP.replace('{mile}', mileId.toString()).replace(
        '{step?}',
        stepId.toString(),
      ),
    );
    return response.data;
  };

  fetchWorkflowStepComments = async (
    stepId: number,
  ): Promise<Interfaces.IFetchWorkflowStepCommentsResponse> => {
    const response = await axiosInstance.get(
      Endpoints.SEARCH_WORKFLOW_STEP_COMMENTS.replace('{step}', stepId.toString()),
    );
    return response.data;
  };

  createWorkflowStepComment = async (
    stepId: number,
    comment: Interfaces.ICreateWorkflowStepCommentRequest,
  ): Promise<Interfaces.ICreateWorkflowStepCommentResponse> => {
    const response = await axiosInstance.post(
      Endpoints.CREATE_WORKFLOW_STEP_COMMENT.replace('{step}', stepId.toString()),
      comment,
    );
    return response.data;
  };

  updatePutWorkflowStepComment = async (
    stepId: number,
    commentId: number,
    comment: Interfaces.IUpdateWorkflowStepCommentRequest,
  ): Promise<Interfaces.IUpdateWorkflowStepCommentResponse> => {
    const response = await axiosInstance.put(
      Endpoints.UPDATE_WORKFLOW_STEP_COMMENT.replace('{step}', stepId.toString()).replace(
        '{comment}',
        commentId.toString(),
      ),
      comment,
    );
    return response.data;
  };

  updatePatchWorkflowStepComment = async (
    stepId: number,
    commentId: number,
    comment: Interfaces.IUpdateWorkflowStepCommentRequest,
  ): Promise<Interfaces.IUpdateWorkflowStepCommentResponse> => {
    const response = await axiosInstance.patch(
      Endpoints.UPDATE_WORKFLOW_STEP_COMMENT.replace('{step}', stepId.toString()).replace(
        '{comment}',
        commentId.toString(),
      ),
      comment,
    );
    return response.data;
  };

  deletePatchWorkflowStepComment = async (
    stepId: number,
    commentId: number,
  ): Promise<Interfaces.IDeleteWorkflowStepCommentResponse> => {
    const response = await axiosInstance.patch(
      Endpoints.DELETE_WORKFLOW_STEP_COMMENT.replace('{step}', stepId.toString()).replace(
        '{comment}',
        commentId.toString(),
      ),
    );
    return response.data;
  };

  //hiányzik body ebből a postból
  searchStepUsers = async (stepId: number): Promise<Interfaces.ISearchStepUsersResponse> => {
    const response = await axiosInstance.post(
      Endpoints.SEARCH_STEP_USERS.replace('{step}', stepId.toString()),
    );
    return response.data;
  };

  attachStepWithUser = async (
    stepId: number,
    resources: Interfaces.IAttachStepWithUserRequest,
  ): Promise<Interfaces.IAttachStepWithUsersResponse> => {
    const response = await axiosInstance.post(
      Endpoints.ATTACH_STEP_WITH_USER.replace('{step}', stepId.toString()),
      resources,
    );
    return response.data;
  };

  fetchStepUsers = async (stepId: number): Promise<Interfaces.IFetchStepUsersResponse> => {
    const response = await axiosInstance.get(
      Endpoints.FETCH_STEP_USERS.replace('{step}', stepId.toString()),
    );
    return response.data;
  };
}
