import { createDataSync } from '@/client_v2/util/createDataSync';
import { CommentRepo } from '@/client_v2/db/repositories/Comment.repo';
import RestClient from '@/client_v2/rest';

const DELAY = 1000 * 20;

export const CommentPoll = createDataSync({
  id: 'comment',
  delay: DELAY,
})
  .init(async () => {
    const res = await RestClient.Comments.get();
    await Promise.all(res.data.data.map(async (doc) => await CommentRepo.save(doc)));
  })
  .poll(async (pollTime) => {
    const res = await RestClient.Comments.poll(pollTime());
    if (res.data.poll.length) {
      return {
        update: true,
        data: res.data.poll,
      };
    }
    return {
      update: false,
    };
  })
  .update(async (data) => {
    if (!data) return;
    const deleted = data.filter(({ operation }) => operation === 'deleted').map(({ id }) => id);
    await Promise.all(deleted.map(CommentRepo.delete));

    const createdOrUpdated = data
      .filter(({ operation }) => operation !== 'deleted')
      .map(({ id }) => id);
    const res = await RestClient.Comments.getByIds(createdOrUpdated);
    await Promise.all(res.data.data.map(CommentRepo.save));
  });
