import { Dispatch } from 'redux';
import client from '@/client';
import {
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  SET_USERS_SCROLLBAR_POSITION,
} from '@/redux/users/users.types';

export const getUsers = () => async (dispatch: Dispatch) => {
  dispatch({
    type: GET_USERS_REQUEST,
  });

  try {
    const users = await client.userManagement.fetchUsers();

    dispatch({
      type: GET_USERS_SUCCESS,
      payload: users,
    });
  } catch (error) {
    dispatch({
      type: GET_USERS_FAILURE,
      error,
    });
  }
};

export const setUsersScrollbarPosition =
  (position: number | undefined) => async (dispatch: Dispatch) =>
    dispatch({
      type: SET_USERS_SCROLLBAR_POSITION,
      payload: position,
    });
