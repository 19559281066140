import { FC, ReactNode, useMemo } from 'react';
import '@/components/Table/style.scss';
import { ComponentHook } from '@/types/ComponentHook';
import { Table as View } from '@/components/Table/Table.view';
import { GetPropsType } from '@/types/GetPropType';
import { useDinCSS } from '@/hooks/useDinCSS';

interface IData {
  header?: boolean;
  span?: number;
  view?: ReactNode;
}

interface Props {
  column: number;
  data: (
    | {
        header: true;
        span?: number;
        view?: ReactNode;
      }
    | {
        header?: false;
        view?: ReactNode;
      }
  )[];
  rightArea?: ReactNode;
  rightAreaSize?: string;
  type?: TableTypes;
  className?: string;
}

export type TableTypes = 'striped' | 'flat';

export const useTable: ComponentHook<Props, typeof View> = ({
  column,
  data,
  rightArea,
  rightAreaSize,
  type = 'striped',
  className,
}) => {
  const [dynColumnsClass, renderDynColumnsClass] = useDinCSS();
  const [dynAreaSizeClass, renderDynAreaSizeClass] = useDinCSS();

  const header = useMemo(() => {
    const headers = data.filter((item) => item.header);
    if (!headers.length) return;
    return headers.reduce((res, { view, span }: IData) => {
      res!.push({
        header: true,
        column: res!.reduce((idx, { columnSpan = 1 }) => idx + columnSpan, 1),
        row: 1,
        children: view,
        columnSpan: span,
      });
      return res;
    }, [] as GetPropsType<typeof View>['header']);
  }, [data]);

  const body = useMemo(() => {
    const addRow = header ? 1 : 0;
    return (
      data &&
      data
        .filter((item) => !item.header)
        .reduce((res, { view }, idx) => {
          res &&
            res.push({
              column: (idx % column) + 1,
              row: addRow + ((idx / column) | 0) + 1,
              children: view,
            });
          return res;
        }, [] as GetPropsType<typeof View>['body'])
    );
  }, [header, data, column]);

  useMemo(() => {
    renderDynColumnsClass({
      gridTemplateColumns: `[first-col] repeat(${column}, minmax(max-content, 1fr)) [last-col] ${
        rightArea ? rightAreaSize : ''
      }`,
    });
  }, [renderDynColumnsClass, rightArea, rightAreaSize, column]);

  useMemo(() => {
    renderDynAreaSizeClass({
      gridColumnEnd: `span 1`,
    });
  }, [renderDynAreaSizeClass]);

  return {
    header,
    body,
    dynColumnsClass,
    rightArea,
    dynAreaSizeClass,
    type,
    className,
  };
};

export const Table: FC<Props> = (props) => {
  const computedProps = useTable(props);
  return <View {...computedProps} />;
};
