/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo } from 'react';

function uuid() {
  return 'xxxxxxxxx'.replace(/x/g, () => ((Math.random() * 16) | 0).toString(16));
}

const kebabCase = (str: string) => str.replace(/[A-Z]/g, (match) => '-' + match.toLowerCase());

function createClassName() {
  return `scope-${uuid()}`;
}

function createStyleTag() {
  const styleEl = document.createElement('style');
  styleEl.type = 'text/css';
  document.head.insertAdjacentElement('beforeend', styleEl);
  return styleEl;
}

export function useDinCSS(selector?: (scope: string) => string) {
  const className = useMemo(createClassName, []);
  const styleEl = useMemo(createStyleTag, []);
  const renderClass = useCallback(
    (style: Partial<CSSStyleDeclaration> | { [key: string]: string | boolean }) => {
      const classSelector = `.${className}`;
      styleEl.innerHTML =
        (selector ? selector(classSelector) : classSelector) +
        `{` +
        Object.keys(style)
          .filter((prop: any) => typeof (style as any)[prop] === 'string')
          .map((prop: any) => `${kebabCase(prop)}: ${(style as any)[prop]};`)
          .join(' ') +
        '}';
    },
    [styleEl, className],
  );
  useEffect(() => () => styleEl.remove(), [styleEl]);

  return [className, renderClass] as const;
}
