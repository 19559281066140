import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SVGLogo from '@/resources/SVG/SVGLogo';
import Input from '@/screens/LoginScreen/components/Input';
import { validateEmail } from '@/resources/utils';
import { AppState } from '@/redux/store';
import { sendForgotPassword } from '@/redux/auth/auth.actions';
import URLS from '@/resources/constants/URLS';

const ForgottenPasswordScreen: React.FC = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const fieldValidation: boolean = validateEmail(email);
  const { isLoading, errorMessage } = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !errorMessage && success) {
      navigate(URLS.root.login.toString());
    }
  }, [success, isLoading, errorMessage, navigate]);

  const onEmailSend = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (fieldValidation) {
      dispatch(sendForgotPassword(email));
      setSuccess(true);
    }
  };
  return (
    <div className="container-fluid loginContainer mr-0">
      <div className="row">
        <div className="col-sm d-flex flex-column justify-content-center align-items-center loginPanelLeft">
          <h2>{t('forgottenPassword.forgottenPasswordHeading')}</h2>
          <small className="text-light text-center w-75">
            {t('forgottenPassword.forgottenPasswordSubheading')}
          </small>
          <form
            className="col-5 d-flex flex-column align-items-stretch my-4"
            onSubmit={onEmailSend}
          >
            <Input
              label={t('login.email')}
              id="forgottenPasswordEmail"
              type="email"
              aria-describedby="emailHelp"
              error={!!errorMessage}
              placeholder={t('common.emailSample')}
              name="email"
              value={email}
              onChange={(e: React.FormEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
            />
            {/* TODO the button component looks terrible on this screen */}
            <button type="submit" className="btn btn-primary mt-4" disabled={!fieldValidation}>
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                t('forgottenPassword.submit')
              )}
            </button>
          </form>
        </div>
        <div className="col-sm loginPanelRight d-flex justify-content-between align-items-center p-0">
          <div className="hidden-md-down" id="triangleLogin" />
          <SVGLogo className="col-7" />
          <div className="col-1" />
        </div>
      </div>
    </div>
  );
};

export default ForgottenPasswordScreen;
