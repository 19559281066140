import { UserPoll } from '@/client_v2/polls/User.poll';
import { CompanyPoll } from '@/client_v2/polls/Company.poll';
import { CommentPoll } from '@/client_v2/polls/Comment.poll';
import { ClientPoll } from '@/client_v2/polls/Client.poll';
import { CompanyRepo } from '@/client_v2/db/repositories/Company.repo';
import { createDataHook } from '@/client_v2/hooks/utils/createDataHook';

export const useHeaderData = createDataHook({
  polls: [UserPoll, CommentPoll, CompanyPoll, ClientPoll],
  dataFactory: async (id: number) => await CompanyRepo.query('get-company-modal-data', { id }),
});
