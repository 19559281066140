import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { FC, ReactElement } from 'react';
import { AppState } from '@/redux/store';
import URLS from '@/resources/constants/URLS';

interface PrivateRouteProps {
  component: ReactElement;
}

/**
 * If the access token is set, it behaves exactly like the Route, but it redirects to the provided path otherwise.
 * @component - Valid react component to be shown if authenticated
 */
const PrivateRoute: FC<PrivateRouteProps> = ({ component }) => {
  const { isAuthenticated } = useSelector((state: AppState) => state.auth);
  return isAuthenticated ? component : <Navigate to={URLS.root.login.toString()} />;
};

export default PrivateRoute;
