import '@/layouts/ProcessesHeaderLayout/style.scss';
import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { cn, CreateScopeCSS } from '@/components/utils';
import { AppState } from '@/redux/store';

const scope = CreateScopeCSS('layouts-processes-layout');

interface Props {
  headerText: ReactNode;
  newProcessButton: ReactNode;
  sortButton: ReactNode;
  filterButton: ReactNode;
  searchField: ReactNode;
}

export const ProcessesHeaderLayout: FC<Props> = ({
  headerText,
  newProcessButton,
  sortButton,
  filterButton,
  searchField,
}) => {
  const expandDocument = useSelector((state: AppState) => state.documents.expand);

  return (
    <div className={cn('row', 'pt-2 text-nowrap', expandDocument ? 'pb-5 pt-4' : 'pb-5')}>
      <div className="col-12">
        <div className={cn(expandDocument ? 'd-none' : 'row')}>
          <div className="col-lg-12 mt-5 ml-4 d-flex justify-content-lg-start justify-content-center">
            {headerText}
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-2 col-md-12 mt-4 ml-lg-4 ml-md-0 d-flex justify-content-center text-nowrap">
            {newProcessButton}
          </div>
          <div className="col-lg-6 mt-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-9 text-nowrap">{searchField}</div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={cn(
              scope,
              ' col-lg-2 mt-4 col-6 d-flex justify-content-lg-end justify-content-center',
            )}
          >
            {sortButton}
          </div>
          <div
            className={cn(
              scope,
              'col-lg-1 ml-lg-3 ml-0 mt-4 col-6 d-flex justify-content-center align-items-center',
            )}
          >
            {filterButton}
          </div>
        </div>
      </div>
    </div>
  );
};
