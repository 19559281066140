import { Dispatch } from 'redux';
import {
  UPLOAD_NEW_CLIENT_REQUEST,
  UPLOAD_NEW_CLIENT_SUCCESS,
  UPLOAD_NEW_CLIENT_FAILURE,
  SET_COLLAPSE_CLIENT_ID,
  SET_CLIENT_SCROLLBAR_POSITION,
} from '@/redux/clients/clients.types';
import { postClient } from '@/client_v2/rest/Clients/postClient';
import { PostClient } from '@/client_v2/rest/types/PostClient';

export const uploadClient = (client: PostClient) => async (dispatch: Dispatch) => {
  dispatch({
    type: UPLOAD_NEW_CLIENT_REQUEST,
  });

  try {
    const res = await postClient(client);
    dispatch({
      type: UPLOAD_NEW_CLIENT_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: UPLOAD_NEW_CLIENT_FAILURE,
      error,
    });
  }
};

export const setCollapseClientId = (id: number | null) => async (dispatch: Dispatch) =>
  dispatch({
    type: SET_COLLAPSE_CLIENT_ID,
    payload: id,
  });

export const setClientScrollbarPosition =
  (position: number | undefined) => async (dispatch: Dispatch) =>
    dispatch({
      type: SET_CLIENT_SCROLLBAR_POSITION,
      payload: position,
    });
