import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { API_DOCUMENTS_$ID, $ID_PARAM } from '@/client_v2/rest/Documents/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';
import { DocumentRes } from '@/client_v2/rest/types/DocumentsRes';
import { DocumentPoll } from '@/client_v2/polls/Document.poll';

export const deleteDocument = async (id: number) => {
  const res = await axiosInstance.delete<DocumentRes>(
    createUrl(API_DOCUMENTS_$ID, {
      params: {
        [$ID_PARAM]: id.toString(),
      },
    }),
  );
  await DocumentPoll.trigger();
  return res;
};
