import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SVGLogo from '@/resources/SVG/SVGLogo';
import * as actions from '@/redux/auth/auth.actions';
import { IUserCredentials } from '@/redux/auth/auth.interfaces';
import { AppState } from '@/redux/store';
import Input from '@/screens/LoginScreen/components/Input';
import ErrorFeedback from '@/components/ErrorFeedback';
import Button from '@/screens/LoginScreen/components/Button';
import { validateEmail } from '@/resources/utils';
import URLS from '@/resources/constants/URLS';
import Icon from '@/components/Icon';

const LoginScreen: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isLoading, errorMessage, error } = useSelector((state: AppState) => state.auth);
  const [isPassword, setIsPassword] = useState<boolean>(true);
  const [isModified, setIsModified] = useState<boolean>(true);
  const [loginData, setLoginData] = useState<IUserCredentials>({
    email: '',
    password: '',
  });

  const fieldValidation = validateEmail(loginData.email) && loginData.password;
  const updateFields = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
    setIsModified(true);
  };

  const onLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(actions.loginUser(loginData));
  };

  useEffect(() => {
    setIsModified(false);
  }, [error]);

  return (
    <div className="container-fluid loginContainer mr-0">
      <div className="row">
        <div className="col-sm d-flex flex-column justify-content-center align-items-center loginPanelLeft">
          <h2>{t('login.loginHeading')}</h2>
          <small className="text-light text-center">{t('login.loginSubheading')}</small>
          <form className="col-5 d-flex flex-column align-items-stretch my-4" onSubmit={onLogin}>
            <Input
              label={t('login.email')}
              id="loginEmailInput"
              type="email"
              aria-describedby="emailHelp"
              error={error && error.emailOrPassword && !isModified}
              placeholder={t('login.emailSample')}
              name="email"
              onChange={updateFields}
            />
            <div>
              <Input
                label={t('login.password')}
                type={isPassword ? 'password' : 'text'}
                placeholder={t('login.passwordSample')}
                name="password"
                id="loginPasswordInput"
                error={error && error.emailOrPassword && !isModified}
                onChange={updateFields}
              />
              <div className={`${!isModified ? 'invisible' : ''} iconContainer`}>
                <Icon
                  type={isPassword ? 'eye' : 'eyeHide'}
                  colorStyle="primary"
                  onClick={() => setIsPassword(!isPassword)}
                />
              </div>
            </div>
            {!isModified && (error.emailOrPassword || error.unexpectedError) && (
              <ErrorFeedback errorMessage={errorMessage} />
            )}
            <Button
              type="submit"
              className="btn btn-primary mt-4"
              disabled={!fieldValidation || isLoading}
            >
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {!isLoading && t('login.login')}
            </Button>
          </form>
          <p>
            <small className="text-light">{t('login.passwordReminderText')}</small>
            <a href={URLS.root.forgottenPassword.toString()} className="mx-1 text-blu">
              <small>{t('login.passwordReminderLink')}</small>
            </a>
          </p>
        </div>
        <div className="col-sm loginPanelRight d-flex justify-content-between align-items-center p-0">
          <div className="hidden-md-down" id="triangleLogin" />
          <SVGLogo className="col-7" />
          <div className="col-1" />
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
