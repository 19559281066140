import {
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  GET_USERS_REQUEST,
  SET_USERS_SCROLLBAR_POSITION,
} from './users.types';
import { IUserState, UsersActionTypes } from './users.interfaces';

const defaultUserState: IUserState = {
  isLoading: true,
  errorMessage: null,
  users: null,
  scrollbarPosition: undefined,
};

export default (state = defaultUserState, action: UsersActionTypes): IUserState => {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        users: action.payload,
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error.message,
      };
    case SET_USERS_SCROLLBAR_POSITION:
      return {
        ...state,
        scrollbarPosition: action.payload,
      };
    default:
      return state;
  }
};
