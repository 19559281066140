import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { $ID_PARAM, API_DOCUMENTS_APPROVE } from '@/client_v2/rest/Documents/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';
import { DocumentRes } from '@/client_v2/rest/types/DocumentsRes';
import { DocumentPoll } from '@/client_v2/polls/Document.poll';

export const approveDocument = async (id: number, status: boolean, token?: string) => {
  const res = await axiosInstance.post<DocumentRes>(
    createUrl(API_DOCUMENTS_APPROVE, {
      params: {
        [$ID_PARAM]: id.toString(),
      },
    }),
    { token, status },
  );
  await DocumentPoll.trigger();
  return res;
};
