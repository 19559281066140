import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDashboardData } from '@/client_v2/hooks/useDashboardData';
import Card from '@/components/Card';
import Text from '@/components/Text';
import DashboardTableLayout from '@/layouts/DashboardTableLayout';
import { Table } from '@/components/Table';
import Badge from '@/components/Badge';
import MilestoneBar from '@/components/MilestoneBar';
import {
  IGetDasboardData,
  IGetDashboardDataWorkflow,
} from '@/client_v2/db/repositories/BusinessArea.repo';
import { ActivityTypes, WorkflowStatuses } from '@/resources/constants/shared';
import CheckBox from '@/components/CheckBox';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import URLS from '@/resources/constants/URLS';
import { AppState } from '@/redux/store';
import { getFilteredBusinessAreas } from '@/resources/utils';
import '@/styles/screens/DashboardScreen/index.scss';
import { setError } from '@/redux/message/message.action';
import { useBusinessAreaData } from '@/client_v2/hooks/useBusinesAreaData';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import { useClientsData } from '@/client_v2/hooks/useClientsData';
import * as filterActions from '@/redux/sortAndFilters/sortAndFilters.actions';
import { ISidebarFilter } from '@/redux/sortAndFilters/sortAndFilters.interfaces';
import Footer from '@/screens/DashboardScreen/Content/footer';
import { resetDocumentFilter } from '@/redux/sortAndFilters/sortAndFilters.actions';
import RestClient from '@/client_v2/rest';

export const DashboardContent: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [businessAreas, setBusinessAreas] = useState<IGetDasboardData[]>([]);

  const filters = useSelector((state: AppState) => state.sortAndFilters.sidebarFilters);
  const selectedCompanyId = useSelector((state: AppState) => state.user.selectedCompanyId);
  const selectedClientId = useSelector((state: AppState) => state.user.selectedClientId);

  const getSelectedBusinessAreas = useCallback(() => {
    return getFilteredBusinessAreas(filters);
  }, [filters]);

  const [isLoading, data, trigger] = useDashboardData({
    renderOnUpdate: false,
    triggerOnce: {
      businessAreaIds: getSelectedBusinessAreas(),
      selectedCompanyId,
      ...(selectedClientId
        ? {
            selectedClientId: selectedClientId,
          }
        : {}),
    },
    onError: (err) => dispatch(setError(err.toString())),
  });

  const [isFilterLoading] = useBusinessAreaData({
    triggerOnce: true,
    renderOnUpdate: false,
    onError: (err) => dispatch(setError(err.toString())),
  });

  const [clientsLoading, clients] = useClientsData({
    renderOnUpdate: true,
    triggerOnce: true,
    onError: (err) => dispatch(setError(err.toString())),
  });

  useEffect(() => {
    trigger({ businessAreaIds: getSelectedBusinessAreas(), selectedCompanyId, selectedClientId });
  }, [trigger, getSelectedBusinessAreas, selectedCompanyId, selectedClientId]);

  const user = useSelector((state: AppState) => state.user.user);

  useEffect(() => {
    const businessAreas: IGetDasboardData[] = [];
    user &&
      user.authority !== Authorities.SuperAdmin &&
      !clientsLoading &&
      data?.map((filter) => {
        const actualClient = clients.clients.find((client) => user?.clients?.includes(client.id));
        const checkBusinessAreaId = actualClient?.activeBusinessAreas.includes(
          filter.businessAreaId,
        );
        if (checkBusinessAreaId) {
          businessAreas.push(filter);
        }
      });

    user && user.authority !== Authorities.SuperAdmin
      ? setBusinessAreas(businessAreas)
      : setBusinessAreas(data);
  }, [clients, clientsLoading, data, user]);

  const headers = ['nameOfProcess', 'state', 'milestones', 'lastEvents'];

  const noActiveProcessError = t('dashboardScreen.noActiveProcess');
  const noActiveResultError = t('dashboardScreen.noActiveResult');

  useEffect(() => {
    dispatch(resetDocumentFilter());
  }, [dispatch]);

  const columnKeys: { [key: string]: number } = {
    name: 1,
    workflowStatus: 2,
    statistic: 3,
    activityType: 4,
    activityDocument: 5,
    activityComment: 5,
    activityModifyUser: 5,
    modifyDate: 6,
  };

  const getColumn = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    subValue: { [key: string]: string | any },
    value: IGetDashboardDataWorkflow,
  ) => {
    if (subValue[0] === 'activityType' && subValue[1]) {
      return (
        <Text type="style17" capitalization="uppercase">
          {t('dashboardScreen.lastEvent.' + subValue[1])}
        </Text>
      );
    } else if (subValue[0] === 'workflowStatus') {
      return (
        <div className="workflowStatus">
          <Badge
            content={t('workflowStatus.' + subValue[1])}
            colorStyle="lightBlue"
            size="small"
            uppercase
            cursor="none"
          />
        </div>
      );
    } else if (subValue[0] == 'statistic' && subValue[1]) {
      const statuses: { [status: string]: number } = {};
      subValue[1].statuses.map((item: { [status: string]: number }) => {
        statuses[item.status] = (item.count / subValue[1].sum) * 100;
      });
      return (
        <MilestoneBar
          donePercent={statuses[WorkflowStatuses.DONE]}
          expiredPercent={statuses[WorkflowStatuses.OVERDUE]}
          nearbyPercent={statuses[WorkflowStatuses.DUE]}
        />
      );
    } else if (
      subValue[0] === 'activityDocument' &&
      subValue[1].name &&
      value.activityType === ActivityTypes.DOCUMENT_ADDED
    ) {
      return subValue[1].documentId ? (
        <span
          data-toggle="tooltip"
          data-placement="top"
          title={t('common.download')}
          key={`document-id-${subValue[1].id}`}
          onClick={() => {
            subValue[1].documentId && RestClient.Documents.download([subValue[1].documentId]);
          }}
        >
          <div className="pl-5 ml-2">
            <Badge content={subValue[1].name} colorStyle="lightBlue" size="small" />
          </div>
        </span>
      ) : (
        <div className="pl-5 ml-2">
          <Badge
            content={<a>{subValue[1].name}</a>}
            colorStyle="lightBlue"
            size="small"
            cursor="none"
            key={`comment-id-${subValue[1].id}`}
          />
        </div>
      );
    } else if (
      subValue[0] === 'activityModifyUser' &&
      subValue[1].name &&
      (value.activityType === ActivityTypes.SET_DONE ||
        value.activityType === ActivityTypes.UNSET_DONE ||
        value.activityType === ActivityTypes.DOCUMENT_REMOVED ||
        value.activityType === ActivityTypes.COMMENT_REMOVED)
    ) {
      return <Badge content={subValue[1].name} colorStyle="lightBlue" size="small" cursor="none" />;
    } else if (
      subValue[0] === 'activityComment' &&
      subValue[1].comment &&
      value.activityType === ActivityTypes.ADD_COMMENT
    ) {
      return (
        <Tooltip bubbleContent={subValue[1].comment} place="top">
          <Badge content={subValue[1].comment} colorStyle="lightBlue" size="small" />
        </Tooltip>
      );
    } else if (subValue[0] === 'modifyDate') {
      return <Text type="style17">{subValue[1]}</Text>;
    } else if (typeof subValue[1] == 'string') {
      return <Text type="style19">{subValue[1]}</Text>;
    }
    return null;
  };

  const getRow = (value: IGetDashboardDataWorkflow, key: number) => {
    return Object.entries(value).map((subValue, subKey: number) => {
      return {
        row: key + 2,
        column:
          typeof subValue[0] === 'string' && columnKeys[subValue[0]]
            ? columnKeys[subValue[0]]
            : subKey + 1,
        children: getColumn(subValue, value),
        isHidden: subValue[0] === 'id',
      };
    });
  };
  const getDescriptionRow = (value: IGetDashboardDataWorkflow[]) => {
    return {
      row: value.length + 2,
      column: 3,
      children: (
        <div className="d-flex justify-content-center mt-2">
          <div className="d-flex align-items-center">
            <CheckBox type="notChecked" colorStyle="success" cursor="none" />
            <Text type="style14" className="mx-1">
              {t('dashboardScreen.table.done')}
            </Text>
          </div>
          <div className="d-flex align-items-center">
            <CheckBox type="notChecked" colorStyle="primary" cursor="none" />
            <Text type="style14" className="mx-1">
              {t('dashboardScreen.table.near')}
            </Text>
          </div>
          <div className="d-flex align-items-center">
            <CheckBox type="notChecked" colorStyle="danger" cursor="none" />
            <Text type="style14" className="mx-1">
              {t('dashboardScreen.table.expired')}
            </Text>
          </div>
          <div className="d-flex align-items-center">
            <CheckBox type="notChecked" colorStyle="primaryLight" cursor="none" />
            <Text type="style14" className="mx-1">
              {t('dashboardScreen.table.later')}
            </Text>
          </div>
        </div>
      ),
    };
  };

  const getTable = (item: IGetDashboardDataWorkflow[]) => {
    return (
      <Table
        header={
          headers &&
          headers.map((value: string, i: number) => {
            return {
              column: i + 1,
              row: 1,
              children: (
                <Text type="style15" className="font-weight-bold">
                  {t('dashboardScreen.table.' + value)}
                </Text>
              ),
              header: true,
            };
          })
        }
        body={[
          ...(item &&
            item
              .map((value, idx: number) => {
                return checkRowAuthorities(value) ? getRow(value, idx) : [];
              })
              .flat(1)),

          getDescriptionRow(item),
        ]}
      />
    );
  };

  const checkRowAuthorities = (value: IGetDashboardDataWorkflow) => {
    return (
      (user?.authority === Authorities.JuniorUser &&
        user?.clients?.includes(value.clientId) &&
        !user?.companies) ||
      (user?.authority === Authorities.JuniorUser && user?.companies.includes(value.companyId)) ||
      (user?.authority === Authorities.ClientUser2 &&
        user?.clients?.includes(value.clientId) &&
        !user?.companies) ||
      (user?.authority === Authorities.ClientUser2 && user?.companies.includes(value.companyId)) ||
      (user?.authority === Authorities.Admin && user?.clients?.includes(value.clientId)) ||
      user?.authority === Authorities.SuperAdmin
    );
  };

  return (
    <LoadingPlaceholder showSpinner={true} isLoading={isLoading || isFilterLoading}>
      <div className="text-nowrap scope">
        <div className="mh-100 pr-0  pl-md-4 pb-5">
          <Card bgColorStyle="white" rounded shadow paddingV="medium" paddingH="medium">
            <Text type="style36Title" className="pb-3">
              {t('dashboardScreen.mainTitle')}
            </Text>
            {businessAreas &&
              businessAreas.map((item, key) => (
                <DashboardTableLayout
                  key={key}
                  table={getTable(item.workflows)}
                  title={<Text type="style33">{t('sidebarFilter.' + item.businessAreaName)}</Text>}
                  subTitle={<Text type="style3">{t('dashboardScreen.transferSubTitle')}</Text>}
                  redirectToProcess={
                    <div
                      onClick={() => {
                        navigate(URLS.root.processes.toString());
                        const filteredData = filters.map(
                          (itemData: ISidebarFilter) =>
                            ({ ...itemData, isChecked: itemData.id === item.businessAreaId } || {
                              ...itemData,
                              isChecked: itemData.id === item.businessAreaId,
                            }),
                        );
                        dispatch(filterActions.modifySidebarFilterData(filteredData));
                      }}
                    >
                      <Text type="style20" className="d-flex float-right mt-2">
                        {t('dashboardScreen.redirectToProcess')}
                        <Icon type="arrowRight" colorStyle="primary" className="ml-1" />
                      </Text>
                    </div>
                  }
                />
              ))}
            {(!data || !data.length) && (
              <Card bgColorStyle="white" rounded className="d-flex justify-content-center">
                <Text type="style7" className="p-5">
                  {filters.some((filter) => !filter.isChecked)
                    ? noActiveResultError
                    : noActiveProcessError}
                </Text>
              </Card>
            )}
          </Card>
          <Card
            className="mt-5"
            bgColorStyle="white"
            rounded
            shadow
            paddingV="nonePadding"
            paddingH="nonePadding"
          >
            <Footer />
          </Card>
        </div>
      </div>
    </LoadingPlaceholder>
  );
};
