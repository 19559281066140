import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@/components/Button';
import Text from '@/components/Text';
import CompaniesHeaderLayout from '@/layouts/CompaniesHeaderLayout';
import { ModalBox } from '@/components/ModalBox';
import AddCompanyModal from '@/screens/AccountSettings/Contents/AddCompanyModal';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import { AppState } from '@/redux/store';
import { setModalOpen } from '@/redux/core/core.actions';

const useCompaniesHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const title = t('nav.companies');
  const description = t('accountSettings.header.companies.description');
  const newCompany = t('accountSettings.header.companies.newCompany');
  const newCompanyModalHeader = t('accountSettings.contents.companies.header');

  const user = useSelector((state: AppState) => state.user.user);
  const isAdmin = user?.authority == Authorities.SuperAdmin || Authorities.Admin;

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(setModalOpen(showModal));
  }, [dispatch, showModal]);

  return {
    title,
    description,
    newCompany,
    showModal,
    newCompanyModalHeader,
    setShowModal,
    isAdmin,
  };
};

export const CompaniesHeader: FC = () => {
  const {
    description,
    title,
    newCompany,
    showModal,
    setShowModal,
    newCompanyModalHeader,
    isAdmin,
  } = useCompaniesHeader();

  const getNewCompanyModal = () => (
    <ModalBox
      headerText={newCompanyModalHeader}
      show={showModal}
      modalWidth="wide"
      onClickClose={() => setShowModal(false)}
      id="companyModal"
    >
      <AddCompanyModal close={() => setShowModal(false)} />
    </ModalBox>
  );

  return (
    <>
      <CompaniesHeaderLayout
        title={<Text type="style13">{title}</Text>}
        description={<Text type="style25">{description}</Text>}
        btn={
          isAdmin && (
            <Button
              size="medium"
              icon="userPlus"
              iconSize="medium"
              paddingSize="medium"
              roundedLarge
              onClick={() => setShowModal(true)}
              id="openAddCompanyModalButton"
            >
              {newCompany}
            </Button>
          )
        }
      />
      {showModal && getNewCompanyModal()}
    </>
  );
};
