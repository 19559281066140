import '@/components/Tooltip/style.scss';
import { FC } from 'react';
import ReactTooltip from 'react-tooltip';

interface Props {
  children?: React.ReactNode;
  bubbleContent?: string;
  place?: 'top' | 'right' | 'left';
}

export const Tooltip: FC<Props> = ({ children, bubbleContent, place = 'top' }) => (
  <>
    {/* Ide majd lehet html-t is rakni, vagy barmi react componenst, egyelore csak szovegre volt szukseg, de ki 
  /* lehet kesobb boviteni
  */}
    <div data-tip={bubbleContent}>{children}</div>
    {/* hardcodeolt a szin, mert a  temak szinei scss-ben vannak irva */}
    <ReactTooltip place={place} backgroundColor="#869AB8" />
  </>
);
