import { Dispatch } from 'redux';
import {
  UPLOAD_DOCUMENT_FAILURE,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_SEARCH_WORDS,
  SET_DOCUMENT_SEARCH_WORDS,
  GET_DOCUMENT_TABLE_REQUEST,
  GET_DOCUMENT_TABLE_SUCCESS,
  GET_DOCUMENT_TABLE_FAILURE,
  EXPAND_DOCUMENT_TABLE,
  SET_DOCUMENT_SCROLLBAR_POSITION,
} from '@/redux/documents/documents.types';
import RestClient from '@/client_v2/rest';
import { IExpandDocumentTable, ISearchWords, PostDocumentParams } from './documents.interfaces';

export const documentUpload = (document: PostDocumentParams) => async (dispatch: Dispatch) => {
  dispatch({
    type: UPLOAD_DOCUMENT_REQUEST,
  });

  try {
    const res = await RestClient.Documents.post(document!);

    dispatch({
      type: UPLOAD_DOCUMENT_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: UPLOAD_DOCUMENT_FAILURE,
      error,
    });
  }
};

export const getDocumentTableDataAction = () => async (dispatch: Dispatch) => {
  dispatch({
    type: GET_DOCUMENT_TABLE_REQUEST,
  });

  try {
    const res = await RestClient.Documents.get();

    dispatch({
      type: GET_DOCUMENT_TABLE_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_DOCUMENT_TABLE_FAILURE,
      error,
    });
  }
};

export const setDocumentSearchWords = (searchWords: ISearchWords) => (dispatch: Dispatch) =>
  dispatch({
    type: SET_DOCUMENT_SEARCH_WORDS,
    payload: searchWords.searchWords,
  });

export const deleteDocumentSearchWords = () => (dispatch: Dispatch) =>
  dispatch({
    type: DELETE_DOCUMENT_SEARCH_WORDS,
  });

export const expandDocumentTable = (expandDocument: IExpandDocumentTable) => (dispatch: Dispatch) =>
  dispatch({
    type: EXPAND_DOCUMENT_TABLE,
    payload: expandDocument,
  });

export const setDocumentScrollbarPosition =
  (position: number | undefined) => (dispatch: Dispatch) =>
    dispatch({
      type: SET_DOCUMENT_SCROLLBAR_POSITION,
      payload: position,
    });
