import React, { ReactElement } from 'react';
import { TFunctionResult } from 'i18next';

interface ButtonProps extends React.ButtonHTMLAttributes<unknown> {
  children: React.ReactChild | TFunctionResult;
}

const Button = ({ className, children, ...buttonProps }: ButtonProps): ReactElement => {
  return (
    <button className={`btn ${className}`} {...buttonProps}>
      {children}
    </button>
  );
};

export default Button;
