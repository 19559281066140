/**
 * The error: any argument must be any because the ascync callback function can throw any kind of error type
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'redux';
import { setError } from '@/redux/message/message.action';

export const createOnError = (dispatch: Dispatch) => (err: any) => {
  const error = err.response?.data?.errors;
  let msg = err.toString();
  if (error) {
    msg = error.email.toString() ? error.email.toString() : err.toString();
  }
  return setError(msg)(dispatch);
};
