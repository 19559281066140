import '@/layouts/UsersHeaderLayout/style.scss';
import { FC, ReactNode } from 'react';

interface Props {
  title?: ReactNode;
  description?: ReactNode;
  btn?: ReactNode;
}

export const UsersHeaderLayout: FC<Props> = ({ description, btn, title }) => (
  <div className="container-fluid my-4 py-3">
    <div className="row">
      <div className="col-md-12">
        <div className="row mb-4 mt-4">
          <div className="col-md-12">{title}</div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 col-xs-1">{description}</div>
          <div className="col-md-6 mt-md-0 mt-sm-4 mt-4 d-flex justify-content-md-end justify-content-center">
            {btn}
          </div>
        </div>
      </div>
    </div>
  </div>
);
