import { IFilterData, IGetDocumentsData } from '@/client_v2/db/repositories/Document.repo';
import { DocumentStatusCheck } from '@/client_v2/hooks/utils/documentStatusCheck';

export const DocumentFilterSortOrder = (
  documents: IGetDocumentsData[],
  filterData: IFilterData,
) => {
  // if the doc is not contain to my client then need to filtering it
  if (filterData.selectedClientId) {
    documents = documents.filter(
      (document: IGetDocumentsData) =>
        document.clientId === filterData.selectedClientId ||
        document.sourceClientId === filterData.selectedClientId,
    );
  }

  // if the doc is not contain to my company then need to filtering it
  if (filterData.selectedCompanyId) {
    documents = documents.filter(
      (document: IGetDocumentsData) =>
        document.companyId === filterData.selectedCompanyId || !document.companyId,
    );
  }

  const s1 = filterData.sort.direction === 'asc' ? 1 : -1;
  const s2 = filterData.sort.direction === 'desc' ? 1 : -1;
  documents.sort((a, b) => {
    if (filterData.sort.field === 'companyName') {
      if (!a.companyName) return s2;
      if (!b.companyName) return s1;
      if (a.companyName.toUpperCase() > b.companyName.toUpperCase()) return s1;
      if (b.companyName.toUpperCase() > a.companyName.toUpperCase()) return s2;
    }
    if (filterData.sort.field === 'clientName') {
      if (!a.clientName) return s2;
      if (!b.clientName) return s1;
      if (a.clientName.toUpperCase() > b.clientName.toUpperCase()) return s1;
      if (b.clientName.toUpperCase() > a.clientName.toUpperCase()) return s2;
    }
    if (filterData.sort.field === 'createdAt') {
      return filterData.sort.direction === 'desc'
        ? b.createdAt.valueOf() - a.createdAt.valueOf()
        : a.createdAt.valueOf() - b.createdAt.valueOf();
    }
    return 0;
  });

  if (
    filterData.filters.companyIds?.length ||
    filterData.filters.clientIds?.length ||
    filterData.filters.direction?.length ||
    filterData.filters.statuses?.length ||
    filterData.filters.approverId?.length ||
    filterData.filters.stepIds?.length ||
    filterData.search?.length
  ) {
    return documents.filter((document: IGetDocumentsData) => {
      let isExist = true;

      if (filterData.filters.companyIds?.length && isExist) {
        isExist = !!filterData.filters.companyIds?.includes(document.companyId);
      }
      if (filterData.filters.clientIds?.length && isExist) {
        isExist = !!filterData.filters.clientIds?.includes(document.clientId);
      }
      if (filterData.filters.direction?.length && isExist) {
        isExist = !!filterData.filters.direction?.includes(document!.direction);
      }
      if (filterData.filters.approverId?.length && isExist) {
        isExist = !!filterData.filters.approverId?.includes(document.approverId);
      }
      if (filterData.filters.stepIds?.length && isExist) {
        isExist = !!filterData.filters.stepIds?.includes(document.workflowStepId);
      }
      if (filterData.filters.statuses?.length && isExist) {
        isExist = DocumentStatusCheck(document, filterData.filters);
      }
      if (filterData.search?.length && isExist) {
        isExist = filterData.search.some(
          (word) =>
            document.name?.toLowerCase().includes(word?.toLowerCase()) ||
            document.clientName?.toLowerCase().includes(word?.toLowerCase()) ||
            document.companyName?.toLowerCase().includes(word?.toLowerCase()) ||
            document.companyName?.toLowerCase().includes(word?.toLowerCase()) ||
            document.tags?.some((tag) => tag.name?.toLowerCase().includes(word?.toLowerCase())),
        );
      }
      return isExist && document;
    });
  }
  return documents;
};
