import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import URLS from '@/resources/constants/URLS';
import { useAsyncCallback } from '@/hooks/useAsyncCallback';
import { createOnError } from '@/redux/message/message.callback';
import RestClient from '@/client_v2/rest';
import { ModalBox } from '@/components/ModalBox';
import Button from '@/components/Button';
import Text from '@/components/Text';

const ApproveDocumentScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isApprove, setIsApprove] = useState<boolean>(true);
  const [documentId, setDocumentId] = useState<number>();
  const [token, setToken] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  const [uploader, setUploader] = useState<string>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const docParam = urlParams.get('id');
    const tokenParam = urlParams.get('token');
    const fnParam = urlParams.get('fn');
    const byParam = urlParams.get('by');

    docParam && setDocumentId(+docParam);
    tokenParam && setToken(tokenParam);
    fnParam && setFileName(fnParam);
    byParam && setUploader(byParam);
  }, []);

  const [save] = useAsyncCallback(
    async (isApproved: boolean) => {
      if (!documentId) {
        throw t('error.missingParameter', { PARAM: 'Document' });
      }
      if (!token) {
        throw t('error.missingParameter', { PARAM: 'Token' });
      }
      try {
        await RestClient.Documents.approve(documentId, isApproved, token);
        setLoading(false);
        redirect();
      } catch (e) {
        setLoading(false);
        throw e.response.data.message;
      }
    },
    createOnError(dispatch),
    [documentId],
  );

  const redirect = () => {
    navigate(URLS.root.documents.toString());
  };
  return (
    <div className="container-fluid loginContainer mr-0">
      <ModalBox headerText={t('approveDocument.title')} show onClickClose={redirect}>
        <div className="d-flex justify-content-center">
          <Text className="px-2 py-1 d-flex" type="style27">
            {t('approveDocument.text')}
          </Text>
        </div>
        <div className="mt-3 d-flex justify-content-center">
          <Button
            size="small"
            icon="download"
            iconSize="medium"
            paddingSize="extraSmall"
            className="btn-secondary"
            onClick={() => {
              documentId && RestClient.Documents.download([documentId]);
            }}
          >
            <Text className="px-2 py-1 d-flex" type="style24">
              {t('approveDocument.download')}
            </Text>
          </Button>
        </div>
        <div className="mt-3 d-flex justify-content-center">
          <Text className="px-2 d-flex" type="style27">
            {t('approveDocument.file')}
          </Text>
          <Text className="px-2 pt-2 d-flex" type="style8">
            {fileName}
          </Text>
        </div>
        <div className="d-flex justify-content-center">
          <Text className="px-2 d-flex" type="style27">
            {t('approveDocument.uploader')}
          </Text>
          <Text className="px-2 pt-2 d-flex" type="style8">
            {uploader}
          </Text>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            size="large"
            rounded
            className="my-4 mr-3"
            onClick={() => {
              save(true);
              setIsApprove(true);
              setLoading(true);
            }}
          >
            <Text className="px-2 py-1 d-flex" type="style24" isLoading={loading && isApprove}>
              {t('approveDocument.approve')}
            </Text>
          </Button>
          <Button
            size="large"
            rounded
            className="my-4 ml-3 btn-secondary"
            onClick={() => {
              save(false);
              setIsApprove(false);
              setLoading(true);
            }}
          >
            <Text className="px-2 py-1 d-flex" type="style24" isLoading={loading && !isApprove}>
              {t('approveDocument.decline')}
            </Text>
          </Button>
        </div>
      </ModalBox>
    </div>
  );
};

export default ApproveDocumentScreen;
