/* eslint-disable camelcase */
import '@/screens/AccountSettings/Contents/ActivityFeed/styles.scss';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import Text from '@/components/Text';
import Table from '@/components/Table';
import Card from '@/components/Card';
import { AppState } from '@/redux/store';
import { LoadingPlaceholder } from '@/components/LoadingPlaceholder/LoadingPlaceholder';
import { getMoreActivityFeed } from '@/redux/activityFeed/activityFeed.actions';
import Button from '@/components/Button';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import URLS from '@/resources/constants/URLS';
import { expandDocumentTable } from '@/redux/documents/documents.actions';
import { resetDocumentFilter } from '@/redux/sortAndFilters/sortAndFilters.actions';

const useActivityFeed = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const modifierName = t('accountSettings.contents.activityFeed.modifierName');
  const modifiedOnDate = t('accountSettings.contents.activityFeed.modifiedOnDate');
  const activity = t('accountSettings.contents.activityFeed.activity');
  const modifiedByClient = t('accountSettings.contents.activityFeed.modifiedByClient');
  const affectedArea = t('accountSettings.contents.activityFeed.affectedArea');
  const client = t('accountSettings.contents.activityFeed.client');
  const loadMore = t('accountSettings.contents.activityFeed.loadMore');
  const dateFormatHours = t('common.dateFormatHours');

  const { isLoading, data: activityFeeds } = useSelector((state: AppState) => state.activityFeeds);

  useEffect(() => {
    dispatch(resetDocumentFilter());
  }, [dispatch]);

  const mappedActivityFeeds = useMemo(() => {
    const array: string[] = [];
    activityFeeds!.forEach(
      ({
        causer_name: causerName,
        created_at: createdAt,
        activity,
        causer_client: causerClient,
        subject_name: subjectName,
        subject_client: subjectClient,
      }) => {
        array.push(
          causerName,
          dayjs(createdAt).format(dateFormatHours),
          activity && t('accountSettings.contents.activityFeed.activities.' + activity),
          causerClient,
          subjectName && t('accountSettings.contents.activityFeed.areas.' + subjectName),
          subjectClient,
        );
      },
    );
    return array;
  }, [activityFeeds, t, dateFormatHours]);

  const tableData = [
    {
      header: true,
      view: (
        <Text key="title-1" type="style34" className="d-flex justify-content-around pt-2 pb-2">
          {modifierName}
        </Text>
      ),
    },
    {
      header: true,
      view: (
        <Text key="title-2" type="style34" className="d-flex justify-content-around pt-2 pb-2">
          {modifiedOnDate}
        </Text>
      ),
    },
    {
      header: true,
      view: (
        <Text key="title-3" type="style34" className="d-flex justify-content-around pt-2 pb-2">
          {activity}
        </Text>
      ),
    },
    {
      header: true,
      view: (
        <Text key="title-4" type="style34" className="d-flex justify-content-around pt-2 pb-2">
          {modifiedByClient}
        </Text>
      ),
    },
    {
      header: true,
      view: (
        <Text key="title-5" type="style34" className="d-flex justify-content-around pt-2 pb-2">
          {affectedArea}
        </Text>
      ),
    },
    {
      header: true,
      view: (
        <Text key="title-6" type="style34" className="d-flex justify-content-around pt-2 pb-2">
          {client}
        </Text>
      ),
    },
    ...mappedActivityFeeds!.map((value, idx) => ({
      view:
        typeof value === 'string' ? (
          <Text
            type="style8"
            key={`${value}-${idx}`}
            className="d-flex justify-content-around pt-3 pb-3"
          >
            {value}
          </Text>
        ) : (
          value
        ),
    })),
  ];

  return {
    modifierName,
    modifiedOnDate,
    activity,
    loadMore,
    modifiedByClient,
    affectedArea,
    client,
    activityFeeds: mappedActivityFeeds,
    isLoading: !activityFeeds || isLoading,
    tableData,
  };
};

export const ActivityFeed: FC = () => {
  const { isLoading, tableData, loadMore } = useActivityFeed();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: AppState) => state.user.user);

  useEffect(() => {
    dispatch(expandDocumentTable({ expand: false }));
  }, [dispatch]);

  useEffect(() => {
    if (user?.authority !== Authorities.SuperAdmin) {
      navigate(URLS.root.accountSettings.users.toString());
    }
  }, [navigate, user?.authority]);

  return (
    <LoadingPlaceholder showSpinner={true} isLoading={isLoading}>
      <div className="scroll pb-5">
        <Card bgColorStyle="white" rounded shadow className="pb-3">
          <div className="scroll-tb pl-3 pr-3">
            <Table column={6} data={tableData} />
          </div>
          <Button
            size="large"
            outline
            className="d-flex justify-content-center my-4 mx-auto"
            onClick={() => dispatch(getMoreActivityFeed())}
          >
            {loadMore}
          </Button>
        </Card>
      </div>
    </LoadingPlaceholder>
  );
};
