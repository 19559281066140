import { FC, ReactNode } from 'react';
import { CreateScopeCSS, cn } from '@/components/utils';
import '@/layouts/AddNewClientLayout/style.scss';

const scope = CreateScopeCSS('layouts-add-client-layout');
const line = scope.and('line');

interface AddNewClientLayoutProps {
  nameInputField: ReactNode;
  taxInputField: ReactNode;
  contactDropdownMenu: ReactNode;
  contactDropdownMenuText: ReactNode;
  partnerDateDropdownMenu: ReactNode;
  partnerDateDropdownMenuText: ReactNode;
  recommenderInputField: ReactNode;
  saveClientButton: ReactNode;
  commentIcon: ReactNode;
  commentHeaderText: ReactNode;
  commentInputField: ReactNode;
  branchHeaderText: ReactNode;
  checkboxHeaderText: ReactNode;
  checkBoxData: { text: ReactNode; checkBoxView: ReactNode }[];
  datePickerPlaceholder?: ReactNode;
  addCommentButton: ReactNode;
  badges: { badgeTexts: ReactNode }[];
  errorField: ReactNode;
}

export const AddNewClientLayout: FC<AddNewClientLayoutProps> = ({
  nameInputField,
  taxInputField,
  contactDropdownMenu,
  contactDropdownMenuText,
  partnerDateDropdownMenu,
  partnerDateDropdownMenuText,
  recommenderInputField,
  saveClientButton,
  commentIcon,
  commentHeaderText,
  commentInputField,
  branchHeaderText,
  checkboxHeaderText,
  checkBoxData,
  datePickerPlaceholder,
  addCommentButton,
  badges,
  errorField,
}) => (
  <div className={cn(scope, 'text-nowrap')}>
    <div className="row">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-6 col-md-12 ">
            <div className="row">
              <div className="col-md-12">{nameInputField}</div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12 ">{contactDropdownMenuText}</div>
            </div>
            <div className="row">
              <div className="col-md-12">{contactDropdownMenu}</div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">{recommenderInputField}</div>
            </div>
            <div className="row mt-5 d-flex ">
              <div className="col-md-1">{commentIcon}</div>
              <div className="col-md-10">{commentHeaderText}</div>
            </div>
            <div className="row ml-1 mt-1 mr-1">
              <div className="col-md-12 d-flex justify-content-start">
                <div className="row mt-3">
                  {badges?.map(({ badgeTexts }, index) => (
                    <div className="mr-1 mb-1" key={`badge-${index}`}>
                      {badgeTexts}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="row mt-3 ">
              <div className="col-md-12">
                <div className="row align-items-center">
                  <div className="col-6">{commentInputField}</div>
                  <div className="col-6 d-flex justify-content-end mt-4">{addCommentButton}</div>
                </div>
                <div className="row ml-2 justify-content-start align-items-center">
                  <div className="col-md-12 ">{errorField}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 ">
            <div className="row">
              <div className="col-md-12">{taxInputField}</div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12 ">{partnerDateDropdownMenuText}</div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-1">{partnerDateDropdownMenu}</div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-1 position-absolute d-flex justify-content-center">
                {datePickerPlaceholder}
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12">
                <div className="row ">
                  <div className="col-6 text-center">{branchHeaderText}</div>
                  <div className="col-4 text-center">{checkboxHeaderText}</div>
                </div>
                <div className="row mt-2 mb-3">
                  <div className="col-12">
                    <div className={cn(line, 'border-bottom')} />
                  </div>
                </div>
                {checkBoxData &&
                  checkBoxData.map(({ text, checkBoxView }, index) => (
                    <div className="row mb-1" key={`checkbox-data-${index}`}>
                      <div className="col-2" />
                      <div className="col-5 pb-2 d-inline-block text-truncate">{text}</div>
                      <div className="col-2 pb-2 d-flex justify-content-center">{checkBoxView}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mb-4 mt-lg-0 mt-md-4 mt-4 d-flex justify-content-lg-start justify-content-center">
            {saveClientButton}
          </div>
        </div>
      </div>
    </div>
  </div>
);
