import { CompanyPoll } from '@/client_v2/polls/Company.poll';
import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { API_COMPANIES_$ID, $ID_PARAM } from '@/client_v2/rest/Companies/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';

export const deleteCompany = async (id: number) => {
  const res = await axiosInstance.delete(
    createUrl(API_COMPANIES_$ID, {
      params: {
        [$ID_PARAM]: id.toString(),
      },
    }),
  );
  await CompanyPoll.trigger();
  return res;
};
