/* eslint-disable camelcase */
import { creatRepo } from '@/client_v2/db/utils/createRepo';
import { WorkflowMile } from '@/client_v2/rest/types/WorkflowMilesRes';

export const WorkflowMileRepo = creatRepo<WorkflowMile>({
  name: 'workflow_mile',
  model: {
    'id:number': { pk: true },
    'workflow_id:number': { default: -1 },
    'name:string': {},
    'deadline:date': {},
    'status:string': {},
    'created_at:date': {},
    'updated_at:date': {},
    'deleted_at:date': {},
  },
}).make();
