//Authentication request endpoints
export const LOGIN = '/auth/login';
export const LOGOUT = '/auth/logout';
export const REGISTER = '/auth/register';
export const FORGOT_PASSWORD = '/auth/forgot-password';
export const RESET_PASSWORD = '/auth/reset-password';

//activity log management endpoints
export const ACTIVITY_LOG_SEARCH = 'api/activity-logs/search';
export const ACTIVITY_LOGS = 'api/activity-logs';
export const ACTIVITY_LOG = 'api/activity-logs/{activity_log}';

//Client management endpoints
export const CLIENT_SEARCH = 'api/clients/search';
export const CREATE_CLIENT = 'api/clients';
export const UPDATE_CLIENT = 'api/clients/{client}';
export const DELETE_CLIENT = 'api/clients/{client}';
export const RESTORE_CLIENT = 'api/clients/{client}/restore';
export const FETCH_CLIENT_COMMENTS_SEARCH = 'api/clients/{client}/comments/search';
export const CREATE_CLIENT_COMMENT = 'api/clients/{client}/comments';
export const UPDATE_CLIENT_COMMENT = 'api/companies/{company}/comments/{comment}';
export const DELETE_CLIENT_COMMENT = 'api/clients/{client}/comments/{comment}';

//Company management endpoints
export const FETCH_COMPANY_SEARCH = 'api/companies/search';
export const FETCH_COMPANIES = 'api/companies';
export const CREATE_COMPANY = 'api/companies';
export const FETCH_COMPANY = 'api/companies/{company}';
export const UPDATE_COMPANY = 'api/companies/{company}';
export const DELETE_COMPANY = 'api/companies/{company}';
export const RESTORE_DELETED_COMPANY = 'api/companies/{company}/restore';
export const FETCH_COMPANY_COMMENTS_SEARCH = 'api/companies/{company}/comments/search';
export const FETCH_COMPANY_COMMENTS = 'api/companies/{company}/comments';
export const CREATE_COMPANY_COMMENT = 'api/companies/{company}/comments';
export const UPDATE_COMPANY_COMMENT = 'api/companies/{company}/comments/{comment}';
export const DELETE_COMPANY_COMMENT = 'api/companies/{company}/comments/{comment}';

//Document management endpoints
export const DOCUMENT_UPLOAD = 'api/documents/upload';
export const DOCUMENT_DOWNLOAD = 'api/documents/download';
export const SHARE_DOCUMENT = 'api/documents/{id}/share';
export const APPROVE_DOCUMENT = 'api/documents/{id}/approve';
export const SEND_DOCUMENT = 'api/documents/{id}/send';
export const DOCUMENT_SEARCH = 'api/documents/search';
export const UPDATE_DOCUMENT = 'api/documents/{document}';
export const DELETE_DOCUMENT = 'api/documents/{document}';
export const RESTORE_DOCUMENT = 'api/documents/{document}/restore';
export const DOCUMENT_COMMENT_SEARCH = 'api/documents/{document}/comments/search';
export const CREATE_DOCUMENT_COMMENT = 'api/documents/{document}/comments';
export const UPDATE_DOCUMENT_COMMENT = 'api/documents/{document}/comments/{comment}';
export const DELETE_DOCUMENT_COMMENT = 'api/documents/{document}/comments/{comment}';

//Tag management endpoints
export const FETCH_TAG_SEARCH = 'api/tags/search';
export const FETCH_TAGS = 'api/tags';
export const CREATE_TAGS = 'api/tags';
export const CREATE_TAG = 'api/tags/{tag}';
export const UPDATE_TAG = 'api/tags/{tag}';
export const DELETE_TAG = 'api/tags/{tag}';

//User management endpoints
export const COUNT_STEPS_USER = 'api/users/{user}/count-steps-by-status';
export const FETCH_USERS_SEARCH = 'api/users/search';
export const FETCH_USERS = 'api/users?pagination_disabled=1';
export const FETCH_USER = 'api/users/{user}';
export const UPDATE_USER = 'api/users/{user}';
export const DELETE_USER = 'api/users/{user}';
export const FETCH_SUBORDINATES_SEARCH = '/api/managers/{manager}/subordinates/search';
export const ATTACH_SUBORDINATE = 'api/managers/{manager}/subordinates/attach';
export const DETACH_SUBORDINATE = 'api/managers/{manager}/subordinates/detach';
export const FETCH_SUBORDINATES = 'api/managers/{manager}/subordinates';
export const FETCH_MANAGERS_SEARCH = 'api/subordinates/{subordinate}/managers/search';
export const FETCH_MANAGERS = 'api/subordinates/{subordinate}/managers';

//workflow management endpoints
export const WORKFLOW_COUNT_STEPS_STATUS = 'api/workflows/{workflow}/count-steps-by-status';
export const WORKFLOW_COUNT_STEPS = 'api/steps/count-steps-by-status';
export const SEARCH_WORKFLOW = 'api/workflows/search';
export const CREATE_WORKFLOW = 'api/workflows';
export const FETCH_WORKFLOW = 'api/workflows/{workflow}';
export const UPDATE_WORKFLOW = 'api/workflows/{workflow}';
export const DELETE_WORKFLOW = 'api/workflows/{workflow}';
export const RESTORE_WORKFLOW = 'api/workflows/{workflow}/restore';
export const WORKFLOW_MILES_SEARCH = 'api/workflows/{workflow}/miles/search';
export const CREATE_WORKFLOW_MILE = 'api/workflows/{workflow}/miles';
export const UPDATE_WORKFLOW_MILE = 'api/workflows/{workflow}/miles/{mile?}';
export const DELETE_WORKFLOW_MILE = 'api/workflows/{workflow}/miles/{mile?}';
export const SEARCH_WORKFLOW_STEPS = 'api/miles/{mile}/steps/search';
export const CREATE_WORKFLOW_STEP = 'api/miles/{mile}/steps';
export const UPDATE_WORKFLOW_STEP = 'api/miles/{mile}/steps/{step?}';
export const DELETE_WORKFLOW_STEP = 'api/miles/{mile}/steps/{step?}';
export const SEARCH_WORKFLOW_STEP_COMMENTS = 'api/steps/{step}/comments/search';
export const CREATE_WORKFLOW_STEP_COMMENT = 'api/steps/{step}/comments';
export const UPDATE_WORKFLOW_STEP_COMMENT = 'api/steps/{step}/comments/{comment}';
export const DELETE_WORKFLOW_STEP_COMMENT = 'api/steps/{step}/comments/{comment}';
export const SEARCH_STEP_USERS = 'api/steps/{step}/users/search';
export const ATTACH_STEP_WITH_USER = 'api/steps/{step}/users/attach';
export const DETACH_STEP_WITH_USER = 'api/steps/{step}/users/detach';
export const FETCH_STEP_USERS = 'api/steps/{step}/users';
