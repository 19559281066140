import '@/layouts/ProcessesTableLayout/style.scss';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Table } from '@/components/Table';
import Text from '@/components/Text';
import { ModalBox } from '@/components/ModalBox';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import { IUserCompaniesData } from '@/client_v2/db/repositories/Company.repo';
import { GetPropsType } from '@/types/GetPropType';
import EditUserModal from '@/screens/AccountSettings/Contents/EditUserModal';
import { sendForgotPassword } from '@/redux/auth/auth.actions';
import CompanyContentTableLayout from '@/layouts/CompanyContentTableLayout';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import { AppState } from '@/redux/store';
import { useAsyncCallback } from '@/hooks/useAsyncCallback';
import { createOnError } from '@/redux/message/message.callback';
import RestClient from '@/client_v2/rest';

interface Props {
  companyId: number;
  data?: IUserCompaniesData[];
}

interface iFunctionButton {
  id?: number;
  text: string;
  iconType: GetPropsType<typeof Icon>['type'];
  color: GetPropsType<typeof Icon>['colorStyle'];
  onClick(): void;
}

export const CompanyContentTable: FC<Props> = ({ data, companyId }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.user.user);
  const isAdmin = user?.authority == Authorities.Admin || user?.authority == Authorities.SuperAdmin;
  const [showEditUserModal, setShowEditUserModal] = useState<boolean>(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState<boolean>(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState<boolean>(false);
  const [editUserId, setEditUserId] = useState<number>();
  const dateFormat = t('common.dateFormat');

  const [selectedCompanyUser, setSelectedCompanyUser] = useState<IUserCompaniesData | null>(null);

  const selectCompanyUser = (selectedId: number) => {
    const user = data!.find(({ userId }) => userId === selectedId);
    setSelectedCompanyUser(user ? user : null);
  };

  const name = t('companyScreen.table.name');
  const email = t('companyScreen.table.email');
  const confirmRemoveCompany = t('companyScreen.confirmRemoveFromCompany');
  const confirmRemoveTitle = t('companyScreen.confirmRemoveTitle');
  const confirmationHeaderForgotPW = t('companyScreen.confirmationPopup2');
  const renderForgotPwText = t('accountSettings.contents.users.forgotPasswordModalText');
  const confirmText = t('companyScreen.confirm');
  const backText = t('companyScreen.back');
  const authority = t('companyScreen.table.authority');
  const lastLogin = t('companyScreen.table.lastLogin');

  const headers = [name, email, authority, lastLogin, null];

  const [editUser] = useAsyncCallback(
    async (user) => {
      const companies = user.companies?.filter(
        (userCompanyId: number) => userCompanyId != companyId,
      );
      if (user && user.userId) {
        await RestClient.Users.patch({
          data: {
            id: user.userId,
            companyIds: companies,
          },
        });
      }
    },
    createOnError(dispatch),
    [selectedCompanyUser],
  );

  const renderDeleteUserModal = () => (
    <ModalBox
      headerText={confirmRemoveTitle}
      show={showDeleteUserModal}
      modalWidth="narrow"
      onClickClose={() => setShowDeleteUserModal(false)}
    >
      <div className="col-12 mb-3">
        <Text type="style30">{confirmRemoveCompany}</Text>
      </div>

      <div className="d-flex justify-content-around">
        <Button
          size="large"
          rounded
          className="my-4 ml-4"
          onClick={() => {
            editUser(selectedCompanyUser);
            setShowDeleteUserModal(false);
          }}
        >
          <Text className="px-2 py-1 d-flex" type="style24">
            {confirmText}
          </Text>
        </Button>

        <Button
          size="large"
          rounded
          className="my-4 ml-4 btn-secondary"
          onClick={() => setShowDeleteUserModal(false)}
        >
          <Text className="px-2 py-1 d-flex" type="style24">
            {backText}
          </Text>
        </Button>
      </div>
    </ModalBox>
  );

  const renderForgotPasswordModal = () => (
    <ModalBox
      headerText={confirmationHeaderForgotPW}
      show={showForgotPasswordModal}
      modalWidth="narrow"
      onClickClose={() => setShowForgotPasswordModal(false)}
    >
      <div className="col-12 mb-3">
        <Text type="style30">{renderForgotPwText}</Text>
      </div>

      <div className="d-flex justify-content-around">
        <Button
          size="large"
          rounded
          className="my-4 ml-4"
          onClick={() => {
            dispatch(sendForgotPassword(selectedCompanyUser!.email));
            setShowForgotPasswordModal(false);
          }}
        >
          <Text className="px-2 py-1 d-flex" type="style24">
            {confirmText}
          </Text>
        </Button>
        <Button
          size="large"
          rounded
          className="my-4 ml-4 btn-secondary"
          onClick={() => setShowForgotPasswordModal(false)}
        >
          <Text className="px-2 py-1 d-flex" type="style24">
            {backText}
          </Text>
        </Button>
      </div>
    </ModalBox>
  );

  const getListingButtons = (userData: IUserCompaniesData) => {
    const functionButtons: iFunctionButton[] = [
      {
        text: t('common.edit'),
        iconType: 'edit3',
        color: 'primary',
        onClick: () => {
          setShowEditUserModal(!showEditUserModal);
          setEditUserId(userData.userId);
        },
      },
      {
        text: t('companyScreen.table.passwordback'),
        iconType: 'key',
        color: 'lightGray',
        onClick: () => {
          selectCompanyUser(userData.userId);
          setShowForgotPasswordModal(!showForgotPasswordModal);
        },
      },
      {
        text: t('common.delete'),
        iconType: 'trash',
        color: 'danger',
        onClick: () => {
          selectCompanyUser(userData.userId);
          setShowDeleteUserModal(!showDeleteUserModal);
        },
      },
    ];

    return (
      <div className="subCompanyButtons">
        {functionButtons.map((value: iFunctionButton, index: number) =>
          user?.authority === Authorities.SuperAdmin ||
          userData.authority !== Authorities.SuperAdmin ||
          value.iconType === 'edit3' ? (
            <div
              data-mdb-toggle="tooltip"
              key={`sub-company-buttons-${index}`}
              data-placement="top"
              title={value.text}
            >
              <Icon
                type={value.iconType}
                colorStyle={value.color}
                onClick={value.onClick}
                className="w-100 pl-5"
              />
            </div>
          ) : (
            <div key={`sub-company-buttons-${index}`} />
          ),
        )}
      </div>
    );
  };

  const getColumn = (subValue: [string, string], value: IUserCompaniesData) => {
    if (subValue[0] === 'lastLogin') {
      return (
        <Text type="style7">
          {subValue[1]
            ? dayjs(subValue[1]).format(dateFormat)
            : t('accountSettings.contents.users.noLastlogin')}
        </Text>
      );
    }
    if (subValue[0] === 'isBlocked') {
      return isAdmin && getListingButtons(value);
    } else {
      return <Text type="style7">{subValue[1]}</Text>;
    }
  };

  const getRow = (value: IUserCompaniesData, key: number) =>
    Object.entries(value).map((subValue, subKey) => ({
      row: key + 2,
      column: subKey,
      children: getColumn(subValue, value),
      isHidden: subValue[0] === 'userId' || subValue[0] === 'companies',
    }));

  return (
    <>
      <CompanyContentTableLayout
        table={
          data && (
            <Table
              header={
                headers &&
                headers.map((value, i: number) => ({
                  column: i + 1,
                  row: 1,
                  children: (
                    <Text type="style30" className="font-weight-bold">
                      {value}
                    </Text>
                  ),
                  header: true,
                }))
              }
              body={
                data &&
                data.map((value: IUserCompaniesData, idx: number) => getRow(value, idx)).flat(1)
              }
            />
          )
        }
      />
      {showDeleteUserModal && renderDeleteUserModal()}
      {showForgotPasswordModal && renderForgotPasswordModal()}
      {showEditUserModal && (
        <ModalBox
          headerText={t('accountSettings.header.users.modifyUser')}
          show={showEditUserModal}
          onClickClose={() => setShowEditUserModal(false)}
          modalWidth="wide"
        >
          <EditUserModal
            isOpenedFromUsersPage={false}
            userId={editUserId!}
            close={() => setShowEditUserModal(false)}
          />
        </ModalBox>
      )}
    </>
  );
};
