/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createWhereQuery } from '@/client_v2/db/utils/createWhereQuery';
import { creatRepo } from '@/client_v2/db/utils/createRepo';
import { Workflow } from '@/client_v2/rest/types/WorkflowsRes';
import { WorkflowMile } from '@/client_v2/rest/types/WorkflowMilesRes';
import { WorkflowStep } from '@/client_v2/rest/types/WorkflowStepsRes';
import { Client } from '@/client_v2/rest/types/ClientsRes';
import { User } from '@/client_v2/rest/types/UsersRes';
import { Document } from '@/client_v2/rest/types/DocumentsRes';
import { Comment } from '@/client_v2/rest/types/CommentsRes';
import { IProcessFilters } from '@/redux/sortAndFilters/sortAndFilters.interfaces';
import { CommentTypes } from '@/resources/constants/shared';
import { ISort } from '@/redux/sort/sort.interfaces';

export interface IGetWorkflowResponsibleUser {
  userId: number;
  userName: string;
}

export interface IGetWorkflowComment {
  commentId: number;
  comment: string;
}

export interface IGetWorkflowDoc {
  documentId: number;
  fileName: string;
  filePath: string;
}

export interface IGetWorkflowSteps {
  stepId: number;
  stepName: string;
  deadline: Date;
  clientName: string;
  clientId: number;
  status: string;
  createdAt: Date;
  updatedAt?: Date;
  responsibleUsers: IGetWorkflowResponsibleUser[] | null;
  documents: IGetWorkflowDoc[] | null;
  comments: IGetWorkflowComment[] | null;
  lastModifyUser: IGetWorkflowLastModifyUser[];
}

export interface IGetWorkflowMiles {
  mileId: number;
  mileName: string;
  status: string;
  steps: IGetWorkflowSteps[];
}

export interface IGetWorkflowData {
  id: number;
  name: string;
  userId: number;
  startDate: Date;
  endDate: Date;
  createdAt: Date;
  client: number;
  companyId: number;
  isArchived: boolean;
  businessAreaId: number;
  miles: IGetWorkflowMiles[];
}

export interface IDataArg {
  businessAreaIds: number[];
  sort: ISort;
  filters: IProcessFilters;
  search?: string[];
  selectedCompanyId?: number | null;
  selectedClientId?: number | null;
}

export interface IGetWorkflowLastModifyUser {
  userId: number;
  userName: string;
}

export const WorkflowRepo = creatRepo<Workflow>({
  name: 'workflow',
  model: {
    'id:number': { pk: true },
    'client_id:number': { default: -1 },
    'user_id:number': { default: -1 },
    'company_id:number': { default: -1 },
    'business_area_id:number': { default: -1 },
    'users_ids:number': { default: -1 },
    'name:string': {},
    'start:date': {},
    'end:date': {},
    'is_archived:bool': {},
    'reminder_days:number': {},
    'activity_type:string': {},
    'activity_comment_id:number': { default: -1 },
    'activity_document_id:number': { default: -1 },
    'activity_causer_id:number': { default: -1 },
    'created_at:date': {},
    'updated_at:date': {},
    'deleted_at:date': {},
  },
})
  .queryName('get-workflow-data')
  .handler(
    async (
      table,
      { businessAreaIds, sort, filters, search, selectedCompanyId, selectedClientId }: IDataArg,
    ): Promise<IGetWorkflowData[]> => {
      const res: IGetWorkflowData[] = (await table()
        .query('select', [
          'id',
          'name',
          'start AS startDate',
          'end AS endDate',
          'user_id AS userId',
          'miles',
          'client_id AS client',
          'created_at AS createdAt',
          'is_archived AS isArchived',
          'company_id AS companyId',
          'business_area_id AS businessAreaId',
        ])
        .where([
          ['business_area_id', 'NOT IN', businessAreaIds],
          ...(selectedCompanyId ? ['AND', ['company_id', '=', selectedCompanyId]] : []),
          ...(selectedClientId ? ['AND', ['client_id', '=', selectedClientId]] : []),
        ])
        .graph([
          {
            single: true,
            key: 'clientId',
            with: { table: 'client' },
            on: createWhereQuery<Client, { workflow: Workflow }>(
              ({ id }, { workflow }) => id === workflow.client_id,
            ),
            select: ['name AS clientName', 'id AS clientId'],
          },
          {
            key: 'miles',
            with: { table: 'workflow_mile' },
            on: createWhereQuery<WorkflowMile, { workflow: Workflow }>(
              ({ workflow_id }, { workflow }) => workflow_id === workflow.id,
            ),
            select: ['id AS mileId', 'name AS mileName', 'status', 'steps'],
            graph: {
              key: 'steps',
              with: { table: 'workflow_step' },
              on: createWhereQuery<WorkflowStep, { workflow_mile: WorkflowMile }>(
                ({ workflow_mile_id }, { workflow_mile }) => workflow_mile_id === workflow_mile.id,
              ),
              select: [
                'id AS stepId',
                'name AS stepName',
                'deadline',
                'client.name AS clientName',
                'client_id AS clientId',
                'status',
                'responsibles AS responsibleUsers',
                'documents',
                'comments',
                'created_at AS createdAt',
                'updated_at AS updatedAt',
                'lastModifyUser',
              ],
              graph: [
                {
                  key: 'responsibles',
                  with: { table: 'user' },
                  on: createWhereQuery<User, { workflow_step: WorkflowStep }>(
                    ({ id }, { workflow_step }) => {
                      return workflow_step.users_ids.includes(id);
                    },
                  ),
                  select: ['id AS userId', 'name AS userName'],
                },
                {
                  key: 'documents',
                  with: { table: 'document' },
                  on: createWhereQuery<Document, { workflow_step: WorkflowStep }>(
                    ({ workflow_step_id }, { workflow_step }) =>
                      workflow_step_id === workflow_step.id,
                  ),
                  select: ['id AS documentId', 'name AS fileName', 'path AS filePath'],
                },
                {
                  key: 'comments',
                  with: { table: 'comment' },
                  on: createWhereQuery<Comment, { workflow_step: WorkflowStep }>(
                    (comment, { workflow_step }) =>
                      workflow_step.comments_ids.includes(comment.id) &&
                      comment.commentable_type === CommentTypes.WORKFLOW_STEP,
                  ),
                  select: ['id AS commentId', 'comment'],
                },
                {
                  key: 'lastModifyUser',
                  with: { table: 'user' },
                  on: createWhereQuery<User, { workflow_step: WorkflowStep }>(
                    ({ id }, { workflow_step }) => workflow_step.last_modify_user_id == id,
                  ),
                  select: ['id AS userId', 'name AS userName'],
                },
              ],
            },
          },
        ])
        .exec()) as IGetWorkflowData[];

      const s1 = sort.direction === 'asc' ? 1 : -1;
      const s2 = sort.direction === 'desc' ? 1 : -1;
      res.sort((a, b) => {
        if (sort.field === 'name') {
          if (a.name.toUpperCase() > b.name.toUpperCase()) return s1;
          if (b.name.toUpperCase() > a.name.toUpperCase()) return s2;
        }
        if (sort.field === 'endDate') {
          return sort.direction === 'desc'
            ? b.endDate.valueOf() - a.endDate.valueOf()
            : a.endDate.valueOf() - b.endDate.valueOf();
        }
        if (sort.field === 'createdAt') {
          return sort.direction === 'desc'
            ? b.createdAt.valueOf() - a.createdAt.valueOf()
            : a.createdAt.valueOf() - b.createdAt.valueOf();
        }
        return 0;
      });

      if (
        filters.clientIds?.length ||
        filters.companyIds?.length ||
        filters.statuses?.length ||
        filters.userIds?.length ||
        filters.archived?.length ||
        search?.length
      ) {
        return res.filter((workflow: IGetWorkflowData) => {
          let isExist = true;

          if (filters.archived?.length) {
            if (filters.archived?.includes('archived')) {
              isExist = workflow.isArchived && !!filters.archived?.includes('archived');
            }

            if (filters.archived?.includes('notArchived')) {
              isExist = !workflow.isArchived && !!filters.archived?.includes('notArchived');
            }
          }

          if (filters.companyIds?.length) {
            isExist = !!filters.companyIds?.includes(workflow.companyId);
          }
          if (filters.clientIds?.length && isExist) {
            isExist = !!filters.clientIds?.includes(workflow.client);
          }
          isExist &&
            workflow.miles.map((mile: IGetWorkflowMiles) => {
              if (mile.steps.length && mile.steps.some((item) => item.status === 'overdue')) {
                mile.status = 'overdue';
              }

              if (filters.statuses?.length && isExist) {
                isExist = !!filters.statuses.includes(mile.status);
              }

              if (filters.userIds?.length && isExist) {
                mile.steps.map((item) => {
                  item.responsibleUsers?.map((r) => {
                    isExist = !!filters.userIds?.includes(r.userId);
                  });
                });
              }
            });

          if (search?.length && isExist) {
            isExist = search.some((word) =>
              workflow.name.toLowerCase().includes(word.toLowerCase()),
            );
          }

          return isExist && workflow;
        });
      }

      return res;
    },
  )
  .queryName('get-filter-modal-data')
  .handler(async (table): Promise<any | undefined> => {
    return (
      (await table()
        .query('select', ['id', 'name', 'start AS startDate', 'end AS endDate', 'miles'])
        .graph({
          key: 'miles',
          with: { table: 'workflow_mile' },
          on: createWhereQuery<WorkflowMile, { workflow: Workflow }>(
            ({ workflow_id }, { workflow }) => workflow_id === workflow.id,
          ),
          select: ['id AS mileId', 'name AS mileName', 'status', 'steps'],
          graph: {
            key: 'steps',
            with: { table: 'workflow_step' },
            on: createWhereQuery<WorkflowStep, { workflow_mile: WorkflowMile }>(
              ({ workflow_mile_id }, { workflow_mile }) => workflow_mile_id === workflow_mile.id,
            ),
            select: [
              'id AS mileId',
              'name AS stepName',
              'deadline',
              'client.name AS clientName',
              'status',
              'users AS responsibleUsers',
              'documents',
              'comments',
              'created_at AS createdAt',
              'updated_at AS updatedAt',
            ],
            graph: [
              {
                single: true,
                key: 'client',
                with: { table: 'client' },
                on: createWhereQuery<Client, { workflow_step: WorkflowStep }>(
                  ({ id }, { workflow_step }) => id === workflow_step.client_id,
                ),
                select: ['name'],
              },
              {
                key: 'users',
                with: { table: 'user' },
                on: createWhereQuery<User, { workflow_step: WorkflowStep }>(
                  ({ id }, { workflow_step }) => workflow_step.users_ids.includes(id),
                ),
                select: ['id AS userId', 'name AS userName'],
              },
              {
                key: 'documents',
                with: { table: 'document' },
                on: createWhereQuery<Document, { workflow_step: WorkflowStep }>(
                  ({ workflow_step_id }, { workflow_step }) =>
                    workflow_step_id === workflow_step.id,
                ),
                select: ['id AS documentId', 'name AS fileName', 'path AS filePath'],
              },
              {
                key: 'comments',
                with: { table: 'comment' },
                on: createWhereQuery<Comment, { workflow_step: WorkflowStep }>(
                  (comment, { workflow_step }) =>
                    workflow_step.comments_ids.includes(comment.id) &&
                    comment.commentable_type === CommentTypes.WORKFLOW_STEP,
                ),
                select: ['id AS commentId', 'comment'],
              },
            ],
          },
        })
        .exec()) as IGetWorkflowData[]
    ).pop();
  })
  .queryName('get-modify-process-modal-data')
  .handler(async (table, { processId }: { processId: number | null }): Promise<any | undefined> => {
    return (
      (await table()
        .query('select', [
          'id',
          'name',
          'business_area_id AS businessAreaId',
          'reminder_days AS reminderDays',
          'company_id AS companyId',
          'client_id AS clientId',
          'end AS endDate',
          'start AS startDate',
          'miles',
        ])
        .where(['id', '=', processId])
        .graph({
          key: 'miles',
          with: { table: 'workflow_mile' },
          on: createWhereQuery<WorkflowMile, { workflow: Workflow }>(
            ({ workflow_id }, { workflow }) => workflow_id === workflow.id,
          ),
          select: ['id', 'name AS mileName', 'status', 'steps'],
          graph: {
            key: 'steps',
            with: { table: 'workflow_step' },
            on: createWhereQuery<WorkflowStep, { workflow_mile: WorkflowMile }>(
              ({ workflow_mile_id }, { workflow_mile }) => workflow_mile_id === workflow_mile.id,
            ),
            select: [
              'id',
              'name AS stepName',
              'deadline',
              'client.name AS clientName',
              'status',
              'users AS responsibleUsers',
              'created_at AS createdAt',
              'updated_at AS updatedAt',
            ],
            graph: [
              {
                single: true,
                key: 'client',
                with: { table: 'client' },
                on: createWhereQuery<Client, { workflow_step: WorkflowStep }>(
                  ({ id }, { workflow_step }) => id === workflow_step.client_id,
                ),
                select: ['name'],
              },
              {
                key: 'users',
                with: { table: 'user' },
                on: createWhereQuery<User, { workflow_step: WorkflowStep }>(
                  ({ id }, { workflow_step }) => workflow_step.users_ids.includes(id),
                ),
                select: ['id as userId', 'name as userName'],
              },
            ],
          },
        })
        .exec()) as IGetWorkflowData[]
    ).pop();
  })
  .make();
