import { ClientPoll } from '@/client_v2/polls/Client.poll';
import { UserPoll } from '@/client_v2/polls/User.poll';
import { createDataHook } from '@/client_v2/hooks/utils/createDataHook';
import { DocumentPoll } from '@/client_v2/polls/Document.poll';
import { CommentPoll } from '@/client_v2/polls/Comment.poll';
import { WorkflowStepPoll } from '@/client_v2/polls/WorkflowStep.poll';
import { WorkflowMilePoll } from '@/client_v2/polls/WorkflowMile.poll';
import { WorkflowPoll } from '@/client_v2/polls/Workflow.poll';
import { WorkflowRepo } from '@/client_v2/db/repositories/Workflow.repo';

export const useModifyProcessModalData = createDataHook({
  polls: [
    ClientPoll,
    UserPoll,
    DocumentPoll,
    CommentPoll,
    WorkflowStepPoll,
    WorkflowMilePoll,
    WorkflowPoll,
  ],
  dataFactory: async (arg: { processId: number | null }) =>
    arg.processId && (await WorkflowRepo.query('get-modify-process-modal-data', arg)),
});
