import { Dispatch } from 'redux';
import {
  REFRESH_ACTIVITY_TABLE_REQUEST,
  REFRESH_ACTIVITY_TABLE_SUCCESS,
  REFRESH_ACTIVITY_TABLE_FAILURE,
  GET_ACTIVITY_TABLE_REQUEST,
  GET_ACTIVITY_TABLE_SUCCESS,
  GET_ACTIVITY_TABLE_FAILURE,
  EXPORT_ACTIVITY_TABLE_REQUEST,
  EXPORT_ACTIVITY_TABLE_SUCCESS,
  EXPORT_ACTIVITY_TABLE_FAILURE,
  GET_MORE_ACTIVITY_REQUEST,
  GET_MORE_ACTIVITY_SUCCESS,
  GET_MORE_ACTIVITY_FAILURE,
} from '@/redux/activityFeed/activityFeed.types';
import RestClient from '@/client_v2/rest/';
import {
  IExportActivityFeedsParams,
  IRefreshActivityFeedsParams,
} from '@/redux/activityFeed/activityFeed.interfaces';
import { AppState } from '@/redux/store';

export const getActivityFeedsAction = () => async (dispatch: Dispatch) => {
  dispatch({
    type: GET_ACTIVITY_TABLE_REQUEST,
  });

  try {
    const res = await RestClient.ActivityFeeds.get();

    dispatch({
      type: GET_ACTIVITY_TABLE_SUCCESS,
      payload: {
        data: res.data.data,
        nextLink: res.data.links.next,
      },
    });
  } catch (error) {
    dispatch({
      type: GET_ACTIVITY_TABLE_FAILURE,
      error,
    });
  }
};

export const getRefreshActivityTableAction =
  (refreshParams: IRefreshActivityFeedsParams) => async (dispatch: Dispatch) => {
    dispatch({
      type: REFRESH_ACTIVITY_TABLE_REQUEST,
    });

    try {
      const res = await RestClient.ActivityFeeds.filter(refreshParams);

      dispatch({
        type: REFRESH_ACTIVITY_TABLE_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({
        type: REFRESH_ACTIVITY_TABLE_FAILURE,
        error,
      });
    }
  };

export const exportActivityFeeds =
  (exportParams?: IExportActivityFeedsParams) => async (dispatch: Dispatch) => {
    dispatch({
      type: EXPORT_ACTIVITY_TABLE_REQUEST,
    });

    try {
      const res = await RestClient.ActivityFeeds.export(exportParams!);
      dispatch({
        type: EXPORT_ACTIVITY_TABLE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: EXPORT_ACTIVITY_TABLE_FAILURE,
        error,
      });
    }
  };

export const getMoreActivityFeed = () => async (dispatch: Dispatch, getState: () => AppState) => {
  try {
    if (getState().activityFeeds.nextLink) {
      dispatch({
        type: GET_MORE_ACTIVITY_REQUEST,
      });
      const response = await RestClient.ActivityFeeds.loadMore(getState().activityFeeds.nextLink);
      dispatch({
        type: GET_MORE_ACTIVITY_SUCCESS,
        payload: { data: response.data, links: response.links },
      });
    }
  } catch (e) {
    dispatch({
      type: GET_MORE_ACTIVITY_FAILURE,
      error: e,
    });
  }
};
