// here comes all the endpoint communication
import { Auth } from '@/client/auth';
import { ActivityLog } from '@/client/activityLog';
import { UserManagement } from '@/client/userManagement';
import { WorkflowManagement } from './workfowManagement';
import { ClientManagement } from './clientManagement';
import { CompanyManagement } from './companyManagement';
import { DocumentManagement } from './documentManagement';
import { TagManagement } from './tagManagement';

class Client {
  auth = new Auth();
  activityLog = new ActivityLog();
  clientManagement = new ClientManagement();
  companyManagement = new CompanyManagement();
  documentManagement = new DocumentManagement();
  tagManagement = new TagManagement();
  userManagement = new UserManagement();
  workflowManagement = new WorkflowManagement();
}

export default new Client();
