import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { PostClient } from '@/client_v2/rest/types/PostClient';
import { postComments } from '@/client_v2/rest/Comments/postComments';
import { API_CLIENTS } from '@/client_v2/rest/Clients/URLS';
import { ClientPoll } from '@/client_v2/polls/Client.poll';

export const postClient = async (
  data: PostClient,
  newComments?: { comments: string[]; userId: number },
) => {
  const res = await axiosInstance.post<{ data: { id: number } }>(API_CLIENTS, data);

  if (newComments && newComments.comments && newComments.comments.length && newComments.userId) {
    await postComments({
      entityId: res.data.data.id,
      entityType: 'clients',
      comments: newComments,
    });
  }
  await ClientPoll.trigger();
  return res;
};
