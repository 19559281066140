import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { IPatchStepStatus } from '@/client_v2/rest/types/PatchWorkflow';
import { $ID_PARAM } from '@/client_v2/rest/Workflow/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';

import { WorkflowStepPoll } from '@/client_v2/polls/WorkflowStep.poll';
import { postComments } from '@/client_v2/rest/Comments/postComments';
import { deleteComment } from '@/client_v2/rest/Comments/deleteComment';
import { API_WORKFLOWS_STEP_$ID } from '@/client_v2/rest/WorkflowStep/URLS';
import { WorkflowPoll } from '@/client_v2/polls/Workflow.poll';
import { WorkflowStatuses } from '@/resources/constants/shared';

export const patchWorkflowSteps = async (
  data: IPatchStepStatus,
  newComments?: { comments: string[]; userId: number },
  deletedComments?: { commentId: number; comment: string }[],
) => {
  const res = await axiosInstance.patch<{ data: { id: number } }>(
    createUrl(API_WORKFLOWS_STEP_$ID, {
      params: {
        [$ID_PARAM]: data.id.toString(),
      },
    }),
    { status: data.status },
  );
  if (newComments && newComments.comments && newComments.comments.length && newComments.userId) {
    await postComments({
      entityId: res.data.data.id,
      entityType: 'workflow-steps',
      comments: newComments,
    });
  }
  if (deletedComments && deletedComments.length) {
    await Promise.all(
      deletedComments.map((comment) =>
        deleteComment({
          commentId: comment.commentId,
          entityType: 'workflow-steps',
          entityId: res.data.data.id,
        }),
      ),
    );
  }
  await WorkflowStepPoll.trigger();

  if (
    (deletedComments && deletedComments.length) ||
    (newComments && newComments.comments && newComments.comments.length) ||
    data.status === WorkflowStatuses.DONE
  ) {
    await WorkflowPoll.trigger();
  }
  return res;
};
