import '@/layouts/AccountSettingSidebarLayout/style.scss';
import { FC, ReactNode } from 'react';
import { CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('layouts-account-setting-sidebar-layout');
const titleClass = scope.and('title');
const usersClass = scope.and('users');
const usersMarkClass = scope.and('usersMark');
const companiesClass = scope.and('companies');
const companiesMarkClass = scope.and('companiesMark');
const customersClass = scope.and('customers');
const customersMarkClass = scope.and('customersMark');
const activityFeedClass = scope.and('activityFeed');
const activityFeedMarkClass = scope.and('activityFeedMark');

interface Props {
  title?: ReactNode;
  users?: ReactNode;
  usersMark?: ReactNode;
  companies?: ReactNode;
  companiesMark?: ReactNode;
  customers?: ReactNode;
  customersMark?: ReactNode;
  activityFeed?: ReactNode;
  activityFeedMark?: ReactNode;
}

export const AccountSettingSidebarLayout: FC<Props> = ({
  title,
  users,
  usersMark,
  companies,
  companiesMark,
  customers,
  customersMark,
  activityFeed,
  activityFeedMark,
}) => (
  <div className={scope}>
    <div className={titleClass}>{title}</div>
    <div className={usersClass}>{users}</div>
    <div className={usersMarkClass}>{usersMark}</div>
    <div className={companiesClass}>{companies}</div>
    <div className={companiesMarkClass}>{companiesMark}</div>
    <div className={customersClass}>{customers}</div>
    <div className={customersMarkClass}>{customersMark}</div>
    <div className={activityFeedClass}>{activityFeed}</div>
    <div className={activityFeedMarkClass}>{activityFeedMark}</div>
  </div>
);
