import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { PostTags } from '@/client_v2/rest/types/PostTags';
import { API_DOCUMENT_$DOCUMENT_ID_TAGS, $DOCUMENT_ID_PARAM } from '@/client_v2/rest/Tags/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';
import { TagPoll } from '@/client_v2/polls/Tag.poll';

export const postTags = async ({ documentId, tags, clientId }: PostTags) => {
  const res = await axiosInstance.post(
    createUrl(API_DOCUMENT_$DOCUMENT_ID_TAGS, {
      params: {
        [$DOCUMENT_ID_PARAM]: documentId.toString(),
      },
    }),
    {
      tags: tags,
      clientId: clientId,
    },
  );
  await TagPoll.trigger();
  return res;
};
