import * as Endpoints from '@/client/endpoints';
import * as Interfaces from '@/client/clientManagementInterfaces';
import axiosInstance from '@/client_v2/rest/axios/axiosInstance';

//CLIENT MANAGEMENT CALLS
export class ClientManagement {
  clientSearch = async (
    fetchbody: Interfaces.IClientSearchRequest,
  ): Promise<Interfaces.IClientSearchResponse> => {
    const response = await axiosInstance.post(Endpoints.CLIENT_SEARCH, fetchbody);
    return response.data;
  };

  createClient = async (
    client: Interfaces.ICreateClientRequest,
  ): Promise<Interfaces.ICreateClientResponse> => {
    const response = await axiosInstance.post(Endpoints.CREATE_CLIENT, client);
    return response.data;
  };

  putClient = async (
    clientId: number,
    client: Interfaces.IUpdateClientRequest,
  ): Promise<Interfaces.IUpdateClientResponse> => {
    const response = await axiosInstance.put(
      Endpoints.UPDATE_CLIENT.replace('{client}', clientId.toString()),
      client,
    );
    return response.data;
  };

  patchClient = async (
    clientId: number,
    client: Interfaces.IUpdateClientRequest,
  ): Promise<Interfaces.IUpdateClientResponse> => {
    const response = await axiosInstance.patch(
      Endpoints.UPDATE_CLIENT.replace('{client}', clientId.toString()),
      client,
    );
    return response.data;
  };

  deleteClient = async (clientId: number): Promise<Interfaces.IDeleteClientResponse> => {
    const response = await axiosInstance.delete(
      Endpoints.DELETE_CLIENT.replace('{client}', clientId.toString()),
    );
    return response.data;
  };

  restoreClient = async (clientId: number): Promise<Interfaces.IRestoreClientResponse> => {
    const response = await axiosInstance.post(
      Endpoints.RESTORE_CLIENT.replace('{client}', clientId.toString()),
    );
    return response.data;
  };

  fetchClientComments = async (): Promise<Interfaces.IFetchClientCommentsResponse> => {
    const response = await axiosInstance.get(Endpoints.FETCH_CLIENT_COMMENTS_SEARCH);
    return response.data;
  };

  createClientComment = async (
    clientId: number,
    comment: Interfaces.ICreateClientCommentRequest,
  ): Promise<Interfaces.ICreateClientCommentResponse> => {
    const response = await axiosInstance.post(
      Endpoints.CREATE_CLIENT_COMMENT.replace('{client}', clientId.toString()),
      comment,
    );
    return response.data;
  };

  putClientComment = async (
    clientId: number,
    commentId: number,
    client: Interfaces.IUpdateClientCommentRequest,
  ): Promise<Interfaces.IUpdateClientCommentResponse> => {
    const response = await axiosInstance.put(
      Endpoints.UPDATE_CLIENT_COMMENT.replace('{client}', clientId.toString()).replace(
        '{comment}',
        commentId.toString(),
      ),
      client,
    );
    return response.data;
  };

  patchClientComment = async (
    clientId: number,
    commentId: number,
    client: Interfaces.IUpdateClientCommentRequest,
  ): Promise<Interfaces.IUpdateClientCommentResponse> => {
    const response = await axiosInstance.patch(
      Endpoints.UPDATE_CLIENT_COMMENT.replace('{client}', clientId.toString()).replace(
        '{comment}',
        commentId.toString(),
      ),
      client,
    );
    return response.data;
  };

  deleteClientComment = async (
    clientId: number,
    commentId: number,
  ): Promise<Interfaces.IDeleteClientCommentResponse> => {
    const response = await axiosInstance.delete(
      Endpoints.DELETE_CLIENT_COMMENT.replace('{client}', clientId.toString()).replace(
        '{comment}',
        commentId.toString(),
      ),
    );
    return response.data;
  };
}
