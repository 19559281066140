import { FC, useMemo } from 'react';
import '@/components/Table/style.scss';
import { TableItem as View } from '@/components/Table/TableItem/TableItem.view';
import { ComponentHook } from '@/types/ComponentHook';
import { useDinCSS } from '@/hooks/useDinCSS';
import { TableTypes } from '@/components/Table/Table';

interface Props {
  column: number;
  columnSpan?: number;
  row: number;
  rowSpan?: number;
  header?: boolean;
  type?: TableTypes;
  isHidden?: boolean;
}

export const useTableItem: ComponentHook<Props, typeof View> = ({
  header,
  isHidden,
  column,
  row,
  columnSpan = 1,
  rowSpan = 1,
  type,
}) => {
  const [dynPosClassName, renderDynPosClassName] = useDinCSS();
  const isDark = useMemo(
    () => (type === 'flat' || header ? false : !(row % 2)),
    [row, header, type],
  );

  renderDynPosClassName({
    gridColumnStart: column.toString(),
    gridColumnEnd: `span ${columnSpan}`,
    gridRowStart: row.toString(),
    gridRowEnd: `span ${rowSpan}`,
  });

  return {
    dynPosClassName,
    header,
    isDark,
    isHidden,
  };
};

export const TableItem: FC<Props> = ({ children, ...props }) => {
  const computedProps = useTableItem(props);
  return <View {...computedProps}>{children}</View>;
};
