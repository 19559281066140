import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ComponentHook } from '@/types/ComponentHook';
import { DropDownBox as View } from '@/components/DropDownBox/DropDownBox.view';

interface Props {
  show?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: { view: ReactNode; data: any; default?: boolean }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect(data: any): void;
  maxHeight?: string;
  onClickOutside?(event: React.MouseEvent<HTMLDivElement>): void;
}

// TODO: implement onClickOutside (to close m)
export const useDropDownBox: ComponentHook<Props, typeof View> = ({
  maxHeight,
  show: _show,
  data,
  onSelect,
}) => {
  const [render, setRender] = useState(_show);
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const [show, setShow] = useState(_show);
  const refContainer = useRef<HTMLDivElement>(null!);

  const defaultItemData = useMemo(() => data.find(({ default: isDefault }) => isDefault), [data]);
  const filteredData = useMemo(() => data.filter(({ default: isDefault }) => !isDefault), [data]);

  const items = useMemo(() => filteredData.map(({ view }) => view), [filteredData]);

  const onClickItem = useCallback(
    (idx: number) => {
      onSelect(filteredData[idx].data);
    },
    [filteredData, onSelect],
  );

  const onTransitionEnd = useCallback(() => {
    if (!show) setRender(false);
  }, [show]);

  const onClickDefault = useCallback(() => {
    defaultItemData && onSelect(defaultItemData.data);
  }, [defaultItemData, onSelect]);

  useEffect(() => {
    if (_show) {
      setRender(true);
      setTimeout(() => setShow(true));
    } else {
      setShow(false);
    }
  }, [setRender, setShow, _show]);

  useEffect(() => {
    const handler = () => {
      if (!refContainer.current) return;
      const { x, y, height } = refContainer.current!.getBoundingClientRect();
      setPos((state) => {
        const calcY = y + height;
        if (state.x === x && state.y === calcY) return state;
        return {
          x,
          y: calcY,
        };
      });
      requestAnimationFrame(handler);
    };
    requestAnimationFrame(handler);
  }, [refContainer, setPos]);

  return {
    maxHeight,
    refContainer,
    show,
    render,
    onTransitionEnd,
    items,
    defaultItem: defaultItemData?.view,
    onClickItem,
    onClickDefault,
    ...pos,
  };
};

export const DropDownBox: FC<Props> = ({ children, ...props }) => {
  const computedProps = useDropDownBox(props);
  return <View {...computedProps}>{children}</View>;
};
