/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import '@/components/ScrollBox/style.scss';
import { FC } from 'react';
import { Scrollbar as View } from 'smooth-scrollbar-react';
import { ComponentHook } from '@/types/ComponentHook';
import { useDinCSS } from '@/hooks/useDinCSS';
import { cn } from '@/components/utils';

interface Props {
  maxHeight?: string;
  maxWidth?: string;
  yBarPos?: 'left' | 'right';
  yBarTransform?: string;
  xBarPos?: 'top' | 'bottom';
  xBarTransform?: string;
  contentClassName?: string;
  outsideBars?: boolean;
  scrollAreaElement?: HTMLElement;
}

const config: any = {
  overscroll: {
    effect: 'bounce',
  },
};

export const useScrollBox: ComponentHook<
  Props,
  typeof View,
  { contentClassName?: string; scrollAreaElement?: HTMLElement }
> = ({
  maxHeight,
  maxWidth,
  contentClassName,
  yBarPos = 'right',
  yBarTransform,
  xBarPos = 'bottom',
  xBarTransform,
  outsideBars,
  scrollAreaElement,
  ...props
}) => {
  const [dynClassName, setCssClass] = useDinCSS();
  const [dynYBarPos, setYBarPos] = useDinCSS((scope) => `${scope} .scrollbar-track-y`);
  const [dynYBarTransform, setYBarTransform] = useDinCSS((scope) => `${scope} .scrollbar-track-y`);
  const [dynXBarPos, setXBarPos] = useDinCSS((scope) => `${scope} .scrollbar-track-x`);
  const [dynXBarTransform, setXBarTransform] = useDinCSS((scope) => `${scope} .scrollbar-track-x`);
  const [padding, setPadding] = useDinCSS();

  setCssClass({
    maxHeight,
    maxWidth,
  });

  if (yBarPos === 'left')
    setYBarPos({
      right: 'unset',
      transform: yBarTransform && `translateX(${yBarTransform})`,
    });

  if (yBarTransform)
    setYBarTransform({
      transform: `translateX(${yBarTransform})`,
    });

  if (xBarPos === 'top')
    setXBarPos({
      top: '0px',
      bottom: 'unset',
    });

  if (xBarTransform)
    setXBarTransform({
      transform: `translateY(${xBarTransform})`,
    });

  if (outsideBars)
    setPadding({
      paddingLeft: yBarPos === 'left' && '8px',
      paddingRight: yBarPos === 'right' && '8px',
      paddingTop: xBarPos === 'top' && '8px',
      paddingBottom: xBarPos === 'bottom' && '8px',
    });

  return {
    ...props,
    className: cn(dynClassName, dynYBarPos, dynXBarPos, dynYBarTransform, dynXBarTransform),
    contentClassName: cn(contentClassName, padding),
    scrollAreaElement,
  };
};

export const ScrollBox: FC<Props> = ({ children, ...props }) => {
  const { contentClassName, scrollAreaElement, ...computedProps } = useScrollBox(props);
  return (
    <View {...computedProps} plugins={config} delegateTo={scrollAreaElement}>
      <div>
        <div className={contentClassName}>{children}</div>
      </div>
    </View>
  );
};
