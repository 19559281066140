import { InanoSQLConfig } from '@nano-sql/core/lib/interfaces';
import db from '@/client_v2/db';
import { migrateV2 } from '@/client_v2/db/migrate/migrateV2';
import { DB_NAME, CURRENT_DB_VERSION } from '@/client_v2/db/config';
import { DBTables } from '@/client_v2/db/DBTables';
import { DBTypes } from './DBTypes';

export const createDatabase = async () => {
  const config: InanoSQLConfig = {
    id: DB_NAME,
    mode: 'PERM',
    tables: DBTables,
    types: DBTypes,
    version: CURRENT_DB_VERSION,
    onVersionUpdate: async (prevVersion) => {
      switch (prevVersion) {
        case 1:
          return await migrateV2();
        default:
          return prevVersion;
      }
    },
  };

  await db.createDatabase(config);
};
