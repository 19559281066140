import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { BusinessAreaRes } from '@/client_v2/rest/types/BusinessAreasRes';
import { API_BUSINESS_AREA_$ID, $ID_PARAM } from '@/client_v2/rest/BusinessAreas/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';

export const getBusinessAreaById = (id: number) =>
  axiosInstance.get<BusinessAreaRes>(
    createUrl(API_BUSINESS_AREA_$ID, {
      params: {
        [$ID_PARAM]: id.toString(),
      },
    }),
  );
