import '@/layouts/ProcessesTableLayout/style.scss';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '@/components/Text';
import Icon from '@/components/Icon';
import Badge from '@/components/Badge';
import { ICommentCompaniesData } from '@/client_v2/db/repositories/Company.repo';
import CompanyContentCommentsLayout from '@/layouts/CompanyContentCommentsLayout';

interface Props {
  badgeComments: ICommentCompaniesData[];
}

const useCompaniesContentComments = ({ badgeComments }: Props) => {
  const { t } = useTranslation();
  const commentHeaderText = t('companyScreen.comments');
  const commentInputTitleText = t('companyScreen.comment');
  const addCommentButtonText = t('companyScreen.addComment');
  const emptyCommentErrorMessage = t('error.emptyBadgeError');
  const sameCommentErrorMessage = t('error.sameBadgeError');

  return {
    commentHeaderText,
    commentInputTitleText,
    addCommentButtonText,
    emptyCommentErrorMessage,
    sameCommentErrorMessage,
    badgeComments,
  };
};

export const CompanyContentComments: FC<Props> = (props) => {
  const { commentHeaderText, badgeComments } = useCompaniesContentComments(props);

  return (
    <CompanyContentCommentsLayout
      commentHeader={
        <>
          <Icon type="chat" colorStyle="lightGray" />
          <Text className="font-weight-bold ml-2" type="style28">
            {commentHeaderText}:
          </Text>
        </>
      }
      badges={badgeComments.map((item) => (
        <div className="p-1" key={item.commentId}>
          <Badge content={item.comment} colorStyle="lightGray" cursor="none" size="small" />
        </div>
      ))}
    />
  );
};
