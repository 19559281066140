import '@/components/Badge/style.scss';
import { FC, MouseEventHandler, ReactNode } from 'react';
import { cn, CreateScopeCSS } from '@/components/utils';
import Icon from '@/components/Icon';
import Text from '@/components/Text';

const scope = CreateScopeCSS('components-badge');
const color = scope.and('color');
const height = scope.and('height');
const icon = scope.and('icon');
const pointer = scope.and('pointer');
const noCursorPointer = scope.and('noCursorPointer');

const iconColorMap = {
  primary: 'white',
  lightGray: 'lightGray',
  lightBlue: 'primary',
} as const;
export interface BadgeProps {
  content: ReactNode;
  colorStyle: keyof typeof iconColorMap;
  size: 'small' | 'medium';
  selfClosing?: boolean;
  uppercase?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  cursor?: 'none' | 'pointer';
}

export const Badge: FC<BadgeProps> = ({
  colorStyle,
  selfClosing,
  uppercase,
  size,
  content,
  onClick,
  cursor,
  ...badgeProps
}) => (
  <div
    className={cn(
      scope,
      colorStyle && color.and(colorStyle),
      size && height.and(size),
      icon && cursor === 'none' ? noCursorPointer : pointer,
    )}
    {...badgeProps}
  >
    <Text
      type={size === 'small' ? 'style29' : 'style30'}
      capitalization={uppercase ? 'uppercase' : 'lowercase'}
    >
      {content}
    </Text>
    {selfClosing && (
      <Icon
        className={cn('ml-2', size && icon.and(size))}
        type="x"
        onClick={onClick}
        colorStyle={iconColorMap[colorStyle]!}
        circle={size === 'medium'}
      />
    )}
  </div>
);
