import { AxiosError } from 'axios';
import { IFetchCompanySearchResponseDatum } from '@/client/companyManagementInterfaces';

export const GET_COMPANY_LIST_REQUEST = 'GET_COMPANY_LIST_REQUEST';
export const GET_COMPANY_LIST_SUCCESS = 'GET_COMPANY_LIST_SUCCESS';
export const GET_COMPANY_LIST_FAILURE = 'GET_COMPANY_LIST_FAILURE';

export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAILURE = 'DELETE_COMPANY_FAILURE';

export const EDIT_COMPANY_REQUEST = 'EDIT_COMPANY_REQUEST';
export const EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS';
export const EDIT_COMPANY_FAILURE = 'EDIT_COMPANY_FAILURE';

export const SET_COLLAPSE_COMPANY_ID = 'SET_COLLAPSE_COMPANY_ID';

export const SET_MODAL_OPEN = 'SET_MODAL_OPEN';

export const SET_COMPANY_SCROLLBAR_POSITION = 'SET_COMPANY_SCROLLBAR_POSITION';

export interface ISetCollapseCompanyId {
  type: typeof SET_COLLAPSE_COMPANY_ID;
  payload: number;
}

export interface IeditCompanyRequestAction {
  type: typeof EDIT_COMPANY_REQUEST;
}
export interface IeditCompanySuccessAction {
  type: typeof EDIT_COMPANY_SUCCESS;
}
export interface IeditCompanyFailureAction {
  type: typeof EDIT_COMPANY_FAILURE;
  error: AxiosError;
}
export interface IdeleteCompanyRequestAction {
  type: typeof DELETE_COMPANY_REQUEST;
}
export interface IdeleteCompanySuccessAction {
  type: typeof DELETE_COMPANY_SUCCESS;
}
export interface IdeleteCompanyFailureAction {
  type: typeof DELETE_COMPANY_FAILURE;
  error: AxiosError;
}

export interface IgetCompaniesRequestAction {
  type: typeof GET_COMPANY_LIST_REQUEST;
}

export interface IgetCompaniesSuccessAction {
  type: typeof GET_COMPANY_LIST_SUCCESS;
  payload: IFetchCompanySearchResponseDatum;
}

export interface IgetCompaniesFailureAction {
  type: typeof GET_COMPANY_LIST_FAILURE;
  error: AxiosError;
}

export interface ISetCompanyScrollbarPositon {
  type: typeof SET_COMPANY_SCROLLBAR_POSITION;
  payload: number;
}

export type CompaniesActionTypes =
  | ISetCollapseCompanyId
  | IgetCompaniesRequestAction
  | IgetCompaniesSuccessAction
  | IgetCompaniesFailureAction
  | IeditCompanyRequestAction
  | IeditCompanySuccessAction
  | IeditCompanyFailureAction
  | IdeleteCompanyRequestAction
  | IdeleteCompanySuccessAction
  | IdeleteCompanyFailureAction
  | ISetCompanyScrollbarPositon;
