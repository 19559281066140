import '@/components/Card/style.scss';
import { FC } from 'react';
import { cn, CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('components-card');
const bg = scope.and('bgColor');
const borderRadius = scope.and('borderRadius');
const boxShadow = scope.and('shadow');
const boxPaddingV = scope.and('paddingV');
const boxPaddingH = scope.and('paddingH');
const boxMarginV = scope.and('marginV');
const boxMarginH = scope.and('marginH');

export interface CardProps {
  bgColorStyle: 'white' | 'light' | 'lighter' | 'dark' | 'darkGray' | 'lightGray';
  shadow?: boolean;
  rounded?: boolean;
  className?: string;
  paddingV?: 'nonePadding' | 'extraSmall' | 'small' | 'medium' | 'large';
  paddingH?: 'nonePadding' | 'extraSmall' | 'small' | 'medium' | 'large';
  marginV?: 'extraSmall' | 'small' | 'medium' | 'large';
  marginH?: 'extraSmall' | 'small' | 'medium' | 'large';
  id?: string;
}

export const Card: FC<CardProps> = ({
  children,
  bgColorStyle,
  shadow,
  rounded,
  className,
  paddingV,
  paddingH,
  marginV,
  marginH,
  id,
}) => (
  <div
    className={cn(
      scope,
      bg.and(bgColorStyle),
      rounded && borderRadius,
      shadow ? boxShadow : '',
      className,
      paddingV && boxPaddingV.and(paddingV),
      paddingH && boxPaddingH.and(paddingH),
      marginV && boxMarginV.and(marginV),
      marginH && boxMarginH.and(marginH),
      className,
    )}
    id={id}
  >
    {children}
  </div>
);
