import { UserPoll } from '@/client_v2/polls/User.poll';
import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { UserRes } from '@/client_v2/rest/types/UsersRes';
import { API_USERS_$ID, $ID_PARAM } from '@/client_v2/rest/Users/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';

export const deleteUser = async (id: number) => {
  const res = await axiosInstance.delete<UserRes>(
    createUrl(API_USERS_$ID, {
      params: {
        [$ID_PARAM]: id.toString(),
      },
    }),
  );
  await UserPoll.trigger();
  return res;
};
