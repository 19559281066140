import { FC, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FileDrop } from 'react-file-drop';
import Button from '@/components/Button';
import Text from '@/components/Text';
import UnderlineInput from '@/components/Input/UnderlineInput';
import CircleImage from '@/components/CircleImage';
import AddCompanyLayout from '@/layouts/AddCompanyLayout';
import Badge from '@/components/Badge';
import { convertFileListToFileArr } from '@/resources/utils';
import Icon from '@/components/Icon';
import MultiselectDropdown from '@/components/MultiselectDropdown';
import { useCompanyModalData } from '@/client_v2/hooks/useCompanyModalData';
import { createOnError } from '@/redux/message/message.callback';
import { useAsyncCallback } from '@/hooks/useAsyncCallback';
import RestClient from '@/client_v2/rest';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import useAddComment from '@/hooks/useAddComment';
import ErrorFeedback from '@/components/ErrorFeedback';
import { BASE_URL } from '@/client/urls';
import { AppState } from '@/redux/store';
import { useUserData } from '@/client_v2/hooks/useUserData';
import { useClientsData } from '@/client_v2/hooks/useClientsData';
import { setCompanyScrollbarPosition } from '@/redux/companyManagement/companyManagement.actions';
import { Authorities } from './interfaces';

interface Props {
  close(): void;
  companyId?: number;
}

const useAddUserModal = ({ close, companyId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const clientDropDownHeader = t('accountSettings.contents.companies.client');
  const selectClientDropdownText = t('accountSettings.contents.companies.selectClient');
  const nameInputText = t('accountSettings.contents.companies.name');
  const commentsText = t('accountSettings.contents.companies.comments');
  const newCommentText = t('accountSettings.contents.companies.newComment');
  const addCommentButtonText = t('accountSettings.contents.companies.addComment');
  const saveCompanyButtonText = t('accountSettings.contents.companies.saveCompany');
  const createCompanyButtonText = t('accountSettings.contents.companies.createCompany');
  const contactDropdownHeader = t('accountSettings.contents.companies.contact');
  const contactDropdownText = t('accountSettings.contents.companies.contactText');
  const taxNumberInputText = t('accountSettings.contents.companies.taxNumber');
  const companyImageText = t('accountSettings.contents.companies.companyImageDescription');
  const companyImageTypeText = t('accountSettings.contents.companies.imageTypeDescription');
  const uploadButtonText = t('upload.buttonText');
  const emptyCommentErrorMessage = t('error.emptyBadgeError');
  const sameCommentErrorMessage = t('error.sameBadgeError');
  const noClientError = t('error.noClientError');
  const noTaxNumberError = t('error.noTaxNumberError');
  const noCompanyNameError = t('error.noCompanyNameError');

  const userId = useSelector((state: AppState) => state.user.user?.id);
  const user = useSelector((state: AppState) => state.user.user);
  const [isLoading, data] = useCompanyModalData({
    renderOnUpdate: false,
    triggerOnce: companyId || true,
    onError: createOnError(dispatch),
  });

  const [, users] = useUserData({
    triggerOnce: true,
    renderOnUpdate: false,
    onError: createOnError(dispatch),
  });

  const [clientsLoading, clients] = useClientsData({
    renderOnUpdate: false,
    triggerOnce: true,
    onError: createOnError(dispatch),
  });

  const [selectedClient, setSelectedClient] = useState<{ id: number; name: string }>();

  const [contactDropdownTextData, setContactDropdownData] =
    useState<{ id: number; name: string }[]>();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const {
    comments,
    setComments,
    comment,
    setCommentInput,
    addComment,
    removeComment,
    showEmptyCommentError,
    showSameCommentError,
  } = useAddComment();

  const [companyName, setCompanyName] = useState<string>('');
  const [taxId, setTaxId] = useState<string>('');
  const [file, setFile] = useState<File>();
  const [fileURL, setFileURL] = useState<string>();

  const [save, isSaving] = useAsyncCallback(
    async () => {
      dispatch(setCompanyScrollbarPosition(undefined));
      const isEdit = !!companyId;
      let clientId: number, contactUserId: number[] | null, name: string, taxNumber: string;
      contactUserId = null;
      if (selectedClient) {
        clientId = selectedClient.id;
      } else {
        throw noClientError;
      }

      if (contactDropdownTextData) {
        contactUserId = contactDropdownTextData.map((item) => item.id);
      }

      if (companyName) {
        name = companyName;
      } else {
        throw noCompanyNameError;
      }
      if (taxId) {
        taxNumber = taxId;
      } else {
        throw noTaxNumberError;
      }

      if (isEdit && data.company) {
        await RestClient.Companies.patch(
          {
            file,
            data: {
              id: companyId!,
              clientId,
              contactIds: contactUserId,
              name,
              taxNumber,
            },
          },
          {
            userId: userId!,
            comments: comments
              .filter(
                (newComment) =>
                  !data.company.comments.some(
                    (oldComment) => newComment.comment === oldComment.comment,
                  ),
              )
              .map(({ comment }) => comment),
          },
          data.company &&
            data.company.comments.filter(
              (oldComment) =>
                !comments.some((newComment) => oldComment.comment === newComment.comment),
            ),
        );
        close();
      } else {
        await RestClient.Companies.post(
          {
            file,
            data: {
              clientId,
              contactIds: contactUserId!,
              name,
              taxNumber,
            },
          },
          {
            userId: userId!,
            comments: comments.map(({ comment }) => comment),
          },
        );
        close();
      }
    },
    createOnError(dispatch),
    [companyId, comments, selectedClient, contactDropdownTextData, companyName, taxId, file, close],
  );

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const file = convertFileListToFileArr(files!)[0];
    if (file) {
      setFile(file);
      setFileURL(URL.createObjectURL(file));
    }
  };

  const onFileSelectClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const onTargetClick = () => {
    fileInputRef.current!.click();
  };

  const filteredContactUsers = selectedClient
    ? users &&
      users.filter(
        (data) =>
          data.clients &&
          data.clients.includes(selectedClient.id) &&
          Authorities.ClientUser1.toString() != data.authority &&
          Authorities.ClientUser2.toString() != data.authority,
      )
    : users && users.filter((data) => Authorities.SuperAdmin.toString() === data.authority);

  useEffect(() => {
    if (isLoading) return;
    if (!data.company) return;
    if (data.company.contact) setContactDropdownData(data.company.contact);
    if (data.company.client && data.company.client.id)
      setSelectedClient({ id: data.company.client.id, name: data.company.client.name });

    setCompanyName(data.company.name);
    setTaxId(data.company.taxId);
    setComments(data.company.comments);
    if (data.company.profileImageUrl) setFileURL(BASE_URL + data.company.profileImageUrl);
  }, [
    isLoading,
    data,
    setSelectedClient,
    setContactDropdownData,
    setCompanyName,
    setTaxId,
    setComments,
    setFileURL,
  ]);

  return {
    onTargetClick,
    onFileSelectClick,
    fileURL,
    clientDropDownHeader,
    selectClientDropdownText,
    nameInputText,
    commentsText,
    newCommentText,
    addCommentButtonText,
    saveCompanyButtonText,
    createCompanyButtonText,
    contactDropdownHeader,
    taxNumberInputText,
    companyImageText,
    companyImageTypeText,
    uploadButtonText,
    contactDropdownTextData,
    setContactDropdownData,
    contactDropdownText,
    companyName,
    setCompanyName,
    taxId,
    setTaxId,
    addComment,
    removeComment,
    setCommentInput,
    comment,
    comments,
    isLoading,
    save,
    fileInputRef,
    onFileInputChange,
    emptyCommentErrorMessage,
    sameCommentErrorMessage,
    showEmptyCommentError,
    showSameCommentError,
    isSaving,
    clients,
    clientsLoading,
    selectedClient,
    setSelectedClient,
    filteredContactUsers,
    companyId,
    user,
  };
};

export const AddCompanyModal: FC<Props> = (props) => {
  const {
    fileURL,
    clientDropDownHeader,
    nameInputText,
    commentsText,
    newCommentText,
    addCommentButtonText,
    saveCompanyButtonText,
    createCompanyButtonText,
    contactDropdownHeader,
    taxNumberInputText,
    companyImageText,
    companyImageTypeText,
    uploadButtonText,
    contactDropdownTextData,
    contactDropdownText,
    selectClientDropdownText,
    setContactDropdownData,
    companyName,
    setCompanyName,
    taxId,
    setTaxId,
    addComment,
    removeComment,
    setCommentInput,
    comment,
    comments,
    isLoading,
    save,
    onFileInputChange,
    onFileSelectClick,
    fileInputRef,
    onTargetClick,
    emptyCommentErrorMessage,
    sameCommentErrorMessage,
    showEmptyCommentError,
    showSameCommentError,
    isSaving,
    clients,
    clientsLoading,
    selectedClient,
    setSelectedClient,
    filteredContactUsers,
    companyId,
  } = useAddUserModal(props);

  return (
    <LoadingPlaceholder showSpinner={true} isLoading={isLoading || clientsLoading}>
      <AddCompanyLayout
        clientDropdownHeader={
          <Text type="style7" required>
            {clientDropDownHeader}
          </Text>
        }
        clientDropdown={
          <LoadingPlaceholder showSpinner={false} isLoading={clientsLoading}>
            {!isLoading && clients && (
              <MultiselectDropdown
                title={selectClientDropdownText}
                options={clients.clients}
                avoidHighlightFirstOption
                selectedOptions={selectedClient && [selectedClient]}
                onSelectItem={(selectedClient) =>
                  setSelectedClient({ id: selectedClient[0].id, name: selectedClient[0].name })
                }
                onRemoveItem={() => {
                  setSelectedClient(undefined), setContactDropdownData(undefined);
                }}
                maxSelection={1}
                id="companyClientSelectDropdownButton"
              />
            )}
          </LoadingPlaceholder>
        }
        nameInput={
          <UnderlineInput
            titleText={nameInputText}
            value={companyName}
            required
            onChange={setCompanyName}
            id="companyNameInput"
          />
        }
        commentIcon={<Icon type="messageCircle" circle={false} colorStyle="darkGray" />}
        comments={<Text type="style8">{commentsText}</Text>}
        newCommentInput={
          <UnderlineInput
            titleText={newCommentText}
            value={comment}
            onChange={setCommentInput}
            onEnterKeyPressed={addComment}
            id="companyCommentInput"
          />
        }
        addNewCommentButton={
          <Button
            size="small"
            paddingSize="extraSmall"
            onClick={() => {
              addComment();
            }}
            id="companyCommentButton"
          >
            {addCommentButtonText}
          </Button>
        }
        contactDropDownHeader={<Text type="style7">{contactDropdownHeader}</Text>}
        contactDropDown={
          <MultiselectDropdown
            avoidHighlightFirstOption
            title={contactDropdownText}
            maxSelection={5}
            options={filteredContactUsers || []}
            selectedOptions={contactDropdownTextData}
            onSelectItem={(selected) => setContactDropdownData(selected)}
            onRemoveItem={(selected) => setContactDropdownData(selected)}
            id="companyContactDropdownButton"
          />
        }
        badges={comments.map((comment) => ({
          badgeTexts: (
            <Badge
              content={comment.comment}
              colorStyle="lightGray"
              size="small"
              selfClosing
              onClick={() => removeComment(comment)}
            />
          ),
        }))}
        errorField={
          <>
            {showEmptyCommentError && <ErrorFeedback errorMessage={emptyCommentErrorMessage} />}
            {showSameCommentError && <ErrorFeedback errorMessage={sameCommentErrorMessage} />}
          </>
        }
        taxInput={
          !isLoading && (
            <UnderlineInput
              titleText={taxNumberInputText}
              value={taxId}
              required
              onChange={setTaxId}
              id="companyTaxInput"
            />
          )
        }
        companyImage={<CircleImage size="medium" image={fileURL} />}
        imageDescription={<Text type="style7">{companyImageText}</Text>}
        imageTypeDescription={<Text type="style6">{companyImageTypeText}</Text>}
        uploadButton={
          <>
            <Button size="small" paddingSize="small" onClick={onFileSelectClick}>
              {uploadButtonText}
            </Button>
            <FileDrop onTargetClick={onTargetClick}>
              <> </>
            </FileDrop>
            <input
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              id="file"
              ref={fileInputRef}
              className="d-none"
              onChange={onFileInputChange}
            />
          </>
        }
        saveCompanyButton={
          <Button
            size="small"
            paddingSize="large"
            roundedLarge
            iconSize="large"
            icon={!companyId ? 'plus' : 'pencil'}
            onClick={isSaving ? undefined : save}
            loadingSpinner={isSaving}
            id="companySubmitButton"
          >
            {companyId ? saveCompanyButtonText : createCompanyButtonText}
          </Button>
        }
      />
    </LoadingPlaceholder>
  );
};

export default AddCompanyModal;
