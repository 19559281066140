import '@/components/Icon/style.scss';
import { FC, MouseEventHandler } from 'react';
import * as iconMap from '@/components/Icon/icons';
import { cn, CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('components-icon');
const padding = scope.and('padding');
const color = scope.and('color');
const border = scope.and('border');
const background = scope.and('background');
const btn = scope.and('btn');

interface Props {
  backgroundColorStyle?: 'primary' | 'danger' | 'success' | 'transparent';
  colorStyle:
    | 'white'
    | 'primary'
    | 'danger'
    | 'success'
    | 'darkGray'
    | 'lightGray'
    | 'xlsx'
    | 'docx'
    | 'pdf'
    | 'txt'
    | 'pptx'
    | 'jpeg'
    | 'png'
    | 'jpg';
  circle?: boolean;
  type: keyof typeof iconMap;
  children?: never;
  className?: string;
  onMouseEnter?: MouseEventHandler<HTMLSpanElement>;
  onMouseLeave?: MouseEventHandler<HTMLSpanElement>;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  id?: string;
}

export const Icon: FC<Props> = ({
  type,
  colorStyle,
  backgroundColorStyle,
  circle,
  className,
  onClick,
  id,
  ...props
}) => (
  <span
    className={cn(
      scope,
      circle && padding,
      circle && border,
      circle && border.and(colorStyle),
      backgroundColorStyle && background.and(backgroundColorStyle),
      colorStyle && color.and(colorStyle),
      onClick && btn,
      className,
    )}
    onClick={onClick}
    {...props}
    id={id}
  >
    {iconMap[type]}
  </span>
);
