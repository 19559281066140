import { nSQL } from '@nano-sql/core';
import db from '@/client_v2/db';

const deleteDatabase = async (): Promise<void> => {
  const allTableas = await db.query('show tables').exec();
  const tables = allTableas.filter(({ table }) => table[0] !== '_');
  for (const table of tables) {
    await nSQL(table.table).query('delete').exec();
  }
};

export default deleteDatabase;
