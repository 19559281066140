import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { PostComments } from '@/client_v2/rest/types/PostComments';
import {
  API_COMMENTS_$ENTITY_TIPE_$ENTITY_ID_COMMENTS,
  $ENTITY_TIPE_PARAM,
  $ENTITY_ID_PARAM,
} from '@/client_v2/rest/Comments/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';
import { CommentPoll } from '@/client_v2/polls/Comment.poll';

export const postComments = async ({ entityType, entityId, comments }: PostComments) => {
  const res = await axiosInstance.post(
    createUrl(API_COMMENTS_$ENTITY_TIPE_$ENTITY_ID_COMMENTS, {
      params: {
        [$ENTITY_TIPE_PARAM]: entityType,
        [$ENTITY_ID_PARAM]: entityId.toString(),
      },
    }),
    comments,
  );
  await CommentPoll.trigger();
  return res;
};
