import axios from 'axios';
import i18next from 'i18next';
import { BASE_URL } from '@/client/urls';
import { store } from '@/redux/store';

export const getCurrentLanguage = (): string => {
  return i18next.language;
};

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 60 * 1000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
  },
});

export const axiosFormDataInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 60 * 1000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
    'Accept-Language': getCurrentLanguage(),
  },
});

const setAuthorizationToken = (token: string): void => {
  axiosInstance.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : '';
  axiosFormDataInstance.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : '';
};

store.subscribe(() => {
  const {
    auth: { token },
  } = store.getState();
  setAuthorizationToken(token!);
});

export default axiosInstance;
