import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setSuccess } from '@/redux/message/message.action';

/**
 * The A generic type must be any[] because its represent the async callbacks arguments types
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAsyncCallback = <A extends any[], R>(
  asyncCallback: (...args: A) => Promise<R>,
  /**
   * The error: any argument must be any because the ascync callback function can throw any kind of error type
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError: (error: any) => void,
  /**
   * The deps: any argument must be any because this hook doesen't konw what context dependeny needed to change the current asyncCallback to the new one.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deps: any[] = [],
  hideSuccessResponse?: boolean,
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [res, setRes] = useState<{ value?: R }>({});
  const successText = t('messageResponse.success');

  const cb = useCallback(
    (...args: A) => {
      if (isLoading) return;
      setIsLoading(true);
      (async () => {
        try {
          const res = await asyncCallback(...args);
          setRes({ value: res });
          !hideSuccessResponse && dispatch(setSuccess(successText));
        } catch (error) {
          onError(error);
        }
        setIsLoading(false);
      })();
    },
    /**
     * The deps arrya comes from an other context and if those dependecies change are triggers means the asyncCallback or onError has been changed.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, setIsLoading, setRes, ...deps],
  );

  return [cb, isLoading, res.value] as const;
};
