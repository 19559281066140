import { createDataSync } from '@/client_v2/util/createDataSync';
import { BusinessAreaRepo } from '@/client_v2/db/repositories/BusinessArea.repo';
import RestClient from '@/client_v2/rest';

const DELAY = 1000 * 20;

export const BusinessAreaPoll = createDataSync({
  id: 'business_area',
  delay: DELAY,
})
  .init(async () => {
    const res = await RestClient.BusinessAreas.get();
    await Promise.all(res.data.data.map(async (doc) => await BusinessAreaRepo.save(doc)));
  })
  .poll(async (pollTime) => {
    const res = await RestClient.BusinessAreas.poll(pollTime());
    if (res.data.poll.length) {
      return {
        update: true,
        data: res.data.poll,
      };
    }
    return {
      update: false,
    };
  })
  .update(async (data) => {
    await Promise.all(
      data!.map(async ({ operation, id }) => {
        if (operation === 'created' || operation === 'updated') {
          const res = await RestClient.BusinessAreas.getById(id);
          await BusinessAreaRepo.save(res.data.data);
        } else if (operation === 'deleted') {
          await BusinessAreaRepo.delete(id);
        }
      }),
    );
  });
