import '@/layouts/ProcessesTableLayout/style.scss';
import { FC, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Icon from '@/components/Icon';
import Text from '@/components/Text';
import { CustomerContentTableLayout } from '@/layouts/CustomerContentTableLayout/CustomerContentTableLayout.view';
import CheckBox from '@/components/CheckBox';
import Badge from '@/components/Badge';
import { AppState } from '@/redux/store';
import { ISidebarFilter } from '@/redux/sortAndFilters/sortAndFilters.interfaces';

interface Props {
  startDate?: Date;
  proposer?: string;
  badgeComments: string[];
  activationDate?: Date;
  customerId?: number;
  businessAreas?: number[];
  companies?: string[];
}

const useCustomerContentTable = ({
  startDate,
  proposer,
  badgeComments,
  activationDate,
  businessAreas,
  companies,
}: Props) => {
  const { t } = useTranslation();

  const partnershipStartDate = t('customerScreen.table.partnershipDate');
  const recommender = t('customerScreen.table.recommender');
  const noRecommenderText = t('customerScreen.table.noRecommenderFound');
  const companyText = t('customerScreen.table.company');
  const noCompanyFoundText = t('customerScreen.table.noCompanyFound');
  const commentListText = t('customerScreen.table.comments');
  const branch = t('customerScreen.table.branch');
  const active = t('customerScreen.table.activity');
  const activityDate = t('customerScreen.table.activityDate');
  const dateFormat = t('common.dateFormat');

  return {
    partnershipStartDate,
    recommender,
    commentListText,
    branch,
    active,
    activityDate,
    businessAreas,
    startDate,
    proposer,
    badgeComments,
    activationDate,
    dateFormat,
    companyText,
    companies,
    noRecommenderText,
    noCompanyFoundText,
  };
};

export const CustomerContentTable: FC<Props> = (props) => {
  const {
    partnershipStartDate,
    recommender,
    commentListText,
    branch,
    active,
    activityDate,
    startDate,
    proposer,
    badgeComments,
    dateFormat,
    companyText,
    companies,
    noRecommenderText,
    noCompanyFoundText,
  } = useCustomerContentTable(props);

  const typeMap = {
    checked: 'checked',
    halfChecked: 'halfChecked',
    lock: 'lock',
    notChecked: 'notChecked',
  } as const;

  const filters = useSelector((state: AppState) => state.sortAndFilters.sidebarFilters);

  const [currentBusinessAreas] = useState(props.businessAreas);

  const getCheckBoxType = (id: number) => {
    return currentBusinessAreas?.indexOf(id) === -1 ? typeMap.notChecked : typeMap.checked;
  };

  const separatedCompanies = companies && companies.join(' ');

  return (
    <CustomerContentTableLayout
      partnershipDate={
        <>
          <Icon type="penTool" colorStyle="lightGray" />
          <Text className="font-weight-bold ml-2" type="style28">
            {partnershipStartDate}:
          </Text>
          <Text type="style28" className="pl-1">
            {startDate && dayjs(startDate).format(dateFormat)}
          </Text>
        </>
      }
      recommender={
        <>
          <Icon type="crosshair" colorStyle="lightGray" />
          <Text className="font-weight-bold ml-2" type="style28">
            {recommender}:
          </Text>
          <Text type="style28" className="pl-1">
            {proposer || noRecommenderText}
          </Text>
        </>
      }
      company={
        <>
          <Icon type="creditCart" colorStyle="lightGray" />
          <Text className="font-weight-bold ml-2" type="style28">
            {companyText}:
          </Text>
          <Text type="style28" className="pl-1 text-wrap">
            {separatedCompanies || noCompanyFoundText}
          </Text>
        </>
      }
      commentList={
        <>
          <Icon type="chat" colorStyle="lightGray" />
          <Text className="font-weight-bold ml-2" type="style28">
            {commentListText}
          </Text>
        </>
      }
      badgeContent={badgeComments.map((badgeData: string) => (
        <div className="m-1" key={badgeData}>
          <Badge content={badgeData} colorStyle="lightGray" cursor="none" size="small" />
        </div>
      ))}
      branch={
        <Text className="font-weight-bold" type="style4">
          {branch}
        </Text>
      }
      active={
        <Text className="font-weight-bold" type="style4">
          {active}
        </Text>
      }
      activationDate={
        <Text className="font-weight-bold" type="style4">
          {activityDate}
        </Text>
      }
      checkBoxData={
        <>
          {filters &&
            filters.map((value: ISidebarFilter) => {
              return (
                <div className="row" key={value.id}>
                  <div className="col-4 d-inline-block text-truncate d-flex justify-content-start">
                    <Text type="style4">{value.name}</Text>
                  </div>
                  <div className="col-4 align-items-center d-flex justify-content-center disabled">
                    <CheckBox type={getCheckBoxType(value.id)} cursor="none" />
                  </div>
                  <div className="col-4 align-items-center d-flex justify-content-end">
                    <Text type="style1">
                      {value.updatedAt && dayjs(value.updatedAt).format(dateFormat)}
                    </Text>
                  </div>
                </div>
              );
            })}
        </>
      }
    />
  );
};
