import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { API_CLIENTS_$ID, $ID_PARAM } from '@/client_v2/rest/Clients/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';
import { ClientPoll } from '@/client_v2/polls/Client.poll';
import { ClientRes } from '@/client_v2/rest/types/ClientsRes';

export const deleteClient = async (id: number) => {
  const res = await axiosInstance.delete<ClientRes>(
    createUrl(API_CLIENTS_$ID, {
      params: {
        [$ID_PARAM]: id.toString(),
      },
    }),
  );
  await ClientPoll.trigger();
  return res;
};
