import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { API_DOCUMENTS_DOWNLOAD, IDS_QUERY } from '@/client_v2/rest/Documents/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';

export const downloadDocuments = async (data: number[]) => {
  try {
    const res = await axiosInstance.get<{ url: string; filename: string }>(
      createUrl(API_DOCUMENTS_DOWNLOAD, {
        queries: {
          [IDS_QUERY]: data.join(),
        },
      }),
    );
    if (res.data) {
      window.open(res.data.url)!;
    }
    return res;
  } catch (e) {
    return;
  }
};
