export enum WorkflowStatuses {
  READY_TO_START = 'ready_to_start',
  IN_PROGRESS = 'in_progress',
  LATER = 'later',
  OVERDUE = 'overdue',
  DUE = 'due_soon',
  DONE = 'done',
}

export enum ActivityTypes {
  ADD_COMMENT = 'add-comment',
  COMMENT_REMOVED = 'removed-comment',
  DOCUMENT_ADDED = 'add-document',
  DOCUMENT_REMOVED = 'removed-document',
  SET_DONE = 'set-done',
  UNSET_DONE = 'unset-done',
}

export enum CommentTypes {
  DOCUMENT = 'document',
  CLIENT = 'client',
  COMPANY = 'company',
  WORKFLOW_STEP = 'workflow_step',
}

export enum DocumentStatuses {
  IS_SENT = 'is_sent',
  IS_APPROVED = 'is_approved',
  IS_ARCHIVED = 'is_archived',
  IS_WAITING_FOR_APPROVAL = 'is_waiting_for_approval',
  IS_UPLOADED = 'is_uploaded',
  IS_DECLINED = 'is_declined',
}
export enum DocumentDirection {
  RECEIVED = 'received',
  SENT = 'sent',
}

export const MAX_ALLOWED_MILESTONE_ITEM = 10;
export const MAX_ALLOWED_MILESTONE_STEP_ITEM = 10;
export const MAX_ALLOWED_MILESTONE_NAME_LENGTH = 30;
export const MAX_ALLOWED_REMINDER_DAYS_LENGTH = 60;

export const OFFER_MAIL = 'info@kondorholding.com';
