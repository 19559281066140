import { FC, ReactNode } from 'react';
import { cn, CreateScopeCSS } from '@/components/utils';
import '@/layouts/DocumentUploadModalLayout/style.scss';

const scope = CreateScopeCSS('components-document-upload');
const draggingArea = scope.and('dragging-area');
const line = scope.and('line');
const lineLeft = scope.and('line-left');
const lineRight = scope.and('line-right');
const text = scope.and('text');

interface Props {
  hasFile: boolean;
  dividerText: ReactNode;
  dragArea: ReactNode;
  uploadedDocsBadges?: ReactNode;
  addFilesButton: ReactNode;
  uploadButton: ReactNode;
  chosenDocsText: ReactNode;
  labelBadgeInput?: ReactNode;
  commentBadgeInput?: ReactNode;
  businessAreaDropdown?: ReactNode;
  partnerDropdown?: ReactNode;
  companyDropdown?: ReactNode;
  accepterPersonDropdown?: ReactNode;
  workflowText?: ReactNode;
  workflowTextOptional?: ReactNode;
  workflowDropdown?: ReactNode;
  milestoneDropdown?: ReactNode;
  stepDropdown?: ReactNode;
  addresseeText?: ReactNode;
  addresseeDropdown?: ReactNode;
  addLabelButton?: ReactNode;
  addCommentButton?: ReactNode;
  juniorAccessibility?: ReactNode;
}

export const DocumentUploadLayout: FC<Props> = ({
  hasFile,
  dragArea,
  dividerText,
  addFilesButton,
  uploadButton,
  uploadedDocsBadges,
  chosenDocsText,
  labelBadgeInput,
  commentBadgeInput,
  businessAreaDropdown,
  partnerDropdown,
  companyDropdown,
  accepterPersonDropdown,
  workflowText,
  workflowDropdown,
  milestoneDropdown,
  stepDropdown,
  workflowTextOptional,
  addresseeText,
  addresseeDropdown,
  addLabelButton,
  addCommentButton,
  juniorAccessibility,
}) => {
  return (
    <div className={cn(scope, 'text-nowrap')}>
      <div className="row">
        <div className={cn(draggingArea, 'col-12')}>{dragArea}</div>
      </div>
      <div className="row">
        <div
          className={cn(
            hasFile
              ? 'd-flex align-items-stretch col-12  pt-md-1 pb-md-1 pt-1 pb-1'
              : 'd-flex align-items-stretch col-12  pt-md-4 pb-md-4 pt-1 pb-1',
          )}
        >
          <hr className={cn(lineLeft, line)} />
          <div className={cn(text, 'px-4')}>{dividerText}</div>
          <hr className={cn(lineRight, line)} />
        </div>
      </div>
      <div className="row">
        <div className={cn('col-12 d-flex justify-content-center')}>{addFilesButton}</div>
      </div>
      {hasFile && (
        <>
          <div className="row">
            <div className={cn('col-12')}>{chosenDocsText}</div>
          </div>
          <div className="row">
            <div className={cn('col-12 d-flex pt-3 flex-wrap')}>{uploadedDocsBadges}</div>
          </div>
          <div className={cn('row d-flex align-items-center')}>
            <div className={cn('col-lg-4 col-12')}>{labelBadgeInput}</div>
            <div className={cn('col-lg-2 col-12 mt-2 d-flex justify-content-start')}>
              {addLabelButton}
            </div>
            <div className={cn('col-lg-4 col-12')}>{commentBadgeInput}</div>
            <div className={cn('col-lg-2 col-12 mt-2 d-flex justify-content-start')}>
              {addCommentButton}
            </div>
          </div>
          <div className="row mt-md-5 mt-1">
            <div className={cn('col-lg-4')}>{businessAreaDropdown}</div>
            <div className={cn('col-lg-4')}>{accepterPersonDropdown}</div>
          </div>
          <div className="row pt-lg-4 pt-3">
            <div className={cn('col-12')}>{addresseeText}</div>
          </div>
          <div className={cn('row pt-3')}>
            <div className={cn('col-lg-4 col-12')}>{partnerDropdown}</div>
            <div className={cn('col-lg-4 col-12 pt-lg-0 pt-3')}>{companyDropdown}</div>
            <div className={cn('col-lg-4 col-12 pt-lg-0 pt-3')}>{addresseeDropdown}</div>
            <div className={cn('col-lg-4 col-12 pt-lg-0 mt-2')}>{juniorAccessibility}</div>
          </div>
          <div className="row pt-lg-4 pt-4">
            <div className="col-12 d-flex flex-nowrap">
              <div className={cn('mr-2')}>{workflowText}</div>
              <div>{workflowTextOptional}</div>
            </div>
          </div>
          <div className={cn('row pt-3')}>
            <div className={cn('col-lg-4 col-12')}>{workflowDropdown}</div>
            <div className={cn('col-lg-4 col-12 pt-lg-0 pt-3')}>{milestoneDropdown}</div>
            <div className={cn('col-lg-4 col-12 pt-lg-0 pt-3')}>{stepDropdown}</div>
          </div>
          <div className={cn('d-flex justify-content-center pt-3 pb-3')}>{uploadButton}</div>
        </>
      )}
    </div>
  );
};
