import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { WorkflowStepRes } from '@/client_v2/rest/types/WorkflowStepsRes';
import { API_WORKFLOWS_STEP_$ID, $ID_PARAM } from '@/client_v2/rest/WorkflowStep/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';

export const getWorkflowStepById = (id: number) =>
  axiosInstance.get<WorkflowStepRes>(
    createUrl(API_WORKFLOWS_STEP_$ID, {
      params: {
        [$ID_PARAM]: id.toString(),
      },
    }),
  );
