import { createDataHook } from '@/client_v2/hooks/utils/createDataHook';
import { ClientPoll } from '@/client_v2/polls/Client.poll';
import { CompanyPoll } from '@/client_v2/polls/Company.poll';
import { UserPoll } from '@/client_v2/polls/User.poll';
import { BusinessAreaPoll } from '@/client_v2/polls/BusinessArea.poll';
import { BusinessAreaRepo } from '@/client_v2/db/repositories/BusinessArea.repo';
import { ClientRepo } from '@/client_v2/db/repositories/Client.repo';

export const useAddWorkflowModalData = createDataHook({
  polls: [ClientPoll, CompanyPoll, BusinessAreaPoll, UserPoll],
  dataFactory: async () => ({
    businessAreas: await BusinessAreaRepo.query('get-active-business-area-list'),
    clientsAndCompanies: await ClientRepo.query('get-clients-and-companies-and-users'),
  }),
});
