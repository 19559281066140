import { postDocument } from '@/client_v2/rest/Documents/postDocument';
import { postWorkflow } from '@/client_v2/rest/Workflow/postWorkflow';

import { getDocuments } from '@/client_v2/rest/Documents/getDocuments';
import { getDocumentById } from '@/client_v2/rest/Documents/getDocumentById';
import { getDocumentsByIds } from '@/client_v2/rest/Documents/getDocumentsByIds';
import { pollDocuments } from '@/client_v2/rest/Documents/pollDocuments';
import { patchDocument } from '@/client_v2/rest/Documents/patchDocument';
import { downloadDocuments } from '@/client_v2/rest/Documents/downloadDocuments';
import { deleteDocument } from '@/client_v2/rest/Documents/deleteDocument';
import { approveDocument } from '@/client_v2/rest/Documents/approveDocument';
import { sendDocument } from '@/client_v2/rest/Documents/sendDocument';
import { sendApprovalDocument } from '@/client_v2/rest/Documents/sendApprovalDocument';

import { getBusinessAreas } from '@/client_v2/rest/BusinessAreas/getBusinessAreas';
import { getBusinessAreaById } from '@/client_v2/rest/BusinessAreas/getBusinessAreaById';
import { pollBusinessAreas } from '@/client_v2/rest/BusinessAreas/pollBusinessAreas';

import { getUsers } from '@/client_v2/rest/Users/getUsers';
import { getUserById } from '@/client_v2/rest/Users/getUserById';
import { getUsersByIds } from '@/client_v2/rest/Users/getUsersByIds';
import { pollUsers } from '@/client_v2/rest/Users/pollUsers';
import { deleteUser } from '@/client_v2/rest/Users/deleteUser';
import { blockUser } from '@/client_v2/rest/Users/blockUser';
import { unblockUser } from '@/client_v2/rest/Users/unblockUser';
import { postUser } from '@/client_v2/rest/Users/postUser';
import { patchUser } from '@/client_v2/rest/Users/patchUser';

import { getClients } from '@/client_v2/rest/Clients/getClients';
import { getClientById } from '@/client_v2/rest/Clients/getClientById';
import { getClientsByIds } from '@/client_v2/rest/Clients/getClientsByIds';
import { pollClients } from '@/client_v2/rest/Clients/pollClients';
import { postClient } from '@/client_v2/rest/Clients/postClient';
import { patchClient } from '@/client_v2/rest/Clients/patchClient';
import { deleteClient } from '@/client_v2/rest/Clients/deleteClient';

import { getCompanies } from '@/client_v2/rest/Companies/getCompanies';
import { getCompanyById } from '@/client_v2/rest/Companies/getCompanyById';
import { getCompaniesByIds } from '@/client_v2/rest/Companies/getCompaniesByIds';
import { pollCompanies } from '@/client_v2/rest/Companies/pollCompanies';
import { deleteCompany } from '@/client_v2/rest/Companies/deleteCompany';
import { patchCompany } from '@/client_v2/rest/Companies/patchCompany';
import { postCompany } from '@/client_v2/rest/Companies/postCompany';

import { getTags } from '@/client_v2/rest/Tags/getTags';
import { getTagById } from '@/client_v2/rest/Tags/getTagById';
import { getTagsByIds } from '@/client_v2/rest/Tags/getTagsByIds';
import { pollTags } from '@/client_v2/rest/Tags/pollTags';
import { postTags } from '@/client_v2/rest/Tags/postTags';
import { deleteTag } from '@/client_v2/rest/Tags/deleteTag';

import { getComments } from '@/client_v2/rest/Comments/getComments';
import { getCommentById } from '@/client_v2/rest/Comments/getCommentById';
import { getCommentsByIds } from '@/client_v2/rest/Comments/getCommentsByIds';
import { pollComments } from '@/client_v2/rest/Comments/pollComments';
import { postComments } from '@/client_v2/rest/Comments/postComments';
import { deleteComment } from '@/client_v2/rest/Comments/deleteComment';

import { getWorkflows } from '@/client_v2/rest/Workflow/getWorkflows';
import { getWorkflowById } from '@/client_v2/rest/Workflow/getWorkflowById';
import { getWorkflowsByIds } from '@/client_v2/rest/Workflow/getWorkflowsByIds';
import { pollWorkflows } from '@/client_v2/rest/Workflow/pollWorkflows';
import { patchWorkflow } from '@/client_v2/rest/Workflow/patchWorkflow';

import { getWorkflowMiles } from '@/client_v2/rest/WorkflowMile/getWorkflowMiles';
import { getWorkflowMileById } from '@/client_v2/rest/WorkflowMile/getWorkflowMileById';
import { getWorkflowMilesByIds } from '@/client_v2/rest/WorkflowMile/getWorkflowMilesByIds';
import { pollWorkflowMiles } from '@/client_v2/rest/WorkflowMile/pollWorkflowMiles';
import { patchWorkflowMiles } from '@/client_v2/rest/WorkflowMile/patchWorkflowMiles';

import { getWorkflowSteps } from '@/client_v2/rest/WorkflowStep/getWorkflowSteps';
import { getWorkflowStepById } from '@/client_v2/rest/WorkflowStep/getWorkflowStepById';
import { getWorkflowStepsByIds } from '@/client_v2/rest/WorkflowStep/getWorkflowStepsByIds';
import { pollWorkflowSteps } from '@/client_v2/rest/WorkflowStep/pollWorkflowSteps';
import { patchWorkflowSteps } from '@/client_v2/rest/WorkflowStep/patchWorkflowSteps';

import { sendForgotPassword } from '@/client_v2/rest/Auth/sendForgotPassword';
import { resetPassword } from '@/client_v2/rest/Auth/resetPassword';
import { logoutUser } from '@/client_v2/rest/Auth/logoutUser';
import { getActivityFeeds } from '@/client_v2/rest/ActivityFeeds/getActivityFeeds';
import { refreshToken } from '@/client_v2/rest/Auth/refreshToken';

import { postActivityFeedsRefresh } from '@/client_v2/rest/ActivityFeeds/postActivityFeeds';
import { postActivityFeedsExport } from '@/client_v2/rest/ActivityFeeds/postActivityFeedsExport';
import { loadMoreActivity } from '@/client_v2/rest/ActivityFeeds/loadMoreActivity';

const RestClient = {
  Auth: {
    sendForgotPassword: sendForgotPassword,
    logout: logoutUser,
    refreshToken: refreshToken,
    resetPassword: resetPassword,
  },
  Documents: {
    post: postDocument,
    get: getDocuments,
    getById: getDocumentById,
    getByIds: getDocumentsByIds,
    poll: pollDocuments,
    patch: patchDocument,
    download: downloadDocuments,
    delete: deleteDocument,
    approve: approveDocument,
    send: sendDocument,
    sendApproval: sendApprovalDocument,
  },
  BusinessAreas: {
    get: getBusinessAreas,
    getById: getBusinessAreaById,
    poll: pollBusinessAreas,
  },
  Users: {
    get: getUsers,
    getById: getUserById,
    getByIds: getUsersByIds,
    poll: pollUsers,
    delete: deleteUser,
    block: blockUser,
    unblock: unblockUser,
    post: postUser,
    patch: patchUser,
  },
  Clients: {
    get: getClients,
    getById: getClientById,
    getByIds: getClientsByIds,
    poll: pollClients,
    post: postClient,
    patch: patchClient,
    delete: deleteClient,
  },
  Companies: {
    get: getCompanies,
    getById: getCompanyById,
    getByIds: getCompaniesByIds,
    post: postCompany,
    poll: pollCompanies,
    delete: deleteCompany,
    patch: patchCompany,
  },
  Tags: {
    get: getTags,
    getById: getTagById,
    getByIds: getTagsByIds,
    poll: pollTags,
    post: postTags,
    delete: deleteTag,
  },
  Comments: {
    get: getComments,
    getById: getCommentById,
    getByIds: getCommentsByIds,
    poll: pollComments,
    post: postComments,
    delete: deleteComment,
  },
  Workflows: {
    post: postWorkflow,
    get: getWorkflows,
    getById: getWorkflowById,
    getByIds: getWorkflowsByIds,
    poll: pollWorkflows,
    patch: patchWorkflow,
  },
  WorkflowsMiles: {
    get: getWorkflowMiles,
    getById: getWorkflowMileById,
    getByIds: getWorkflowMilesByIds,
    poll: pollWorkflowMiles,
    patch: patchWorkflowMiles,
  },
  WorkflowsSteps: {
    get: getWorkflowSteps,
    getById: getWorkflowStepById,
    getByIds: getWorkflowStepsByIds,
    poll: pollWorkflowSteps,
    patch: patchWorkflowSteps,
  },
  ActivityFeeds: {
    get: getActivityFeeds,
    filter: postActivityFeedsRefresh,
    export: postActivityFeedsExport,
    loadMore: loadMoreActivity,
  },
};

export default RestClient;
