import { InanoSQLConfig } from '@nano-sql/core/lib/interfaces';

export const DateType: InanoSQLConfig['types'] = {
  date: {
    interfaceText: 'date',
    onSelect(value) {
      if (typeof value === 'string') return new Date(value);

      return value;
    },
  },
};
