/* eslint-disable camelcase */
import { creatRepo } from '@/client_v2/db/utils/createRepo';
import { WorkflowStep } from '@/client_v2/rest/types/WorkflowStepsRes';

export const WorkflowStepRepo = creatRepo<WorkflowStep>({
  name: 'workflow_step',
  model: {
    'id:number': { pk: true },
    'workflow_mile_id:number': { default: -1 },
    'name:string': {},
    'deadline:date': {},
    'company_id:number': { default: -1 },
    'client_id:number': { default: -1 },
    'status:string': {},
    'last_modify_user_id:number': { default: -1 },
    'comments_ids:number[]': { default: [] },
    'users_ids:number[]': { default: [] },
    'created_at:date': {},
    'updated_at:date': {},
    'deleted_at:date': {},
  },
})
  .queryName('get-statuses-for-filter')
  .handler(async (table) => {
    const res: WorkflowStep[] = (await table()
      .query('select', ['id', 'status'])
      .exec()) as WorkflowStep[];
    return res;
  })
  .make();
