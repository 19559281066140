import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { WorkflowRes } from '@/client_v2/rest/types/WorkflowsRes';
import { API_WORKFLOWS_$ID, $ID_PARAM } from '@/client_v2/rest/Workflow/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';

export const getWorkflowById = (id: number) =>
  axiosInstance.get<WorkflowRes>(
    createUrl(API_WORKFLOWS_$ID, {
      params: {
        [$ID_PARAM]: id.toString(),
      },
    }),
  );
