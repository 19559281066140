import { Dispatch } from 'redux';
import i18n from '@/i18next';
import RestClient from '@/client_v2/rest';
import client from '@/client';
import deleteDatabase from '@/client_v2/db/deleteDatabase';
import { IUserCredentials, IUserRegistration, IResetPassword } from '@/redux/auth/auth.interfaces';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTRATION_REQUEST,
  REGISTRATION_SUCCESS,
  REGISTRATION_FAILURE,
  SEND_FORGOT_PASSWORD_REQUEST,
  SEND_FORGOT_PASSWORD_FAILURE,
  SEND_FORGOT_PASSWORD_SUCCESS,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_FAILURE,
} from '@/redux/auth/auth.types';
import { getUserByEmail } from '@/redux/user/user.actions';
import { TagPoll } from '@/client_v2/polls/Tag.poll';
import { UserPoll } from '@/client_v2/polls/User.poll';
import { ClientPoll } from '@/client_v2/polls/Client.poll';
import { CommentPoll } from '@/client_v2/polls/Comment.poll';
import { CompanyPoll } from '@/client_v2/polls/Company.poll';
import { DocumentPoll } from '@/client_v2/polls/Document.poll';
import { BusinessAreaPoll } from '@/client_v2/polls/BusinessArea.poll';
import { WorkflowMilePoll } from '@/client_v2/polls/WorkflowMile.poll';
import { WorkflowPoll } from '@/client_v2/polls/Workflow.poll';
import { WorkflowStepPoll } from '@/client_v2/polls/WorkflowStep.poll';
import { SET_ERROR } from '@/redux/message/message.types';

export const loginUser = (userCredentials: IUserCredentials) => async (dispatch: Dispatch) => {
  dispatch({
    type: LOGIN_REQUEST,
  });

  try {
    const res = await client.auth.login(userCredentials);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        token: res.token,
        refreshToken: res.refresh_token,
        userId: res.user_id,
      },
    });
    await getUserByEmail(userCredentials)(dispatch);
  } catch (error) {
    const errorDetail =
      error && error.response && error.response.data && error.response.data.errors
        ? error.response.data.errors
        : i18n.t('error.generalError');
    dispatch({
      type: LOGIN_FAILURE,
      error: errorDetail,
    });
  }
};

export const userRegistration = (user: IUserRegistration) => async (dispatch: Dispatch) => {
  dispatch({
    type: REGISTRATION_REQUEST,
  });

  try {
    const res = await client.auth.register(user);
    dispatch({
      type: REGISTRATION_SUCCESS,
      payload: res,
    });
  } catch (error) {
    const errorDetail = error && error.message ? error.message : i18n.t('error.generalError');
    dispatch({
      type: REGISTRATION_FAILURE,
      error: errorDetail,
    });
  }
};

export const sendForgotPassword = (email: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: SEND_FORGOT_PASSWORD_REQUEST,
  });

  try {
    await RestClient.Auth.sendForgotPassword(email);

    dispatch({
      type: SEND_FORGOT_PASSWORD_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: SEND_FORGOT_PASSWORD_FAILURE,
      error,
    });
  }
};

export const logout = (message?: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: LOGOUT_REQUEST,
  });

  try {
    BusinessAreaPoll.stop();
    ClientPoll.stop();
    CommentPoll.stop();
    CompanyPoll.stop();
    DocumentPoll.stop();
    TagPoll.stop();
    UserPoll.stop();
    WorkflowPoll.stop();
    WorkflowMilePoll.stop();
    WorkflowStepPoll.stop();

    await deleteDatabase();
    await localStorage.clear();

    if (message) {
      dispatch({
        type: SET_ERROR,
        payload: message,
      });
    }

    await RestClient.Auth.logout();

    location.reload();
  } catch (error) {
    dispatch({
      type: LOGOUT_FAILURE,
      error,
    });
  }
};

export const refreshToken =
  (refreshToken: string, onTokenRefreshed: () => void) => async (dispatch: Dispatch) => {
    dispatch({
      type: REFRESH_TOKEN_REQUEST,
    });

    try {
      const response = await RestClient.Auth.refreshToken(refreshToken);
      dispatch({
        type: REFRESH_TOKEN_SUCCESS,
        payload: {
          // eslint-disable-next-line camelcase
          refresh_token: response.data.refresh_token,
          token: response.data.token,
        },
      });
      onTokenRefreshed();
    } catch (error) {
      dispatch({
        type: REFRESH_TOKEN_FAILURE,
      });
    }
  };

export const resetPassword =
  (resetPasswordObject: IResetPassword) => async (dispatch: Dispatch) => {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
    });

    try {
      await RestClient.Auth.resetPassword(resetPasswordObject);

      dispatch({
        type: RESET_PASSWORD_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: RESET_PASSWORD_FAILURE,
        error,
      });
    }
  };
