import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { CompaniesRes } from '@/client_v2/rest/types/CompaniesRes';
import { API_COMPANIES, DISABLE_PAGINATIONS_QUERY } from '@/client_v2/rest/Companies/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';

export const getCompanies = () =>
  axiosInstance.get<CompaniesRes>(
    createUrl(API_COMPANIES, {
      queries: {
        [DISABLE_PAGINATIONS_QUERY]: '1',
      },
    }),
  );
