import '@/layouts/CompanyContentTableLayout/style.scss';
import { FC, ReactNode } from 'react';
import { cn, CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('layouts-company-content-table-layout');

interface Props {
  table: ReactNode;
}

export const CompanyContentTableLayout: FC<Props> = ({ table }) => (
  <>
    <div className={cn(scope, 'container-fluid')}>
      <div className="row text-nowrap">
        <div className="scroll-tb">
          <div className="col-12">{table}</div>
        </div>
      </div>
    </div>
  </>
);
