import React, { FC, useState, useMemo, useEffect, useCallback, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import '@pathofdev/react-tag-input/build/index.css';
import { CalendarContainer } from 'react-datepicker';
import ProcessCreateModalLayout from '@/layouts/ProcessCreateModalLayout';
import Button from '@/components/Button';
import Text from '@/components/Text';
import UnderlineInput from '@/components/Input/UnderlineInput';
import DatePicker from '@/components/DatePicker';
import { useModifyProcessModalData } from '@/client_v2/hooks/useModifyProcessModalData';
import { useAddWorkflowModalData } from '@/client_v2/hooks/useAddWorkflowModalData';
import Collapse from '@/components/Collapse';
import { postWorkflow } from '@/client_v2/rest/Workflow/postWorkflow';
import {
  IStepData,
  MileStones,
} from '@/screens/ProcessesScreen/components/ProcessCreateModal/MileStones';
import { setError, setSuccess } from '@/redux/message/message.action';
import Icon from '@/components/Icon';
import { patchWorkflow } from '@/client_v2/rest/Workflow/patchWorkflow';
import { getMileDataForPatch } from '@/resources/functions';
import MultiselectDropdown from '@/components/MultiselectDropdown';
import { IGetClientsCompaniesAndUsers } from '@/client_v2/db/repositories/Client.repo';
import {
  MAX_ALLOWED_MILESTONE_ITEM,
  MAX_ALLOWED_MILESTONE_NAME_LENGTH,
  MAX_ALLOWED_REMINDER_DAYS_LENGTH,
} from '@/resources/constants/shared';
import { cn } from '@/components/utils';
import { AppState } from '@/redux/store';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import { IGetBusinessAreaData } from '@/client_v2/db/repositories/BusinessArea.repo';
import { IGetWorkflowResponsibleUser } from '@/client_v2/db/repositories/Workflow.repo';
import {
  isProcessModified,
  setProcessScrollbarPosition,
} from '@/redux/processes/processes.actions';

export interface IidName {
  id: number;
  name: string;
}

export interface Mile {
  id?: number;
  mileName: string;
  steps?: IStepData[];
  nameError?: boolean;
}

interface Props {
  processId?: number;
  onClose: () => void;
  stepsNotChecked?: boolean;
}

const useProcessCreateModal = (stepsNotChecked: boolean) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.user.user);
  const selectedCompanyId = useSelector((state: AppState) => state.user.selectedCompanyId);
  const selectedClientId = useSelector((state: AppState) => state.user.selectedClientId);
  const [businessAreasData, setBusinessAreasData] = useState<IGetBusinessAreaData[]>([]);
  const [processId, setProcessId] = useState<number>();
  const [processName, setProcessName] = useState<string>();
  const [businessArea, setBusinessArea] = useState<IidName>();
  const [client, setClient] = useState<IGetClientsCompaniesAndUsers>();
  const [company, setCompany] = useState<{ id: number; name: string; clientId: number }>();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [reminderDays, setReminderDays] = useState<number>(0);
  const [removeStepIds, setRemoveStepIds] = useState<number[]>([]);
  const [filteredClients, setFilteredClients] = useState<IGetClientsCompaniesAndUsers[]>();
  const [isSuccess, setIsSuccess] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [showStartModal, setShowStartModal] = useState(false);
  const [showEndModal, setShowEndModal] = useState(false);
  const [showMilestones, setShowMilestones] = useState(true);

  const [errors, setErrors] = useState({
    processName: false,
    businessArea: false,
    client: false,
    company: false,
    startDate: false,
    endDate: false,
    reminderDays: false,
  });

  const processNameText = t('processesScreen.modal.processName');
  const businessAreasText = t('processesScreen.modal.businessAreas');
  const clientText = t('processesScreen.modal.client');
  const companyText = t('processesScreen.modal.company');
  const startText = t('processesScreen.modal.start');
  const endText = t('processesScreen.modal.end');
  const reminderText = t('processesScreen.modal.reminder');
  const saveText = t('processesScreen.modal.save');
  const createText = t('processesScreen.modal.create');
  const saveAndNewText = t('processesScreen.modal.saveAndNew');
  const milestonesTitleText = t('processesScreen.modal.milestonesTitle');
  const milestoneNameText = t('processesScreen.modal.milestoneName');
  const milestonesSubtitleText = t('processesScreen.modal.milestonesSubtitle');
  const milestoneStepsText = t('processesScreen.modal.milestoneSteps');
  const newMilestoneText = t('processesScreen.modal.newMilestone');
  const checkFieldText = t('messageResponse.checkFields');
  const successText = t('messageResponse.success');
  const dateFormat = t('common.dateFormat');

  const [isLoading, defaultData] = useAddWorkflowModalData({
    triggerOnce: true,
    renderOnUpdate: false,
  });

  const newMilestoneData: Mile = {
    id: Math.random(),
    mileName: '',
    nameError: false,
    steps: [
      {
        stepName: '',
        responsibleUserIds: [],
        deadline: new Date(),
        responsibleUsers: [],
      },
    ],
  };
  const [mileStonesData, setMileStonesData] = useState<Mile[]>([newMilestoneData]);
  interface IDateProps {
    className?: string;
    children?: ReactNode;
  }

  const startDateContainer = ({ className, children }: IDateProps) => {
    return (
      <div className="d-flex justify-content-center user-select-none">
        <CalendarContainer className={cn(className)}>
          <div className="position-relative">{children}</div>
        </CalendarContainer>
      </div>
    );
  };
  const endDateContainer = ({ className, children }: IDateProps) => {
    return (
      <div className="d-flex justify-content-center user-select-none">
        <CalendarContainer className={cn(className)}>
          <div className="position-relative">{children}</div>
        </CalendarContainer>
      </div>
    );
  };

  const removeSelectedUsersFromMilestoneData = useCallback(() => {
    mileStonesData.map((item) => {
      item.steps &&
        item.steps.map((step) => {
          step.responsibleUsers = [];
          step.responsibleUserIds = [];
          return step;
        });
      return item;
    });
  }, [mileStonesData]);

  useEffect(() => {
    const businessAreaIds =
      client &&
      defaultData?.clientsAndCompanies.find(({ id }) => id === client.id)?.activeBusinessAreas;

    businessAreaIds &&
      setBusinessAreasData(
        defaultData?.businessAreas?.filter((ba) => businessAreaIds.includes(ba.id)),
      );
  }, [defaultData, client]);

  const businessAreaDropdown = useMemo(
    () =>
      defaultData && (
        <MultiselectDropdown
          title={businessAreasText}
          label={businessAreasText}
          options={businessAreasData}
          selectedOptions={businessArea && [businessArea]}
          avoidHighlightFirstOption
          onSelectItem={(item) => {
            setBusinessArea(item[0]);
            setErrors({ ...errors, businessArea: false });
          }}
          onRemoveItem={() => setBusinessArea(undefined)}
          singleSelect
          isError={errors.businessArea}
          isDisabled={!stepsNotChecked || !client}
          id="processBusinessArea"
        />
      ),
    [
      defaultData,
      businessAreasText,
      businessAreasData,
      client,
      businessArea,
      errors,
      stepsNotChecked,
    ],
  );

  const [isSaveAndNew, setIsSaveAndNew] = useState<boolean>(false);

  useEffect(() => {
    defaultData && setFilteredClients(defaultData.clientsAndCompanies);
  }, [setFilteredClients, defaultData]);

  useEffect(() => {
    if (user?.authority !== Authorities.SuperAdmin) {
      setClient(filteredClients?.find((client) => user?.clients?.includes(client.id)));
    }
  }, [filteredClients, user?.authority, user?.clients]);

  useEffect(() => {
    if (selectedClientId) {
      setClient(filteredClients?.find((client) => client.id === selectedClientId));
    }
  }, [selectedClientId, filteredClients, client?.companies]);

  useEffect(() => {
    if (selectedCompanyId) {
      setCompany(client?.companies.find((client) => client.id === selectedCompanyId));
    }
  }, [selectedCompanyId, filteredClients, client?.companies]);

  const clientDropdown = useMemo(
    () =>
      defaultData &&
      defaultData.clientsAndCompanies &&
      !isLoading && (
        <MultiselectDropdown
          title={clientText}
          label={clientText}
          singleSelect
          options={filteredClients || []}
          selectedOptions={client && [client]}
          avoidHighlightFirstOption
          maxSelection={1}
          onSelectItem={(item) => {
            setClient(item[0]);
            setCompany(undefined);
            setBusinessArea(undefined);
            setErrors({ ...errors, client: false });
            removeSelectedUsersFromMilestoneData();
          }}
          onRemoveItem={() => {
            setClient(undefined);
            setCompany(undefined);
            setBusinessArea(undefined);
            removeSelectedUsersFromMilestoneData();
          }}
          isDisabled={!!selectedClientId || !stepsNotChecked || !!processId}
          isError={errors.client}
          id="processClient"
        />
      ),
    [
      defaultData,
      isLoading,
      clientText,
      filteredClients,
      client,
      selectedClientId,
      stepsNotChecked,
      processId,
      errors,
      removeSelectedUsersFromMilestoneData,
    ],
  );

  useEffect(() => {
    if (user?.authority !== Authorities.SuperAdmin && user?.companies.length) {
      !company &&
        setCompany(
          client?.companies?.find((company) => {
            if (selectedCompanyId) {
              return company.id === selectedCompanyId;
            } else if (
              (user?.authority === Authorities.ClientUser1 ||
                user?.authority === Authorities.ClientUser2) &&
              user?.companies.length === 1
            ) {
              return user?.companies.includes(company.id);
            } else {
              return null;
            }
          }),
        );
    }
  }, [client?.companies, company, selectedCompanyId, user?.authority, user?.companies]);

  const companyDropdown = useMemo(
    () => (
      <MultiselectDropdown
        title={companyText}
        label={companyText}
        singleSelect
        options={(client && client.companies) || []}
        selectedOptions={company && [company]}
        avoidHighlightFirstOption
        onSelectItem={(item) => setCompany(item[0])}
        onRemoveItem={() => setCompany(undefined)}
        isDisabled={!stepsNotChecked || !!processId || !client}
        id="processCompany"
        isError={errors.company}
      />
    ),
    [companyText, client, company, stepsNotChecked, processId, errors.company],
  );

  const isValidData = () => {
    let isValid = true;
    if (
      !businessArea ||
      !processName ||
      processName.length > MAX_ALLOWED_MILESTONE_NAME_LENGTH ||
      !client ||
      (reminderDays < 0 && reminderDays <= MAX_ALLOWED_REMINDER_DAYS_LENGTH) ||
      !startDate ||
      !endDate ||
      !(startDate && endDate >= startDate)
    ) {
      isValid = false;
      setErrors((state) => ({
        ...state,
        businessArea: !businessArea,
        processName: !processName || processName.length > MAX_ALLOWED_MILESTONE_NAME_LENGTH,
        client: !client,
        company: !company,
        reminderDays: reminderDays < 0 && reminderDays <= MAX_ALLOWED_REMINDER_DAYS_LENGTH,
        startDate: !startDate,
        endDate: !endDate || !(startDate && endDate >= startDate),
      }));
    }

    const checkMileStonesData = mileStonesData.map((item) => {
      if (
        !item.mileName ||
        item.mileName.length > MAX_ALLOWED_MILESTONE_NAME_LENGTH ||
        reminderDays >= MAX_ALLOWED_REMINDER_DAYS_LENGTH
      ) {
        isValid = false;
      }
      return {
        ...item,
        nameError: !item.mileName || item.mileName.length > MAX_ALLOWED_MILESTONE_NAME_LENGTH,
        steps:
          item.steps &&
          item.steps.map((step) => {
            if (
              !step.stepName ||
              step.stepName.length > MAX_ALLOWED_MILESTONE_NAME_LENGTH ||
              !step.deadline ||
              !(startDate && startDate <= step.deadline)
            ) {
              isValid = false;
            }
            return {
              ...step,
              nameError: !step.stepName || step.stepName.length > MAX_ALLOWED_MILESTONE_NAME_LENGTH,
              deadlineError: !step.deadline || !(startDate && startDate <= step.deadline),
            };
          }),
      };
    });

    setMileStonesData(checkMileStonesData);
    return isValid;
  };

  const clearFields = () => {
    setProcessName('');
    setStartDate(undefined);
    setEndDate(undefined);
    setBusinessArea(undefined);
    setClient(undefined);
    setCompany(undefined);
    setReminderDays(0);
    setMileStonesData([newMilestoneData]);
  };

  const isCompanyDropdownValid = !!company || !client?.companies.length;

  const submitProcess = async (isPost: boolean, isSaveAndNew?: boolean) => {
    if (!saveLoading) {
      if (
        isValidData() &&
        !!processName &&
        !!businessArea &&
        isCompanyDropdownValid &&
        !!client &&
        startDate &&
        endDate &&
        mileStonesData.length >= 1
      ) {
        setSaveLoading(true);
        dispatch(setProcessScrollbarPosition(undefined));
        if (isPost) {
          postWorkflow({
            name: processName,
            businessAreaId: businessArea.id,
            clientId: client.id,
            companyId: company && company.id,
            startDate: startDate,
            endDate: endDate,
            reminderDays: reminderDays,
            miles: mileStonesData,
          });
        } else {
          if (processId) {
            await patchWorkflow(
              {
                id: processId,
                name: processName,
                businessAreaId: businessArea.id,
                clientId: client.id,
                companyId: company && company.id,
                startDate: startDate,
                endDate: endDate,
                reminderDays: reminderDays,
                ...getMileDataForPatch(mileStonesData),
              },
              removeStepIds,
            );
            await setRemoveStepIds([]);
          }
        }
        if (!isSaveAndNew) setIsSuccess(true);
        clearFields();
        setSaveLoading(false);
        dispatch(setSuccess(successText));
      } else {
        dispatch(setError(checkFieldText));
      }
    }
  };

  const setRemoveMilestoneStepIds = (stepId: number) => {
    setRemoveStepIds([...removeStepIds, stepId]);
  };

  const updateMilestone = (milestoneItem: Mile) => {
    setMileStonesData(
      mileStonesData.map((milestone) => {
        milestone.steps?.map((step) => {
          step.responsibleUserIds =
            step.responsibleUsers?.map((user: IGetWorkflowResponsibleUser) => user?.userId) || [];
        });

        return milestone.id === milestoneItem.id ? milestoneItem : milestone;
      }),
    );
  };

  return {
    submitProcess,
    errors,
    setErrors,
    updateMilestone,
    mileStonesData,
    setMileStonesData,
    defaultData,
    processNameText,
    businessAreasText,
    clientText,
    companyText,
    startText,
    endText,
    reminderText,
    saveText,
    createText,
    saveAndNewText,
    milestonesTitleText,
    milestonesSubtitleText,
    milestoneStepsText,
    milestoneNameText,
    newMilestoneText,
    processName,
    setProcessName,
    businessArea,
    setBusinessArea,
    client,
    setClient,
    company,
    setCompany,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    reminderDays,
    setReminderDays,
    isSuccess,
    showStartModal,
    setShowStartModal,
    showEndModal,
    setShowEndModal,
    showMilestones,
    setShowMilestones,
    businessAreaDropdown,
    setProcessId,
    clientDropdown,
    companyDropdown,
    newMilestoneData,
    dateFormat,
    startDateContainer,
    endDateContainer,
    setIsSaveAndNew,
    isSaveAndNew,
    setRemoveMilestoneStepIds,
    saveLoading,
    selectedClientId,
  };
};

const ProcessCreateModal: FC<Props> = ({ processId = null, onClose, stepsNotChecked = true }) => {
  const {
    submitProcess,
    updateMilestone,
    mileStonesData,
    setMileStonesData,
    defaultData,
    processNameText,
    createText,
    errors,
    setErrors,
    startText,
    endText,
    reminderText,
    saveText,
    saveAndNewText,
    milestonesTitleText,
    milestonesSubtitleText,
    milestoneStepsText,
    newMilestoneText,
    processName,
    setProcessName,
    setBusinessArea,
    client,
    setClient,
    setCompany,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    reminderDays,
    setReminderDays,
    showStartModal,
    setShowStartModal,
    showEndModal,
    setShowEndModal,
    setProcessId,
    showMilestones,
    setShowMilestones,
    businessAreaDropdown,
    company,
    clientDropdown,
    companyDropdown,
    isSuccess,
    newMilestoneData,
    milestoneNameText,
    dateFormat,
    startDateContainer,
    endDateContainer,
    setIsSaveAndNew,
    isSaveAndNew,
    setRemoveMilestoneStepIds,
    saveLoading,
    selectedClientId,
  } = useProcessCreateModal(stepsNotChecked);

  const dispatch = useDispatch();

  const [isLoading, modalData] = useModifyProcessModalData({
    triggerOnce: { processId: processId },
    renderOnUpdate: false,
    onError: (err) => dispatch(setError(err.toString())),
  });

  useEffect(() => {
    if (isSuccess && !isSaveAndNew) {
      onClose();
    }
  }, [isSuccess, onClose, isSaveAndNew]);

  useEffect(() => {
    if (!isLoading && modalData && defaultData) {
      setProcessId(modalData.id);
      setProcessName(modalData.name);
      setStartDate(modalData.startDate);
      setEndDate(modalData.endDate);
      setBusinessArea(defaultData.businessAreas.find(({ id }) => modalData.businessAreaId === id));
      setClient(defaultData.clientsAndCompanies.find(({ id }) => modalData.clientId === id));
      setCompany(
        defaultData.clientsAndCompanies
          .find(({ id }) => modalData.clientId === id)
          ?.companies.find(({ id }) => id == modalData.companyId),
      );
      setReminderDays(modalData.reminderDays);
      modalData.miles && setShowMilestones(true);
      setMileStonesData(modalData.miles);
    }
  }, [
    defaultData,
    setMileStonesData,
    setReminderDays,
    setShowMilestones,
    isLoading,
    modalData,
    setStartDate,
    setEndDate,
    setProcessName,
    setCompany,
    setClient,
    setBusinessArea,
    setProcessId,
  ]);

  return (
    defaultData && (
      <ProcessCreateModalLayout
        nameInput={
          <UnderlineInput
            error={errors.processName}
            required
            titleText={processNameText}
            max={30}
            value={processName || ''}
            onChange={(value) => {
              setProcessName(value);
              setErrors({ ...errors, processName: false });
            }}
            readOnly={!stepsNotChecked}
            id="processTitle"
          />
        }
        businessAreasInput={businessAreaDropdown}
        clientInput={clientDropdown}
        companyInput={companyDropdown}
        startInput={
          <div className={`${errors.startDate ? '-errorDropdown' : ''}`}>
            <Button
              hasDropdown
              icon="calendar"
              iconSize="large"
              outline
              paddingSize="small"
              size="small"
              className="pt-3 pb-3 d-flex datePickerButton"
              onClick={() => setShowStartModal(!showStartModal)}
              disabled={!stepsNotChecked}
              id="processStartDatePicker"
            >
              {startDate ? `${dayjs(startDate).format(dateFormat)}` : startText}
            </Button>
          </div>
        }
        startDatePlaceholder={
          <DatePicker
            selected={startDate!}
            onChange={(date: Date) => {
              date && !Array.isArray(date) && setStartDate(date);
              setShowStartModal(false);
              setErrors({ ...errors, startDate: false });
            }}
            show={showStartModal}
            shouldCloseOnSelect
            onClickOutside={() => setShowStartModal(false)}
            calendarContainer={startDateContainer}
            id="processStartDayOptions"
          />
        }
        endInput={
          <div className={`${errors.endDate ? '-errorDropdown' : ''}`}>
            <Button
              hasDropdown
              icon="calendar"
              iconSize="large"
              outline
              paddingSize="small"
              size="small"
              className="pt-3 pb-3 d-flex datePickerButton"
              onClick={() => setShowEndModal(!showEndModal)}
              disabled={!stepsNotChecked}
              id="processEndDatePicker"
            >
              {endDate ? `${dayjs(endDate).format(dateFormat)}` : endText}
            </Button>
          </div>
        }
        endDatePlaceholder={
          <DatePicker
            onChange={(date: Date) => {
              date && !Array.isArray(date) && setEndDate(date);
              setShowEndModal(false);
              setErrors({ ...errors, endDate: false });
            }}
            onClickOutside={() => setShowEndModal(false)}
            show={showEndModal}
            selected={endDate}
            withPortal={showEndModal}
            minDate={startDate}
            openToDate={startDate && (!endDate || endDate < startDate) ? startDate : undefined}
            shouldCloseOnSelect
            calendarContainer={endDateContainer}
            id="processEndDayOptions"
          />
        }
        reminderInput={
          <UnderlineInput
            titleText={reminderText}
            type="number"
            error={errors.reminderDays}
            value={reminderDays?.toString()}
            onChange={(dayValue) => {
              const maxDayValue = parseInt(dayValue);
              maxDayValue <= 60
                ? setErrors({ ...errors, reminderDays: false })
                : setErrors({ ...errors, reminderDays: true });
              setReminderDays(+dayValue);
            }}
            readOnly={!stepsNotChecked}
          />
        }
        saveButton={
          <Button
            icon={saveLoading ? undefined : !processId ? 'plus' : 'pencil'}
            paddingSize="medium"
            roundedLarge
            onClick={() => {
              dispatch(isProcessModified({ isModified: true })), submitProcess(!processId);
              setIsSaveAndNew(false);
            }}
            loadingSpinner={saveLoading}
            id="processSubmitButton"
          >
            {!processId ? createText : saveText}
          </Button>
        }
        saveAndNewButton={
          <Button
            icon={saveLoading ? undefined : 'chevronsRight'}
            paddingSize="medium"
            roundedLarge
            outline
            loadingSpinner={saveLoading}
            onClick={() => {
              dispatch(isProcessModified({ isModified: true })), submitProcess(!processId);
              setIsSaveAndNew(true);
            }}
          >
            {saveAndNewText}
          </Button>
        }
        milestoneTitleText={<Text type="style33">{milestonesTitleText}</Text>}
        milestoneSubtitleText={<Text type="style1">{milestonesSubtitleText}</Text>}
        milestoneDropDown={
          <div
            key={processId}
            onClick={() => setShowMilestones(!showMilestones)}
            className="mt-3 align-items-center"
          >
            <Collapse />
          </div>
        }
        milestoneStepsText={<Text type="style1">{milestoneStepsText}</Text>}
        milestones={
          mileStonesData &&
          mileStonesData.map((item: Mile, key) => {
            return (
              <div key={item.id}>
                <div className="d-flex text-nowrap">
                  <div className="w-75">
                    <UnderlineInput
                      titleText={milestoneNameText}
                      value={item.mileName}
                      error={item.nameError}
                      onChange={(value) => {
                        updateMilestone({ ...item, mileName: value, nameError: false });
                      }}
                      max={30}
                      readOnly={!stepsNotChecked}
                      id="mileStoneInput"
                    />
                  </div>
                  {!!key && stepsNotChecked && (
                    <div className="ml-5 align-items-center mt-4">
                      <Icon
                        colorStyle="lightGray"
                        type="trash"
                        onClick={() =>
                          mileStonesData &&
                          setMileStonesData(mileStonesData.filter((mile) => mile.id != item.id))
                        }
                      />
                    </div>
                  )}
                </div>
                <div className="mw-50">
                  <MileStones
                    minDate={startDate}
                    key={key}
                    steps={item.steps}
                    updateItem={(steps: IStepData[]) => updateMilestone({ ...item, steps: steps })}
                    responsibleUsers={
                      (client &&
                        selectedClientId &&
                        client.companies.find((companyData) => companyData.id === company?.id)
                          ?.responsibles) ||
                      client?.users
                    }
                    setRemoveStepIds={setRemoveMilestoneStepIds}
                    stepsNotChecked={stepsNotChecked}
                  />
                </div>
              </div>
            );
          })
        }
        newMilestoneButton={
          <Button
            disabled={mileStonesData.length > MAX_ALLOWED_MILESTONE_ITEM || !stepsNotChecked}
            icon="flag"
            iconSize="medium"
            paddingSize="small"
            className="btn-secondary mt-2 mb-5"
            roundedLarge
            onClick={() => {
              setMileStonesData([...mileStonesData, newMilestoneData]);
            }}
          >
            <Text type="style24">{newMilestoneText}</Text>
          </Button>
        }
        showMilestones={showMilestones}
      />
    )
  );
};

export default ProcessCreateModal;
