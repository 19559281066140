import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import PageLayout from '@/layouts/PageLayout';
import Header from '@/screens/MainScreen/components/Header';
import PageNavigator from '@/screens/MainScreen/components/PageNavigator';
import URLS from '@/resources/constants/URLS';
import { UsersHeader } from '@/screens/AccountSettings/Headers/UsersHeader';
import { Sidebar } from '@/screens/AccountSettings/Sidebar';
import { CompaniesHeader } from '@/screens/AccountSettings/Headers/CompaniesHeader';
import { CustomersHeader } from '@/screens/AccountSettings/Headers/CustomersHeader';
import { DocumentScreenHeader } from '@/screens/DocumentsScreen/Header';
import { DocumentScreenSidebar } from '@/screens/DocumentsScreen/Sidebar';
import { DocumentScreenContent } from '@/screens/DocumentsScreen/Content';
import { DashboardContent } from '@/screens/DashboardScreen/Content';
import { DashboardHeader } from '@/screens/DashboardScreen/Header';
import { ProcessesHeader } from '@/screens/ProcessesScreen/Header';
import { DashboardSidebar } from '@/screens/DashboardScreen/Sidebar';
import { ProcessesSidebar } from '@/screens/ProcessesScreen/Sidebar';
import { ActivityFeedHeader } from '@/screens/AccountSettings/Headers/ActivityFeedHeader';
import { UsersContent } from '@/screens/AccountSettings/Contents/Users';
import { ActivityFeed } from '@/screens/AccountSettings/Contents/ActivityFeed/ActivityFeed';
import { ProcessesContent } from '@/screens/ProcessesScreen/Content';
import { CompanyContent } from '@/screens/AccountSettings/Contents/Company';
import { CustomersContent } from '@/screens/AccountSettings/Contents/Customers';
import { AppState } from '@/redux/store';
import { getFilteredBusinessAreas } from '@/resources/utils';
import { useDashboardData } from '@/client_v2/hooks/useDashboardData';
import { setError } from '@/redux/message/message.action';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import { useDashboardContactsData } from '@/client_v2/hooks/useDashboardContactsData';
import { createOnError } from '@/redux/message/message.callback';
import AdminRoute from '@/components/AdminRoute';

const useMainScreen = () => {
  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.user);
  const { isLoading: authLoading } = useSelector((state: AppState) => state.auth);

  const filters = useSelector((state: AppState) => state.sortAndFilters.sidebarFilters);
  const selectedCompanyId = useSelector((state: AppState) => state.user.selectedCompanyId);
  const selectedClientId = useSelector((state: AppState) => state.user.selectedClientId);

  const getSelectedBusinessAreas = useCallback(() => {
    return getFilteredBusinessAreas(filters);
  }, [filters]);

  const [isDashboardLoading] = useDashboardData({
    renderOnUpdate: false,
    triggerOnce: {
      businessAreaIds: getSelectedBusinessAreas(),
      selectedCompanyId,
      ...(selectedClientId
        ? {
            selectedClientId: selectedClientId,
          }
        : {}),
    },
    onError: (err) => dispatch(setError(err.toString())),
  });

  const [isSidebarLoading] = useDashboardContactsData({
    triggerOnce: {
      clientId: selectedClientId && selectedClientId,
      companyId: selectedCompanyId && selectedCompanyId,
    },
    renderOnUpdate: true,
    onError: createOnError(dispatch),
  });

  return {
    isUserLoading: user.isLoading,
    isDashboardLoading,
    isSidebarLoading,
    authLoading,
  };
};

const MainScreen = () => {
  const { isUserLoading, isDashboardLoading, isSidebarLoading, authLoading } = useMainScreen();
  return (
    <LoadingPlaceholder
      showSpinner={true}
      isLoading={isUserLoading || isDashboardLoading || isSidebarLoading || authLoading}
    >
      <PageLayout
        header={<Header />}
        path={<PageNavigator />}
        contentHeader={
          <Routes>
            <Route path={URLS.root.documents.toString()} element={<DocumentScreenHeader />} />
            <Route
              path={URLS.root.accountSettings.toString()}
              element={<Navigate to={URLS.root.accountSettings.users.toString()} replace />}
            />
            <Route
              path={URLS.root.accountSettings.users.toString()}
              element={<AdminRoute component={<UsersHeader />} />}
            />
            <Route
              path={URLS.root.accountSettings.companies.toString()}
              element={<AdminRoute component={<CompaniesHeader />} />}
            />
            <Route
              path={URLS.root.accountSettings.clients.toString()}
              element={<AdminRoute component={<CustomersHeader />} />}
            />
            <Route
              path={URLS.root.accountSettings.activityFeed.toString()}
              element={<AdminRoute component={<ActivityFeedHeader />} />}
            />
            <Route path={URLS.root.dashboard.toString()} element={<DashboardHeader />} />
            <Route path={URLS.root.processes.toString()} element={<ProcessesHeader />} />
          </Routes>
        }
        sidebar={
          <Routes>
            <Route path={URLS.root.documents.toString()} element={<DocumentScreenSidebar />} />
            <Route
              path={URLS.root.accountSettings.toString()}
              element={<AdminRoute component={<Sidebar />} hidden />}
            />
            <Route path={URLS.root.dashboard.toString()} element={<DashboardSidebar />} />
            <Route path={URLS.root.processes.toString()} element={<ProcessesSidebar />} />
          </Routes>
        }
        content={
          <>
            <Routes>
              <Route path={URLS.root.processes.toString()} element={<ProcessesContent />} />
              <Route path={URLS.root.documents.toString()} element={<DocumentScreenContent />} />
              <Route
                path={URLS.root.accountSettings.toString()}
                element={<Navigate to={URLS.root.accountSettings.users.toString()} replace />}
              />
              <Route
                path={URLS.root.accountSettings.users.toString()}
                element={<AdminRoute component={<UsersContent />} hidden />}
              />
              <Route
                path={URLS.root.accountSettings.companies.toString()}
                element={<AdminRoute component={<CompanyContent />} hidden />}
              />
              <Route
                path={URLS.root.accountSettings.clients.toString()}
                element={<AdminRoute component={<CustomersContent />} hidden />}
              />
              <Route
                path={URLS.root.accountSettings.activityFeed.toString()}
                element={<AdminRoute component={<ActivityFeed />} hidden />}
              />
              <Route path={URLS.root.dashboard.toString()} element={<DashboardContent />} />
              <Route
                path={URLS.root.toString()}
                element={<Navigate to={URLS.root.dashboard.toString()} />}
              />
            </Routes>
          </>
        }
      />
      <Outlet />
    </LoadingPlaceholder>
  );
};

export default MainScreen;
