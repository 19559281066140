import '@/components/KondorLogo/style.scss';
import { FC } from 'react';
import { CreateScopeCSS } from '@/components/utils';
import SVGLogo from '@/resources/SVG/SVGLogo';

const scope = CreateScopeCSS('components-kondor-logo');
const company = scope.and('company');

export enum LogoTypes {
  COMPANY = 'company',
  KONDOR = 'kondor',
}

interface Props {
  onClick?(): void;
  companyImage?: string;
  companyName?: string;
  type?: string;
}

export const KondorLogo: FC<Props> = ({
  companyImage,
  companyName,
  onClick,
  type = LogoTypes.KONDOR,
}) => (
  <>
    {companyImage && type === LogoTypes.COMPANY ? (
      <span className={`m-1 ${scope}`} onClick={onClick}>
        <img
          src={process.env.REACT_APP_API_BASE_URL + companyImage}
          className={company}
          alt={companyName}
        />
      </span>
    ) : (
      type === LogoTypes.KONDOR && (
        <span className={scope} onClick={onClick}>
          <SVGLogo width="128" height="80" />
        </span>
      )
    )}
  </>
);
