import '@/layouts/ProcessesTableLayout/style.scss';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@/components/Button';
import Text from '@/components/Text';
import Icon from '@/components/Icon';
import { ModalBox } from '@/components/ModalBox';
import AddClientModal from '@/screens/AccountSettings/Contents/AddClientModal';
import { CustomerTableHeaderLayout } from '@/layouts/CustomerTableHeaderLayout/CustomerTableHeaderLayout.view.stories';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import { AppState } from '@/redux/store';
import { setModalOpen } from '@/redux/core/core.actions';

const useCustomerContentTableHeader = () => {
  const { t } = useTranslation();

  const taxNumber = t('customerScreen.taxNumber');
  const modification = t('customerScreen.modification');
  const confirmRemove = t('customerScreen.confirmRemove');
  const contactName = t('customerScreen.contactUser');
  const confirm = t('common.confirm');
  const cancel = t('common.cancel');
  const areYouSure = t('common.areYouSure');

  return {
    taxNumber,
    modification,
    confirmRemove,
    confirm,
    cancel,
    contactName,
    areYouSure,
  };
};

interface IProps {
  customerId: number;
  customerName: string;
  contact?: string[];
  taxId: string;
  onDelete: (id: number) => void;
  index: number;
}

export const CustomersContentHeader: FC<IProps> = ({
  customerName,
  customerId,
  contact,
  taxId,
  onDelete,
  index,
}) => {
  const { taxNumber, modification, confirmRemove, confirm, cancel, contactName, areYouSure } =
    useCustomerContentTableHeader();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showEditCustomer, setShowEditCustomer] = useState(false);
  const user = useSelector((state: AppState) => state.user.user);
  const isAdmin = user?.authority == Authorities.Admin || user?.authority == Authorities.SuperAdmin;

  const getShowDeletePopup = () => (
    <ModalBox
      headerText={areYouSure}
      show={showDeletePopup}
      modalWidth="narrow"
      onClickClose={() => setShowDeletePopup(false)}
    >
      <Text type="style1">{confirmRemove}</Text>
      <div className="d-flex justify-content-around">
        <Button
          size="large"
          rounded
          className="my-4 mr-3"
          onClick={() => {
            onDelete(customerId);
            setShowDeletePopup(false);
          }}
          id="deleteClientConfirmation"
        >
          <Text className="px-2 py-1 d-flex" type="style24">
            {confirm}
          </Text>
        </Button>
        <Button
          size="large"
          rounded
          className="my-4 ml-3 btn-secondary"
          onClick={() => setShowDeletePopup(false)}
        >
          <Text className="px-2 py-1 d-flex" type="style24">
            {cancel}
          </Text>
        </Button>
      </div>
    </ModalBox>
  );

  const getShowEditCustomer = () => (
    <ModalBox
      headerText={t('accountSettings.contents.clients.modifyHeader', {
        CUSTOMER: customerName,
      })}
      modalWidth="wide"
      show={showEditCustomer}
      onClickClose={() => setShowEditCustomer(false)}
    >
      <AddClientModal customerId={customerId} close={() => setShowEditCustomer(false)} />
    </ModalBox>
  );

  useEffect(() => {
    dispatch(setModalOpen(showEditCustomer));
  }, [dispatch, showEditCustomer]);

  return (
    <>
      <CustomerTableHeaderLayout
        headerTitle={<Text type="style34">{customerName}</Text>}
        modifyButton={
          <>
            {isAdmin && (
              <Icon
                type="trash"
                colorStyle="lightGray"
                className="customerScreenIcon "
                onClick={() => {
                  setShowDeletePopup(true);
                }}
                id={`deleteIcon-${customerName}-${index}`}
              />
            )}
            {isAdmin && (
              <Button
                outline
                size="medium"
                className="customerContentButton ml-3"
                iconSize="medium"
                icon="pencil"
                rounded
                onClick={() => {
                  setShowEditCustomer(true);
                }}
              >
                <Text className="font-weight-normal" type="style30">
                  {modification}
                </Text>
              </Button>
            )}
          </>
        }
        taxNumber={
          <>
            <Icon type="bookOpen" colorStyle="lightGray" />
            <Text className="font-weight-bold ml-2" type="style28">
              {taxNumber}:
            </Text>
            <Text type="style28" className="pl-1">
              {taxId}
            </Text>
          </>
        }
        contactText={
          <>
            <Icon type="person" colorStyle="lightGray" />
            <Text className="font-weight-bold ml-2" type="style28">
              {contactName}:
            </Text>
            <Text type="style28" className="pl-1">
              {contact}
            </Text>
          </>
        }
      />
      {showDeletePopup && getShowDeletePopup()}
      {showEditCustomer && getShowEditCustomer()}
    </>
  );
};
