import { AxiosError } from 'axios';
import { IDocumentUploadResponse } from '@/client/documentManagementInterfaces';
import { IGetDocumentsData } from '@/client_v2/db/repositories/Document.repo';
import { IExpandDocumentTable } from './documents.interfaces';

export const UPLOAD_DOCUMENT_REQUEST = 'UPLOAD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAILURE = 'UPLOAD_DOCUMENT_FAILURE';
export const SET_DOCUMENT_SEARCH_WORDS = 'SET_SEARCH_WORDS';
export const DELETE_DOCUMENT_SEARCH_WORDS = 'DELETE_SEARCH_WORDS';
export const SEARCH_DOCUMENT_REQUEST = 'SEARCH_DOCUMENT_REQUEST';
export const SEARCH_DOCUMENT_SUCCESS = 'SEARCH_DOCUMENT_SUCCESS';
export const SEARCH_DOCUMENT_FAILURE = 'SEARCH_DOCUMENT_FAILURE';
export const GET_DOCUMENT_TABLE_REQUEST = 'GET_DOCUMENT_TABLE_REQUEST';
export const GET_DOCUMENT_TABLE_SUCCESS = 'GET_DOCUMENT_TABLE_SUCCESS';
export const GET_DOCUMENT_TABLE_FAILURE = 'GET_DOCUMENT_TABLE_FAILURE';
export const EXPAND_DOCUMENT_TABLE = 'EXPAND_DOCUMENT_TABLE';
export const SET_DOCUMENT_SCROLLBAR_POSITION = 'SET_DOCUMENT_SCROLLBAR_POSITION';

export interface IUploadDocumentRequestAction {
  type: typeof UPLOAD_DOCUMENT_REQUEST;
}

export interface IUploadDocumentSuccessAction {
  type: typeof UPLOAD_DOCUMENT_SUCCESS;
  payload: IDocumentUploadResponse;
}

export interface IUploadDocumentFailureAction {
  type: typeof UPLOAD_DOCUMENT_FAILURE;
  error: AxiosError;
}

export interface ISetSearchWordsAction {
  type: typeof SET_DOCUMENT_SEARCH_WORDS;
  payload: string[];
}
export interface IDeleteSearchWordsAction {
  type: typeof DELETE_DOCUMENT_SEARCH_WORDS;
}

export interface ISearchDocumentRequestAction {
  type: typeof SEARCH_DOCUMENT_REQUEST;
}

export interface ISearchDocumentSuccessAction {
  type: typeof SEARCH_DOCUMENT_SUCCESS;
  payload: IGetDocumentsData[];
}

export interface ISearchDocumentFailureAction {
  type: typeof SEARCH_DOCUMENT_FAILURE;
  error: AxiosError;
}

export interface IGetDocumentTableDataRequestAction {
  type: typeof GET_DOCUMENT_TABLE_REQUEST;
}
export interface IGetDocumentTableDataSuccessAction {
  type: typeof GET_DOCUMENT_TABLE_SUCCESS;
  payload: IGetDocumentsData[];
}

export interface IGetDocumentTableDataFailureAction {
  type: typeof GET_DOCUMENT_TABLE_FAILURE;
  error: AxiosError;
}

export interface IExpandDocumentTableAction {
  type: typeof EXPAND_DOCUMENT_TABLE;
  payload: IExpandDocumentTable;
}
export interface ISetDocumentScrollbarPositon {
  type: typeof SET_DOCUMENT_SCROLLBAR_POSITION;
  payload: number;
}

export type DocumentsActionTypes =
  | IUploadDocumentRequestAction
  | IUploadDocumentSuccessAction
  | IUploadDocumentFailureAction
  | ISetSearchWordsAction
  | IDeleteSearchWordsAction
  | ISearchDocumentRequestAction
  | ISearchDocumentSuccessAction
  | ISearchDocumentFailureAction
  | ISetDocumentScrollbarPositon
  | IGetDocumentTableDataRequestAction
  | IGetDocumentTableDataSuccessAction
  | IGetDocumentTableDataFailureAction
  | IExpandDocumentTableAction;
