import { FC, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppState } from '@/redux/store';
import Card from '@/components/Card';
import Collapse from '@/components/Collapse';
import { IGetCompaniesData } from '@/client_v2/db/repositories/Company.repo';
import { useCompanyData } from '@/client_v2/hooks/useCompanyData';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import { createOnError } from '@/redux/message/message.callback';
import { CompanyContentTable } from '@/screens/AccountSettings/Contents/components/CompanyContentTable';
import { CompanyContentHeader } from '@/screens/AccountSettings/Contents/components/CompanyContentHeader';
import { CompanyContentComments } from '@/screens/AccountSettings/Contents/components/CompanyContentComments';
import { useAsyncCallback } from '@/hooks/useAsyncCallback';
import RestClient from '@/client_v2/rest';
import {
  setCollapseCompanyId,
  setCompanyScrollbarPosition,
} from '@/redux/companyManagement/companyManagement.actions';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import URLS from '@/resources/constants/URLS';
import Text from '@/components/Text';
import { expandDocumentTable } from '@/redux/documents/documents.actions';
import { useClientsData } from '@/client_v2/hooks/useClientsData';
import { resetDocumentFilter } from '@/redux/sortAndFilters/sortAndFilters.actions';

export const CompanyContent: FC = () => {
  const { collapseCompanyId } = useSelector((state: AppState) => state.companies);

  const user = useSelector((state: AppState) => state.user.user);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, tableData] = useCompanyData({
    triggerOnce: true,
    renderOnUpdate: true,
    onError: createOnError(dispatch),
  });

  const [sendDelete, deleteLoading] = useAsyncCallback(
    RestClient.Companies.delete,
    createOnError(dispatch),
    [dispatch],
  );

  useEffect(() => {
    dispatch(resetDocumentFilter());
  }, [dispatch]);

  const [clientsLoading, clients] = useClientsData({
    renderOnUpdate: true,
    triggerOnce: true,
    onError: createOnError(dispatch),
  });

  useEffect(() => {
    dispatch(expandDocumentTable({ expand: false }));
  }, [dispatch]);

  useEffect(() => {
    if (user?.authority !== Authorities.Admin && user?.authority !== Authorities.SuperAdmin) {
      navigate(URLS.root.dashboard.toString());
    }
  }, [navigate, user?.authority]);

  const noResult =
    tableData && tableData.companies.length && tableData.users.length
      ? undefined
      : t('accountSettings.contents.companies.noDataResult');

  const scrollDivRef = useRef<HTMLDivElement>(null);
  const scrollbarPosition = useSelector((state: AppState) => state.companies.scrollbarPosition);

  useEffect(() => {
    if (!scrollbarPosition) {
      dispatch(setCompanyScrollbarPosition(scrollDivRef.current?.scrollTop || 0));
    }
  }, [dispatch, scrollbarPosition]);

  useEffect(() => {
    if (scrollbarPosition) {
      scrollDivRef.current?.scrollTo(0, scrollbarPosition || 0);
    }
  }, [scrollbarPosition, isLoading, tableData]);

  return (
    <LoadingPlaceholder showSpinner={true} isLoading={isLoading || deleteLoading || clientsLoading}>
      <div className="scroll-tb">
        <div className="companyContent" ref={scrollDivRef}>
          <div className="d-flex flex-column ">
            {clients &&
              tableData &&
              tableData.companies.map((companyData: IGetCompaniesData, index: number) => {
                return (
                  <div className="mb-4 w-100" key={`company-main-table-${index}`}>
                    <Card bgColorStyle="white" shadow rounded id={`company-card-${index}`}>
                      <CompanyContentHeader
                        taxId={companyData.taxId}
                        contact={tableData?.users
                          .filter((user) => companyData?.contact?.includes(user.id))
                          .map(
                            (value, key) =>
                              value.name + (key + 1 >= companyData.contact.length ? '' : ', '),
                          )}
                        companyName={companyData.name}
                        companyId={companyData.id}
                        clientId={companyData.clientId}
                        comments={companyData.comments}
                        onDelete={sendDelete}
                        clientName={
                          clients.clients.find((clientData) =>
                            clientData.id == companyData.clientId ? clientData.name : '',
                          )?.name
                        }
                        index={index}
                      />
                      <Collapse
                        className="companyCollapse mr-3"
                        isOpened={companyData.id === collapseCompanyId}
                        onClick={() =>
                          companyData.id && dispatch(setCollapseCompanyId(companyData.id))
                        }
                      >
                        <CompanyContentTable
                          companyId={companyData.id}
                          data={companyData.users}
                          key={`company-main-table-${index}`}
                        />
                        <hr className="my-2" />
                        <CompanyContentComments badgeComments={companyData.comments} />
                      </Collapse>
                    </Card>
                  </div>
                );
              })}
            {noResult && (
              <Card bgColorStyle="white" rounded className="d-flex justify-content-center">
                <Text type="style7" className="p-5">
                  {noResult}
                </Text>
              </Card>
            )}
          </div>
        </div>
      </div>
    </LoadingPlaceholder>
  );
};
