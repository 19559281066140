import '@/components/Button/style.scss';
import { FC, MouseEventHandler } from 'react';
import { cn, CreateScopeCSS } from '@/components/utils';
import Icon from '@/components/Icon';
import { GetPropsType } from '@/types/GetPropType';
import { iconColorSwitch } from '@/resources/utils';

const scope = CreateScopeCSS('components-button');
const iconClass = scope.and('icon');
const dropDown = scope.and('dropdown');
const isCircle = scope.and('circle');
const pointer = scope.and('pointer');
const noCursorPointer = scope.and('noCursorPointer');
const notAllowed = scope.and('notAllowed');

const sizeMap = {
  small: 'btn-sm',
  medium: '',
  large: 'btn-lg',
};

const paddingMap = {
  extraSmall: 'px1 py-0',
  small: 'px-3 py-1',
  medium: 'px-4 py-2',
  large: 'px-4 py-3',
};

export interface ButtonProps {
  size?: keyof typeof sizeMap;
  paddingSize?: keyof typeof paddingMap;
  className?: string;
  outline?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  icon?: GetPropsType<typeof Icon>['type'];
  iconSize?: 'small' | 'medium' | 'large' | 'extraLarge';
  disabled?: boolean;
  rounded?: boolean;
  roundedLarge?: boolean;
  hasDropdown?: boolean;
  type?: 'submit' | 'button' | 'reset';
  circle?: boolean;
  white?: boolean;
  loadingSpinner?: boolean;
  id?: string;
  cursor?: 'none' | 'pointer' | 'notAllowed';
}

function getCursorType(cursor: string) {
  switch (cursor) {
    case 'pointer':
      return pointer;
    case 'none':
      return noCursorPointer;
    case 'notAllowed':
      return notAllowed;
    default:
      return pointer;
  }
}

export const Button: FC<ButtonProps> = ({
  children,
  size,
  paddingSize = 'small',
  className,
  outline,
  icon,
  iconSize,
  onClick,
  rounded,
  roundedLarge,
  disabled,
  hasDropdown,
  type,
  circle,
  white,
  loadingSpinner = false,
  id,
  cursor = 'pointer',
}) => (
  <button
    onClick={!disabled ? onClick : undefined}
    className={cn(
      scope,
      'btn',
      outline ? 'btn-outline-primary' : 'btn-primary',
      sizeMap[size!],
      rounded && scope.and('rounded'),
      roundedLarge && scope.and('roundedLarge'),
      paddingMap[paddingSize],
      className,
      hasDropdown && dropDown,
      circle && isCircle,
      getCursorType(cursor),
    )}
    disabled={disabled}
    type={type ? type! : 'button'}
    id={id ? id : `button-${size ? size : 'medium'}`}
  >
    {loadingSpinner ? <div className="spinner-border text-light" role="status" /> : children}

    {icon && (
      <Icon
        type={icon}
        colorStyle={iconColorSwitch(outline, hasDropdown, white)}
        className={cn(iconClass, iconClass.and(iconSize!), getCursorType(cursor))}
      />
    )}
  </button>
);
