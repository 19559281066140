interface Options {
  params?: {
    [param: string]: string;
  };
  queries?: {
    [query: string]: string;
  };
}

export function createUrl(url: string, { params = {}, queries }: Options = {}) {
  let URL = url;
  Object.entries(params).forEach(([key, value]) => {
    URL = URL.replaceAll(key, value);
  });
  if (queries) {
    URL +=
      '?' +
      Object.entries(queries)
        .map(([key, value]) => `${key}=${value}`)
        .join(',');
  }
  return URL;
}
