import '@/styles/index.scss';
import { CreateTemplate, meta } from '@/storybook/utils';
import { CustomerTableHeaderLayout as component } from '@/layouts/CustomerTableHeaderLayout/CustomerTableHeaderLayout.view';
export default meta({
  title: 'Layouts/CustomerTableHeaderLayout',
  component,
});

const Template = CreateTemplate(component);

export const CustomerTableHeaderLayout = Template.bind({});
CustomerTableHeaderLayout.args = {
  headerTitle: <div>Title</div>,
  modifyButton: <div>button</div>,
  taxNumber: <div>Tax</div>,
  contactText: <div>Contact</div>,
};
