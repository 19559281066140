import { UserPoll } from '@/client_v2/polls/User.poll';
import { DocumentPoll } from '@/client_v2/polls/Document.poll';
import { ClientPoll } from '@/client_v2/polls/Client.poll';
import { CompanyPoll } from '@/client_v2/polls/Company.poll';
import { CommentPoll } from '@/client_v2/polls/Comment.poll';
import { TagPoll } from '@/client_v2/polls/Tag.poll';
import { DocumentRepo, IFilterData } from '@/client_v2/db/repositories/Document.repo';
import { createDataHook } from '@/client_v2/hooks/utils/createDataHook';

export const useDocumentData = createDataHook({
  polls: [ClientPoll, CompanyPoll, UserPoll, CommentPoll, TagPoll, DocumentPoll],
  dataFactory: async (filterData: IFilterData) =>
    (await DocumentRepo.query('document-direction-sent', filterData)) &&
    (await DocumentRepo.query('document-direction-received', filterData)) &&
    (await DocumentRepo.query('get-documents-data', filterData)),
});
