/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { createWhereQuery } from '@/client_v2/db/utils/createWhereQuery';
import { creatRepo } from '@/client_v2/db/utils/createRepo';
import { Client } from '@/client_v2/rest/types/ClientsRes';
import { User } from '@/client_v2/rest/types/UsersRes';
import { Comment } from '@/client_v2/rest/types/CommentsRes';
import { Company } from '@/client_v2/rest/types/CompaniesRes';
import { CommentTypes } from '@/resources/constants/shared';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';

export interface IGetClientData {
  id: number;
  name: string;
  taxId: string;
  proposer: string;
  startDate: Date;
  contact: number[];
  comments: { commentId: number; comment: string }[];
  activeBusinessAreas: number[];
  updatedAt: Date;
  createdAt: Date;
}

export interface IGetClientModalData {
  id: number;
  name: string;
  taxId: string;
  contact: {
    id: number;
    name: string;
  }[];
  comments: {
    commentId: number;
    comment: string;
  }[];
  redommender: string;
  startDate: Date;
  proposer: string;
  businessAreasIds: number[];
}

export interface IGetAddUserModalData {
  clientId: number;
  clientName: string;
}

export interface IGetCompanyModalData {
  id: number;
  name: string;
}

export interface IGetClientsCompaniesAndUsers {
  id: number;
  name: string;
  companies: {
    id: number;
    name: string;
    clientId: number;
    responsibles: { userId: number; userName: string }[];
  }[];
  activeBusinessAreas: number[];
  users: { userId: number; userName: string; companyId?: number }[];
}

export interface IGetContactForDashboard {
  id: number;
  contact: IGetContactDataDashboard[];
}
export interface IGetContactDataDashboard {
  id: number;
  name: string;
  phone: string;
  email: string;
  profileImageUrl: string;
  position?: string;
}

export const ClientRepo = creatRepo<Client>({
  name: 'client',
  model: {
    'id:number': { pk: true },
    'name:string': {},
    'tax_id:string': {},
    'start_date:date': {},
    'proposer:string': {},
    'contacts_ids:number[]': { default: [] },
    'users_ids:number[]': { default: [] },
    'companies_ids:number[]': { default: [] },
    'comments_ids:number[]': { default: [] },
    'business_areas_ids:number[]': { default: [] },
    'created_at:date': {},
    'updated_at:date': {},
    'deleted_at:date': {},
  },
})
  .queryName('get-client-data')
  .handler(async (table): Promise<IGetClientData[]> => {
    const res: IGetClientData[] = (await table()
      .query('select', [
        'id',
        'name',
        'tax_id AS taxId',
        'proposer',
        'start_date AS startDate',
        'created_at AS createdAt',
        'comments',
        'contacts_ids AS contact',
        'updated_at AS updatedAt',
        'business_areas_ids AS activeBusinessAreas',
      ])
      .graph([
        {
          key: 'users_ids',
          single: true,
          with: { table: 'user' },
          select: ['id', 'name'],
          on: createWhereQuery<User, { client: Client }>(({ clients }, { client }) =>
            clients.includes(client.id),
          ),
        },
        {
          key: 'comments',
          with: { table: 'comment' },
          select: ['id AS commentId', 'comment'],
          on: createWhereQuery<Comment, { client: Client }>(
            (comment, { client }) =>
              client.comments_ids.includes(comment.id) &&
              comment.commentable_type === CommentTypes.CLIENT,
          ),
        },
      ])
      .exec()) as IGetClientData[];

    res.sort((a, b) => {
      return b.createdAt.valueOf() - a.createdAt.valueOf();
    });

    return res;
  })
  .queryName('get-add-or-modify-user-modal-data')
  .handler((table): Promise<IGetAddUserModalData[]> => {
    return table().query('select', ['id AS clientId', 'name AS clientName']).exec() as Promise<
      IGetAddUserModalData[]
    >;
  })
  .queryName('get-clients-for-filters')
  .handler(
    async (table) =>
      (await table().query('select', ['id', 'name']).exec()) as { id: number; name: string }[],
  )
  .queryName('get-clients-and-companies-and-users')
  .handler(
    async (table) =>
      (await table()
        .query('select', [
          'id',
          'name',
          'companies',
          'users',
          'business_areas_ids AS activeBusinessAreas',
        ])
        .graph([
          {
            key: 'companies',
            with: { table: 'company' },
            select: [
              'id',
              'name',
              'client_id AS clientId',
              'users_ids AS usersIds',
              'responsibles',
            ],
            on: createWhereQuery<Company, { client: Client }>(({ id }, { client }) =>
              client.companies_ids.includes(id),
            ),
            graph: {
              key: 'responsibles',
              with: { table: 'user' },
              on: createWhereQuery<User, { company: Company }>(({ id, authority }, { company }) => {
                return company.users_ids.includes(id) || authority === Authorities.SuperAdmin;
              }),
              select: ['id AS userId', 'name AS userName'],
            },
          },
          {
            key: 'users',
            with: { table: 'user' },
            on: createWhereQuery<User, { client: Client }>(({ id, authority }, { client }) => {
              return authority === Authorities.SuperAdmin || client.users_ids.includes(id);
            }),
            select: ['id AS userId', 'name AS userName', 'client_id AS clientId'],
          },
        ])
        .exec()) as IGetClientsCompaniesAndUsers[],
  )
  .queryName('get-responsibles-for-filters')
  .handler(async (table) => {
    const res: IGetClientData[] = (await table()
      .query('select', ['id', 'contact'])
      .graph([
        {
          key: 'contact',
          with: { table: 'user' },
          select: ['id', 'name'],
          on: createWhereQuery<User, { client: Client }>(({ id }, { client }) =>
            client.contacts_ids?.includes(id),
          ),
        },
      ])
      .exec()) as IGetClientData[];

    return res.map((item) => item.contact).flat(1);
  })

  .queryName('get-company-modal-data')
  .handler((table): Promise<IGetCompanyModalData[]> => {
    return table().query('select', ['id', 'name']).exec() as Promise<IGetCompanyModalData[]>;
  })

  .queryName('get-customer-modal-data')
  .handler(async (table, { id }: { id: number }): Promise<IGetClientModalData> => {
    const res = table()
      .query('select', [
        'id',
        'name',
        'tax_id AS taxId',
        'client',
        'users_ids AS contact',
        'comments',
        'start_date AS startDate',
        'proposer',
        'business_areas_ids AS businessAreasIds',
      ])
      .where(['id', '=', id])
      .graph([
        {
          key: 'users_ids',
          with: { table: 'user' },
          on: createWhereQuery<User, { client: Client }>(({ id }, { client }) =>
            client.contacts_ids?.includes(id),
          ),
          select: ['id', 'name'],
        },
        {
          key: 'comments',
          with: { table: 'comment' },
          select: ['id AS commentId', 'comment'],
          on: createWhereQuery<Comment, { client: Client }>(
            (comment, { client }) =>
              client.comments_ids.includes(comment.id) &&
              comment.commentable_type === CommentTypes.CLIENT,
          ),
        },
      ])
      .exec();

    return (await res).pop() as IGetClientModalData;
  })
  .queryName('get-client-contact-for-dashboard')
  .handler(async (table, { clientId }: { clientId: number | null }) => {
    const res: IGetContactForDashboard[] = (await table()
      .query('select', ['id', 'users_ids AS contact'])
      .where(clientId ? ['id', '=', clientId] : [])
      .graph([
        {
          key: 'users_ids',
          with: { table: 'user' },
          select: [
            'id',
            'name',
            'phone',
            'email',
            'position',
            'profile_image_url AS profileImageUrl',
          ],
          on: createWhereQuery<User, { client: Client }>(({ id }, { client }) =>
            client.contacts_ids?.includes(id),
          ),
        },
      ])
      .exec()) as IGetContactForDashboard[];
    return res.map((item) => item.contact).flat(1);
  })
  .make();
