/* eslint-disable camelcase */
import { creatRepo } from '@/client_v2/db/utils/createRepo';
import { Tag } from '@/client_v2/rest/types/TagsRes';

export const TagRepo = creatRepo<Tag>({
  name: 'tag',
  model: {
    'id:number': { pk: true },
    'name:string': {},
    'created_at:date': {},
    'updated_at:date': {},
  },
}).make();
