import { FC, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useClientsData } from '@/client_v2/hooks/useClientsData';
import Card from '@/components/Card';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import { IGetClientData } from '@/client_v2/db/repositories/Client.repo';
import Collapse from '@/components/Collapse';
import { createOnError } from '@/redux/message/message.callback';
import { useAsyncCallback } from '@/hooks/useAsyncCallback';
import RestClient from '@/client_v2/rest';
import { setCollapseClientId, setClientScrollbarPosition } from '@/redux/clients/clients.actions';
import { AppState } from '@/redux/store';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import URLS from '@/resources/constants/URLS';
import Text from '@/components/Text';
import { useCompanyData } from '@/client_v2/hooks/useCompanyData';
import { resetDocumentFilter } from '@/redux/sortAndFilters/sortAndFilters.actions';
import { CustomersContentHeader } from './components/CustomerContentHeader';
import { CustomerContentTable } from './components/CustomerContentTable';

export const CustomersContent: FC = () => {
  const { collapseClientId } = useSelector((state: AppState) => state.clients);
  const user = useSelector((state: AppState) => state.user.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, tableData] = useClientsData({
    triggerOnce: true,
    renderOnUpdate: true,
    onError: createOnError(dispatch),
  });
  const [sendDelete, deleteLoading] = useAsyncCallback(
    RestClient.Clients.delete,
    createOnError(dispatch),
    [dispatch],
  );

  const [isCompanyLoading, companies] = useCompanyData({
    triggerOnce: true,
    renderOnUpdate: true,
    onError: createOnError(dispatch),
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (user?.authority != Authorities.Admin && user?.authority != Authorities.SuperAdmin) {
      navigate(URLS.root.dashboard.toString());
    }
  }, [navigate, user?.authority]);

  useEffect(() => {
    dispatch(resetDocumentFilter());
  }, [dispatch]);

  useEffect(() => {
    if (user?.authority === Authorities.Admin) {
      tableData?.clients.filter((client) => user?.clients?.includes(client.id));
    }
  }, [tableData, user?.authority, user?.clients]);

  const noResult =
    tableData && tableData.clients.length
      ? undefined
      : t('accountSettings.contents.clients.noDataResult');

  const scrollDivRef = useRef<HTMLDivElement>(null);
  const scrollbarPosition = useSelector((state: AppState) => state.clients.scrollbarPosition);

  useEffect(() => {
    if (!scrollbarPosition) {
      dispatch(setClientScrollbarPosition(scrollDivRef.current?.scrollTop || 0));
    }
  }, [dispatch, scrollbarPosition]);

  useEffect(() => {
    if (scrollbarPosition) {
      scrollDivRef.current?.scrollTo(0, scrollbarPosition || 0);
    }
  }, [scrollbarPosition, isLoading, isCompanyLoading, tableData]);

  return (
    <LoadingPlaceholder
      showSpinner={true}
      isLoading={isLoading || deleteLoading || isCompanyLoading}
    >
      <div className="scroll-tb" ref={scrollDivRef}>
        <div className="customerContent">
          <div className="d-flex flex-column w-100">
            {companies &&
              tableData &&
              tableData.clients.map((item: IGetClientData, key) => {
                return (
                  <div className="mb-4" key={item.id}>
                    <Card bgColorStyle="white" shadow rounded>
                      <CustomersContentHeader
                        key={item.id}
                        customerId={item.id}
                        customerName={item.name}
                        taxId={item.taxId}
                        contact={tableData?.users
                          .filter((user) => item?.contact?.includes(user.id))
                          .map(
                            (value, key) =>
                              value.name + (key + 1 >= item.contact?.length ? '' : ', '),
                          )}
                        onDelete={sendDelete}
                        index={key}
                      />
                      <Collapse
                        className="customerCollapse mr-3"
                        isOpened={item.id === collapseClientId}
                        onClick={() => item.id && dispatch(setCollapseClientId(item.id))}
                      >
                        <CustomerContentTable
                          key={key}
                          startDate={item.startDate}
                          proposer={item.proposer}
                          badgeComments={item.comments.map((value) => value.comment)}
                          activationDate={item.createdAt}
                          businessAreas={item.activeBusinessAreas}
                          companies={companies.companies
                            .filter((company) => company.clientId === item.id)
                            .map(
                              (value, key) =>
                                value.name + (key + 1 >= companies.companies.length ? '' : ', '),
                            )}
                        />
                      </Collapse>
                    </Card>
                  </div>
                );
              })}
            {noResult && (
              <Card bgColorStyle="white" rounded className="d-flex justify-content-center">
                <Text type="style7" className="p-5">
                  {noResult}
                </Text>
              </Card>
            )}
          </div>
        </div>
      </div>
    </LoadingPlaceholder>
  );
};
