import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { TagsRes } from '@/client_v2/rest/types/TagsRes';
import { API_TAGS, DISABLE_PAGINATIONS_QUERY } from '@/client_v2/rest/Tags/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';

export const getTags = () =>
  axiosInstance.get<TagsRes>(
    createUrl(API_TAGS, {
      queries: {
        [DISABLE_PAGINATIONS_QUERY]: '1',
      },
    }),
  );
