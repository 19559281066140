/* eslint-disable camelcase */
import { axiosFormDataInstance } from '@/client_v2/rest/axios/axiosInstance';
import { PostCompany } from '@/client_v2/rest/types/PostCompany';
import { postComments } from '@/client_v2/rest/Comments/postComments';
import { API_COMPANIES } from '@/client_v2/rest/Companies/URLS';
import { CompanyPoll } from '@/client_v2/polls/Company.poll';

export const postCompany = async (
  { file, data }: PostCompany,
  newComments?: { comments: string[]; userId: number },
) => {
  const formData = new FormData();
  if (file) formData.set('file', file);
  formData.set('data', JSON.stringify(data));
  formData.set('_method', 'POST');
  const res = await axiosFormDataInstance.post<{ data: { id: number } }>(API_COMPANIES, formData);

  if (newComments && newComments.comments && newComments.comments.length && newComments.userId) {
    await postComments({
      entityId: res.data.data.id,
      entityType: 'companies',
      comments: newComments,
    });
  }
  await CompanyPoll.trigger();
  return res;
};
