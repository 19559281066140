import '@/components/SearchInput/style.scss';
import { FC, ChangeEventHandler, FormEvent } from 'react';
import { cn, CreateScopeCSS } from '@/components/utils';
import Icon from '@/components/Icon';
import Button from '@/components/Button';

const scope = CreateScopeCSS('components-search-input');
const input = CreateScopeCSS(scope.and('input'));

export interface Props {
  placeholder?: string;
  label?: string;
  ariaDescribedby?: string;
  error?: boolean;
  name?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  buttonText?: string;
  onSubmit: (e: FormEvent) => void;
  loading?: boolean;
}

export const SearchInput: FC<Props> = ({
  placeholder,
  label,
  ariaDescribedby,
  error,
  name,
  onChange,
  buttonText,
  onSubmit,
  loading,
}) => (
  <form
    onSubmit={onSubmit}
    className={cn(scope, 'w-100', 'form-group', 'mb-0', 'h-100', 'd-flex', 'align-items-center')}
  >
    <div className="d-flex align-items-center justify-content-between w-100 h-75 bg-white rounded p-2">
      <div className="flex-fill d-flex">
        <div className={cn('d-flex', 'align-items-center')}>
          <Icon type="google" colorStyle="lightGray" />
        </div>
        <div className="flex-fill">
          {label && (
            <label htmlFor={name} className={`form-label-sm ${error && 'text-danger'}`}>
              {label}
            </label>
          )}
          <input
            className={cn(error && 'is-invalid', 'form-control', 'border-0', input)}
            onChange={onChange}
            aria-describedby={ariaDescribedby}
            placeholder={placeholder}
            name={name}
          />
        </div>
      </div>
      <Button size="medium" type="submit" outline className="h-100">
        {!loading ? (
          buttonText
        ) : (
          <div className="spinner-border spinner-border-sm text-light" role="status" />
        )}
      </Button>
    </div>
  </form>
);
