import { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarContainer } from 'react-datepicker';
import ActivityFeedHeaderLayout from '@/layouts/ActivityFeedHeader';
import Button from '@/components/Button';
import Text from '@/components/Text';
import DatePickerComponent from '@/components/DatePicker';
import {
  getActivityFeedsAction,
  getRefreshActivityTableAction,
  exportActivityFeeds,
} from '@/redux/activityFeed/activityFeed.actions';
import { AppState } from '@/redux/store';
import CSVDownloadButton from '@/components/CSVDownloadButton';
import { cn } from '@/components/utils';

interface IDateProps {
  className?: string;
  children?: ReactNode;
}

const useActivityFeedHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const header = t('nav.activityFeed');
  const downloadList = t('accountSettings.header.activityFeed.downloadListButton');
  const dateFormat = t('common.dateFormat');
  const setDateText = t('accountSettings.header.activityFeed.setDateText');

  const [showDatepicker, setShowDatepicker] = useState(false);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [hasDateSelected, setHasDateSelected] = useState(false);

  const {
    isLoading,
    isExportLoading,
    fileExportData,
    data: activityFeed,
  } = useSelector((state: AppState) => state.activityFeeds);

  useEffect(() => {
    dispatch(exportActivityFeeds({}));
    if (!activityFeed.length) {
      dispatch(getActivityFeedsAction());
    }
  }, [dispatch, activityFeed]);

  const datePickerContainer = ({ className, children }: IDateProps) => {
    return (
      <div className=" d-lg-block  d-sm-flex  justify-content-around user-select-none">
        <CalendarContainer className={cn(className)}>
          <div className="position-relative">{children}</div>
          <div className="col-12 d-flex justify-content-center">
            <Button
              size="small"
              className="mb-3"
              onClick={() => {
                setShowDatepicker(false);
                hasDateSelected && dateRange
                  ? dispatch(
                      getRefreshActivityTableAction({
                        startDate: dayjs(startDate).format(dateFormat),
                        endDate: dayjs(endDate).format(dateFormat),
                      }),
                    )
                  : dispatch(getActivityFeedsAction());
              }}
            >
              {setDateText}
            </Button>
            <Button
              size="small"
              iconSize="large"
              icon="x"
              className="mb-3 ml-4"
              onClick={() => {
                setShowDatepicker(false);
                setDateRange([]);
              }}
            />
          </div>
        </CalendarContainer>
      </div>
    );
  };

  return {
    header,
    downloadList,
    showDatepicker,
    setShowDatepicker,
    startDate,
    endDate,
    setDateRange,
    dispatch,
    dateFormat,
    isLoading,
    isExportLoading,
    hasDateSelected,
    setHasDateSelected,
    fileExportData,
    datePickerContainer,
    selectDateRangeText: t('accountSettings.header.activityFeed.selectDateRange'),
    filterByDateText: t('accountSettings.header.activityFeed.filterByDate'),
  };
};

export const ActivityFeedHeader: FC = () => {
  const {
    header,
    downloadList,
    showDatepicker,
    setShowDatepicker,
    startDate,
    endDate,
    setDateRange,
    dispatch,
    dateFormat,
    isLoading,
    isExportLoading,
    hasDateSelected,
    setHasDateSelected,
    fileExportData,
    selectDateRangeText,
    filterByDateText,
    datePickerContainer,
  } = useActivityFeedHeader();

  return (
    <ActivityFeedHeaderLayout
      activityFeedHeader={<Text type="style13">{header}</Text>}
      activityFeedDatePicker={
        <>
          <>
            <Button
              className="bg-white rounded pt-3 pb-3 d-flex justify-content-center"
              hasDropdown
              icon="calendar"
              iconSize="large"
              outline
              paddingSize="small"
              size="medium"
              onClick={() => {
                setShowDatepicker(true);
              }}
            >
              {startDate && endDate && hasDateSelected
                ? `${dayjs(startDate).format(dateFormat)} - ${dayjs(endDate).format(dateFormat)}`
                : selectDateRangeText}
            </Button>
          </>
          <Text type="style6">{filterByDateText}</Text>
        </>
      }
      datePickerPlaceholder={
        <DatePickerComponent
          onChange={(date: [Date, Date]) => {
            setDateRange(date);
            setHasDateSelected(true);
          }}
          onClickOutside={() => {
            setShowDatepicker(false);
          }}
          show={showDatepicker}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          shouldCloseOnSelect={true}
          calendarContainer={datePickerContainer}
        />
      }
      refreshButton={
        isLoading ? (
          <Button roundedLarge size="large" circle>
            <div className="spinner-grow text-light" role="status" />
          </Button>
        ) : (
          <Button
            icon="refreshCcw"
            iconSize="extraLarge"
            paddingSize="large"
            roundedLarge
            size="large"
            circle
            onClick={() => {
              dispatch(
                exportActivityFeeds(
                  hasDateSelected && startDate && endDate
                    ? {
                        startDate: dayjs(startDate).format(dateFormat),
                        endDate: dayjs(endDate).format(dateFormat),
                      }
                    : {},
                ),
              );
              hasDateSelected && startDate && endDate
                ? dispatch(
                    getRefreshActivityTableAction({
                      startDate: dayjs(startDate).format(dateFormat),
                      endDate: dayjs(endDate).format(dateFormat),
                    }),
                  )
                : dispatch(getActivityFeedsAction());
            }}
          />
        )
      }
      downloadListButton={
        <CSVDownloadButton
          data={fileExportData! || ''}
          icon="download"
          iconSize="extraLarge"
          paddingSize="large"
          roundedLarge
          size="medium"
          className="text-truncate btn-outline-light"
          outline
          white
          fileName="activity_feed.txt"
        >
          {!isExportLoading ? (
            downloadList
          ) : (
            <div className="spinner-border text-dark" role="status" />
          )}
        </CSVDownloadButton>
      }
    />
  );
};
