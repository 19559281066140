import '@/components/CircleImage/style.scss';
import { FC } from 'react';
import { cn, CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('components-circleImage');
const height = scope.and('height');
const noPic = scope.and('no-pic');

export interface CircleImageProps {
  image?: string;
  alt?: string;
  children?: never;
  size?: 'small' | 'medium';
  smallText?: string;
  color?: 'white' | 'secondary';
}

export const CircleImage: FC<CircleImageProps> = ({
  image,
  size,
  alt,
  smallText,
  color = 'white',
}) => {
  if (image) {
    return (
      <div className={'text-center'}>
        <img className={cn(scope, size && height.and(size))} src={image} alt={alt} />
      </div>
    );
  } else {
    return <div className={cn(scope, size && height.and(size), noPic, color)}>{smallText}</div>;
  }
};
