import React from 'react';
import { useTranslation } from 'react-i18next';
import '@/screens/ProcessesScreen/components/ProcessCreateModal/style.scss';
import { cn, CreateScopeCSS } from '@/components/utils';
import Text from '@/components/Text';
import { Table } from '@/components/Table';
import Button from '@/components/Button';
import { MileStoneStep } from '@/screens/ProcessesScreen/components/ProcessCreateModal/MileStoneStep';
import { MAX_ALLOWED_MILESTONE_STEP_ITEM } from '@/resources/constants/shared';
import { IGetWorkflowResponsibleUser } from '@/client_v2/db/repositories/Workflow.repo';
import '@/styles/screens/ProcessScreen/index.scss';

const scope = CreateScopeCSS('milestone-items');
const addStepStyle = scope.and('add-step');

interface IProps {
  responsibleUsers?: IGetWorkflowResponsibleUser[] | [];
  updateItem: (item: IStepData[]) => void;
  minDate: Date | undefined;
  steps?: IStepData[];
  setRemoveStepIds: (stepId: number) => void;
  stepsNotChecked?: boolean;
}

export interface IStepData {
  id?: number;
  stepName: string;
  deadline: Date;
  responsibleUserIds: number[];
  nameError?: boolean;
  deadlineError?: boolean;
  responsibleUsers: IGetWorkflowResponsibleUser[] | null;
}

export const MileStones: React.FC<IProps> = ({
  minDate,
  responsibleUsers,
  updateItem,
  steps,
  setRemoveStepIds,
  stepsNotChecked,
}) => {
  const newItem: IStepData = {
    deadline: new Date(),
    stepName: '',
    responsibleUserIds: [],
    id: Math.random(),
    responsibleUsers: [],
  };

  const { t } = useTranslation();
  const milestoneStepNameText = t('processesScreen.modal.milestoneStepName');
  const responsiblesText = t('processesScreen.modal.responsibles');
  const deadlineText = t('processesScreen.modal.deadline');
  const actionsText = t('processesScreen.modal.actions');
  const addStep = t('processesScreen.modal.addStep');
  const stepsOfMilestones = t('processesScreen.modal.stepsOfMilestones');

  const headers = [milestoneStepNameText, responsiblesText, deadlineText, actionsText];

  const removeData = (index: number) => {
    setRemoveStepIds(index);
    steps && updateItem(steps.filter((milestone) => milestone.id != index));
  };

  const updateData = (item: IStepData) => {
    steps && updateItem(steps.map((milestone) => (milestone.id === item.id ? item : milestone)));
  };

  return (
    <div className={cn(scope)}>
      <div className="row mt-4">
        <div className="col-12">
          <Text type="style1">{stepsOfMilestones}</Text>
        </div>
      </div>
      <div className="table-width">
        <Table
          header={
            headers &&
            headers.map((value: string, i: number) => {
              return {
                column: i + 1,
                row: 1,
                children: (
                  <Text
                    type="style34"
                    className="font-weight-bold d-flex justify-content-between text-nowrap"
                  >
                    {value}
                  </Text>
                ),
                header: true,
              };
            })
          }
        />
        <hr className="mt-0 mb-4" />
        {steps &&
          steps.map((item: IStepData, key) => {
            return (
              <div key={`milestones${item.id}`}>
                <MileStoneStep
                  minDate={minDate}
                  data={item}
                  removable={!!key}
                  responsibleUsers={responsibleUsers || []}
                  removeItem={removeData}
                  updateItem={updateData}
                  cloneItem={(item) => updateItem([...steps, { ...item, id: Math.random() }])}
                  stepsNotChecked={stepsNotChecked}
                />
              </div>
            );
          })}
        <div className={`${addStepStyle} col-12 `}>
          <Button
            disabled={
              (steps && steps?.length > MAX_ALLOWED_MILESTONE_STEP_ITEM) || !stepsNotChecked
            }
            className="float-right btn-secondary"
            size="small"
            paddingSize="small"
            onClick={() => {
              steps
                ? updateItem([...steps, { ...newItem, id: Math.random() }])
                : updateItem([{ ...newItem, id: Math.random() }]);
            }}
          >
            <Text type="style24">{addStep}</Text>
          </Button>
        </div>
      </div>

      <hr className="mt-5" />
    </div>
  );
};
