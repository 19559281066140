/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react';

export const useAddComment = () => {
  const [showEmptyCommentError, setShowEmptyCommentError] = useState<boolean>(false);
  const [showSameCommentError, setShowSameCommentError] = useState<boolean>(false);
  const [comments, setComments] = useState<{ comment: string; commentId?: number }[]>([]);
  const [comment, setCommentInput] = useState<string>('');

  const addComment = useCallback(() => {
    if (comment === '') {
      setShowEmptyCommentError(true);
      setShowSameCommentError(false);
      return;
    }
    if (comments.find(({ comment: c }) => c === comment)) {
      setShowSameCommentError(true);
      setShowEmptyCommentError(false);
      return;
    }
    setComments((comments) => [...comments, { comment }]);
    setCommentInput('');
    setShowEmptyCommentError(false);
    setShowSameCommentError(false);
  }, [comment, comments, setComments, setCommentInput]);

  const removeComment = useCallback(
    ({ comment }: { comment: string; commentId?: number }) => {
      setComments((comments) => comments.filter(({ comment: c }) => c !== comment));
    },
    [setComments],
  );

  return {
    comments,
    setComments,
    comment,
    setCommentInput,
    addComment,
    removeComment,
    showEmptyCommentError,
    showSameCommentError,
  };
};

export default useAddComment;
