import { FC, ReactNode } from 'react';
import { CreateScopeCSS, cn } from '@/components/utils';
import '@/layouts/AddCompanyLayout/style.scss';

const scope = CreateScopeCSS('layouts-add-company-layout');

interface Props {
  clientDropdownHeader?: ReactNode;
  clientDropdown?: ReactNode;
  nameInput?: ReactNode;
  comments?: ReactNode;
  newCommentInput?: ReactNode;
  addNewCommentButton?: ReactNode;
  contactDropDownHeader?: ReactNode;
  contactDropDown?: ReactNode;
  commentIcon?: ReactNode;
  taxInput?: ReactNode;
  imageDescription?: ReactNode;
  imageTypeDescription?: ReactNode;
  badges?: { badgeTexts: ReactNode }[];
  companyImage?: ReactNode;
  uploadButton?: ReactNode;
  saveCompanyButton?: ReactNode;
  errorField?: ReactNode;
}

export const AddCompanyLayout: FC<Props> = ({
  clientDropdownHeader,
  clientDropdown,
  nameInput,
  comments,
  newCommentInput,
  addNewCommentButton,
  contactDropDownHeader,
  contactDropDown,
  taxInput,
  badges,
  companyImage,
  commentIcon,
  imageDescription,
  imageTypeDescription,
  saveCompanyButton,
  uploadButton,
  errorField,
}) => (
  <div className={cn(scope, 'container-fluid')}>
    <div className="row text-nowrap">
      <div className="col-md-12">
        <div className="row justify-content-around">
          <div className="col-md-12 col-lg-6">
            <div className="row">
              <div className="col-md-12">{clientDropdownHeader}</div>
            </div>
            <div className="row">
              <div className="col-md-12">{clientDropdown}</div>
            </div>
            <div className="row mt-5 pt-3 mt-md-0">
              <div className="col-md-12">{nameInput}</div>
            </div>
            <div className="row mt-5 mt-md-4">
              <div className="col-md-1">{commentIcon}</div>
              <div className="col-md-5">{comments}</div>
            </div>
            <div className="row mt-3">
              <div className="row mt-3">
                {badges?.map(({ badgeTexts }, badgeData) => (
                  <div className="ml-4 mt-1" key={badgeData}>
                    {badgeTexts}
                  </div>
                ))}
              </div>
            </div>
            <div className="row mt-3 align-items-center">
              <div className="col-md-6">{newCommentInput}</div>
              <div className="col-md-6 mt-3">{addNewCommentButton}</div>
            </div>
            <div className="row mt-3 ">
              <div className="col-md-12">{errorField}</div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 mt-lg-0 mt-md-4">
            <div className="row">
              <div className="col-md-12">{contactDropDownHeader}</div>
            </div>
            <div className="row">
              <div className="col-md-12">{contactDropDown}</div>
            </div>
            <div className="row mt-5 pt-2 mt-md-2">
              <div className="col-md-12">{taxInput}</div>
            </div>
            <div className="row mt-5 align-items-center">
              <div className="col-md-12 col-lg-3 d-flex justify-content-md-center">
                {companyImage}
              </div>
              <div className="col-md-7 col-lg-5 ">
                <div className="row">
                  <div className="col-lg-12 d-inline-block text-truncate">{imageDescription}</div>
                </div>
                <div className="row">
                  <div className="col-lg-12 d-inline-block text-truncate">
                    {imageTypeDescription}
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-lg-4 align-self-center">{uploadButton}</div>
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-4">
          <div className="col-md-12 d-flex justify-content-lg-start justify-content-md-center">
            {saveCompanyButton}
          </div>
        </div>
      </div>
    </div>
  </div>
);
