/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { createWhereQuery } from '@/client_v2/db/utils/createWhereQuery';
import { creatRepo } from '@/client_v2/db/utils/createRepo';
import { Company } from '@/client_v2/rest/types/CompaniesRes';
import { User } from '@/client_v2/rest/types/UsersRes';
import { Comment } from '@/client_v2/rest/types/CommentsRes';
import { Client } from '@/client_v2/rest/types/ClientsRes';
import { CommentTypes } from '@/resources/constants/shared';

export interface IUserCompaniesData {
  userId: number;
  userName: string;
  email: string;
  authority: string;
  lastLogin: Date;
  isBlocked: boolean;
  companies: number[] | null;
}

export interface ICommentCompaniesData {
  commentId: number;
  comment: string;
}

export interface ICompanyForSelector {
  id: number;
  name: string;
  clientId: number;
  profileImageUrl: string | null;
}

export interface IGetCompaniesData {
  id: number;
  name: string;
  taxId: string;
  contact: number[];
  users: IUserCompaniesData[];
  comments: ICommentCompaniesData[];
  clientId: number;
  createdAt: Date;
}

export interface IGetContactForFilter {
  id: number;
  contacts: IGetContactDataForFilter[];
}

export interface IGetContactDataForFilter {
  contactId: number;
  contactName: string;
}

export interface IGetCompanyModalData {
  id: number;
  name: string;
  taxId: string;
  profileImageUrl: string;
  client: {
    name: string;
    id: number;
  };
  contact: {
    name: string;
    id: number;
  }[];
  comments: {
    commentId: number;
    comment: string;
  }[];
}

export interface IGetContactForDashboard {
  id: number;
  contacts: IGetContactDataDashboard[];
}
export interface IGetContactDataDashboard {
  id: number;
  name: string;
  phone: string;
  email: string;
  profileImageUrl: string;
  position?: string;
}

export interface IGetAddUserModalData {
  companyId: number;
  companyName: string;
  clientId: number;
}

export const CompanyRepo = creatRepo<Company>({
  name: 'company',
  model: {
    'id:number': { pk: true },
    'name:string': {},
    'client_id:number': { default: -1 },
    'contacts_ids:number[]': { default: [] },
    'tax_id:string': {},
    'profile_image:string': {},
    'profile_image_url:string': {},
    'users_ids:number[]': { default: [] },
    'comments_ids:number[]': { default: [] },
    'created_at:date': {},
    'updated_at:date': {},
    'deleted_at:date': {},
  },
})
  .queryName('get-companies-data')
  .handler(async (table): Promise<IGetCompaniesData[]> => {
    const res: IGetCompaniesData[] = (await table()
      .query('select', [
        'id',
        'name',
        'tax_id AS taxId',
        'users',
        'comments',
        'contacts_ids AS contact',
        'client_id AS clientId',
        'created_at AS createdAt',
      ])
      .graph([
        {
          key: 'users',
          with: { table: 'user' },
          select: [
            'id AS userId',
            'name AS userName',
            'email',
            'authority',
            'last_login AS lastLogin',
            'is_blocked AS isBlocked',
            'companies',
          ],
          on: createWhereQuery<User, { company: Company }>(({ id }, { company }) =>
            company.users_ids.includes(id),
          ),
        },
        {
          key: 'comments',
          with: { table: 'comment' },
          select: ['id AS commentId', 'comment'],
          on: createWhereQuery<Comment, { company: Company }>(
            (comment, { company }) =>
              company.comments_ids.includes(comment.id) &&
              comment.commentable_type === CommentTypes.COMPANY,
          ),
        },
      ])
      .exec()) as IGetCompaniesData[];

    res.sort((a, b) => {
      return b.createdAt.valueOf() - a.createdAt.valueOf();
    });

    return res;
  })
  .queryName('get-companies-list-for-selector-data')
  .handler(async (table): Promise<ICompanyForSelector[]> => {
    const res: ICompanyForSelector[] = (await table()
      .query('select', [
        'id',
        'name',
        'client_id AS clientId',
        'profile_image_url as profileImageUrl',
      ])
      .exec()) as ICompanyForSelector[];
    return res;
  })
  .queryName('get-companies-for-filters')
  .handler((table): Promise<IGetCompaniesData[]> => {
    return table().query('select', ['id', 'name', 'client_id AS clientId']).exec() as Promise<
      IGetCompaniesData[]
    >;
  })
  .queryName('get-companies-data-for-table')
  .handler(async (table): Promise<IGetCompaniesData[]> => {
    const res: IGetCompaniesData[] = (await table()
      .query('select', [
        'id',
        'name',
        'tax_id AS taxId',
        'users',
        'comments',
        'contacts_ids AS contact',
        'client_id AS clientId',
        'created_at AS createdAt',
      ])
      .graph([
        {
          key: 'users',
          with: { table: 'user' },
          select: [
            'id AS userId',
            'name AS userName',
            'email',
            'authority',
            'last_login AS lastLogin',
            'is_blocked AS isBlocked',
            'companies',
          ],
          on: createWhereQuery<User, { company: Company }>(({ id }, { company }) =>
            company.users_ids.includes(id),
          ),
        },
        {
          key: 'comments',
          with: { table: 'comment' },
          select: ['id AS commentId', 'comment'],
          on: createWhereQuery<Comment, { company: Company }>(
            (comment, { company }) =>
              company.comments_ids.includes(comment.id) &&
              comment.commentable_type === CommentTypes.COMPANY,
          ),
        },
      ])
      .exec()) as IGetCompaniesData[];

    res.sort((a, b) => {
      return b.createdAt.valueOf() - a.createdAt.valueOf();
    });

    return res;
  })
  .queryName('get-responsibles-for-filters')
  .handler(async (table) => {
    const res: IGetContactForFilter[] = (await table()
      .query('select', ['id', 'contacts'])
      .graph([
        {
          key: 'contacts',
          with: { table: 'user' },
          select: ['id', 'name'],
          on: createWhereQuery<User, { company: Company }>(({ id }, { company }) =>
            company.contacts_ids.includes(id),
          ),
        },
      ])
      .exec()) as IGetContactForFilter[];
    return res.map((item) => item.contacts).flat(1);
  })
  .queryName('get-company-modal-data')
  .handler(async (table, { id }: { id: number }): Promise<IGetCompanyModalData> => {
    const res = table()
      .query('select', [
        'id',
        'name',
        'tax_id AS taxId',
        'profile_image_url AS profileImageUrl',
        'client',
        'users_ids AS contact',
        'comments',
      ])
      .where(createWhereQuery<Company>(({ id: _ }) => _ === id))
      .graph([
        {
          key: 'client',
          with: { table: 'client' },
          single: true,
          select: ['id', 'name'],
          on: createWhereQuery<Client, { company: Company }>(
            ({ id }, { company }) => company.client_id === id,
          ),
        },
        {
          key: 'users_ids',
          with: { table: 'user' },
          select: ['id', 'name'],
          on: createWhereQuery<User, { company: Company }>(({ id }, { company }) =>
            company.contacts_ids.includes(id),
          ),
        },
        {
          key: 'comments',
          with: { table: 'comment' },
          select: ['id AS commentId', 'comment'],
          on: createWhereQuery<Comment, { company: Company }>(
            (comment, { company }) =>
              company.comments_ids.includes(comment.id) &&
              comment.commentable_type === CommentTypes.COMPANY,
          ),
        },
      ])
      .exec();

    return (await res).pop() as IGetCompanyModalData;
  })
  .queryName('get-company-contacts-for-dashboard')
  .handler(async (table, { companyId, clientId }) => {
    const res: IGetContactForDashboard[] = (await table()
      .query('select', ['id', 'contacts', 'client_id'])
      .where([
        ...(companyId ? ['id', '=', companyId] : []),
        ...(companyId && clientId ? ['AND'] : []),
        ...(clientId ? ['client_id', '=', clientId] : []),
      ])
      .graph([
        {
          key: 'contacts',
          with: { table: 'user' },
          select: [
            'id',
            'name',
            'phone',
            'email',
            'position',
            'profile_image_url AS profileImageUrl',
          ],
          on: createWhereQuery<User, { company: Company }>(({ id }, { company }) => {
            return company?.contacts_ids?.includes(id);
          }),
        },
      ])
      .exec()) as IGetContactForDashboard[];
    return res.map((item) => item.contacts).flat(1);
  })
  .queryName('get-add-or-modify-user-modal-data')
  .handler((table): Promise<IGetAddUserModalData[]> => {
    return table()
      .query('select', ['id AS companyId', 'name AS companyName', 'client_id AS clientId'])
      .exec() as Promise<IGetAddUserModalData[]>;
  })

  .make();
