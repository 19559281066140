import { UserPoll } from '@/client_v2/polls/User.poll';
import { CompanyPoll } from '@/client_v2/polls/Company.poll';
import { CommentPoll } from '@/client_v2/polls/Comment.poll';
import { ClientPoll } from '@/client_v2/polls/Client.poll';
import { CompanyRepo } from '@/client_v2/db/repositories/Company.repo';
import { UserRepo } from '@/client_v2/db/repositories/User.repo';
import { createDataHook } from '@/client_v2/hooks/utils/createDataHook';

export const useCompanyData = createDataHook({
  polls: [UserPoll, CommentPoll, CompanyPoll, ClientPoll],
  dataFactory: async () => {
    return {
      companies: await CompanyRepo.query('get-companies-data'),
      users: await UserRepo.query('get-users-list'),
    };
  },
});

export const useCompaniesListForSelectorData = createDataHook({
  polls: [UserPoll, CommentPoll, CompanyPoll, ClientPoll],
  dataFactory: async () => await CompanyRepo.query('get-companies-list-for-selector-data'),
});
