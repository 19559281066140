import { AxiosError } from 'axios';
import { IClientUploadResponse } from '@/redux/clients/clients.interfaces';

export const UPLOAD_NEW_CLIENT_REQUEST = 'UPLOAD_NEW_CLIENT_REQUEST';
export const UPLOAD_NEW_CLIENT_SUCCESS = 'UPLOAD_NEW_CLIENT_SUCCESS';
export const UPLOAD_NEW_CLIENT_FAILURE = 'UPLOAD_NEW_CLIENT_FAILURE';

export const SET_CLIENT_SCROLLBAR_POSITION = 'SET_CLIENT_SCROLLBAR_POSITION';

export const SET_COLLAPSE_CLIENT_ID = 'SET_COLLAPSE_CLIENT_ID';

export interface ISetCollapseClientId {
  type: typeof SET_COLLAPSE_CLIENT_ID;
  payload: number;
}

export interface IUploadClientRequestAction {
  type: typeof UPLOAD_NEW_CLIENT_REQUEST;
}

export interface IUploadClientSuccessAction {
  type: typeof UPLOAD_NEW_CLIENT_SUCCESS;
  payload: IClientUploadResponse;
}

export interface IUploadClientFailureAction {
  type: typeof UPLOAD_NEW_CLIENT_FAILURE;
  error: AxiosError;
}

export interface ISetClientScrollbarPositon {
  type: typeof SET_CLIENT_SCROLLBAR_POSITION;
  payload: number;
}

export type ClientActionTypes =
  | ISetCollapseClientId
  | IUploadClientRequestAction
  | IUploadClientSuccessAction
  | IUploadClientFailureAction
  | ISetClientScrollbarPositon;
