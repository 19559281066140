import { UserPoll } from '@/client_v2/polls/User.poll';
import { CommentPoll } from '@/client_v2/polls/Comment.poll';
import { ClientPoll } from '@/client_v2/polls/Client.poll';
import { ClientRepo } from '@/client_v2/db/repositories/Client.repo';
import { BusinessAreaPoll } from '@/client_v2/polls/BusinessArea.poll';
import { createDataHook } from '@/client_v2/hooks/utils/createDataHook';
import { UserRepo } from '@/client_v2/db/repositories/User.repo';

export const useClientsData = createDataHook({
  polls: [UserPoll, CommentPoll, ClientPoll, BusinessAreaPoll],
  dataFactory: async () => {
    return {
      clients: await ClientRepo.query('get-client-data'),
      users: await UserRepo.query('get-users-list'),
    };
  },
});

export const useClientListForSelectorData = createDataHook({
  polls: [UserPoll, CommentPoll, ClientPoll],
  dataFactory: async () => await ClientRepo.query('get-add-or-modify-user-modal-data'),
});
