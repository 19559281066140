import { AxiosRequestConfig, AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import i18n from 'i18next';
import axiosInstance, { axiosFormDataInstance } from '@/client_v2/rest/axios/axiosInstance';
import { store } from '@/redux/store';
import { refreshToken, logout } from '@/redux/auth/auth.actions';
import { BASE_URL } from '@/client/urls';

interface ExtendedRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

const exludedRoutes = ['auth/login', 'auth/refresh-token'];
let tokenRefreshing = false;
let subscribers: ((accessToken: string) => void)[] = [];

const onAccessTokenFetched = (): void => {
  tokenRefreshing = false;
  const { token } = store.getState().auth;
  subscribers.map((callback) => callback(token!));
  subscribers = [];
};

/**
 *
 * @param callback function that will subscribe to token refresh success
 */
const addSubscriber = (callback: (token: string) => void): void => {
  subscribers.push(callback);
};

/**
 *
 * @param error error of the failed api request
 * @param axios instance of axios
 *
 * resets token and reruns all requests that happened during the refresh
 */
const resetTokenAndReattemptRequest = async (
  error: AxiosError,
  axios: AxiosInstance,
): Promise<AxiosResponse | void | unknown> => {
  const originalRequest: ExtendedRequestConfig = error.config;
  const { response } = error;
  if (
    response &&
    response.status &&
    (response.status === 401 || response.status === 403) &&
    !originalRequest._retry
  ) {
    const { refreshToken: refreshTokenValue } = store.getState().auth;
    if (
      !refreshTokenValue ||
      originalRequest.url === `${BASE_URL}/auth/refresh-token` ||
      originalRequest._retry
    ) {
      store.dispatch(logout(i18n.t('messageResponse.autoLogout')));
      return Promise.reject(error);
    }

    originalRequest._retry = true;
    if (!tokenRefreshing) {
      tokenRefreshing = true;
      store.dispatch(refreshToken(refreshTokenValue, onAccessTokenFetched));
    }

    const retryOrigReq = new Promise((resolve) => {
      addSubscriber((accessToken) => {
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return resolve(axios(originalRequest));
      });
    });
    return retryOrigReq;
  }
  return Promise.reject(error);
};

const setAxiosInterceptors = (): void => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { response } = error;
      if (
        response &&
        (response.status === 401 || response.status === 403) &&
        !exludedRoutes.includes(response.config.url)
      ) {
        return resetTokenAndReattemptRequest(error, axiosInstance);
      } else if (response) {
        return Promise.reject(error);
      }
    },
  );
  axiosFormDataInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { response } = error;
      if (response && (response.status === 401 || response.status === 403)) {
        return resetTokenAndReattemptRequest(error, axiosFormDataInstance);
      } else if (response) {
        return Promise.reject(error);
      }
    },
  );
};

export default setAxiosInterceptors;
