import '@/layouts/PorcessAddCommentPopupLayout/style.scss';
import { FC, ReactNode } from 'react';

interface Props {
  badgeContainer?: ReactNode;
  commentInput?: ReactNode;
  addCommentButton?: ReactNode;
  saveCommentButton?: ReactNode;
  errorField?: ReactNode;
}

export const PorcessAddCommentPopupLayout: FC<Props> = ({
  badgeContainer,
  commentInput,
  addCommentButton,
  saveCommentButton,
  errorField,
}) => (
  <div className="container-fluid text-nowrap">
    <div className="row ">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12 d-flex flex-wrap">{badgeContainer}</div>
        </div>
        <div className="row  align-items-center">
          <div className="col-md-8">{commentInput}</div>
          <div className="col-md-4 d-flex justify-content-center mt-3">{addCommentButton}</div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-lg-start justify-content-center">
            {errorField}
          </div>
        </div>
        <div className="row mt-2 mb-3">
          <div className="col-md-12 d-flex justify-content-lg-start justify-content-center">
            {saveCommentButton}
          </div>
        </div>
      </div>
    </div>
  </div>
);
