import '@/styles/index.scss';
import '@/components/MilestoneBar/style.scss';
import { FC } from 'react';
import { cn, CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('components-milestone-bar');

export interface MilestoneBarProps {
  nearbyPercent: number;
  expiredPercent: number;
  donePercent: number;
}

export const MilestoneBar: FC<MilestoneBarProps> = ({
  nearbyPercent,
  expiredPercent,
  donePercent,
}) => {
  return (
    <div className={scope}>
      <div className="progress">
        <div
          className={cn('progress-bar', 'done')}
          role="progressbar"
          style={{ width: `${Math.round(donePercent)}.%` }}
        />
        <div
          className={cn('progress-bar', 'nearby')}
          role="progressbar"
          style={{ width: `${Math.round(nearbyPercent)}.%` }}
        />
        <div
          className={cn('progress-bar', 'expired')}
          role="progressbar"
          style={{ width: `${Math.round(expiredPercent)}.%` }}
        />
      </div>
    </div>
  );
};
