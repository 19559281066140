import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { TagRes } from '@/client_v2/rest/types/TagsRes';
import { API_TAGS_$ID, $ID_PARAM } from '@/client_v2/rest/Tags/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';

export const getTagById = (id: number) =>
  axiosInstance.get<TagRes>(
    createUrl(API_TAGS_$ID, {
      params: {
        [$ID_PARAM]: id.toString(),
      },
    }),
  );
