/* eslint-dsiable @typescript-eslint/no-explicit-any */
import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { PatchDocument } from '@/client_v2/rest/types/PatchDocument';
import { postComments } from '@/client_v2/rest/Comments/postComments';
import { API_DOCUMENTS_$ID, $ID_PARAM } from '@/client_v2/rest/Documents/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';
import { DocumentPoll } from '@/client_v2/polls/Document.poll';
import { postTags } from '@/client_v2/rest/Tags/postTags';
import { deleteComment } from '@/client_v2/rest/Comments/deleteComment';
import { deleteTag } from '@/client_v2/rest/Tags/deleteTag';

export const patchDocument = async (
  data: PatchDocument,
  newComments?: { comments: string[]; userId: number },
  newTags?: string[],
  deletedComments?: { commentId: number; comment: string }[],
  deletedTags?: { tagId: number; name: string }[],
) => {
  const res = await axiosInstance.patch<{ data: { id: number } }>(
    createUrl(API_DOCUMENTS_$ID, {
      params: {
        [$ID_PARAM]: data.id.toString(),
      },
    }),
    data,
  );

  if (newComments && newComments.comments && newComments.comments.length && newComments.userId) {
    await postComments({
      entityId: data.id,
      entityType: 'documents',
      comments: newComments,
    });
  }
  if (newTags && newTags.length) {
    await postTags({
      documentId: data.id,
      tags: newTags,
      clientId: data.sourceClientId,
    });
  }
  if (deletedComments && deletedComments.length) {
    await Promise.all(
      deletedComments.map((comment) =>
        deleteComment({
          commentId: comment.commentId,
          entityType: 'documents',
          entityId: data.id,
        }),
      ),
    );
  }
  if (deletedTags && deletedTags.length) {
    await Promise.all(deletedTags.map((comment) => deleteTag(data.id, comment.tagId)));
  }
  await DocumentPoll.trigger();
  return res;
};
