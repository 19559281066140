import { UserPoll } from '@/client_v2/polls/User.poll';
import { BusinessAreaPoll } from '@/client_v2/polls/BusinessArea.poll';
import { CommentPoll } from '@/client_v2/polls/Comment.poll';
import { ClientPoll } from '@/client_v2/polls/Client.poll';
import { ClientRepo } from '@/client_v2/db/repositories/Client.repo';
import { createDataHook } from '@/client_v2/hooks/utils/createDataHook';
import { UserRepo } from '@/client_v2/db/repositories/User.repo';

export const useCustomerModalData = createDataHook({
  polls: [BusinessAreaPoll, UserPoll, CommentPoll, ClientPoll],
  dataFactory: async (id?: number) => {
    if (typeof id !== 'undefined')
      return {
        customer: await ClientRepo.query('get-customer-modal-data', { id }),
        contacts: await UserRepo.query('get-filtered-contact-data'),
      };

    return {
      contacts: await UserRepo.query('get-filtered-contact-data'),
    };
  },
});
