import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { WorkflowsRes } from '@/client_v2/rest/types/WorkflowsRes';
import { API_WORKFLOWS_SEARCH } from '@/client_v2/rest/Workflow/URLS';

export const getWorkflowsByIds = (ids: number[]) =>
  axiosInstance.post<WorkflowsRes>(API_WORKFLOWS_SEARCH, {
    scopes: [
      {
        name: 'ids',
        parameters: [ids.toString()],
      },
    ],
  });
