import {
  DELETE_DOCUMENT_SEARCH_WORDS,
  DocumentsActionTypes,
  SET_DOCUMENT_SEARCH_WORDS,
} from './documents.types';
import { IDocumentsSearchState } from './documents.interfaces';

const defaultDocumentsSearchState: IDocumentsSearchState = {
  searchWords: null,
};

export default (
  state = defaultDocumentsSearchState,
  action: DocumentsActionTypes,
): IDocumentsSearchState => {
  switch (action.type) {
    case SET_DOCUMENT_SEARCH_WORDS:
      return {
        ...state,
        searchWords: action.payload,
      };
    case DELETE_DOCUMENT_SEARCH_WORDS:
      return {
        ...state,
        searchWords: null,
      };
    default:
      return state;
  }
};
