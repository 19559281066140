import { createDataHook } from '@/client_v2/hooks/utils/createDataHook';
import { WorkflowStepPoll } from '@/client_v2/polls/WorkflowStep.poll';
import { WorkflowStepRepo } from '@/client_v2/db/repositories/WorkflowStep.repo';
import { WorkflowPoll } from '@/client_v2/polls/Workflow.poll';
import { WorkflowMilePoll } from '@/client_v2/polls/WorkflowMile.poll';

export const useWorkflowStepData = createDataHook({
  polls: [WorkflowStepPoll, WorkflowPoll, WorkflowMilePoll],
  dataFactory: async () => await WorkflowStepRepo.query('get-statuses-for-filter'),
});
