import { AxiosError } from 'axios';
import { IFetchUserResponseData } from '@/client/userManagementInterfaces';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const BLOCK_USER_REQUEST = 'BLOCK_USER_REQUEST';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';
export const BLOCK_USER_FAILURE = 'BLOCK_USER_FAILURE';

export const UNBLOCK_USER_REQUEST = 'UNBLOCK_USER_REQUEST';
export const UNBLOCK_USER_SUCCESS = 'UNBLOCK_USER_SUCCESS';
export const UNBLOCK_USER_FAILURE = 'UNBLOCK_USER_FAILURE';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const SET_SELECTED_COMPANY_ID = 'SET_SELECTED_COMPANY_ID';
export const SET_SELECTED_CLIENT_ID = 'SET_SELECTED_CLIENT_ID';

export interface ISetSelectedCompanyAction {
  type: typeof SET_SELECTED_COMPANY_ID;
  payload: number;
}
export interface ISetSelectedClientAction {
  type: typeof SET_SELECTED_CLIENT_ID;
  payload: number;
}

export interface IeditUserRequestAction {
  type: typeof EDIT_USER_REQUEST;
}
export interface IeditUserSuccessAction {
  type: typeof EDIT_USER_SUCCESS;
}
export interface IeditUserFailureAction {
  type: typeof EDIT_USER_FAILURE;
  error: AxiosError;
}
export interface IunblockUserRequestAction {
  type: typeof UNBLOCK_USER_REQUEST;
}
export interface IunblockUserSuccessAction {
  type: typeof UNBLOCK_USER_SUCCESS;
}
export interface IunblockUserFailureAction {
  type: typeof UNBLOCK_USER_FAILURE;
  error: AxiosError;
}
export interface IblockUserRequestAction {
  type: typeof BLOCK_USER_REQUEST;
}
export interface IblockUserSuccessAction {
  type: typeof BLOCK_USER_SUCCESS;
}
export interface IblockUserFailureAction {
  type: typeof BLOCK_USER_FAILURE;
  error: AxiosError;
}
export interface IdeleteUserRequestAction {
  type: typeof DELETE_USER_REQUEST;
}
export interface IdeleteUserSuccessAction {
  type: typeof DELETE_USER_SUCCESS;
}
export interface IdeleteUserFailureAction {
  type: typeof DELETE_USER_FAILURE;
  error: AxiosError;
}
export interface IgetUserRequestAction {
  type: typeof GET_USER_REQUEST;
}

export interface IgetUserSuccessAction {
  type: typeof GET_USER_SUCCESS;
  payload: IFetchUserResponseData;
}

export interface IgetUserFailureAction {
  type: typeof GET_USER_FAILURE;
  error: AxiosError;
}

export interface IcreateUserRequestAction {
  type: typeof CREATE_USER_REQUEST;
}

export interface IcreateUserSuccessAction {
  type: typeof CREATE_USER_SUCCESS;
  payload: IFetchUserResponseData;
}

export interface IcreateUserFailureAction {
  type: typeof CREATE_USER_FAILURE;
  error: AxiosError;
}

export type UserActionTypes =
  | ISetSelectedClientAction
  | IeditUserRequestAction
  | ISetSelectedClientAction
  | ISetSelectedCompanyAction
  | IeditUserSuccessAction
  | IeditUserFailureAction
  | IunblockUserRequestAction
  | IunblockUserSuccessAction
  | IunblockUserFailureAction
  | IblockUserRequestAction
  | IblockUserSuccessAction
  | IblockUserFailureAction
  | IdeleteUserRequestAction
  | IdeleteUserSuccessAction
  | IdeleteUserFailureAction
  | IgetUserRequestAction
  | IgetUserSuccessAction
  | IgetUserFailureAction
  | IcreateUserRequestAction
  | IcreateUserSuccessAction
  | IcreateUserFailureAction;
