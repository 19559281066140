import '@/layouts/MilestoneStepLayout/style.scss';
import { FC, ReactNode } from 'react';
import { CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('layouts-milestone-step-layout');
interface Props {
  stepNameInput?: ReactNode;
  responsibleDropdown?: ReactNode;
  datePicker?: ReactNode;
  datePickerPlaceholder?: ReactNode;
  icon?: ReactNode;
}

export const MilestoneStepLayout: FC<Props> = ({
  stepNameInput,
  responsibleDropdown,
  datePicker,
  datePickerPlaceholder,
  icon,
}) => (
  <div className={scope}>
    <div className="row pt-2 text-nowrap">
      <div className="col-12">
        <div className="row align-items-center">
          <div className="col-3">{stepNameInput}</div>
          <div className="col-3">{responsibleDropdown}</div>
          <div className="col-3">
            {datePicker}
            <div className="row">
              <div>{datePickerPlaceholder}</div>
            </div>
          </div>
          <div className="col-1">{icon}</div>
        </div>
      </div>
    </div>
  </div>
);
