import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import URLS from '@/resources/constants/URLS';
import MainScreen from '@/screens/MainScreen';
import LoginScreen from '@/screens/LoginScreen';
import MessageToast from '@/components/MessageToast';
import setAxiosInterceptors from '@/client_v2/rest/axios/interceptors';
import ForgottenPasswordScreen from '@/screens/ForgottenPasswordScreen';
import ResetPasswordScreen from '@/screens/ResetPasswordScreen';
import ApproveDocumentScreen from '@/screens/ApproveDocumentScreen';
import { AppState } from '@/redux/store';
import PrivateRoute from './PrivateRoute';

const RootRouter = () => {
  useEffect(() => {
    setAxiosInterceptors();
  }, []);
  const { isAuthenticated } = useSelector((state: AppState) => state.auth);

  return (
    <Router>
      <MessageToast />
      <Routes>
        <Route
          path={URLS.root.login.toString()}
          element={
            isAuthenticated ? <Navigate to={URLS.root.dashboard.toString()} /> : <LoginScreen />
          }
        />
        <Route
          path={URLS.root.forgottenPassword.toString()}
          element={<ForgottenPasswordScreen />}
        />
        <Route path={URLS.root.resetPassword.toString()} element={<ResetPasswordScreen />} />
        <Route
          path={URLS.root.approveDocument.toString()}
          element={<PrivateRoute component={<ApproveDocumentScreen />} />}
        />
        <Route path={URLS.root.toString()} element={<PrivateRoute component={<MainScreen />} />} />
      </Routes>
    </Router>
  );
};

export default RootRouter;
