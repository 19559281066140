import { IProcessesSearchState } from './processes.interfaces';
import {
  DELETE_PROCESS_SEARCH_WORDS,
  ProcessesActionTypes,
  SET_PROCESS_SEARCH_WORDS,
} from './processes.types';

const defaultProcessesSearchState: IProcessesSearchState = {
  searchWords: null,
};

export default (
  state = defaultProcessesSearchState,
  action: ProcessesActionTypes,
): IProcessesSearchState => {
  switch (action.type) {
    case SET_PROCESS_SEARCH_WORDS:
      return {
        ...state,
        searchWords: action.payload,
      };
    case DELETE_PROCESS_SEARCH_WORDS:
      return {
        ...state,
        searchWords: null,
      };
    default:
      return state;
  }
};
