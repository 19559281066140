import '@/layouts/DocumentTableContentLayout/style.scss';
import { FC, ReactNode, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cn, CreateScopeCSS } from '@/components/utils';
import { AppState } from '@/redux/store';
import { setDocumentScrollbarPosition } from '@/redux/documents/documents.actions';
import Card from '@/components/Card';

const scope = CreateScopeCSS('layouts-document-table-content');
const tableStyle = scope.and('table');

interface Props {
  table?: ReactNode;
  noSearchResult?: ReactNode;
  isLoading?: boolean;
}

export const DocumentTableContentLayout: FC<Props> = ({ table, noSearchResult, isLoading }) => {
  const dispatch = useDispatch();

  const scrollDivRef = useRef<HTMLDivElement>(null);
  const scrollbarPosition = useSelector((state: AppState) => state.documents.scrollbarPosition);

  useEffect(() => {
    if (!scrollbarPosition) {
      dispatch(setDocumentScrollbarPosition(scrollDivRef.current?.scrollTop || 0));
    }
  }, [dispatch, scrollbarPosition, table, isLoading]);

  useEffect(() => {
    if (scrollbarPosition) {
      scrollDivRef.current?.scrollTo(0, scrollbarPosition || 0);
    }
  }, [dispatch, scrollbarPosition]);

  return (
    <div className={cn(scope, 'container-fluid')} ref={scrollDivRef}>
      <div className="row text-nowrap">
        <Card bgColorStyle="white" className="col-lg-12">
          <div className={cn(tableStyle, 'scroll-doc-tb')}>{table}</div>
          <div className="row">
            <div className="col-lg-12">{noSearchResult}</div>
          </div>
        </Card>
      </div>
    </div>
  );
};
