import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import * as Endpoints from '@/client/endpoints';
import * as Interfaces from '@/client/companyManagementInterfaces';

//COMPANY-MANAGEMENT CALLS
export class CompanyManagement {
  fetchCompanySearch = async (
    fetchbody: Interfaces.IFetchCompanySearchRequest,
  ): Promise<Interfaces.IFetchCompanySearchResponse> => {
    const response = await axiosInstance.post(Endpoints.FETCH_COMPANY_SEARCH, fetchbody);
    return response.data;
  };

  fetchCompanies = async (): Promise<Interfaces.IFetchCompaniesResponse> => {
    const response = await axiosInstance.get(Endpoints.FETCH_COMPANIES);
    return response.data;
  };

  createCompany = async (
    company: Interfaces.ICreateCompanyRequest,
  ): Promise<Interfaces.ICreateCompanyResponse> => {
    const response = await axiosInstance.post(Endpoints.CREATE_COMPANY, company);
    return response.data;
  };

  putCompany = async (
    companyId: number,
    company: Interfaces.IUpdateCompanyRequest,
  ): Promise<Interfaces.IUpdateCompanyResponse> => {
    const response = await axiosInstance.put(
      Endpoints.UPDATE_COMPANY.replace('{company}', companyId.toString()),
      company,
    );
    return response.data;
  };

  patchCompany = async (
    companyId: number,
    company: Interfaces.IUpdateCompanyRequest,
  ): Promise<Interfaces.IUpdateCompanyResponse> => {
    const response = await axiosInstance.patch(
      Endpoints.UPDATE_COMPANY.replace('{company}', companyId.toString()),
      company,
    );
    return response.data;
  };

  deleteCompany = async (companyId: number): Promise<Interfaces.IDeleteCompanyResponse> => {
    const response = await axiosInstance.delete(
      Endpoints.DELETE_COMPANY.replace('{company}', companyId.toString()),
    );
    return response.data;
  };

  restoreCompany = async (
    companyId: number,
  ): Promise<Interfaces.IRestoreDeletedCompanyResponse> => {
    const response = await axiosInstance.post(
      Endpoints.RESTORE_DELETED_COMPANY.replace('{company}', companyId.toString()),
    );
    return response.data;
  };

  fetchCompanyComments = async (): Promise<Interfaces.IFetchCompanyCommentsSearchResponse> => {
    const response = await axiosInstance.post(Endpoints.FETCH_COMPANY_COMMENTS_SEARCH);
    return response.data;
  };

  createCompanyComment = async (
    companyId: number,
    companyComment: Interfaces.ICreateCompanyCommentRequest,
  ): Promise<Interfaces.ICreateCompanyCommentResponse> => {
    const response = await axiosInstance.post(
      Endpoints.CREATE_COMPANY_COMMENT.replace('{company}', companyId.toString()),
      companyComment,
    );
    return response.data;
  };

  putCompanyComment = async (
    companyId: number,
    commentId: number,
    companyComment: Interfaces.IUpdateCompanyCommentRequest,
  ): Promise<Interfaces.IUpdateCompanyResponse> => {
    const response = await axiosInstance.put(
      Endpoints.UPDATE_COMPANY_COMMENT.replace('{company}', companyId.toString()).replace(
        '{comment}',
        commentId.toString(),
      ),
      companyComment,
    );
    return response.data;
  };

  patchCompanyComment = async (
    companyId: number,
    commentId: number,
    companyComment: Interfaces.IUpdateCompanyCommentRequest,
  ): Promise<Interfaces.IUpdateCompanyResponse> => {
    const response = await axiosInstance.patch(
      Endpoints.UPDATE_COMPANY_COMMENT.replace('{company}', companyId.toString()).replace(
        '{comment}',
        commentId.toString(),
      ),
      companyComment,
    );
    return response.data;
  };

  deleteCompanyComment = async (
    companyId: number,
    commentId: number,
  ): Promise<Interfaces.IDeleteCompanyCommentResponse> => {
    const response = await axiosInstance.delete(
      Endpoints.DELETE_COMPANY_COMMENT.replace('{company}', companyId.toString()).replace(
        '{comment}',
        commentId.toString(),
      ),
    );
    return response.data;
  };
}
