import '@/components/LanguageSelector/style.scss';

import { FC } from 'react';
import { CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('language-selector');

interface Flag {
  key: string;
  imgSrc: string;
}

interface Props {
  flags: Flag[];
  isDropdownOpen: boolean;
  toggleDropdown: () => void;
  selectedFlag: Flag;
  onFlagClick: (e: string) => void;
}

interface IButtonWithFlag {
  onClick: () => void;
  imgSrc: string;
  label: string;
}

const ButtonWithFlag: FC<IButtonWithFlag> = ({ onClick, imgSrc, label }) => (
  <button className="btn btn-default col d-flex align-items-center" type="button" onClick={onClick}>
    {label}
    <img className="flagImage" src={imgSrc} />
  </button>
);

export const LanguageSelector: FC<Props> = (props) => (
  <div className={scope}>
    <div className="dropdown">
      <ButtonWithFlag
        imgSrc={props.selectedFlag?.imgSrc ? props.selectedFlag.imgSrc : ''}
        onClick={props.toggleDropdown}
        label={props.selectedFlag?.key ? props.selectedFlag.key : ''}
      />
      <ul className={`dropdown-menu min-w-0 ${props.isDropdownOpen ? 'd-block' : 'd-none'}`}>
        {props.flags.map(({ key, imgSrc }) => (
          <li key={key.toString()}>
            <ButtonWithFlag imgSrc={imgSrc} label={key} onClick={() => props.onFlagClick(key)} />
          </li>
        ))}
      </ul>
    </div>
  </div>
);
