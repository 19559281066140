import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SidebarFilter } from '@/screens/common/SidebarFilter';
import Card from '@/components/Card';

const useDocumentScreenSidebar = () => {
  const { t } = useTranslation();

  return {
    title: t('sidebarFilter.businessAreas'),
  };
};

export const DocumentScreenSidebar: FC = () => {
  const { title } = useDocumentScreenSidebar();
  return (
    <Card bgColorStyle="light" rounded paddingV="small" paddingH="small">
      <SidebarFilter title={title} />
    </Card>
  );
};
