import '@/layouts/DocumentFilterModalLayout/style.scss';
import { FC, ReactNode } from 'react';
import { CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('layouts-document-filter-modal-layout');

interface Props {
  directionFilterOption?: ReactNode;
  statusFilterOption?: ReactNode;
  clientFilterOption?: ReactNode;
  companyFilterOption?: ReactNode;
  approverFilterOption?: ReactNode;
  filterButton?: ReactNode;
}

export const DocumentFilterModalLayout: FC<Props> = ({
  directionFilterOption,
  statusFilterOption,
  clientFilterOption,
  companyFilterOption,
  approverFilterOption,
  filterButton,
}) => (
  <div className={scope}>
    <div className="row">
      <div className="col-lg-12 text-nowrap">
        <div className="row">
          <div className="col-lg-6 col-12">{directionFilterOption}</div>
          <div className="col-lg-6 col-12 mt-lg-0 mt-4">{statusFilterOption}</div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-12 mt-4">{clientFilterOption}</div>
          <div className="col-lg-4 col-12 mt-4">{companyFilterOption}</div>
          <div className="col-lg-4 col-12 mt-4">{approverFilterOption}</div>
        </div>
        <div className="row d-flex justify-content-lg-start justify-content-center">
          <div className="col-lg-12 mt-4 mb-5">{filterButton}</div>
        </div>
      </div>
    </div>
  </div>
);
