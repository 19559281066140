import '@/screens/ProcessesScreen/Header/style.scss';
import { FC, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@/components/Button';
import Text from '@/components/Text';
import { ModalBox } from '@/components/ModalBox';
import { DropDownBox } from '@/components/DropDownBox/DropDownBox';
import SearchInput from '@/components/SearchInput';
import ProcessesHeaderLayout from '@/layouts/ProcessesHeaderLayout';
import {
  deleteProcessSearchWords,
  setProcessSearchWords,
} from '@/redux/processes/processes.actions';
import ProcessCreateModal from '@/screens/ProcessesScreen/components/ProcessCreateModal';
import FilterModal from '@/screens/ProcessesScreen/components/FilterModal';
import { AppState } from '@/redux/store';
import { cn, CreateScopeCSS } from '@/components/utils';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import { expandDocumentTable } from '@/redux/documents/documents.actions';
import Icon from '@/components/Icon';
import { setModalOpen } from '@/redux/core/core.actions';
import { setProcessSortAction } from '@/redux/sort/sort.actions';

interface ISortType {
  name: string;
  field: string;
  direction: 'asc' | 'desc';
  default?: boolean;
}

const useProcessHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const scope = CreateScopeCSS('screens-processes-header-layout');
  const buttonTextStyles = CreateScopeCSS(scope.and('componentsText'));
  const [showCreateProcessModal, setShowCreateProcessModal] = useState(false);
  const headerText = t('processesScreen.modal.headerText');
  const uploadFilesButtonText = t('upload.uploadText');
  const newProcessText = t('processesScreen.newProcess');
  const screenHeaderText = t('processesScreen.headerText');
  const filterText = t('processesScreen.filter');
  const processSortText = t('processesScreen.sort');

  const title = t('docs.title');
  const [showPopup, setShowPopup] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [searchWords, setSearchWords] = useState<string[]>([]);
  const [sortText, setSortText] = useState<string>(processSortText);

  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const selectedClientId = useSelector((state: AppState) => state.user.selectedClientId);
  const { processFilters } = useSelector((state: AppState) => state.sortAndFilters);
  const user = useSelector((state: AppState) => state.user.user);
  const canAdd =
    user?.authority == Authorities.Admin ||
    user?.authority == Authorities.SuperAdmin ||
    user?.authority == Authorities.JuniorUser; //TODO: Check specifics

  useEffect(() => {
    dispatch(setModalOpen(showCreateProcessModal));
  }, [dispatch, showCreateProcessModal]);

  useEffect(() => {
    if (!searchWords.length) {
      dispatch(deleteProcessSearchWords());
    }
  }, [searchWords, dispatch]);

  const search = useCallback(async () => {
    setIsSearchLoading(true);
    await dispatch(setProcessSearchWords({ searchWords }));
    setIsSearchLoading(false);
  }, [searchWords, dispatch]);

  const sorts: ISortType[] = [
    { name: 'dueDateAsc', field: 'endDate', direction: 'asc' },
    { name: 'dueDateDesc', field: 'endDate', direction: 'desc' },
    { name: 'nameAsc', field: 'name', direction: 'asc' },
    { name: 'nameDesc', field: 'name', direction: 'desc' },
    { name: 'createDateAsc', field: 'createdAt', direction: 'asc' },
    { name: 'createDateDesc', field: 'createdAt', direction: 'desc' },
  ];

  const expandDocument = useSelector((state: AppState) => state.documents.expand);
  const { processSort } = useSelector((state: AppState) => state.sort);

  useEffect(() => {
    dispatch(expandDocumentTable({ expand: false }));
  }, [dispatch]);

  useEffect(() => {
    setSortText(
      processSort.name ? t('processesScreen.sorts.' + processSort.name) : processSortText,
    );
  }, [processSort.name, processSortText, t]);

  return {
    canAdd,
    t,
    dispatch,
    sorts,
    headerText,
    filterText,
    showPopup,
    screenHeaderText,
    setShowPopup,
    newProcessText,
    showSort,
    setShowSort,
    uploadFilesButtonText,
    showCreateProcessModal,
    setShowCreateProcessModal,
    title,
    searchText: t('processesScreen.search'),
    newSearchText: t('processesScreen.newSearch'),
    searchWords,
    setSearchWords,
    search,
    buttonTextStyles,
    processFilters,
    sortText,
    setSortText,
    isSearchLoading,
    expandDocument,
    selectedClientId,
    processSortText,
    processSort,
  };
};

export const ProcessesHeader: FC = () => {
  const {
    canAdd,
    t,
    dispatch,
    headerText,
    showPopup,
    filterText,
    screenHeaderText,
    newProcessText,
    setShowPopup,
    setShowSort,
    showSort,
    sorts,
    showCreateProcessModal,
    setShowCreateProcessModal,
    newSearchText,
    searchText,
    isSearchLoading,
    setSearchWords,
    search,
    processFilters,
    buttonTextStyles,
    sortText,
    setSortText,
    expandDocument,
    selectedClientId,
    processSortText,
    processSort,
  } = useProcessHeader();

  return (
    <div>
      <ProcessesHeaderLayout
        headerText={<Text type="style13">{screenHeaderText}</Text>}
        newProcessButton={
          canAdd && (
            <Button
              size="large"
              paddingSize="medium"
              rounded
              disabled={!selectedClientId}
              onClick={() => setShowCreateProcessModal(true)}
              id="processModalOpenButton"
            >
              <Text className="py-1 d-flex" type="style24">
                {newProcessText}
              </Text>
            </Button>
          )
        }
        searchField={
          <SearchInput
            placeholder={searchText}
            buttonText={newSearchText}
            onSubmit={(e) => {
              e.preventDefault();
              search();
            }}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              const value = e.currentTarget.value.split(' ');
              setSearchWords(value);
            }}
            loading={isSearchLoading}
          />
        }
        sortButton={
          <>
            <DropDownBox
              data={sorts.map((item) => {
                return {
                  data: item,
                  view: <div key={item.field}>{t('processesScreen.sorts.' + item.name)}</div>,
                };
              })}
              onSelect={(item: ISortType) => {
                setSortText(t('processesScreen.sorts.' + item.name));
                setShowSort(false);
                dispatch(
                  setProcessSortAction({
                    name: item.name,
                    field: item.field,
                    direction: item.direction,
                  }),
                );
              }}
              onClickOutside={() => setShowSort(false)}
              show={showSort}
            />
            <Button
              size="large"
              className="bg-transparent border border-white"
              iconSize="medium"
              icon="slider"
              onClick={() => setShowSort(!showSort)}
            >
              <Text className={cn(buttonTextStyles)} type="style17">
                {processSort.name ? sortText : processSortText}
              </Text>
            </Button>
          </>
        }
        filterButton={
          <>
            <Button
              size="large"
              iconSize="medium"
              className={
                processFilters.hasSelected
                  ? 'border border-white'
                  : 'bg-transparent border border-white'
              }
              icon="filter"
              onClick={() => setShowPopup(true)}
            >
              <Text className={cn(buttonTextStyles)} type="style17">
                {filterText}
              </Text>
            </Button>
            {expandDocument ? (
              <Icon
                type="minimize"
                colorStyle="white"
                onClick={() => dispatch(expandDocumentTable({ expand: !expandDocument }))}
                className="ml-3"
              />
            ) : (
              <Icon
                type="expand"
                colorStyle="white"
                onClick={() => dispatch(expandDocumentTable({ expand: !expandDocument }))}
                className="ml-3"
              />
            )}
          </>
        }
      />
      {showPopup && <FilterModal showPopup={showPopup} setHidePopup={setShowPopup} />}
      {showCreateProcessModal && (
        <ModalBox
          headerText={headerText}
          show={showCreateProcessModal}
          modalWidth="wide"
          onClickClose={() => setShowCreateProcessModal(false)}
        >
          <ProcessCreateModal onClose={() => setShowCreateProcessModal(false)} />
        </ModalBox>
      )}
    </div>
  );
};
