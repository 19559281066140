import { ICoreState } from '@/redux/core/core.interfaces';
import { CoreActionTypes, MODAL_OPEN } from '@/redux/core/core.types';

const defaultCoreState: ICoreState = {
  isOpenModal: false,
};

export default (state = defaultCoreState, action: CoreActionTypes): ICoreState => {
  switch (action.type) {
    case MODAL_OPEN:
      return {
        ...state,
        isOpenModal: action.payload,
      };
    default:
      return state;
  }
};
