import { IProcessesState } from './processes.interfaces';
import {
  CREATE_PROCESS_REQUEST,
  CREATE_PROCESS_SUCCESS,
  CREATE_PROCESS_FAILURE,
  ProcessesActionTypes,
  SET_COLLAPSE_PROCESS_ID,
  SET_MEMORIZE_MILE_ID,
  SET_PROCESS_SCROLLBAR_POSITION,
  IS_PROCESS_MODIFIED,
} from './processes.types';

const defaultProcessesState: IProcessesState = {
  isLoading: true,
  errorMessage: null,
  collapseProcessId: null,
  memorizedMileId: null,
  scrollbarPosition: undefined,
  isModified: false,
};

export default (state = defaultProcessesState, action: ProcessesActionTypes): IProcessesState => {
  switch (action.type) {
    case CREATE_PROCESS_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case CREATE_PROCESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
      };
    case CREATE_PROCESS_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error.message,
      };
    case SET_COLLAPSE_PROCESS_ID:
      return {
        ...state,
        collapseProcessId: action.payload,
      };
    case SET_PROCESS_SCROLLBAR_POSITION:
      return {
        ...state,
        scrollbarPosition: action.payload,
      };
    case SET_MEMORIZE_MILE_ID:
      return {
        ...state,
        memorizedMileId: action.payload,
      };
    case IS_PROCESS_MODIFIED:
      return {
        ...state,
        isModified: action.payload.isModified,
      };
    default:
      return state;
  }
};
