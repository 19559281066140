import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '@pathofdev/react-tag-input/build/index.css';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@/components/Button';
import Text from '@/components/Text';
import { ModalBox } from '@/components/ModalBox';
import MultiselectDropdown from '@/components/MultiselectDropdown';
import { useFilterModalData } from '@/client_v2/hooks/useFilterModalData';
import { DocumentDirection, DocumentStatuses } from '@/resources/constants/shared';
import { setDocumentFilter } from '@/redux/sortAndFilters/sortAndFilters.actions';
import { AppState } from '@/redux/store';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import { setError } from '@/redux/message/message.action';
import DocumentFilterModalLayout from '@/layouts/DocumentFilterModalLayout';
import { useUserData } from '@/client_v2/hooks/useUserData';
import { IGetCompaniesData } from '@/client_v2/db/repositories/Company.repo';
import { IGetCompanyModalData } from '@/client_v2/db/repositories/Client.repo';

interface filterData {
  id: number;
  name: string;
  clientId?: number;
}

interface Props {
  showPopup: boolean;
  setHidePopup: (value: boolean) => void;
}

const useDocumentFilterModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filterHeaderText = t('filters.documentFilterHeader');
  const filterApproverText = t('filters.approver');
  const filterDirectionText = t('filters.direction');
  const filterClientText = t('filters.client');
  const filterCompanyText = t('filters.company');
  const filterStatusText = t('filters.status');
  const filterButtonText = t('filters.submitDocuments');

  const [isLoading, data] = useFilterModalData({
    triggerOnce: true,
    renderOnUpdate: true,
    onError: (err) => dispatch(setError(err.toString())),
  });

  const [, users] = useUserData({
    triggerOnce: true,
    renderOnUpdate: true,
    onError: (err) => dispatch(setError(err.toString())),
  });

  const { documentFilters } = useSelector((state: AppState) => state.sortAndFilters);
  const { user } = useSelector((state: AppState) => state.user);

  const { selectedClientId } = useSelector((state: AppState) => state.user);
  const { selectedCompanyId } = useSelector((state: AppState) => state.user);
  let approverUsers = null;

  if (selectedCompanyId) {
    approverUsers = users?.filter((user) =>
      user?.companies?.some((company) => company === selectedCompanyId),
    );
  } else if (selectedClientId) {
    approverUsers = users?.filter((user) =>
      user?.clients?.some((client) => client === selectedClientId),
    );
  }

  const [selectedClients, setSelectedClients] = useState<number[]>();
  const [selectedCompanies, setSelectedCompanies] = useState<number[]>();
  const [selectedApprover, setSelectedApprover] = useState<number[]>();
  const [selectedDirection, setSelectedDirection] = useState<string[]>();
  const [selectedStatus, setSelectedStatuses] = useState<string[]>();
  const [filterClientIds, setFilterClientIds] = useState<IGetCompanyModalData[]>();
  const [filterCompanyIds, setFilterCompanyIds] = useState<IGetCompaniesData[]>();

  const documentStatuses = Object.entries(DocumentStatuses).map(([value, key]) => {
    return { name: t('documentStatus.' + key), id: key, key: value };
  });
  const documentDirection = Object.entries(DocumentDirection).map(([value, key]) => {
    return { name: t('documentDirection.' + key), id: key, key: value };
  });

  useEffect(() => {
    documentFilters.statuses && setSelectedStatuses(documentFilters.statuses);
    documentFilters.direction && setSelectedDirection(documentFilters.direction);
    documentFilters.companyIds && setSelectedCompanies(documentFilters.companyIds);
    documentFilters.clientIds && setSelectedClients(documentFilters.clientIds);
    documentFilters.approverId && setSelectedApprover(documentFilters.approverId);
  }, [documentFilters]);

  useEffect(() => {
    if (selectedClients?.length) {
      const newCompanyFilter = data?.companies.filter((item: IGetCompaniesData) => {
        return !!selectedClients.includes(item.clientId);
      });
      setFilterCompanyIds(newCompanyFilter);
    } else {
      setFilterCompanyIds(data?.companies);
    }

    if (selectedCompanies?.length) {
      const newClientFilter = data?.clients.filter((client: IGetCompanyModalData) => {
        return !!selectedCompanies.filter(
          (selectedCompanyId) =>
            data?.companies.find((company) => company.id === selectedCompanyId)?.clientId ==
            client.id,
        ).length;
      });
      setFilterClientIds(newClientFilter);
    } else {
      setFilterClientIds(data?.clients);
    }
  }, [data, selectedClients, selectedCompanies]);

  const getSelectType = (
    text: string,
    data: { name: string; id: number | string; key?: string }[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (list: any) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selected: any,
    fieldName: string,
  ) => {
    return (
      <LoadingPlaceholder showSpinner={true} width="100%" height="100%" isLoading={isLoading}>
        <MultiselectDropdown
          avoidHighlightFirstOption
          showIcon
          maxSelection={5}
          options={data || []}
          title={text}
          label={text}
          selectedOptions={data && data.filter((item) => selected && selected.includes(item.id))}
          onSelectItem={(list) => {
            onChange(list.map((i: filterData) => i.id));
            filterDataChange(list, fieldName);
          }}
          onRemoveItem={(list) => {
            onChange(list.map((i: filterData) => i.id));
            filterDataChange(list, fieldName);
          }}
          closeOnSelect={true}
        />
      </LoadingPlaceholder>
    );
  };

  const filterDataChange = (list: filterData[], fieldName: string) => {
    if (fieldName === 'clients') {
      clientsFilter(list);
    }
    if (fieldName === 'companies') {
      companiesFilter(list);
    }
  };

  const searchData = (list: filterData[], searchData: number, arrayKey: string) => {
    return list.some((c: filterData) => c[arrayKey as keyof filterData] == searchData);
  };

  const clientsFilter = (list: filterData[]) => {
    if (list.length) {
      const newCompanyFilter = data?.companies.filter((item: IGetCompaniesData) => {
        return searchData(list, item.clientId, 'id');
      });
      setFilterCompanyIds(newCompanyFilter);
    } else {
      setFilterCompanyIds(data?.companies);
    }
  };

  const companiesFilter = (list: filterData[]) => {
    if (list.length) {
      const newClientFilter = data?.clients.filter((item: IGetCompanyModalData) => {
        return searchData(list, item.id, 'clientId');
      });
      setFilterClientIds(newClientFilter);
    } else {
      setFilterClientIds(data?.clients);
    }
  };

  return {
    isLoading,
    data,
    users,
    filterHeaderText,
    filterApproverText,
    filterDirectionText,
    filterClientText,
    filterCompanyText,
    filterStatusText,
    documentStatuses,
    getSelectType,
    selectedClients,
    setSelectedClients,
    selectedCompanies,
    setSelectedCompanies,
    selectedApprover,
    setSelectedApprover,
    selectedStatus,
    setSelectedStatuses,
    selectedDirection,
    setSelectedDirection,
    documentFilters,
    dispatch,
    documentDirection,
    filterButtonText,
    filterClientIds,
    filterCompanyIds,
    user,
    approverUsers,
  };
};

const DocumentFilterModal: FC<Props> = ({ showPopup, setHidePopup }) => {
  const {
    isLoading,
    users,
    filterHeaderText,
    filterApproverText,
    filterDirectionText,
    filterClientText,
    filterCompanyText,
    filterStatusText,
    documentStatuses,
    getSelectType,
    selectedClients,
    setSelectedClients,
    selectedCompanies,
    setSelectedCompanies,
    selectedApprover,
    setSelectedApprover,
    selectedStatus,
    setSelectedStatuses,
    selectedDirection,
    setSelectedDirection,
    documentFilters,
    documentDirection,
    dispatch,
    filterButtonText,
    filterClientIds,
    filterCompanyIds,
    approverUsers,
  } = useDocumentFilterModal();
  return (
    <ModalBox
      headerText={filterHeaderText}
      show={showPopup}
      onClickClose={() => setHidePopup(false)}
      modalWidth="wide"
    >
      <LoadingPlaceholder showSpinner={true} isLoading={isLoading}>
        <DocumentFilterModalLayout
          directionFilterOption={getSelectType(
            filterDirectionText,
            documentDirection,
            setSelectedDirection,
            documentFilters.direction,
            'direction',
          )}
          statusFilterOption={getSelectType(
            filterStatusText,
            documentStatuses,
            setSelectedStatuses,
            documentFilters.statuses,
            'statuses',
          )}
          clientFilterOption={getSelectType(
            filterClientText,
            filterClientIds || [],
            setSelectedClients,
            documentFilters.clientIds,
            'clients',
          )}
          approverFilterOption={getSelectType(
            filterApproverText,
            approverUsers || users,
            setSelectedApprover,
            documentFilters.approverId,
            'approver',
          )}
          companyFilterOption={getSelectType(
            filterCompanyText,
            filterCompanyIds || [],
            setSelectedCompanies,
            documentFilters.companyIds,
            'companies',
          )}
          filterButton={
            <Button
              size="large"
              rounded
              onClick={() => {
                setHidePopup(false);
                dispatch(
                  setDocumentFilter({
                    ...(selectedApprover && { approverId: selectedApprover }),
                    ...(selectedDirection && { direction: selectedDirection }),
                    ...(selectedStatus && { statuses: selectedStatus }),
                    ...(selectedClients && { clientIds: selectedClients }),
                    ...(selectedCompanies && { companyIds: selectedCompanies }),
                    ...(selectedClients?.length ||
                    selectedCompanies?.length ||
                    selectedApprover?.length ||
                    selectedDirection?.length ||
                    selectedStatus?.length
                      ? { hasSelected: true }
                      : { hasSelected: false }),
                  }),
                );
              }}
            >
              <Text className="px-2 py-1 d-flex" type="style24">
                {filterButtonText}
              </Text>
            </Button>
          }
        />
      </LoadingPlaceholder>
    </ModalBox>
  );
};

export default DocumentFilterModal;
