/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { createWhereQuery } from '@/client_v2/db/utils/createWhereQuery';
import { creatRepo } from '@/client_v2/db/utils/createRepo';
import { Document } from '@/client_v2/rest/types/DocumentsRes';
import { Client } from '@/client_v2/rest/types/ClientsRes';
import { Company } from '@/client_v2/rest/types/CompaniesRes';
import { User } from '@/client_v2/rest/types/UsersRes';
import { Comment } from '@/client_v2/rest/types/CommentsRes';
import { Tag } from '@/client_v2/rest/types/TagsRes';
import { BusinessArea } from '@/client_v2/rest/types/BusinessAreasRes';
import { WorkflowStep } from '@/client_v2/rest/types/WorkflowStepsRes';
import { IDocumentFilters } from '@/redux/sortAndFilters/sortAndFilters.interfaces';
import { CommentTypes } from '@/resources/constants/shared';
import { DocumentFilterSortOrder } from '@/client_v2/hooks/utils/documentFilterSortOrder';
import { ISort } from '@/redux/sort/sort.interfaces';

export interface IGetDocumentsData {
  id: number;
  name: string;
  extension: string;
  path: string;
  isApproved: boolean;
  isWaitingForApproval: boolean;
  isDeclined: boolean;
  isSent: boolean;
  isArchived: boolean;
  direction: Document['direction'];
  createdAt: Date;
  updatedAt: Date;
  clientName: string;
  clientId: number;
  companyName: string;
  companyId: number;
  approverId: number;
  approverName: string;
  comments: { commentId: number; comment: string }[];
  tags: { tagId: number; name: string }[];
  sourceUserId: number;
  sourceClientId: number;
  workflowStepId: number;
  downloadedBy: number;
  isDownloaded: boolean;
  targetUsers: number[];
}

export interface IGetDocumentModalData extends IGetDocumentsData {
  businessAreaId: number;
  businessAreaName: string;
  workflow_step: { id: number; name: string; workflowMileId: number };
  addressees: { name: string; id: number; clientId: number }[];
  isPublic: boolean;
}

export interface IFilterData {
  search?: string[];
  businessAreaIds: number[];
  filters: IDocumentFilters;
  sort: ISort;
  currentUserId?: number;
  selectedCompanyId: number | null;
  selectedClientId: number | null;
}

export const DocumentRepo = creatRepo<Document>({
  name: 'document',
  model: {
    'id:number': { pk: true },
    'name:string': {},
    'path:string': {},
    'extension:string': {},
    'approver_id:number': { default: -1 },
    'source_client_id:number': { default: -1 },
    'source_user_id:number': { default: -1 },
    'target_client_id:number': { default: -1 },
    'target_company_id:number': { default: -1 },
    'target_users_ids:number[]': { default: [] },
    'workflow_step_id:number': { default: -1 },
    'business_area_id:number': { default: -1 },
    'shared_with_user_id:number': { default: -1 },
    'comments_ids:number[]': { default: [] },
    'tags_ids:number[]': { default: [] },
    'is_approved:boolean': {},
    'is_declined:boolean': {},
    'is_waiting_for_approval:boolean': {},
    'is_sent:boolean': {},
    'is_archived:boolean': {},
    'is_public:boolean': {},
    'created_at:date': {},
    'updated_at:date': {},
    'deleted_at:date': {},
    'direction:string': { default: '' },
    'downloaded_by:number': {},
    'is_downloaded:boolean': {},
  },
})
  .queryName('get-documents-data')
  .handler(async (table, params: IFilterData): Promise<IGetDocumentsData[]> => {
    const res: IGetDocumentsData[] = (await table()
      .query('select', [
        'id', // id: number;
        'name', // name: string;
        'extension', // extension: string;
        'path', // path: string;
        'is_approved AS isApproved', // isApproved: boolean;
        'is_sent AS isSent', // isSent: boolean;
        'is_archived AS isArchived', // isArchived: boolean;
        'is_declined AS isDeclined', // isDeclined: boolean;
        'is_waiting_for_approval AS isWaitingForApproval', // isDeclined: boolean;
        'created_at AS createdAt', // createdAt: Date;
        'updated_at AS updatedAt', // updatedAt: Date;
        'target_client.name AS clientName', // clientName: string;
        'target_client.id AS clientId', // clientId: number;
        'target_company.name AS companyName', // companyName: string;
        'target_company.id AS companyId', // companyId: number;
        'approver.id AS approverId', // approverId: number;
        'approver.name AS approverName', // approverName: string;
        'direction', // direction: string;
        'comments', // comments: {commentId: number, comment: string}[];
        'tags', // tags: {tagId: number, name: string}[];
        'source_client_id AS sourceClientId',
        'source_user_id AS sourceUserId', // sourceUserId number
        'workflow_step_id AS workflowStepId',
        'is_downloaded AS isDownloaded',
        'downloaded_by AS downloadedBy',
        'target_users_ids AS targetUsers',
      ])
      .where([
        ['business_area_id', 'NOT IN', params.businessAreaIds],
        'AND',
        params.filters.fromDate
          ? ['created_at', '>=', params.filters.fromDate]
          : ['created_at', 'NOT IN', [params.filters.fromDate]],
        'AND',
        params.filters.toDate
          ? ['created_at', '<=', params.filters.toDate]
          : ['created_at', 'NOT IN', [params.filters.toDate]],
      ])
      .graph([
        {
          key: 'target_client',
          single: true,
          with: { table: 'client' },
          select: ['id', 'name'],
          on: createWhereQuery<Client, { document: Document }>(
            ({ id }, { document }) => document.target_client_id === id,
          ),
        },
        {
          key: 'target_company',
          single: true,
          with: { table: 'company' },
          select: ['id', 'name'],
          on: createWhereQuery<Company, { document: Document }>(
            ({ id }, { document }) => document.target_company_id === id,
          ),
        },
        {
          key: 'target_users',
          with: { table: 'user' },
          select: ['id', 'name', 'client_id'],
          on: createWhereQuery<User, { document: Document }>(({ id }, { document }) =>
            document.target_users_ids.includes(id),
          ),
        },
        {
          key: 'approver',
          single: true,
          with: { table: 'user' },
          select: ['id', 'name'],
          on: createWhereQuery<User, { document: Document }>(
            ({ id }, { document }) => document.approver_id === id,
          ),
        },
        {
          key: 'comments',
          with: { table: 'comment' },
          select: ['id AS commentId', 'comment'],
          on: createWhereQuery<Comment, { document: Document }>(
            (comment, { document }) =>
              document.comments_ids.includes(comment.id) &&
              comment.commentable_type === CommentTypes.DOCUMENT,
          ),
        },
        {
          key: 'tags',
          with: { table: 'tag' },
          select: ['id AS tagId', 'name'],
          on: createWhereQuery<Tag, { document: Document }>(({ id }, { document }) =>
            document.tags_ids.includes(id),
          ),
        },
      ])
      .exec()) as IGetDocumentsData[];

    return DocumentFilterSortOrder(res, params);
  })
  .queryName('document-direction-sent')
  .handler(async (table, params: IFilterData): Promise<IGetDocumentsData[]> => {
    const res: IGetDocumentsData[] = (await table()
      .query('upsert', [{ direction: 'sent' }])
      .where(['source_user_id', '=', params.currentUserId])
      .exec()) as IGetDocumentsData[];
    return res;
  })
  .queryName('document-direction-received')
  .handler(async (table, params: IFilterData): Promise<IGetDocumentsData[]> => {
    const res: IGetDocumentsData[] = (await table()
      .query('upsert', [{ direction: 'received' }])
      .where(['source_user_id', '!=', params.currentUserId])
      .exec()) as IGetDocumentsData[];
    return res;
  })

  .queryName('get-documents-modal-data')
  .handler(async (table, { id }: { id: number }): Promise<IGetDocumentModalData> => {
    const res = table()
      .query('select', [
        'id', // id: number;
        'name', // name: string;
        'extension', // extension: string;
        'path', // path: string;
        'is_approved AS isApproved', // isApproved: boolean;
        'is_sent AS isSent', // isSent: boolean;
        'is_archived AS isArchived', // isArchived: boolean;
        'direction', // direction: string;
        'created_at AS createdAt', // createdAt: Date;
        'updated_at AS updatedAt', // updatedAt: Date;
        'target_client.name AS clientName', // clientName: string;
        'target_client.id AS clientId', // clientId: number;
        'target_company.name AS companyName', // companyName: string;
        'target_company.id AS companyId', // companyId: number;
        'approver.name AS approverName', // approverName: string;
        'approver.id AS approverId', // approverId: number;
        'comments', // comments: {commentId: number, comment: string}[];
        'tags', // tags: {tagId: number, name: string}[];
        'business_area.id AS businessAreaId',
        'business_area.name AS businessAreaName',
        'workflow_step',
        'target_users AS addressees',
        'is_public as isPublic',
        'is_downloaded as isDownloaded',
        'downloaded_by as downloadedBy',
        'target_users_ids as targetUsers',
      ])
      .where(createWhereQuery<Client>(({ id: _ }) => _ === id))
      .graph([
        {
          key: 'target_client',
          single: true,
          with: { table: 'client' },
          select: ['id', 'name'],
          on: createWhereQuery<Client, { document: Document }>(
            ({ id }, { document }) => document.target_client_id === id,
          ),
        },
        {
          key: 'target_company',
          single: true,
          with: { table: 'company' },
          select: ['id', 'name'],
          on: createWhereQuery<Company, { document: Document }>(
            ({ id }, { document }) => document.target_company_id === id,
          ),
        },
        {
          key: 'approver',
          single: true,
          with: { table: 'user' },
          select: ['id', 'name'],
          on: createWhereQuery<User, { document: Document }>(
            ({ id }, { document }) => document.approver_id === id,
          ),
        },
        {
          key: 'comments',
          with: { table: 'comment' },
          select: ['id AS commentId', 'comment'],
          on: createWhereQuery<Comment, { document: Document }>(
            (comment, { document }) =>
              document.comments_ids.includes(comment.id) &&
              comment.commentable_type === CommentTypes.DOCUMENT,
          ),
        },
        {
          key: 'tags',
          with: { table: 'tag' },
          select: ['id AS tagId', 'name'],
          on: createWhereQuery<Tag, { document: Document }>(({ id }, { document }) =>
            document.tags_ids.includes(id),
          ),
        },
        {
          key: 'business_area',
          single: true,
          with: { table: 'business_area' },
          select: ['id', 'name'],
          on: createWhereQuery<BusinessArea, { document: Document }>(
            ({ id }, { document }) => document.business_area_id === id,
          ),
        },
        {
          key: 'workflow_step',
          single: true,
          with: { table: 'workflow_step' },
          select: ['id', 'name', 'workflow_mile_id AS workflowMileId'],
          on: createWhereQuery<WorkflowStep, { document: Document }>(
            ({ id }, { document }) => document.workflow_step_id === id,
          ),
        },
        {
          key: 'target_users',
          with: { table: 'user' },
          select: ['id', 'name', 'client_id'],
          on: createWhereQuery<User, { document: Document }>(({ id }, { document }) =>
            document.target_users_ids.includes(id),
          ),
        },
      ])
      .exec();

    return (await res).pop() as IGetDocumentModalData;
  })
  .make();
