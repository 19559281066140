import '@/layouts/PageLayout/style.scss';
import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { cn, CreateScopeCSS } from '@/components/utils';
import { AppState } from '@/redux/store';

const scope = CreateScopeCSS('components-page-layout');
const headerClass = scope.and('header');
const pathClass = scope.and('path');
const decorClass = scope.and('decor');
const contentHeaderClass = scope.and('contentHeader');
const sidebarClass = scope.and('sidebar');
const contentClass = scope.and('content');

interface Props {
  header?: ReactNode;
  path?: ReactNode;
  contentHeader?: ReactNode;
  sidebar?: ReactNode;
  content?: ReactNode;
}

export const PageLayout: FC<Props> = ({ header, path, contentHeader, sidebar, content }) => {
  const expandDocument = useSelector((state: AppState) => state.documents.expand);

  return (
    <div className={scope}>
      <div className={cn(headerClass)}>{header}</div>
      <div className={cn(pathClass)}>{path}</div>
      <div className={decorClass} />
      <div className={cn(contentHeaderClass)}>{contentHeader}</div>
      <div className={cn(sidebarClass, expandDocument ? 'd-none' : '')}>{sidebar}</div>
      <div className={contentClass}>{content}</div>
    </div>
  );
};
