import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@/components/Button';
import Text from '@/components/Text';
import CustomersHeaderLayout from '@/layouts/CustomersHeaderLayout';
import { ModalBox } from '@/components/ModalBox';
import AddClientModal from '@/screens/AccountSettings/Contents/AddClientModal';
import { AppState } from '@/redux/store';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import { setModalOpen } from '@/redux/core/core.actions';

const useCustomersHeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { errorMessage, client } = useSelector((state: AppState) => state.clients);
  const user = useSelector((state: AppState) => state.user.user);
  const isAdmin = user?.authority == Authorities.SuperAdmin || Authorities.Admin;

  const title = t('nav.clients');
  const description = t('accountSettings.header.customers.description');
  const newCustomer = t('accountSettings.header.customers.newcustomer');
  const newCustomerModalHeader = t('accountSettings.contents.clients.header');

  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    if (errorMessage || client) {
      setShowModal(false);
    }
  }, [errorMessage, client]);

  useEffect(() => {
    dispatch(setModalOpen(showModal));
  }, [dispatch, showModal]);

  const getShowNewCustomer = () => (
    <ModalBox
      headerText={newCustomerModalHeader}
      show={showModal}
      modalWidth="wide"
      onClickClose={() => setShowModal(false)}
      id="clientModal"
    >
      <AddClientModal close={() => setShowModal(false)} />
    </ModalBox>
  );

  return {
    isAdmin,
    title,
    description,
    newCustomer,
    showModal,
    newCustomerModalHeader,
    setShowModal,
    showErrorModal,
    setShowErrorModal,
    getShowNewCustomer,
    errorText: t('error.generalError'),
  };
};

export const CustomersHeader: FC = () => {
  const { isAdmin, description, title, newCustomer, showModal, setShowModal, getShowNewCustomer } =
    useCustomersHeader();

  return (
    <div>
      <CustomersHeaderLayout
        title={<Text type="style13">{title}</Text>}
        description={<Text type="style25">{description}</Text>}
        btn={
          isAdmin && (
            <Button
              size="medium"
              icon="userPlus"
              iconSize="medium"
              paddingSize="medium"
              roundedLarge
              onClick={() => setShowModal(true)}
              id="addClientModalButton"
            >
              {newCustomer}
            </Button>
          )
        }
      />
      {showModal && getShowNewCustomer()}
    </div>
  );
};
