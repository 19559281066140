import '@/components/Link/style.scss';
import { FC } from 'react';
import { CreateScopeCSS } from '@/components/utils';
import { GetPropsType } from '@/types/GetPropType';
import Text from '@/components/Text';

const scope = CreateScopeCSS('components-link');

interface Props extends GetPropsType<typeof Text> {
  onClick?(): void;
  onMouseOver?(): void;
  onMouseOut?(): void;
  id?: string;
}

export const Link: FC<Props> = ({ children, onClick, onMouseOver, onMouseOut, id, ...props }) => (
  <span
    className={scope}
    onClick={onClick}
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
    id={id ? id : 'nav-link'}
  >
    <Text {...props}>{children}</Text>
  </span>
);
