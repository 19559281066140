function createURLStr<P, T>(path: P, subRoutes?: T): P & T {
  return subRoutes ? Object.assign(path, subRoutes) : (path as P & T);
}

const URLS = {
  root: createURLStr('/*', {
    login: createURLStr('/login'),
    dashboard: createURLStr('/dashboard'),
    processes: createURLStr('/processes'),
    documents: createURLStr('/documents'),
    approveDocument: createURLStr('/approve-document'),
    forgottenPassword: createURLStr('/forgotten-password'),
    resetPassword: createURLStr('/password-reset'),
    accountSettings: createURLStr('/accountSettings/*', {
      users: createURLStr('/accountSettings/users'),
      companies: createURLStr('/accountSettings/companies'),
      clients: createURLStr('/accountSettings/clients'),
      activityFeed: createURLStr('/accountSettings/activityFeed'),
    }),
  }),
};

export default URLS;
