import { ISidebarFilter } from '@/redux/sortAndFilters/sortAndFilters.interfaces';

export const convertFileListToFileArr = (list: FileList) => {
  const fileArr: File[] = [];
  for (let i = 0; i < list.length; i++) {
    const file = list.item(i)!;
    fileArr.push(file);
  }
  return fileArr;
};

export const iconColorSwitch = (outline?: boolean, dropdown?: boolean, white?: boolean) => {
  if (dropdown) {
    return 'lightGray';
  } else if (outline && !white) {
    return 'primary';
  } else return 'white';
};

export const validateEmail = (email: string) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

// Minimum eight characters
export const validatePassword = (password: string) => /^.{8,}$/.test(password);

export const samePassword = (password: string, passwordConfirm: string) =>
  password === passwordConfirm;

export const getFilteredBusinessAreas = (filteredAreas: ISidebarFilter[]) => {
  return filteredAreas
    .filter((item) => item.isActive && !item.isChecked && item.id)
    .map((item) => item.id);
};
