import { ClientPoll } from '@/client_v2/polls/Client.poll';
import { CompanyPoll } from '@/client_v2/polls/Company.poll';
import { UserPoll } from '@/client_v2/polls/User.poll';
import { ClientRepo } from '@/client_v2/db/repositories/Client.repo';
import { CompanyRepo } from '@/client_v2/db/repositories/Company.repo';
import { createDataHook } from '@/client_v2/hooks/utils/createDataHook';
import { uniqueFilterItems } from '@/resources/functions';

export const useFilterModalData = createDataHook({
  polls: [ClientPoll, UserPoll, CompanyPoll],
  dataFactory: async () => ({
    clients: await ClientRepo.query('get-clients-for-filters'),
    companies: await CompanyRepo.query('get-companies-for-filters'),
    responsibleUsers: uniqueFilterItems(
      [
        ...(await ClientRepo.query('get-responsibles-for-filters')),
        ...(await CompanyRepo.query('get-responsibles-for-filters')),
      ],
      'id',
    ),
  }),
});
