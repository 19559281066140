import { FC } from 'react';
import { Portal as View } from '@/components/Portal/Portal.view';
import { useDinCSS } from '@/hooks/useDinCSS';
import { ComponentHook } from '@/types/ComponentHook';

interface Props {
  x?: number | string;
  y?: number | string;
}

export const usePortal: ComponentHook<Props, typeof View> = ({ x, y }) => {
  const [className, renderCSS] = useDinCSS();
  renderCSS({
    left: typeof x === 'number' ? `${x}px` : x,
    top: typeof y === 'number' ? `${y}px` : y,
  });
  return {
    className,
  };
};

export const Portal: FC<Props> = ({ children, ...props }) => {
  const { className } = usePortal(props);
  return <View className={className}>{children}</View>;
};
