import { FC, ReactNode } from 'react';
import { cn, CreateScopeCSS } from '@/components/utils';
import '@/layouts/CompanyContentHeaderLayout/style.scss';

const scope = CreateScopeCSS('layouts-company-table-header-layout');

interface Props {
  headerTitle: ReactNode;
  trashIcon: ReactNode;
  newUserButton: ReactNode;
  modifyButton: ReactNode;
  taxNumber: ReactNode;
  contactText: ReactNode;
  clientName?: ReactNode;
}

export const CompanyContentHeaderLayout: FC<Props> = ({
  headerTitle,
  trashIcon,
  newUserButton,
  modifyButton,
  taxNumber,
  contactText,
  clientName,
}) => (
  <div className={cn(scope)}>
    <div className="row text-nowrap">
      <div className="col-md-12 ">
        <div className="row ml-2 mt-4 mr-md-3 mr-sm-3 align-items-center">
          <div className="col-md-6 col-sm-7">{headerTitle}</div>
          <div className="col-md-6 col-sm-3 d-flex justify-content-md-end justify-content-sm-center">
            <div className="col-md-0">{trashIcon}</div>
            <div className="col-md-0">{newUserButton}</div>
            <div className="col-md-0">{modifyButton}</div>
          </div>
        </div>
        <div className="row mt-3 mb-3">
          <div className="col-12">
            <div className="border-bottom" />
          </div>
        </div>
        <div className="row ml-3 mt-4 mr-md-5 mb-md-3 mb-3 mr-0">
          <div className="col-md-4 d-flex d-inline-block text-truncate">{taxNumber}</div>
          <div className="col-md-4 d-flex d-inline-block text-truncate">{clientName}</div>
          <div className="col-md-4 d-flex d-inline-block text-truncate">{contactText}</div>
        </div>
      </div>
    </div>
  </div>
);
