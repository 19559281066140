import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { UsersRes } from '@/client_v2/rest/types/UsersRes';
import { API_USERS_SEARCH } from '@/client_v2/rest/Users/URLS';

export const getUsersByIds = (ids: number[]) =>
  axiosInstance.post<UsersRes>(API_USERS_SEARCH, {
    scopes: [
      {
        name: 'ids',
        parameters: [ids.toString()],
      },
    ],
  });
