import '@/components/PageNavigator/style.scss';
import { FC, Fragment, ReactNode } from 'react';
import { CreateScopeCSS } from '@/components/utils';
import Icon from '@/components/Icon';

const scope = CreateScopeCSS('components-page-navigator');
const navClass = scope.and('nav');
const languageSelectorClass = scope.and('languageSelector');
const clientSelectorClass = scope.and('clientSelector');
const companySelectorClass = scope.and('companySelector');
const syncButtonClass = scope.and('syncButton');
const iconSize = scope.and('iconSize');

interface Props {
  children: ReactNode;
  languageSelector?: ReactNode;
  clientSelector?: ReactNode;
  companySelector?: ReactNode;
  syncButton?: ReactNode;
}

export const PageNavigator: FC<Props> = ({
  languageSelector,
  clientSelector,
  companySelector,
  syncButton,
  children,
}) => (
  <div className={scope}>
    <div className={navClass}>
      {(children as ReactNode[]).map((child, idx) => (
        <Fragment key={idx}>
          {child}
          {idx !== (children as ReactNode[]).length - 1 && (
            <Icon type="chevronRight" colorStyle="lightGray" className={iconSize} />
          )}
        </Fragment>
      ))}
    </div>
    <div className={clientSelectorClass}>{clientSelector}</div>
    <div className={companySelectorClass}>{companySelector}</div>
    <div className={languageSelectorClass}>{languageSelector}</div>
    <div className={syncButtonClass}>{syncButton}</div>
  </div>
);
