import React, { useState, useEffect, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import '@/screens/ProcessesScreen/components/ProcessCreateModal/style.scss';
import dayjs from 'dayjs';
import { CalendarContainer } from 'react-datepicker';
import DatePicker from '@/components/DatePicker';
import Icon from '@/components/Icon';
import UnderlineInput from '@/components/Input/UnderlineInput';
import MultiselectDropdown from '@/components/MultiselectDropdown';
import { IStepData } from '@/screens/ProcessesScreen/components/ProcessCreateModal/MileStones';
import Button from '@/components/Button';
import { IGetWorkflowResponsibleUser } from '@/client_v2/db/repositories/Workflow.repo';
import MilestoneStepLayout from '@/layouts/MilestoneStepLayout';
import { cn } from '@/components/utils';
interface iProps {
  data: IStepData;
  responsibleUsers: IGetWorkflowResponsibleUser[] | [];
  removeItem: (key: number) => void;
  updateItem: (item: IStepData) => void;
  cloneItem: (item: IStepData) => void;
  minDate: Date | undefined;
  removable?: boolean;
  className?: string;
  stepsNotChecked?: boolean;
}

export const MileStoneStep: React.FC<iProps> = ({
  responsibleUsers,
  data,
  removeItem,
  updateItem,
  removable = true,
  minDate,
  cloneItem,
  stepsNotChecked,
}) => {
  const { t } = useTranslation();
  const [showDateModal, setShowDateModal] = useState(false);
  const stepNameText = t('processesScreen.modal.milestoneStepName');
  const responsibleText = t('processesScreen.modal.responsibles');

  const dateFormat = t('common.dateFormat');

  const selectedResponsibles = () => {
    const getResponsibleUserIds = data?.responsibleUsers?.map((user) => user.userId);
    return responsibleUsers.filter((item: IGetWorkflowResponsibleUser) =>
      getResponsibleUserIds?.includes(item.userId),
    );
  };
  interface IDateProps {
    className?: string;
    children?: ReactNode;
  }

  const deadlineDateContainer = ({ className, children }: IDateProps) => {
    return <CalendarContainer className={cn(className, 'calendar')}>{children}</CalendarContainer>;
  };

  useEffect(() => {
    updateItem({
      ...data,
      responsibleUserIds: data.responsibleUserIds
        ? data.responsibleUserIds
        : selectedResponsibles().map((i: IGetWorkflowResponsibleUser) => i.userId),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MilestoneStepLayout
        stepNameInput={
          <UnderlineInput
            titleText={stepNameText}
            value={data.stepName}
            error={data.nameError}
            onChange={(value) => updateItem({ ...data, stepName: value, nameError: false })}
            readOnly={!stepsNotChecked}
            id="stepNameInput"
          />
        }
        responsibleDropdown={
          <MultiselectDropdown
            avoidHighlightFirstOption
            title={responsibleText}
            displayValue="userName"
            options={responsibleUsers}
            selectedOptions={
              data.responsibleUserIds
                ? responsibleUsers.filter((item) => data.responsibleUserIds.includes(item.userId))
                : selectedResponsibles()
            }
            maxSelection={5}
            onSelectItem={(list) => {
              updateItem({
                ...data,
                responsibleUserIds: list.map((i: IGetWorkflowResponsibleUser) => i.userId),
                responsibleUsers: list.map((i: IGetWorkflowResponsibleUser) => i),
              });
            }}
            onRemoveItem={(list) => {
              updateItem({
                ...data,
                responsibleUserIds: list.map((i: IGetWorkflowResponsibleUser) => i.userId),
                responsibleUsers: list.map((i: IGetWorkflowResponsibleUser) => i),
              });
            }}
          />
        }
        datePicker={
          <div className={`${data.deadlineError ? '-errorDropdown' : ''}`}>
            <Button
              hasDropdown
              icon="calendar"
              iconSize="large"
              outline
              paddingSize="small"
              size="small"
              className="pt-3 pb-3 d-flex d-inline-block text-truncate datePickerButton"
              onClick={() => setShowDateModal(!showDateModal)}
              disabled={!stepsNotChecked}
              id="stepEndDatePickerButton"
            >
              {data.deadline && dayjs(data.deadline).format(dateFormat)}
            </Button>
          </div>
        }
        datePickerPlaceholder={
          <DatePicker
            minDate={minDate}
            selected={data.deadline}
            onChange={(date: Date) => {
              updateItem({ ...data, deadline: date, deadlineError: false });
              setShowDateModal(false);
            }}
            show={showDateModal}
            shouldCloseOnSelect
            onClickOutside={() => setShowDateModal(false)}
            openToDate={minDate && data.deadline < minDate ? minDate : data.deadline}
            id="stepLastDayOptions"
            calendarContainer={deadlineDateContainer}
          />
        }
        icon={
          <div className="d-flex">
            {stepsNotChecked && (
              <Icon colorStyle="lightGray" type="copy" onClick={() => cloneItem(data)} />
            )}
            {removable && stepsNotChecked && (
              <Icon
                colorStyle="lightGray"
                type="trash"
                onClick={() => data.id && removeItem(data.id)}
                className="ml-3"
              />
            )}
          </div>
        }
      />
    </>
  );
};
