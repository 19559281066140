import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { CommentsRes } from '@/client_v2/rest/types/CommentsRes';
import { API_COMMENTS_SEARCH } from '@/client_v2/rest/Comments/URLS';

export const getCommentsByIds = (ids: number[]) =>
  axiosInstance.post<CommentsRes>(API_COMMENTS_SEARCH, {
    scopes: [
      {
        name: 'ids',
        parameters: [ids.toString()],
      },
    ],
  });
