import { UserPoll } from '@/client_v2/polls/User.poll';
import { DocumentPoll } from '@/client_v2/polls/Document.poll';
import { ClientPoll } from '@/client_v2/polls/Client.poll';
import { CompanyPoll } from '@/client_v2/polls/Company.poll';
import { CommentPoll } from '@/client_v2/polls/Comment.poll';
import { TagPoll } from '@/client_v2/polls/Tag.poll';
import { DocumentRepo } from '@/client_v2/db/repositories/Document.repo';
import { createDataHook } from '@/client_v2/hooks/utils/createDataHook';

export const useDocumentModalData = createDataHook({
  polls: [ClientPoll, CompanyPoll, UserPoll, CommentPoll, TagPoll, DocumentPoll],
  dataFactory: async (id?: number) => {
    if (id) {
      return await DocumentRepo.query('get-documents-modal-data', { id });
    }
    return null;
  },
});
