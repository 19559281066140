import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@/components/Button';
import Text from '@/components/Text';
import UnderlineInput from '@/components/Input/UnderlineInput';
import Badge from '@/components/Badge';
import AddNewClientLayout from '@/layouts/AddNewClientLayout';
import CheckBox from '@/components/CheckBox';
import DatePickerComponent from '@/components/DatePicker';
import { typeMap as checkBoxTypeMap } from '@/components/CheckBox/CheckBox.view';
import Icon from '@/components/Icon';
import ErrorFeedback from '@/components/ErrorFeedback';
import useAddComment from '@/hooks/useAddComment';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import { useCustomerModalData } from '@/client_v2/hooks/useCustomerModalData';
import { useAsyncCallback } from '@/hooks/useAsyncCallback';
import RestClient from '@/client_v2/rest';
import { createOnError } from '@/redux/message/message.callback';
import MultiselectDropdown from '@/components/MultiselectDropdown';
import { useBusinessAreaData } from '@/client_v2/hooks/useBusinesAreaData';
import { AppState } from '@/redux/store';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import { setClientScrollbarPosition } from '@/redux/clients/clients.actions';
import Tooltip from '@/components/Tooltip';

interface Props {
  customerId?: number;
  close(): void;
}

const useAddClientModal = ({ customerId, close }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.user.user);

  const userId = useSelector((state: AppState) => state.user.user?.id);
  const isAdmin = user?.authority == Authorities.Admin || user?.authority == Authorities.SuperAdmin;

  const nameInputTitleText = t('accountSettings.contents.clients.name');
  const commentHeaderText = t('accountSettings.contents.clients.comment');
  const recommender = t('accountSettings.contents.clients.recommender');
  const newCommentText = t('accountSettings.contents.clients.newComment');
  const addCommentButtonText = t('accountSettings.contents.clients.addComment');
  const saveClientButtonText = t('accountSettings.contents.clients.saveClient');
  const createClientButtonText = t('accountSettings.contents.clients.createClient');
  const contact = t('accountSettings.contents.clients.contact');
  const contactText = t('accountSettings.contents.clients.contactText');
  const taxNumber = t('accountSettings.contents.clients.taxNumber');
  const partnershipStartDate = t('accountSettings.contents.clients.partnershipStartDate');
  const branch = t('accountSettings.contents.clients.branch');
  const active = t('accountSettings.contents.clients.active');
  const selectPartnershipDateDropdownText = t('accountSettings.contents.clients.partnershipDate');
  const emptyCommentErrorMessage = t('error.emptyBadgeError');
  const sameCommentErrorMessage = t('error.sameBadgeError');
  const noClientNameError = t('error.noClientNameError');
  const noContactPersonError = t('error.noContactPersonError');
  const noDateSelectedError = t('error.noDateSelectedError');
  const noTaxNumberError = t('error.noTaxNumberError');
  const noBusinessAreaError = t('error.noBusinessAreaError');
  const dateFormat = t('common.dateFormat');
  const tooltipMessage = !isAdmin ? t('sidebarFilter.tooltipMessage') : '';
  //Hooks
  const [checkBoxState, setCheckBoxState] = useState<keyof typeof checkBoxTypeMap>('notChecked');
  const [showDropDown, setShowDropdown] = useState(false);
  const [showDatepicker, setshowDatepicker] = useState(false);

  const {
    comments,
    setComments,
    comment,
    setCommentInput,
    addComment,
    removeComment,
    showEmptyCommentError,
    showSameCommentError,
  } = useAddComment();

  const [, businessAreaData] = useBusinessAreaData({
    triggerOnce: true,
    renderOnUpdate: false,
    onError: createOnError(dispatch),
  });

  const [clientsLoading, clients] = useCustomerModalData({
    renderOnUpdate: false,
    triggerOnce: customerId || true,
    onError: createOnError(dispatch),
  });

  const [contactDropdownTextData, setContactDropdownData] =
    useState<{ id: number; name: string }[]>();

  const [partnerDateDropdownTextData, setPartnerDateDropdownData] = useState<Date>(new Date());
  const [clientName, setClientName] = useState<string>('');
  const [taxIdNumber, setTaxId] = useState<string>('');
  const [recommenderNameData, setRecommenderNameData] = useState<string | undefined>(undefined);
  const [businessAreasIds, setBusinessAreasIds] = useState<number[]>([]);

  const [save, isSaving] = useAsyncCallback(
    async () => {
      dispatch(setClientScrollbarPosition(undefined));
      const isEdit = !!customerId;
      let contactUserId: number[] | null,
        name: string,
        startDate: Date,
        taxId: string,
        proposer: string | undefined;
      contactUserId = null;
      if (clientName) {
        name = clientName;
      } else {
        throw noClientNameError;
      }
      if (contactDropdownTextData) {
        contactUserId = contactDropdownTextData.map((item) => item.id);
      } else {
        throw noContactPersonError;
      }

      if (partnerDateDropdownTextData) {
        startDate = partnerDateDropdownTextData;
      } else {
        throw noDateSelectedError;
      }

      if (recommenderNameData) {
        proposer = recommenderNameData;
      }

      if (taxIdNumber) {
        taxId = taxIdNumber;
      } else {
        throw noTaxNumberError;
      }

      if (!businessAreasIds || businessAreasIds.length < 1) {
        throw noBusinessAreaError;
      }

      if (isEdit && clients.customer) {
        await RestClient.Clients.patch(
          {
            id: customerId!,
            name,
            taxId,
            startDate,
            proposer: proposer,
            contactUserIds: contactUserId,
            businessAreasIds: businessAreasIds,
          },
          {
            userId: userId!,
            comments: comments
              .filter(
                (newComment) =>
                  !clients.customer.comments.some(
                    (oldComment) => newComment.comment === oldComment.comment,
                  ),
              )
              .map(({ comment }) => comment),
          },
          clients.customer &&
            clients.customer.comments.filter(
              (oldComment) =>
                !comments.some((newComment) => oldComment.comment === newComment.comment),
            ),
        );
        close();
      } else {
        await RestClient.Clients.post(
          {
            name,
            taxId,
            startDate,
            proposer: proposer,
            contactUserIds: contactUserId!,
            businessAreasIds: businessAreasIds,
          },
          {
            userId: userId!,
            comments: comments.map(({ comment }) => comment),
          },
        );
        close();
      }
    },
    createOnError(dispatch),
    [
      customerId,
      comments,
      partnerDateDropdownTextData,
      contactDropdownTextData,
      recommenderNameData,
      taxIdNumber,
      clientName,
      businessAreasIds,
      close,
    ],
  );

  const onCheckBoxClick = (id: number) => {
    if (businessAreasIds.indexOf(id) !== -1) {
      setBusinessAreasIds(businessAreasIds.filter((item) => item !== id));
    } else {
      setBusinessAreasIds([...businessAreasIds, id]);
    }
  };

  useEffect(() => {
    if (clientsLoading) return;
    if (!clients.customer) return;

    if (clients.customer.contact) setContactDropdownData(clients.customer.contact);
    setPartnerDateDropdownData(clients.customer.startDate);
    setBusinessAreasIds(clients.customer.businessAreasIds);
    setClientName(clients.customer.name);
    setTaxId(clients.customer.taxId);
    setComments(clients.customer.comments);
    setRecommenderNameData(clients.customer.proposer);
  }, [
    clientsLoading,
    clients,
    setContactDropdownData,
    setBusinessAreasIds,
    setClientName,
    setTaxId,
    setComments,
    setPartnerDateDropdownData,
  ]);

  return {
    nameInputTitleText,
    commentHeaderText,
    recommender,
    newCommentText,
    addCommentButtonText,
    saveClientButtonText,
    createClientButtonText,
    contact,
    contactText,
    taxNumber,
    partnershipStartDate,
    branch,
    active,
    setShowDropdown,
    showDropDown,
    checkBoxState,
    setCheckBoxState,
    selectPartnershipDateDropdownText,
    showDatepicker,
    setshowDatepicker,
    onCheckBoxClick,
    comments,
    setComments,
    comment,
    setCommentInput,
    addComment,
    removeComment,
    showEmptyCommentError,
    showSameCommentError,
    dispatch,
    emptyCommentErrorMessage,
    sameCommentErrorMessage,
    save,
    clientsLoading,
    clients,
    contactDropdownTextData,
    setContactDropdownData,
    clientName,
    partnerDateDropdownTextData,
    taxIdNumber,
    recommenderNameData,
    setPartnerDateDropdownData,
    setClientName,
    setTaxId,
    setRecommenderNameData,
    loadingText: t('loading'),
    isSaving,
    businessAreaData,
    businessAreasIds,
    dateFormat,
    customerId,
    user,
    tooltipMessage,
    isAdmin,
  };
};

export const AddClientModal: FC<Props> = (props) => {
  const {
    nameInputTitleText,
    commentHeaderText,
    recommender,
    saveClientButtonText,
    createClientButtonText,
    contact,
    contactText,
    taxNumber,
    addCommentButtonText,
    partnershipStartDate,
    branch,
    active,
    showDatepicker,
    setshowDatepicker,
    comments,
    comment,
    setCommentInput,
    addComment,
    removeComment,
    showEmptyCommentError,
    showSameCommentError,
    save,
    clientsLoading,
    emptyCommentErrorMessage,
    sameCommentErrorMessage,
    clients,
    contactDropdownTextData,
    setContactDropdownData,
    clientName,
    partnerDateDropdownTextData,
    taxIdNumber,
    recommenderNameData,
    setPartnerDateDropdownData,
    setClientName,
    setTaxId,
    setRecommenderNameData,
    isSaving,
    businessAreaData,
    onCheckBoxClick,
    businessAreasIds,
    dateFormat,
    newCommentText,
    customerId,
    user,
    tooltipMessage,
    isAdmin,
  } = useAddClientModal(props);

  return (
    <LoadingPlaceholder showSpinner={true} isLoading={clientsLoading}>
      <AddNewClientLayout
        key={`add-new-client-${taxNumber}`}
        nameInputField={
          <UnderlineInput
            titleText={nameInputTitleText}
            value={clientName}
            onChange={setClientName}
            required
            id="clientNameInput"
          />
        }
        taxInputField={
          <UnderlineInput
            titleText={taxNumber}
            value={taxIdNumber}
            onChange={setTaxId}
            required
            id="clientTaxNumberInput"
          />
        }
        contactDropdownMenu={
          <LoadingPlaceholder showSpinner={true} isLoading={clientsLoading}>
            {!clientsLoading && (
              <MultiselectDropdown
                title={contactText}
                avoidHighlightFirstOption
                onRemoveItem={(selected) => setContactDropdownData(selected)}
                options={clients.contacts}
                onSelectItem={(selected) => setContactDropdownData(selected)}
                selectedOptions={contactDropdownTextData}
                showIcon
                id="clientContact"
              />
            )}
          </LoadingPlaceholder>
        }
        contactDropdownMenuText={
          <Text type="style7" required>
            {contact}
          </Text>
        }
        recommenderInputField={
          <UnderlineInput
            titleText={recommender}
            value={recommenderNameData}
            onChange={setRecommenderNameData}
          />
        }
        commentIcon={<Icon type="chat" colorStyle="darkGray" />}
        commentHeaderText={<Text type="style8">{commentHeaderText}</Text>}
        badges={comments.map((comment, index) => ({
          badgeTexts: (
            <Badge
              key={`client-badge-comment-${index}`}
              content={comment.comment}
              colorStyle="lightGray"
              size="small"
              selfClosing
              onClick={() => removeComment(comment)}
            />
          ),
        }))}
        commentInputField={
          <UnderlineInput
            titleText={newCommentText}
            value={comment}
            onChange={setCommentInput}
            onEnterKeyPressed={addComment}
            id="clientCommentInput"
          />
        }
        addCommentButton={
          <Button paddingSize="small" size="small" onClick={addComment} id="clientCommentButton">
            {addCommentButtonText}
          </Button>
        }
        errorField={
          <>
            {showEmptyCommentError && <ErrorFeedback errorMessage={emptyCommentErrorMessage} />}
            {showSameCommentError && <ErrorFeedback errorMessage={sameCommentErrorMessage} />}
          </>
        }
        saveClientButton={
          <Button
            size="small"
            paddingSize="large"
            roundedLarge
            icon={!customerId ? 'plus' : 'pencil'}
            iconSize="large"
            onClick={isSaving ? undefined : save}
            loadingSpinner={isSaving}
            id="clientSubmitButton"
            disabled={
              !isAdmin ||
              !contactDropdownTextData ||
              !clientName ||
              !taxIdNumber ||
              !partnerDateDropdownTextData
            }
          >
            {!customerId ? createClientButtonText : saveClientButtonText}
          </Button>
        }
        partnerDateDropdownMenuText={
          <Text type="style7" required>
            {partnershipStartDate}
          </Text>
        }
        partnerDateDropdownMenu={
          <Button
            hasDropdown
            icon="chevronDown"
            iconSize="extraLarge"
            outline
            paddingSize="large"
            size="medium"
            onClick={() => {
              setshowDatepicker(true);
            }}
            id="clientStartDatePicker"
          >
            {dayjs(partnerDateDropdownTextData).format(dateFormat)}
          </Button>
        }
        datePickerPlaceholder={
          <DatePickerComponent
            onChange={(date: Date) => {
              setshowDatepicker(false);
              setPartnerDateDropdownData(date);
            }}
            onClickOutside={() => setshowDatepicker(false)}
            show={showDatepicker}
            id="clientStartDateCalendar"
          />
        }
        branchHeaderText={<Text type="style8">{branch}</Text>}
        checkboxHeaderText={
          <Text type="style8" required>
            {active}
          </Text>
        }
        checkBoxData={
          businessAreaData &&
          businessAreaData.map((area, index) => {
            const checkboxAuthority =
              user?.authority === Authorities.SuperAdmin || Authorities.Admin;
            return {
              text: <Text type="style7">{area.name}</Text>,
              checkBoxView: (
                <>
                  <Tooltip bubbleContent={tooltipMessage} place={'right'}>
                    <CheckBox
                      key={`client-business-areas-${index}`}
                      type={businessAreasIds.indexOf(area.id) !== -1 ? 'checked' : 'notChecked'}
                      onClick={() => {
                        checkboxAuthority && onCheckBoxClick(area.id);
                      }}
                      cursor={checkboxAuthority ? 'pointer' : 'notAllowed'}
                      id={`businessAreaClientCheck${area.id}`}
                    />
                  </Tooltip>
                </>
              ),
            };
          })
        }
      />
    </LoadingPlaceholder>
  );
};

export default AddClientModal;
