import { createDataSync } from '@/client_v2/util/createDataSync';
import { ClientRepo } from '@/client_v2/db/repositories/Client.repo';
import RestClient from '@/client_v2/rest';

const DELAY = 1000 * 20;

export const ClientPoll = createDataSync({
  id: 'client',
  delay: DELAY,
})
  .init(async () => {
    const res = await RestClient.Clients.get();
    await Promise.all(res.data.data.map(async (doc) => await ClientRepo.save(doc)));
  })
  .poll(async (pollTime) => {
    const res = await RestClient.Clients.poll(pollTime());
    if (res.data.poll.length) {
      return {
        update: true,
        data: res.data.poll,
      };
    }
    return {
      update: false,
    };
  })
  .update(async (data) => {
    if (!data) return;
    const deleted = data.filter(({ operation }) => operation === 'deleted').map(({ id }) => id);
    await Promise.all(deleted.map(ClientRepo.delete));

    const createdOrUpdated = data
      .filter(({ operation }) => operation !== 'deleted')
      .map(({ id }) => id);
    if (createdOrUpdated && createdOrUpdated.length) {
      const res = await RestClient.Clients.getByIds(createdOrUpdated);
      await Promise.all(res.data.data.map(ClientRepo.save));
    }
  });
