import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AccountSettingSidebarLayout from '@/layouts/AccountSettingSidebarLayout';
import Link from '@/components/Link';
import Text from '@/components/Text';
import Card from '@/components/Card';
import BlueMark from '@/components/BlueMark';
import URLS from '@/resources/constants/URLS';
import { AppState } from '@/redux/store';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';

const useSidebar = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const user = useSelector((state: AppState) => state.user.user);
  const superAdminUser = user && user.authority === Authorities.SuperAdmin;
  const isAdmin =
    user?.authority === Authorities.SuperAdmin || user?.authority === Authorities.Admin;

  const onClickUsers = useCallback(() => {
    navigate(URLS.root.accountSettings.users.toString());
  }, [navigate]);

  const onClickCompanies = useCallback(() => {
    navigate(URLS.root.accountSettings.companies.toString());
  }, [navigate]);

  const onClickCustomers = useCallback(() => {
    navigate(URLS.root.accountSettings.clients.toString());
  }, [navigate]);

  const onClickActivityFeed = useCallback(() => {
    navigate(URLS.root.accountSettings.activityFeed.toString());
  }, [navigate]);

  return {
    superAdminUser,
    pathname,
    title: t('accountSettings.sidebar.title'),
    users: t('nav.users'),
    companies: t('nav.companies'),
    customers: t('nav.clients'),
    activityFeed: t('nav.activityFeed'),
    onClickUsers,
    onClickCompanies,
    onClickCustomers,
    onClickActivityFeed,
    isAdmin,
  };
};

export const Sidebar: FC = () => {
  const {
    superAdminUser,
    activityFeed,
    companies,
    customers,
    title,
    users,
    pathname,
    onClickActivityFeed,
    onClickCompanies,
    onClickCustomers,
    onClickUsers,
    isAdmin,
  } = useSidebar();

  return isAdmin ? (
    <Card bgColorStyle="light" rounded>
      <AccountSettingSidebarLayout
        title={
          <Text type="style28" capitalization="uppercase">
            {title}
          </Text>
        }
        users={
          <Link defaultType="style8" onHoverType="style9" onClick={onClickUsers} id="usersLink">
            {users}
          </Link>
        }
        companies={
          <Link
            defaultType="style8"
            onHoverType="style9"
            onClick={onClickCompanies}
            id="companiesLink"
          >
            {companies}
          </Link>
        }
        customers={
          <Link
            defaultType="style8"
            onHoverType="style9"
            onClick={onClickCustomers}
            id="clientsLink"
          >
            {customers}
          </Link>
        }
        activityFeed={
          superAdminUser && (
            <Link
              defaultType="style8"
              onHoverType="style9"
              onClick={onClickActivityFeed}
              id="activityFeedLink"
            >
              {activityFeed}
            </Link>
          )
        }
        activityFeedMark={pathname.includes('activityFeed') && <BlueMark />}
        companiesMark={pathname.includes('companies') && <BlueMark />}
        customersMark={pathname.includes('clients') && <BlueMark />}
        usersMark={pathname.includes('users') && <BlueMark />}
      />
    </Card>
  ) : (
    <></>
  );
};
