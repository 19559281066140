import '@/screens/DocumentsScreen/Header/style.scss';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarContainer } from 'react-datepicker';
import { useLocation } from 'react-router-dom';
import {
  deleteDocumentSearchWords,
  expandDocumentTable,
  getDocumentTableDataAction,
  setDocumentSearchWords,
} from '@/redux/documents/documents.actions';
import DocumentUploadModal from '@/screens/DocumentsScreen/components/DocumentUploadModal';
import DocumentsHeaderLayout from '@/layouts/DocumentsHeaderLayout/index';
import { ModalBox } from '@/components/ModalBox';
import Button from '@/components/Button';
import Text from '@/components/Text';
import SearchInput from '@/components/SearchInput';
import DatePickerComponent from '@/components/DatePicker';
import { cn, CreateScopeCSS } from '@/components/utils';
import { AppState } from '@/redux/store';
import { DropDownBox } from '@/components/DropDownBox/DropDownBox';
import { setDocumentFilter } from '@/redux/sortAndFilters/sortAndFilters.actions';
import DocumentFilterModal from '@/screens/DocumentsScreen/Content/components/DocumentFilterModal';
import {
  IGetWorkflowData,
  IGetWorkflowMiles,
  IGetWorkflowSteps,
} from '@/client_v2/db/repositories/Workflow.repo';
import Icon from '@/components/Icon';
import { setModalOpen } from '@/redux/core/core.actions';
import { resetSortAction, setDocumentSortAction } from '@/redux/sort/sort.actions';

const scope = CreateScopeCSS('screens-documents-header-layout');
const buttonTextStyles = CreateScopeCSS(scope.and('componentsText'));

interface workflowDataType {
  selectedProcessData: IGetWorkflowData;
  selectedMileData: IGetWorkflowMiles;
  selectedWorkflowStep: IGetWorkflowSteps;
}

interface ISortType {
  name: string;
  field: string;
  direction: 'asc' | 'desc';
  default?: boolean;
}

interface IDateProps {
  className?: string;
  children?: ReactNode;
}

const useDocumentScreenHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(new Date());
  const [showPopup, setShowPopup] = useState(false);
  const [searchWords, setSearchWords] = useState<string[]>([]);
  const title = t('docs.title');
  const headerText = t('upload.headerText');
  const uploadFilesButtonText = t('upload.uploadText');
  const pleaseSelectACompanyText = t('upload.selectACompany');
  const documentOrderText = t('documents.header.order');
  const documentFilterText = t('documents.header.filter');
  const dateFormat = t('common.dateFormat');
  const selectDateRangeText = t('accountSettings.header.activityFeed.selectDateRange');
  const filterByDateText = t('accountSettings.header.activityFeed.filterByDate');
  const filterDocumentDateButtonText = t('filters.filterDocumentDateButtonText');

  const initialDateRangeState = [new Date(), new Date()];
  const [dateRange, setDateRange] = useState(initialDateRangeState);
  const [startDate, endDate] = dateRange;
  const [hasDateSelected, setHasDateSelected] = useState(false);
  const [showSortField, setShowSortField] = useState(false);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [sortText, setSortText] = useState<string>('');
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const { errorMessage, isLoading, uploadedDocument } = useSelector(
    (state: AppState) => state.documents,
  );
  const { selectedClientId } = useSelector((state: AppState) => state.user);
  const { documentFilters } = useSelector((state: AppState) => state.sortAndFilters);
  const { documentSort } = useSelector((state: AppState) => state.sort);

  useEffect(() => {
    if (!isLoading && (errorMessage || uploadedDocument)) {
      setShowPopup(false);
    }
  }, [errorMessage, isLoading, uploadedDocument]);

  useEffect(() => {
    if (!searchWords.length) {
      dispatch(deleteDocumentSearchWords());
    }
  }, [searchWords, dispatch]);

  useEffect(() => {
    dispatch(setModalOpen(showPopup));
  }, [dispatch, showPopup]);

  const search = useCallback(async () => {
    setIsSearchLoading(true);
    await dispatch(setDocumentSearchWords({ searchWords }));
    setIsSearchLoading(false);
  }, [searchWords, dispatch]);

  const sortOptions: ISortType[] = [
    { name: 'companyNameAsc', field: 'companyName', direction: 'asc', default: true },
    { name: 'companyNameDesc', field: 'companyName', direction: 'desc' },
    { name: 'clientNameAsc', field: 'clientName', direction: 'asc' },
    { name: 'clientNameDesc', field: 'clientName', direction: 'desc' },
    { name: 'createDateAsc', field: 'createdAt', direction: 'asc' },
    { name: 'createDateDesc', field: 'createdAt', direction: 'desc' },
  ];

  useEffect(() => {
    dispatch(expandDocumentTable({ expand: false }));
    dispatch(resetSortAction());
  }, [dispatch]);

  useEffect(() => {
    setSortText(documentSort.name ? t('documents.sorts.' + documentSort.name) : documentOrderText);
  }, [documentSort.name, documentOrderText, t]);

  const datePickerContainer = ({ className, children }: IDateProps) => {
    return (
      <div className=" d-lg-block  d-sm-flex  justify-content-around user-select-none">
        <CalendarContainer className={cn(className)}>
          <div className="position-relative">{children}</div>
          <div className="col-12 d-flex justify-content-center">
            <Button
              size="small"
              className="mb-3"
              onClick={() => {
                setShowDatepicker(false);
                dateRange && hasDateSelected
                  ? dispatch(
                      setDocumentFilter({
                        ...(startDate && { fromDate: startDate }),
                        ...(endDate && { toDate: endDate }),
                      }),
                    )
                  : dispatch(getDocumentTableDataAction());
              }}
            >
              {filterDocumentDateButtonText}
            </Button>
            <Button
              size="small"
              iconSize="large"
              icon="x"
              className="mb-3 ml-5"
              onClick={() => {
                setShowDatepicker(false);
                setDateRange(initialDateRangeState);
                dispatch(
                  setDocumentFilter({
                    ...(startDate && { fromDate: undefined }),
                    ...(endDate && { toDate: undefined }),
                  }),
                );
              }}
            />
          </div>
        </CalendarContainer>
      </div>
    );
  };

  const expandDocument = useSelector((state: AppState) => state.documents.expand);

  return {
    t,
    headerText,
    showPopup,
    setShowPopup,
    selectedClientId,
    uploadFilesButtonText,
    title,
    pleaseSelectACompanyText,
    uploadDocument: t('documents.header.uploadDocument'),
    datePickerOnChange: setSelected,
    datePickerSelected: selected,
    searchText: t('processesScreen.search'),
    newSearchText: t('processesScreen.newSearch'),
    setSearchWords,
    documentFilterText,
    setDateRange,
    setHasDateSelected,
    startDate,
    endDate,
    hasDateSelected,
    dateRange,
    dateFormat,
    selectDateRangeText,
    filterByDateText,
    search,
    dispatch,
    sortOptions,
    showSortField,
    setShowSortField,
    showFilterPopup,
    setShowFilterPopup,
    isSearchLoading,
    datePickerContainer,
    showDatepicker,
    setShowDatepicker,
    documentFilters,
    sortText,
    setSortText,
    expandDocument,
    documentOrderText,
    documentSort,
  };
};

export const DocumentScreenHeader: FC = () => {
  const {
    t,
    headerText,
    showPopup,
    setShowPopup,
    uploadFilesButtonText,
    title,
    searchText,
    newSearchText,
    setSearchWords,
    documentFilterText,
    setDateRange,
    setHasDateSelected,
    startDate,
    endDate,
    filterByDateText,
    isSearchLoading,
    sortOptions,
    search,
    showSortField,
    setShowSortField,
    pleaseSelectACompanyText,
    dispatch,
    dateFormat,
    showFilterPopup,
    setShowFilterPopup,
    datePickerContainer,
    showDatepicker,
    setShowDatepicker,
    selectDateRangeText,
    documentFilters,
    selectedClientId,
    sortText,
    setSortText,
    expandDocument,
    documentOrderText,
    documentSort,
  } = useDocumentScreenHeader();

  const location = useLocation();
  const [selectedWorkflow, setSelectedWorkflow] = useState<workflowDataType | undefined>();

  const locationState = location.state as {
    openModal: boolean;
    selectedWorkflow: workflowDataType | undefined;
  };

  useEffect(() => {
    if (locationState?.openModal && locationState?.selectedWorkflow) {
      setSelectedWorkflow(locationState?.selectedWorkflow);
      setShowPopup(true);
    }
  }, [locationState, setShowPopup]);

  useEffect(() => {
    if (!showPopup && selectedWorkflow) {
      setSelectedWorkflow(undefined);
    }
  }, [selectedWorkflow, showPopup]);

  return (
    <div className="pt-4">
      <DocumentsHeaderLayout
        title={<Text type="style13">{title}</Text>}
        searchBar={
          <SearchInput
            placeholder={searchText}
            buttonText={newSearchText}
            onSubmit={(e) => {
              e.preventDefault();
              search();
            }}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              const value = e.currentTarget.value.split(' ');
              setSearchWords(value);
            }}
            loading={isSearchLoading}
          />
        }
        uploadDocumentButton={
          <div
            data-mdb-toggle="tooltip"
            data-placement="top"
            title={!selectedClientId ? pleaseSelectACompanyText : ''}
          >
            <Button
              size="medium"
              onClick={() => setShowPopup(true)}
              icon="uploadCloud"
              rounded
              className="py-2"
              id="documentModalButton"
              disabled={!selectedClientId}
              cursor={!selectedClientId ? 'none' : 'pointer'}
            >
              <Text className="d-flex" type="style24">
                {uploadFilesButtonText}
              </Text>
            </Button>
          </div>
        }
        datePicker={
          <>
            <Button
              className={cn('bg-white rounded h-100 pt-3 pb-3 d-flex justify-content-center')}
              hasDropdown
              icon="calendar"
              iconSize="large"
              outline
              paddingSize="small"
              size="medium"
              onClick={() => {
                setShowDatepicker(true);
              }}
            >
              {documentFilters.fromDate && documentFilters.toDate
                ? `${dayjs(documentFilters.fromDate).format(dateFormat)} - ${dayjs(
                    documentFilters.toDate,
                  ).format(dateFormat)}`
                : selectDateRangeText}
            </Button>
            <Text type="style6">{filterByDateText}</Text>
          </>
        }
        datePickerPlaceholder={
          <DatePickerComponent
            onChange={(date: [Date, Date]) => {
              setDateRange(date);
              setHasDateSelected(true);
            }}
            show={showDatepicker}
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onClickOutside={() => {
              setShowDatepicker(false);
            }}
            calendarContainer={datePickerContainer}
            shouldCloseOnSelect={false}
            isClearable
          />
        }
        sortOption={
          <>
            <DropDownBox
              data={sortOptions.map((item) => {
                return {
                  data: item,
                  view: <div key={item.field}>{t('documents.sorts.' + item.name)}</div>,
                };
              })}
              onSelect={(item: ISortType) => {
                setSortText(t('documents.sorts.' + item.name));
                setShowSortField(false);
                dispatch(
                  setDocumentSortAction({
                    name: item.name,
                    field: item.field,
                    direction: item.direction,
                  }),
                );
              }}
              onClickOutside={() => setShowSortField(false)}
              show={showSortField}
            />
            <Button
              size="large"
              iconSize="medium"
              icon="slider"
              className={'bg-transparent border border-white '}
              onClick={() => {
                setShowSortField(!showSortField);
              }}
            >
              <Text className={cn(buttonTextStyles)} type="style17">
                {documentSort.name ? sortText : documentOrderText}
              </Text>
            </Button>
          </>
        }
        filterOption={
          <>
            <Button
              size="large"
              iconSize="medium"
              icon="slider"
              className={
                documentFilters.hasSelected
                  ? 'border border-white'
                  : 'bg-transparent border border-white'
              }
              onClick={() => {
                setShowFilterPopup(true);
              }}
            >
              <Text className={cn(buttonTextStyles)} type="style17">
                {documentFilterText}
              </Text>
            </Button>
            {expandDocument ? (
              <Icon
                type="minimize"
                colorStyle="white"
                onClick={() => dispatch(expandDocumentTable({ expand: !expandDocument }))}
                className="ml-3"
              />
            ) : (
              <Icon
                type="expand"
                colorStyle="white"
                onClick={() => dispatch(expandDocumentTable({ expand: !expandDocument }))}
                className="ml-3"
              />
            )}
          </>
        }
      />
      {showFilterPopup && (
        <DocumentFilterModal showPopup={showFilterPopup} setHidePopup={setShowFilterPopup} />
      )}
      {showPopup && (
        <ModalBox
          headerText={headerText}
          modalWidth="wide"
          show={showPopup}
          onClickClose={() => setShowPopup(false)}
          id="documentUploadModal"
        >
          <DocumentUploadModal
            close={async () => {
              setShowPopup(false);
              return true;
            }}
            selectedWorkflowData={selectedWorkflow}
          />
        </ModalBox>
      )}
    </div>
  );
};
