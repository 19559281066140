import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '@pathofdev/react-tag-input/build/index.css';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@/components/Button';
import Text from '@/components/Text';
import { ModalBox } from '@/components/ModalBox';
import MultiselectDropdown from '@/components/MultiselectDropdown';
import { useFilterModalData } from '@/client_v2/hooks/useFilterModalData';
import FilterModalContentLayout from '@/layouts/FilterModalContentLayout';
import { WorkflowStatuses } from '@/resources/constants/shared';
import { setProcessFilter } from '@/redux/sortAndFilters/sortAndFilters.actions';
import { AppState } from '@/redux/store';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import { setError } from '@/redux/message/message.action';
import { IGetCompaniesData } from '@/client_v2/db/repositories/Company.repo';
import { IGetCompanyModalData } from '@/client_v2/db/repositories/Client.repo';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';

export interface statuses {
  status: WorkflowStatuses;
}

interface filterData {
  id: number;
  name: string;
  clientId?: number;
}

interface Props {
  showPopup: boolean;
  setHidePopup: (value: boolean) => void;
}

const FilterModal: FC<Props> = ({ showPopup, setHidePopup }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, data] = useFilterModalData({
    triggerOnce: true,
    renderOnUpdate: true,
    onError: (err) => dispatch(setError(err.toString())),
  });
  const { processFilters } = useSelector((state: AppState) => state.sortAndFilters);
  const user = useSelector((state: AppState) => state.user.user);
  const seesArchived =
    user?.authority != Authorities.ClientUser1 && user?.authority != Authorities.ClientUser2;

  const [selectedClients, setSelectedClients] = useState<number[]>();
  const [selectedCompanies, setSelectedCompanies] = useState<number[]>();
  const [selectedUsers, setSelectedUsers] = useState<number[]>();
  const [selectedStatus, setSelectedStatuses] = useState<string[]>();
  const [selectedArchived, setSelectedArchived] = useState<string[]>();
  const [filterClientIds, setFilterClientIds] = useState<IGetCompanyModalData[]>();
  const [filterCompanyIds, setFilterCompanyIds] = useState<IGetCompaniesData[]>();

  const statuses = Object.entries(WorkflowStatuses).map(([value, key]) => {
    return { name: t('workflowStatus.' + key), id: key, key: value };
  });

  const archived = [
    { name: t('filters.archived'), id: 'archived', key: 'archived' },
    { name: t('filters.notArchived'), id: 'notArchived', key: 'notArchived' },
  ];

  useEffect(() => {
    processFilters.statuses && setSelectedStatuses(processFilters.statuses);
    processFilters.userIds && setSelectedUsers(processFilters.userIds);
    processFilters.companyIds && setSelectedCompanies(processFilters.companyIds);
    processFilters.clientIds && setSelectedClients(processFilters.clientIds);
    processFilters.archived && setSelectedArchived(processFilters.archived);
  }, [processFilters]);

  useEffect(() => {
    if (selectedClients?.length) {
      const newCompanyFilter = data?.companies.filter((item: IGetCompaniesData) => {
        return !!selectedClients.includes(item.clientId);
      });
      setFilterCompanyIds(newCompanyFilter);
    } else {
      setFilterCompanyIds(data?.companies);
    }

    if (selectedCompanies?.length) {
      const newClientFilter = data?.clients.filter((client: IGetCompanyModalData) => {
        return !!selectedCompanies.filter(
          (selectedCompanyId) =>
            data?.companies.find((company) => company.id === selectedCompanyId)?.clientId ==
            client.id,
        ).length;
      });
      setFilterClientIds(newClientFilter);
    } else {
      setFilterClientIds(data?.clients);
    }
  }, [data, selectedClients, selectedCompanies]);

  interface selectProps {
    text: string;
    data: { name: string; id: number | string; key?: string }[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (list: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selected: any;
    fieldName: string;
    singleSelect: boolean;
  }

  const getSelectType = (props: selectProps) => {
    return (
      <MultiselectDropdown
        showIcon
        avoidHighlightFirstOption
        maxSelection={5}
        options={props.data}
        title={props.text}
        label={props.text}
        selectedOptions={
          props.data &&
          props.data.filter((item) => props.selected && props.selected.includes(item.id))
        }
        onSelectItem={(list) => {
          props.onChange(list.map((i: filterData) => i.id));
          filterDataChange(list, props.fieldName);
        }}
        onRemoveItem={(list) => {
          props.onChange(list.map((i: filterData) => i.id));
          filterDataChange(list, props.fieldName);
        }}
        singleSelect={props.singleSelect}
      />
    );
  };

  const filterDataChange = (list: filterData[], fieldName: string) => {
    if (fieldName === 'clients') {
      clientsFilter(list);
    }
    if (fieldName === 'companies') {
      companiesFilter(list);
    }
  };

  const searchData = (list: filterData[], searchData: number, arrayKey: string) => {
    return list.some((c: filterData) => c[arrayKey as keyof filterData] == searchData);
  };

  const clientsFilter = (list: filterData[]) => {
    if (list.length) {
      const newCompanyFilter = data?.companies.filter((item: IGetCompaniesData) => {
        return searchData(list, item.clientId, 'id');
      });
      setFilterCompanyIds(newCompanyFilter);
    } else {
      setFilterCompanyIds(data?.companies);
    }
  };

  const companiesFilter = (list: filterData[]) => {
    if (list.length) {
      const newClientFilter = data?.clients.filter((item: IGetCompanyModalData) => {
        return searchData(list, item.id, 'clientId');
      });
      setFilterClientIds(newClientFilter);
    } else {
      setFilterClientIds(data?.clients);
    }
  };

  return (
    <ModalBox
      headerText={t('filters.header')}
      show={showPopup}
      onClickClose={() => setHidePopup(false)}
      modalWidth="wide"
    >
      <LoadingPlaceholder showSpinner={true} isLoading={isLoading}>
        <FilterModalContentLayout
          filterApprover={getSelectType({
            text: t('filters.approver'),
            data: data?.responsibleUsers,
            onChange: setSelectedUsers,
            selected: processFilters.userIds,
            fieldName: 'responsibleUsers',
            singleSelect: false,
          })}
          filterClient={getSelectType({
            text: t('filters.client'),
            data: filterClientIds || [],
            onChange: setSelectedClients,
            selected: processFilters.clientIds,
            fieldName: 'clients',
            singleSelect: false,
          })}
          filterCompany={getSelectType({
            text: t('filters.company'),
            data: filterCompanyIds || [],
            onChange: setSelectedCompanies,
            selected: processFilters.companyIds,
            fieldName: 'companies',
            singleSelect: false,
          })}
          filterStatus={getSelectType({
            text: t('filters.status'),
            data: statuses,
            onChange: setSelectedStatuses,
            selected: processFilters.statuses,
            fieldName: 'statuses',
            singleSelect: false,
          })}
          filterArchived={
            seesArchived &&
            getSelectType({
              text: t('filters.archivedFiltering'),
              data: archived,
              onChange: setSelectedArchived,
              selected: processFilters.archived,
              fieldName: 'archived',
              singleSelect: true,
            })
          }
          submitButton={
            <Button
              size="large"
              rounded
              onClick={() => {
                setHidePopup(false);
                dispatch(
                  setProcessFilter({
                    ...(selectedStatus && { statuses: selectedStatus }),
                    ...(selectedClients && { clientIds: selectedClients }),
                    ...(selectedCompanies && { companyIds: selectedCompanies }),
                    ...(selectedUsers && { userIds: selectedUsers }),
                    ...(selectedStatus?.length ||
                    selectedClients?.length ||
                    selectedCompanies?.length ||
                    selectedUsers?.length
                      ? { hasSelected: true }
                      : { hasSelected: false }),
                    ...(selectedArchived && { archived: selectedArchived }),
                  }),
                );
              }}
            >
              <Text className="px-2 py-1 d-flex" type="style24">
                {t('filters.submitProcesses')}
              </Text>
            </Button>
          }
        />
      </LoadingPlaceholder>
    </ModalBox>
  );
};

export default FilterModal;
