import '@/layouts/FilterModalContentLayout/style.scss';
import { FC, ReactNode } from 'react';
import { CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('components-filter-modal-content-layout');

interface Props {
  submitButton: ReactNode;
  filterApprover: ReactNode;
  filterClient: ReactNode;
  filterCompany: ReactNode;
  filterStatus: ReactNode;
  filterArchived: ReactNode;
}

export const FilterModalContentLayout: FC<Props> = ({
  submitButton,
  filterApprover,
  filterClient,
  filterCompany,
  filterStatus,
  filterArchived,
}) => {
  return (
    <div className={scope}>
      <div className="row">
        <div className="col-lg-12 text-nowrap">
          <div className="row">
            <div className="col-lg-6 col-12">{filterApprover}</div>
            <div className="col-lg-6 col-12 mt-lg-0 mt-4">{filterClient}</div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-12 mt-4">{filterCompany}</div>
            <div className="col-lg-4 col-12 mt-4">{filterStatus}</div>
            <div className="col-lg-4 col-12 mt-4">{filterArchived}</div>
          </div>
          <div className="row">
            <div className="col-lg-12 mt-4 mb-5 d-flex justify-content-lg-start justify-content-center">
              {submitButton}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
