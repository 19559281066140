import * as Endpoints from '@/client/endpoints';
import * as Interfaces from '@/client/documentManagementInterfaces';
import axiosInstance from '@/client_v2/rest/axios/axiosInstance';

//Document management calls
export class DocumentManagement {
  documentUpload = async (
    document: Interfaces.IDocumentUploadRequest,
  ): Promise<Interfaces.IDocumentUploadResponse> => {
    const response = await axiosInstance.post(Endpoints.DOCUMENT_UPLOAD, document);
    return response.data;
  };

  shareDocument = async (): Promise<Interfaces.IShareDocumentResponse> => {
    const response = await axiosInstance.post(Endpoints.SHARE_DOCUMENT);
    return response.data;
  };

  approveDocument = async (
    document: Interfaces.IApproveDocumentRequest,
  ): Promise<Interfaces.IApproveDocumentResponse> => {
    const response = await axiosInstance.post(Endpoints.APPROVE_DOCUMENT, document);
    return response.data;
  };

  sendDocument = async (
    document: Interfaces.ISendDocumentRequest,
  ): Promise<Interfaces.ISendDocumentResponse> => {
    const response = await axiosInstance.post(Endpoints.SEND_DOCUMENT, document);
    return response.data;
  };

  documentSearch = async (fetchbody: Interfaces.IDocumentSearchRequest) => {
    const response = await axiosInstance.post(Endpoints.DOCUMENT_SEARCH, fetchbody);
    return response.data;
  };

  putDocument = async (
    documentId: number,
    document: Interfaces.IUpdateDocumentRequest,
  ): Promise<Interfaces.IUpdateDocumentResponse> => {
    const response = await axiosInstance.put(
      Endpoints.UPDATE_DOCUMENT.replace('{document}', documentId.toString()),
      document,
    );
    return response.data;
  };

  patchDocument = async (
    documentId: number,
    document: Interfaces.IUpdateDocumentRequest,
  ): Promise<Interfaces.IUpdateDocumentResponse> => {
    const response = await axiosInstance.patch(
      Endpoints.UPDATE_DOCUMENT.replace('{document}', documentId.toString()),
      document,
    );
    return response.data;
  };

  deleteDocument = async (documentId: number): Promise<Interfaces.IDeleteDocumentResponse> => {
    const response = await axiosInstance.delete(
      Endpoints.DELETE_DOCUMENT.replace('{document}', documentId.toString()),
    );
    return response.data;
  };

  restoreDocument = async (documentId: number): Promise<Interfaces.IRestoreDocumentResponse> => {
    const response = await axiosInstance.post(
      Endpoints.RESTORE_DOCUMENT.replace('{document}', documentId.toString()),
    );
    return response.data;
  };

  fetchDocumentComments = async (): Promise<Interfaces.IDocumentCommentSearchResponse> => {
    const response = await axiosInstance.post(Endpoints.DOCUMENT_COMMENT_SEARCH);
    return response.data;
  };

  createDocumentComment = async (
    documentComment: Interfaces.ICreateDocumentCommentRequest,
  ): Promise<Interfaces.ICreateDocumentCommentResponse> => {
    const response = await axiosInstance.post(Endpoints.CREATE_DOCUMENT_COMMENT, documentComment);
    return response.data;
  };

  putDocumentComment = async (
    documentId: number,
    commentId: number,
    document: Interfaces.IUpdateDocumentCommentRequest,
  ): Promise<Interfaces.IUpdateDocumentCommentResponse> => {
    const response = await axiosInstance.put(
      Endpoints.UPDATE_DOCUMENT_COMMENT.replace('{document}', documentId.toString()).replace(
        '{comment}',
        commentId.toString(),
      ),
      document,
    );
    return response.data;
  };

  patchDocumentComment = async (
    documentId: number,
    commentId: number,
    document: Interfaces.IUpdateDocumentCommentRequest,
  ): Promise<Interfaces.IUpdateDocumentCommentResponse> => {
    const response = await axiosInstance.patch(
      Endpoints.UPDATE_DOCUMENT_COMMENT.replace('{document}', documentId.toString()).replace(
        '{comment}',
        commentId.toString(),
      ),
      document,
    );
    return response.data;
  };

  deleteDocumentComment = async (
    documentId: number,
    commentId: number,
  ): Promise<Interfaces.IDeleteDocumentCommentResponse> => {
    const response = await axiosInstance.delete(
      Endpoints.DELETE_DOCUMENT_COMMENT.replace('{client}', documentId.toString()).replace(
        '{comment}',
        commentId.toString(),
      ),
    );
    return response.data;
  };
}
