import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { UsersRes } from '@/client_v2/rest/types/UsersRes';
import { API_USERS, DISABLE_PAGINATIONS_QUERY } from '@/client_v2/rest/Users/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';

export const getUsers = () =>
  axiosInstance.get<UsersRes>(
    createUrl(API_USERS, {
      queries: {
        [DISABLE_PAGINATIONS_QUERY]: '1',
      },
    }),
  );
