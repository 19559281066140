import '@/components/CheckBox/style.scss';
import { FC, MouseEventHandler } from 'react';
import Icon from '@/components/Icon';
import { cn, CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('components-check-box');
const icon = scope.and('icon');
const pointer = scope.and('pointer');
const noCursorPointer = scope.and('noCursorPointer');
const notAllowed = scope.and('notAllowed');

export const typeMap = {
  checked: 'check',
  halfChecked: 'minus',
  lock: 'lock',
  notChecked: 'none',
} as const;

export interface Props {
  type: keyof typeof typeMap;
  children?: never;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  colorStyle?: 'lightGray' | 'secondaryDark' | 'primary' | 'primaryLight' | 'danger' | 'success';
  cursor?: 'none' | 'pointer' | 'notAllowed';
  id?: string;
}

function getCursorType(cursor: string) {
  switch (cursor) {
    case 'pointer':
      return pointer;
    case 'none':
      return noCursorPointer;
    case 'notAllowed':
      return notAllowed;
    default:
      return pointer;
  }
}

export const CheckBox: FC<Props> = ({ type, onClick, colorStyle, cursor = 'pointer', id }) => (
  <span
    className={cn(scope, getCursorType(cursor), scope.and(type)) + ' ' + colorStyle}
    onClick={onClick}
    id={id ? id : `checkbox-${colorStyle ? colorStyle : 'default'}`}
  >
    {type !== 'notChecked' && (
      <Icon
        type={typeMap[type]}
        backgroundColorStyle="transparent"
        colorStyle="white"
        className={icon}
      />
    )}
  </span>
);
