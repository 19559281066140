import '@/components/DropDownBox/style.scss';
import { FC, ReactNode, Ref, TransitionEventHandler } from 'react';
import { cn, CreateScopeCSS } from '@/components/utils';
import Portal from '@/components/Portal';
import Card from '@/components/Card';
import ScrollBox from '@/components/ScrollBox';

const scope = CreateScopeCSS('components-drop-down-box');
const decor = scope.and('decor');
const defaultValue = scope.and('defaultValue');
const itemsContainer = scope.and('itemsContainer');
const item = scope.and('item');
const separator = scope.and('separator');
const itemContent = scope.and('itemContent');

interface Props {
  maxHeight?: string;
  defaultItem?: ReactNode;
  items: ReactNode[];
  refContainer?: Ref<HTMLDivElement>;
  onClickDefault?(): void;
  onClickItem(idx: number): void;
  onTransitionEnd: TransitionEventHandler<HTMLDivElement>;
  show?: boolean;
  render?: boolean;
  x: number;
  y: number;
}

export const DropDownBox: FC<Props> = ({
  maxHeight = 'fit-content',
  defaultItem,
  refContainer,
  items,
  x,
  y,
  show,
  render,
  children,
  onClickDefault,
  onClickItem,
  onTransitionEnd,
}) => (
  <div ref={refContainer}>
    {children}
    {render && (
      <Portal x={x} y={y}>
        <div
          className={cn(scope.and('hide'), scope.and(show && 'show'))}
          onTransitionEnd={onTransitionEnd}
        >
          <Card bgColorStyle="white" rounded shadow>
            <div className={scope}>
              {defaultItem && (
                <>
                  <div className={defaultValue} onClick={onClickDefault}>
                    {defaultItem}
                  </div>
                  <div className={decor} />
                </>
              )}
              <ScrollBox maxHeight={maxHeight} contentClassName={itemsContainer}>
                {items.map((child, idx) => (
                  <div
                    key={idx}
                    className={item}
                    onClick={onClickItem ? () => onClickItem(idx) : undefined}
                  >
                    <div className={itemContent}>{child}</div>
                    {items.length - 1 !== idx && <div className={separator} />}
                  </div>
                ))}
              </ScrollBox>
            </div>
          </Card>
        </div>
      </Portal>
    )}
  </div>
);
