import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { CommentsRes } from '@/client_v2/rest/types/CommentsRes';
import { API_COMMENTS, DISABLE_PAGINATIONS_QUERY } from '@/client_v2/rest/Comments/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';

export const getComments = () =>
  axiosInstance.get<CommentsRes>(
    createUrl(API_COMMENTS, {
      queries: {
        [DISABLE_PAGINATIONS_QUERY]: '1',
      },
    }),
  );
