import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SVGLogo from '@/resources/SVG/SVGLogo';
import Input from '@/screens/LoginScreen/components/Input';
import { AppState } from '@/redux/store';
import URLS from '@/resources/constants/URLS';
import { samePassword, validateEmail, validatePassword } from '@/resources/utils';
import { useAsyncCallback } from '@/hooks/useAsyncCallback';
import { createOnError } from '@/redux/message/message.callback';
import RestClient from '@/client_v2/rest';

const ResetPasswordScreen: React.FC = () => {
  const { t } = useTranslation();

  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [success, setSuccess] = useState(false);
  const { isLoading, errorMessage } = useSelector((state: AppState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && !errorMessage && success) {
      navigate(URLS.root.login.toString());
    }
  }, [success, isLoading, errorMessage, navigate]);

  const [save] = useAsyncCallback(
    async () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const tokenParam = urlParams.get('token');
      const emailParam = urlParams.get('email');

      if (!tokenParam) {
        throw t('error.missingParameter', { PARAM: 'Token' });
      }

      if (!emailParam) {
        throw t('error.missingParameter', { PARAM: 'Email' });
      }

      if (!password) {
        throw t('error.missingParameter', { PARAM: t('password') });
      }

      if (!passwordConfirm) {
        throw t('error.missingParameter', { PARAM: t('passwordConfirm') });
      }
      if (!validatePassword(password)) {
        throw t('error.passwordNotValid');
      }

      if (!samePassword(password, passwordConfirm)) {
        throw t('error.passwordsArentSame');
      }

      if (!validateEmail(emailParam)) {
        throw t('error.emailInvalidFormat');
      }
      try {
        await RestClient.Auth.resetPassword({
          password,
          passwordConfirm,
          token: tokenParam,
          email: emailParam,
        });
        setSuccess(true);
      } catch (e) {
        throw e.response.data.message;
      }
    },
    createOnError(dispatch),
    [password, passwordConfirm],
  );

  const onResetPassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    save();
  };
  return (
    <div className="container-fluid loginContainer mr-0">
      <div className="row">
        <div className="col-sm d-flex flex-column justify-content-center align-items-center loginPanelLeft">
          <h2>{t('resetPassword.resetPasswordHeading')}</h2>
          <small className="text-light text-center w-75">
            {t('resetPassword.resetPasswordSubheading')}
          </small>
          <form
            className="col-5 d-flex flex-column align-items-stretch my-4"
            onSubmit={onResetPassword}
          >
            <Input
              label={t('common.password')}
              id="password"
              type="password"
              aria-describedby="password"
              error={!!errorMessage}
              placeholder={t('common.password')}
              name="email"
              value={password}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setPassword(e.currentTarget.value)
              }
            />
            <Input
              label={t('common.confirmPassword')}
              id="passwordConfirm"
              type="password"
              aria-describedby="passwordConfirm"
              error={!!errorMessage}
              placeholder={t('common.confirmPassword')}
              name="email"
              value={passwordConfirm}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setPasswordConfirm(e.currentTarget.value)
              }
            />
            <button type="submit" className="btn btn-primary mt-4">
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                t('resetPassword.submit')
              )}
            </button>
          </form>
        </div>
        <div className="col-sm loginPanelRight d-flex justify-content-between align-items-center p-0">
          <div className="hidden-md-down" id="triangleLogin" />
          <SVGLogo className="col-7" />
          <div className="col-1" />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordScreen;
