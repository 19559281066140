import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { TagRes } from '@/client_v2/rest/types/TagsRes';
import {
  $DOCUMENT_ID_PARAM,
  $TAG_ID_PARAM,
  API_DOCUMENT_$DOCUMENT_ID_TAGS_$TAGID,
} from '@/client_v2/rest/Tags/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';
import { TagPoll } from '@/client_v2/polls/Tag.poll';

export const deleteTag = async (documentId: number, tagId: number) => {
  const res = axiosInstance.delete<TagRes>(
    createUrl(API_DOCUMENT_$DOCUMENT_ID_TAGS_$TAGID, {
      params: {
        [$DOCUMENT_ID_PARAM]: documentId.toString(),
        [$TAG_ID_PARAM]: tagId.toString(),
      },
    }),
  );
  await TagPoll.trigger();
  return res;
};
