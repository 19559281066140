import '@/layouts/DashboardTableLayout/style.scss';
import { FC, ReactNode } from 'react';
import { cn, CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('layouts-dashboard-table-layout');
const titleClass = scope.and('title');
const subTitleClass = scope.and('subTitle');
const tableItemsClass = scope.and('tableItems');
const tableHelperClass = scope.and('tableHelper');
const redirectClass = scope.and('redirectClass');

interface Props {
  title: ReactNode;
  subTitle: ReactNode;
  table?: ReactNode;
  tableHelper?: ReactNode;
  redirectToProcess?: ReactNode;
}

export const DashboardTableLayout: FC<Props> = ({
  title,
  subTitle,
  table,
  tableHelper,
  redirectToProcess,
}) => {
  return (
    <div className={scope}>
      <div className={titleClass}>{title}</div>
      <div className={subTitleClass}>{subTitle}</div>
      <div className={cn(tableItemsClass)}>{table}</div>
      {table && (
        <>
          <div className={tableHelperClass}>{tableHelper}</div>
          <div className={redirectClass}>{redirectToProcess}</div>
        </>
      )}
    </div>
  );
};
