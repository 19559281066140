import {
  DocumentsActionTypes,
  UPLOAD_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE,
  GET_DOCUMENT_TABLE_REQUEST,
  GET_DOCUMENT_TABLE_SUCCESS,
  GET_DOCUMENT_TABLE_FAILURE,
  EXPAND_DOCUMENT_TABLE,
  SET_DOCUMENT_SCROLLBAR_POSITION,
} from '@/redux/documents/documents.types';
import { IDocumentsState } from '@/redux/documents/documents.interfaces';

const defaultDocumentsState: IDocumentsState = {
  isLoading: false,
  errorMessage: null,
  uploadedDocument: null,
  data: [],
  expand: false,
  scrollbarPosition: undefined,
};

export default (state = defaultDocumentsState, action: DocumentsActionTypes): IDocumentsState => {
  switch (action.type) {
    case UPLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        uploadedDocument: action.payload,
      };
    case UPLOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error.message,
        uploadedDocument: null,
      };
    case GET_DOCUMENT_TABLE_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case GET_DOCUMENT_TABLE_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isLoading: false,
        data: action.payload,
      };
    case GET_DOCUMENT_TABLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error.message,
      };
    case EXPAND_DOCUMENT_TABLE:
      return {
        ...state,
        expand: action.payload.expand,
      };

    case SET_DOCUMENT_SCROLLBAR_POSITION:
      return {
        ...state,
        scrollbarPosition: action.payload,
      };

    default:
      return state;
  }
};
