import '@/layouts/CustomerTableHeaderLayout/style.scss';
import { FC, ReactNode } from 'react';
import { cn, CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('layouts-customer-table-header-layout');

interface Props {
  headerTitle: ReactNode;
  modifyButton: ReactNode;
  taxNumber: ReactNode;
  contactText: ReactNode;
}

export const CustomerTableHeaderLayout: FC<Props> = ({
  headerTitle,
  modifyButton,
  taxNumber,
  contactText,
}) => (
  <div className={cn(scope, 'container-fluid')}>
    <div className="row text-nowrap">
      <div className="col-md-12 ">
        <div className="row ml-2 mt-4 mr-3 align-items-center">
          <div className="col-md-10">{headerTitle}</div>
          <div className="col-md-2 d-flex flex-nowrap justify-content-end">{modifyButton}</div>
        </div>
        <div className="row mt-3 mb-3">
          <div className="col-12">
            <div className="border-bottom" />
          </div>
        </div>
        <div className="row ml-1 mt-4 mr-md-3 mb-md-3 mb-3 mr-0 align-items-center">
          <div className="col-md-6 col-12 d-flex flex-nowrap justify-content-start">
            {taxNumber}
          </div>
          <div className={cn('col-md-6 d-flex flex-nowrap col-12 mt-md-0 mt-2')}>{contactText}</div>
        </div>
      </div>
    </div>
  </div>
);
