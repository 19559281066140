import { FC, ReactNode } from 'react';
import { CreateScopeCSS, cn } from '@/components/utils';
import '@/layouts/AddUserLayout/style.scss';

const scope = CreateScopeCSS('layouts-add-user-layout');

interface AddUserLayoutProps {
  userImage?: ReactNode;
  imageDescription?: ReactNode;
  imageTypeDescription?: ReactNode;
  uploadBtn?: ReactNode;
  nameInputField?: ReactNode;
  emailInputField?: ReactNode;
  phoneNumberInputField?: ReactNode;
  positionInputField?: ReactNode;
  saveUserButton?: ReactNode;
  clientDropdownMenuHeader?: ReactNode;
  clientDropdownMenu?: ReactNode;
  companyDropdownMenuHeader?: ReactNode;
  companyDropdownMenu?: ReactNode;
  permissionDropdownMenuHeader?: ReactNode;
  permissionDropdownMenu?: ReactNode;
  image?: boolean;
}

export const AddUserLayout: FC<AddUserLayoutProps> = ({
  userImage,
  imageDescription,
  imageTypeDescription,
  uploadBtn,
  nameInputField,
  emailInputField,
  phoneNumberInputField,
  positionInputField,
  saveUserButton,
  clientDropdownMenuHeader,
  clientDropdownMenu,
  companyDropdownMenuHeader,
  companyDropdownMenu,
  permissionDropdownMenuHeader,
  permissionDropdownMenu,
  image,
}) => (
  <div className={cn(scope, 'text-nowrap')}>
    <div className="row">
      <div className="col-md-12">
        <div className="row justify-content-center">
          <div className="col-md-12 col-sm-0 col-lg-6">
            <div className="row">
              <div className="col-md-12 mb-md-3 mb-0 mb-0">{nameInputField}</div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-md-1 mb-4 mt-0">{emailInputField}</div>
            </div>
            <div className="row">
              <div className="col-md-12">{permissionDropdownMenuHeader}</div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-4">{permissionDropdownMenu}</div>
            </div>
            <div className="row">
              <div className="col-md-12">{clientDropdownMenuHeader}</div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-3">{clientDropdownMenu}</div>
            </div>
          </div>
          <div className="col-md-12 col-sm-12 col-lg-6">
            <div className="row">
              <div className="col-md-12 mb-md-0 mb-0">
                <div className="row mt-0 mt-md-2 ml-md-0 mb-2 ml-2">
                  {image && <div className="col-md-2 col-lg-3">{userImage}</div>}
                  <div className={`col-md-${image ? '5' : '7'} col-lg-${image ? '5' : '7'}`}>
                    <div className="row ">
                      <div className="col-md-12 d-inline-block text-truncate">
                        {imageDescription}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 d-inline-block text-truncate">
                        {imageTypeDescription}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 align-self-center">{uploadBtn}</div>
                </div>
              </div>
            </div>
            <div className="row custom-margin-1">
              <div className="col-md-12 mb-md-1 mt-md-1 mb-0 mt-0">{phoneNumberInputField}</div>
            </div>
            <div className="row mt-md-4 mb-md-0 mb-0 mt-0">
              <div className="col-md-12 mb-md-4 mb-4">{positionInputField}</div>
            </div>
            <div className="row mt-md-0 pt-md-2 pt-0 mt-1">
              <div className="col-md-12">{companyDropdownMenuHeader}</div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-md-4 mb-2">{companyDropdownMenu}</div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-12 mt-4 mb-4 d-flex justify-content-lg-start justify-content-center">
            <div>{saveUserButton}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
