import '@/components/Collapse/style.scss';
import { FC, useEffect, useState } from 'react';
import { cn, CreateScopeCSS } from '@/components/utils';
import Icon from '@/components/Icon';

const scope = CreateScopeCSS('components-collapse');

interface Props {
  className?: string;
  onClick?: () => void;
  isOpened?: boolean;
}

export const Collapse: FC<Props> = ({ children, className, onClick, isOpened }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    isOpened && setShow(isOpened);
  }, [isOpened]);

  return (
    <div className={cn(scope, className)} onClick={onClick}>
      <div className="collapse show" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li className={`nav-item dropdown ${show ? 'show' : ''}`}>
            <Icon
              type="chevronUp"
              colorStyle="lightGray"
              className={`ml-1 collapseIcon float-right ${show ? 'rotate' : 'rotate down'}`}
              onClick={() => setShow(!show)}
            />
            <div
              className="mt-2 dropdown-menu dropdown-menu-right w-auto show p-0"
              id="navbarDropdown"
              aria-labelledby="navbarDropdownLbl"
            >
              {children}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
