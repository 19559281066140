import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@/components/Button';
import Text from '@/components/Text';
import UsersHeaderLayout from '@/layouts/UsersHeaderLayout';
import { ModalBox } from '@/components/ModalBox';
import AddUserModal from '@/screens/AccountSettings/Contents/AddUserModal';
import { AppState } from '@/redux/store';
import { expandDocumentTable } from '@/redux/documents/documents.actions';
import { setModalOpen } from '@/redux/core/core.actions';
import { resetSortAction } from '@/redux/sort/sort.actions';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';

const useUsersHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state: AppState) => state.user.user);
  const isAdmin = user?.authority == Authorities.Admin || user?.authority == Authorities.SuperAdmin;

  const { errorMessage, userCreated, isLoading } = useSelector((state: AppState) => state.user);

  useEffect(() => {
    if (!isLoading && (errorMessage || userCreated)) {
      setShowModal(false);
    }
  }, [errorMessage, isLoading, userCreated]);

  useEffect(() => {
    dispatch(expandDocumentTable({ expand: false }));
    dispatch(resetSortAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setModalOpen(showModal));
  }, [dispatch, showModal]);

  return {
    isAdmin,
    user,
    title: t('nav.users'),
    description: t('accountSettings.header.users.description'),
    newUser: t('accountSettings.header.users.newUser'),
    headerText: t('accountSettings.header.users.newUser'),
    showModal,
    setShowModal,
    isLoading,
  };
};

export const UsersHeader: FC = () => {
  const { isAdmin, description, title, newUser, showModal, setShowModal, headerText } =
    useUsersHeader();

  return (
    <div>
      <UsersHeaderLayout
        title={<Text type="style13">{title}</Text>}
        description={<Text type="style25">{description}</Text>}
        btn={
          isAdmin && (
            <Button
              size="medium"
              icon="userPlus"
              iconSize="large"
              paddingSize="large"
              roundedLarge
              onClick={() => setShowModal(true)}
              id="openAddUserModalButton"
            >
              {newUser}
            </Button>
          )
        }
      />
      {showModal && (
        <ModalBox
          headerText={headerText}
          show={showModal}
          modalWidth="wide"
          onClickClose={() => setShowModal(false)}
          id="userModal"
        >
          <AddUserModal close={() => setShowModal(false)} />
        </ModalBox>
      )}
    </div>
  );
};
