import { Dispatch } from 'redux';
import { IGetBusinessAreaData } from '@/client_v2/db/repositories/BusinessArea.repo';
import {
  IDocumentDateFilters,
  IDocumentFilters,
  IProcessFilters,
} from './sortAndFilters.interfaces';
import {
  CREATE_SIDEBARFILTER,
  MODIFY_SIDEBARFILTER,
  RESET_DOCUMENT_FILTER,
  SET_DOCUMENT_DATE_FILTER,
  SET_DOCUMENT_FILTER,
  SET_PROCESS_FILTER,
} from './sortAndFilters.types';

export const setProcessFilter = (filters: IProcessFilters) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_PROCESS_FILTER,
    payload: filters,
  });
};
export const setDocumentFilter = (filters: IDocumentFilters) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_DOCUMENT_FILTER,
    payload: filters,
  });
};
export const resetDocumentFilter = (filters?: IDocumentFilters) => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_DOCUMENT_FILTER,
    payload: filters,
  });
};

export const setDocumentDateFilter = (filters: IDocumentDateFilters) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_DOCUMENT_DATE_FILTER,
    payload: filters,
  });
};

export const createSidebarFilterData =
  (filters: IGetBusinessAreaData[]) => (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_SIDEBARFILTER,
      payload: filters.map((item) => {
        return { ...item, isChecked: true };
      }),
    });
  };

export const modifySidebarFilterData = (filter: IGetBusinessAreaData[]) => (dispatch: Dispatch) => {
  dispatch({
    type: MODIFY_SIDEBARFILTER,
    payload: filter,
  });
};
