import { ClientPoll } from '@/client_v2/polls/Client.poll';
import { BusinessAreaPoll } from '@/client_v2/polls/BusinessArea.poll';
import { UserPoll } from '@/client_v2/polls/User.poll';
import { DocumentPoll } from '@/client_v2/polls/Document.poll';
import { CommentPoll } from '@/client_v2/polls/Comment.poll';
import { WorkflowStepPoll } from '@/client_v2/polls/WorkflowStep.poll';
import { WorkflowMilePoll } from '@/client_v2/polls/WorkflowMile.poll';
import { WorkflowPoll } from '@/client_v2/polls/Workflow.poll';
import { WorkflowRepo, IDataArg } from '@/client_v2/db/repositories/Workflow.repo';
import { createDataHook } from '@/client_v2/hooks/utils/createDataHook';
import { ClientRepo } from '@/client_v2/db/repositories/Client.repo';
import { CompanyRepo } from '@/client_v2/db/repositories/Company.repo';

export const useWorkflowData = createDataHook({
  polls: [
    BusinessAreaPoll,
    ClientPoll,
    UserPoll,
    DocumentPoll,
    CommentPoll,
    WorkflowStepPoll,
    WorkflowMilePoll,
    WorkflowPoll,
  ],
  dataFactory: async (paramData: IDataArg) => ({
    workflowData: await WorkflowRepo.query('get-workflow-data', paramData),
    clients: await ClientRepo.query('get-add-or-modify-user-modal-data'),
    companies: await CompanyRepo.query('get-add-or-modify-user-modal-data'),
  }),
});
