export enum Authorities {
  JuniorUser = 'junior-user',
  ClientUser1 = 'client-user1',
  ClientUser2 = 'client-user2',
  Admin = 'admin',
  SuperAdmin = 'super-admin',
}

export interface Authority {
  value: string;
  text: string;
}

export interface IAuthorities {
  name: string;
  value: Authorities;
}

export interface Client {
  clientId: number | null;
  clientName: string;
}

export interface Company {
  companyId: number | null;
  companyName: string;
}

export interface IUserData {
  name: string;
  email: string;
  authority: string;
  position: string;
  phone: string;
  clientId: number | null;
  file: File | null;
}

export interface DropdownItem {
  value: number;
  name: string;
}
