import { setError } from '@/redux/message/message.action';
import { store } from '@/redux/store';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IPollHandler {
  trigger: () => Promise<void>;
  start: () => Promise<void>;
  stop: () => void;
  isRunning: () => boolean;
  onUpdateEnd: (cb: () => void) => () => void;
  sync: () => Promise<void>;
}

export function createDataSync({ delay, id }: { delay: number; id: string }) {
  const setLastPoll: () => number = () => {
    const lastUpdate = Date.now();
    localStorage.setItem(`poll:${id}`, lastUpdate.toString());
    return lastUpdate;
  };
  const getLastPoll: () => number = () => +localStorage.getItem(`poll:${id}`)!;

  const builder = {
    init(initCallback: () => Promise<void>) {
      return {
        poll<Data>(
          pollCallback: (
            getLastPoll: () => number,
          ) => Promise<{ update: false } | { update: true; data: Data }>,
        ) {
          return {
            update(doCallback: (data: Data) => Promise<void>) {
              const subscribers: (() => void)[] = [];
              const trigger = () => action();

              const start = async () => {
                if (getLastPoll() === 0) {
                  try {
                    await initCallback();
                    setLastPoll();
                  } catch (e) {
                    setError(`Something went wrong with ${id}s data still loading...`)(
                      store.dispatch,
                    );
                    await start();
                    return;
                  }
                }
                if (action._isRunning) return;
                action._isRunning = true;
                action._TID = setInterval(action, delay);
              };

              const sync = async () => {
                await initCallback();
                if (action._isRunning) return;
                action._isRunning = true;
                action._TID = setInterval(action, delay);
              };

              const stop = () => {
                if (!action._isRunning) return;
                action._isRunning = false;
                clearInterval(action._TID);
              };

              const isRunning = () => {
                return action._isRunning;
              };

              const onUpdateEnd = (cb: () => void) => {
                subscribers.push(cb);
                const unsubscribe = () => {
                  const idx = subscribers.indexOf(cb);
                  if (idx > -1) {
                    subscribers.splice(idx, 1);
                  }
                };
                return unsubscribe;
              };

              const action = async () => {
                const wasRunning = isRunning();
                if (wasRunning) stop();
                const res = await pollCallback(getLastPoll);
                if (res.update) {
                  await doCallback(res.data);
                  setLastPoll();
                  subscribers.forEach((cb) => cb());
                }
                if (wasRunning) start();
              };
              action._TID = null! as any;
              action._isRunning = false;
              return {
                trigger,
                start,
                stop,
                isRunning,
                onUpdateEnd,
                sync,
              } as IPollHandler;
            },
          };
        },
      };
    },
  };

  return builder;
}
