import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import {
  API_COMMENTS_$ENTITY_TIPE_$ENTITY_ID_COMMENTS_$COMMENT_ID,
  $ENTITY_TIPE_PARAM,
  $COMMENT_ID,
  $ENTITY_ID_PARAM,
} from '@/client_v2/rest/Comments/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';
import { CommentPoll } from '@/client_v2/polls/Comment.poll';
import { Entities } from '@/client_v2/rest/types/Entities';

interface DeleteComment {
  entityType: Entities;
  entityId: number;
  commentId: number;
}

export const deleteComment = async ({ entityType, entityId, commentId }: DeleteComment) => {
  const res = await axiosInstance.delete(
    createUrl(API_COMMENTS_$ENTITY_TIPE_$ENTITY_ID_COMMENTS_$COMMENT_ID, {
      params: {
        [$ENTITY_TIPE_PARAM]: entityType,
        [$ENTITY_ID_PARAM]: entityId.toString(),
        [$COMMENT_ID]: commentId.toString(),
      },
    }),
  );
  await CommentPoll.trigger();
  return res;
};
