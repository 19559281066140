/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createWhereQuery } from '@/client_v2/db/utils/createWhereQuery';
import { creatRepo } from '@/client_v2/db/utils/createRepo';
import { User } from '@/client_v2/rest/types/UsersRes';
import { Client } from '@/client_v2/rest/types/ClientsRes';

export interface IGetUsersData {
  id: number;
  name: string;
  email: string;
  authority: string;
  lastLogIn: Date;
  isBlocked: boolean;
  clients: number[] | null;
  companies: number[] | null;
  createdAt: Date;
}

export interface IGetModifyUserModalData {
  id: number;
  name: string;
  email: string;
  phone: string;
  position: string;
  authority: string;
  profileImageUrl: string;
  clients: number[] | null | [];
  companies: number[] | null | [];
}

export interface IGetCompanyModalData {
  id: number;
  name: string;
  profileImageUrl: string;
}
export interface IGetCustomerModalData {
  id: number;
  name: string;
  authority: string;
}

export const UserRepo = creatRepo<User>({
  name: 'user',
  model: {
    'id:number': { pk: true },
    'name:string': {},
    'email:string': {},
    'authority:string': {},
    'position:string': {},
    'last_login:date': {},
    'phone:string': {},
    'status:string': {},
    'is_contact:bool': {},
    'email_verified_at:string': {},
    'is_blocked:bool': {},
    'deleted_at:date': {},
    'profile_image:string': {},
    'profile_image_url:string': {},
    'created_at:date': {},
    'updated_at:date': {},
    'clients:number[]': { default: [] },
    'companies:number[]': { default: [] },
  },
})
  .queryName('get-users-data')
  .handler(async (table): Promise<IGetUsersData[]> => {
    const res: IGetUsersData[] = (await table()
      .query('select', [
        'id',
        'name',
        'email',
        'authority',
        'last_login AS lastLogIn',
        'is_blocked AS isBlocked',
        'created_at AS createdAt',
        'clients',
        'companies',
      ])
      .graph({
        key: 'clientsDetails',
        with: { table: 'client' },
        select: ['id AS clientId', 'name AS clientName'],
        on: createWhereQuery<Client, { user: User }>(({ users_ids }, { user }) =>
          users_ids.includes(user.id),
        ),
      })
      .exec()) as IGetUsersData[];

    res.sort((a, b) => {
      return b.createdAt.valueOf() - a.createdAt.valueOf();
    });

    return res;
  })
  .queryName('get-users-list')
  .handler((table): Promise<IGetUsersData[]> => {
    return table().query('select', ['id', 'name']).exec() as Promise<IGetUsersData[]>;
  })
  .queryName('get-modify-user-modal-data')
  .handler(
    async (table, { userId }: { userId: number }): Promise<IGetModifyUserModalData | undefined> => {
      return (
        (await table()
          .query('select', [
            'id',
            'name',
            'email',
            'phone',
            'position',
            'authority',
            'profile_image_url AS profileImageUrl',
            'clients',
            'companies',
          ])
          .where(['id', '=', userId])
          .exec()) as IGetModifyUserModalData[]
      ).pop();
    },
  )
  .queryName('get-company-modal-data')
  .handler((table): Promise<IGetCompanyModalData[]> => {
    return table()
      .query('select', ['id', 'name', 'profile_image_url AS profileImageUrl'])
      .exec() as Promise<IGetCompanyModalData[]>;
  })
  .queryName('get-filtered-contact-data')
  .handler((table): Promise<IGetCustomerModalData[]> => {
    return table()
      .query('select', ['id', 'name', 'authority'])
      .where([
        ['authority', '=', 'admin'],
        'OR',
        ['authority', '=', 'super-admin'],
        'OR',
        ['authority', '=', 'junior-user'],
      ])
      .exec() as Promise<IGetCustomerModalData[]>;
  })
  .make();
