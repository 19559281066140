import React, { ReactElement } from 'react';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  children?: React.ReactChildren;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  id?: string;
  error?: boolean;
}

const Input = ({ label, id, error, children, ...inputProps }: InputProps): ReactElement => {
  return (
    <div className="form-group">
      {label && (
        <label htmlFor={id} className={`form-label-sm ${error && 'text-danger'}`}>
          {label}
        </label>
      )}
      <input className={`form-control ${error ? 'is-invalid' : ''}`} {...inputProps}>
        {children}
      </input>
    </div>
  );
};

export default Input;
