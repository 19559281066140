import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { BusinessAreasRes } from '@/client_v2/rest/types/BusinessAreasRes';
import { API_BUSINESS_AREA, DISABLE_PAGINATIONS_QUERY } from '@/client_v2/rest/BusinessAreas/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';

export const getBusinessAreas = () =>
  axiosInstance.get<BusinessAreasRes>(
    createUrl(API_BUSINESS_AREA, {
      queries: {
        [DISABLE_PAGINATIONS_QUERY]: '1',
      },
    }),
  );
