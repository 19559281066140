import { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '@/redux/store';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import ErrorScreen from '@/screens/ErrorScreen';
interface AdminRouteProps {
  component: ReactElement;
  hidden?: boolean;
}

export const AdminRoute: FC<AdminRouteProps> = ({ component, hidden }) => {
  const user = useSelector((state: AppState) => state.user);
  const isAdmin =
    user.user?.authority == Authorities.Admin || user.user?.authority == Authorities.SuperAdmin;
  return isAdmin ? component : hidden ? <></> : <ErrorScreen />;
};
