import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileDrop } from 'react-file-drop';
import Multiselect from 'multiselect-react-dropdown';
import Button from '@/components/Button';
import Text from '@/components/Text';
import UnderlineInput from '@/components/Input/UnderlineInput';
import CircleImage from '@/components/CircleImage';
import AddUserLayout from '@/layouts/AddUserLayout';
import { useClientsData } from '@/client_v2/hooks/useClientsData';
import { convertFileListToFileArr, validateEmail } from '@/resources/utils';
import ErrorFeedback from '@/components/ErrorFeedback';
import MultiselectDropdown from '@/components/MultiselectDropdown';
import { setError } from '@/redux/message/message.action';
import { PostUser } from '@/client_v2/rest/types/PostUser';
import { useAsyncCallback } from '@/hooks/useAsyncCallback';
import RestClient from '@/client_v2/rest';
import { createOnError } from '@/redux/message/message.callback';
import { useCompanyData } from '@/client_v2/hooks/useCompanyData';
import { AppState } from '@/redux/store';
import { setUsersScrollbarPosition } from '@/redux/users/users.actions';
import { Authorities, Authority, DropdownItem } from './interfaces';

interface Props {
  close(): void;
  companyId?: number;
  clientId?: number;
}

interface clientFilter {
  name: string;
  value: number;
}

interface companyFilter {
  name: string;
  value: number;
  clientId: number;
}

const useAddUserModal = ({ close, companyId: propCompanyId, clientId: propClientId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const nameInputTitle = t('accountSettings.contents.users.name');
  const emailInputTitle = t('accountSettings.contents.users.email');
  const phoneNumberInputTitle = t('accountSettings.contents.users.phoneNumber');
  const positionInputTitle = t('accountSettings.contents.users.position');
  const selectClientDropdownHeader = t('accountSettings.contents.users.client');
  const selectClientDropdownText = t('accountSettings.contents.users.selectClient');
  const selectCompanyDropdownHeader = t('accountSettings.contents.users.company');
  const selectCompanyDropdownText = t('accountSettings.contents.users.selectCompany');
  const permissionDropdownHeader = t('accountSettings.contents.users.permission');
  const permissionDropdownHeaderText = t('accountSettings.contents.users.selectPermission');
  const uploadButtonText = t('upload.buttonText');
  const newUserButtonText = t('accountSettings.contents.users.createUser');
  const profileImageText = t('accountSettings.contents.users.profileImageDescription');
  const profileImageTypeText = t('accountSettings.contents.users.imageTypeDescription');

  const [showDropdwon, setShowDropdown] = useState(false);
  const [showAuthorities, setShowAuthorities] = useState(false);
  const [showClients, setShowClients] = useState(false);
  const [selectedPic, setSelectedPic] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [clientList, setClientList] = useState<clientFilter[] | undefined>();
  const [companyList, setCompanyList] = useState<companyFilter[] | undefined>();
  const [filteredCompanyList, setFilteredCompanyList] = useState<companyFilter[] | undefined>();
  const [clientsLoading, clients] = useClientsData({
    renderOnUpdate: false,
    triggerOnce: true,
    onError: (err) => dispatch(setError(err.toString())),
  });

  const userAuthority = useSelector((state: AppState) => state.user.user?.authority);

  const [companiesLoading, companiesData] = useCompanyData({
    renderOnUpdate: true,
    triggerOnce: true,
    onError: (err) => dispatch(setError(err.toString())),
  });

  const [userForm, setUserForm] = useState<PostUser>({
    data: {
      name: '',
      clientIds: propClientId ? [propClientId] : [],
      companyIds: propCompanyId ? [propCompanyId] : [],
      email: '',
      phone: '',
      position: '',
      authority: '',
    },
  });

  const onChangeName = (value: string) => {
    setUserForm({ ...userForm, data: { ...userForm.data, name: value } });
  };

  const onChangeEmail = (value: string) => {
    setUserForm({ ...userForm, data: { ...userForm.data, email: value } });
    if (validateEmail(value)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  };

  const onChangePhone = (value: string) => {
    setUserForm({ ...userForm, data: { ...userForm.data, phone: value } });
  };

  const onChangePosition = (value: string) => {
    setUserForm({ ...userForm, data: { ...userForm.data, position: value } });
  };

  const onChangeAuthority = (value: Authority) => {
    if (!propCompanyId || !propClientId) {
      setFilteredCompanyList(filteredCompanyId([]));
      setUserForm({
        ...userForm,
        data: { ...userForm.data, authority: value.value, clientIds: [], companyIds: [] },
      });
      multiselectRef.current?.resetSelectedValues();
    } else {
      setUserForm({
        ...userForm,
        data: { ...userForm.data, authority: value.value },
      });
    }
  };

  const onChangeClient = (values: number[]) => {
    setUserForm({ ...userForm, data: { ...userForm.data, clientIds: values, companyIds: [] } });
  };

  const onChangeCompanies = (values: number[]) => {
    setUserForm({ ...userForm, data: { ...userForm.data, companyIds: values } });
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const file = convertFileListToFileArr(files!)[0];
    if (file) {
      setUserForm({ ...userForm, file });
      setSelectedPic(URL.createObjectURL(file));
    }
  };

  const onFileSelectClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const onTargetClick = () => {
    fileInputRef.current!.click();
  };

  const filteredCompanyId = (clientIds: number[]) => {
    if (clientIds && companyList) {
      return companyList.filter((item: companyFilter) => clientIds.includes(item.clientId));
    } else {
      return clientIds ? companyList : [];
    }
  };

  const filteredClientId = (companyId: number | null) => {
    if (!companyId) {
      setFilteredCompanyList(filteredCompanyId(userForm?.data?.clientIds || []));
    }
    const company = companyList?.find((company) => company.value === companyId);
    const clients = clientList?.filter((client) => {
      return client.value === company?.clientId;
    });

    return companyId ? clients : [];
  };

  useEffect(() => {
    const clientList = clients
      ? clients!.clients.map((client) => {
          return {
            name: client.name,
            value: client.id,
          };
        })
      : [];
    const companyList: companyFilter[] = companiesData
      ? companiesData.companies?.map((company) => {
          return {
            name: company.name,
            value: company.id,
            clientId: company.clientId,
          };
        })
      : [];
    setCompanyList(companyList);
    setClientList(clientList);
  }, [propCompanyId, companiesData, propClientId, clients]);

  useEffect(() => {
    if (!propCompanyId && !propClientId) {
      filteredCompanyId([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companiesData]);

  useEffect(() => {
    if (!userForm.data.clientIds && !userForm.data.companyIds && !propClientId && !propCompanyId) {
      setFilteredCompanyList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userForm.data.clientIds, clientList, userForm.data.companyIds]);

  const authorityList = [
    {
      name: t('authorities.admin'),
      value: Authorities.Admin,
    },
    userAuthority === Authorities.SuperAdmin && {
      name: t('authorities.superAdmin'),
      value: Authorities.SuperAdmin,
    },
    {
      name: t('authorities.juniorUser'),
      value: Authorities.JuniorUser,
    },
    {
      name: t('authorities.clientUser1'),
      value: Authorities.ClientUser1,
    },
    {
      name: t('authorities.clientUser2'),
      value: Authorities.ClientUser2,
    },
  ].filter(Boolean);

  const modalValidation =
    userForm.data.name &&
    userForm.data.email &&
    isEmailValid &&
    userForm.data.authority &&
    userForm.data.clientIds?.length;

  const [upload, isLoading, res] = useAsyncCallback(
    RestClient.Users.post,
    createOnError(dispatch),
    [dispatch],
  );

  useEffect(() => {
    if (res) {
      close();
    }
  }, [close, res]);

  const canHaveMultipleClients =
    userForm.data.authority && !['client-user1', 'client-user2'].includes(userForm.data.authority);

  const multiselectRef = React.useRef<Multiselect>(null);

  const [allSelectedCompanies, setAllSelectedCompanies] = useState<number[]>([]);

  useEffect(() => {
    userForm?.data?.companyIds?.length &&
      setAllSelectedCompanies(allSelectedCompanies.concat(userForm.data.companyIds));
    // must be disabled to prevent an infitine loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAllSelectedCompanies, userForm.data.companyIds]);

  const selectedCompanyOptions =
    allSelectedCompanies &&
    filteredCompanyList &&
    filteredCompanyList.filter(
      (filteredCompany: companyFilter) =>
        userForm?.data?.companyIds?.includes(filteredCompany.value) ||
        allSelectedCompanies.includes(filteredCompany.value),
    );

  return {
    nameInputTitle,
    emailInputTitle,
    phoneNumberInputTitle,
    positionInputTitle,
    selectClientDropdownHeader,
    selectClientDropdownText,
    selectCompanyDropdownHeader,
    selectCompanyDropdownText,
    permissionDropdownHeader,
    permissionDropdownHeaderText,
    uploadButtonText,
    newUserButtonText,
    profileImageText,
    profileImageTypeText,
    showDropdwon,
    setShowDropdown,
    userForm,
    setUserForm,
    onChangeName,
    onChangeEmail,
    onFileSelectClick,
    fileInputRef,
    onChangePhone,
    onChangePosition,
    authorityList,
    showAuthorities,
    setShowAuthorities,
    onChangeAuthority,
    clientList,
    filteredCompanyList,
    showClients,
    setShowClients,
    onChangeClient,
    onChangeCompanies,
    clientsLoading,
    companiesLoading,
    dispatch,
    clients,
    onFileInputChange,
    onTargetClick,
    selectedPic,
    isEmailValid,
    emailErrorText: t('error.emailFormatError'),
    modalValidation,
    isLoading,
    upload,
    propCompanyId,
    setFilteredCompanyList,
    filteredCompanyId,
    filteredClientId,
    propClientId,
    canHaveMultipleClients,
    multiselectRef,
    selectedCompanyOptions,
  };
};

export const AddUserModal: FC<Props> = (props: Props) => {
  const {
    nameInputTitle,
    emailInputTitle,
    phoneNumberInputTitle,
    positionInputTitle,
    selectClientDropdownHeader,
    selectClientDropdownText,
    selectCompanyDropdownHeader,
    selectCompanyDropdownText,
    permissionDropdownHeaderText,
    uploadButtonText,
    newUserButtonText,
    profileImageText,
    profileImageTypeText,
    userForm,
    onChangeName,
    onChangeEmail,
    fileInputRef,
    onFileSelectClick,
    onChangePhone,
    onChangePosition,
    authorityList,
    onFileInputChange,
    onTargetClick,
    selectedPic,
    isEmailValid,
    emailErrorText,
    modalValidation,
    onChangeClient,
    onChangeCompanies,
    filteredCompanyList,
    isLoading,
    upload,
    propCompanyId,
    propClientId,
    setFilteredCompanyList,
    filteredCompanyId,
    dispatch,
    canHaveMultipleClients,
    clientList,
    multiselectRef,
    onChangeAuthority,
    selectedCompanyOptions,
  } = useAddUserModal(props);

  const { name, email, phone, position } = userForm.data;
  return (
    <AddUserLayout
      image={!!userForm.file}
      nameInputField={
        <UnderlineInput
          titleText={nameInputTitle}
          onChange={onChangeName}
          value={name || ''}
          required
          id="userNameInput"
        />
      }
      emailInputField={
        <>
          <UnderlineInput
            titleText={emailInputTitle}
            onChange={onChangeEmail}
            value={email || ''}
            error={!isEmailValid}
            required
            id="userEmailInput"
          />
          {!isEmailValid && <ErrorFeedback errorMessage={emailErrorText} />}
        </>
      }
      clientDropdownMenuHeader={
        !propCompanyId && (
          <Text type="style7" required>
            {selectClientDropdownHeader}
          </Text>
        )
      }
      clientDropdownMenu={
        !propCompanyId && (
          <MultiselectDropdown
            avoidHighlightFirstOption
            options={clientList}
            isDisabled={!!(propClientId && propCompanyId)}
            title={selectClientDropdownText}
            selectedOptions={propClientId || undefined}
            onSelectItem={(e: DropdownItem[]) => {
              onChangeClient(e.map((e) => e.value));
              setFilteredCompanyList(filteredCompanyId(e.map((e) => e.value)));
            }}
            singleSelect={!canHaveMultipleClients}
            maxSelection={99}
            onRemoveItem={(e: DropdownItem[]) => {
              onChangeClient(e.map((e) => e.value));
              setFilteredCompanyList(filteredCompanyId(e.map((e) => e.value)));
            }}
            directRef={multiselectRef}
            id="userClient"
          />
        )
      }
      companyDropdownMenuHeader={
        !propCompanyId && <Text type="style7">{selectCompanyDropdownHeader}</Text>
      }
      companyDropdownMenu={
        !propCompanyId && (
          <MultiselectDropdown
            avoidHighlightFirstOption
            options={filteredCompanyList}
            isDisabled={!!(propClientId && propCompanyId)}
            selectedOptions={selectedCompanyOptions}
            title={selectCompanyDropdownText}
            maxSelection={canHaveMultipleClients ? 99 : 5}
            onSelectItem={(e: DropdownItem[]) => {
              const cIds = e.map((e) => e.value);
              onChangeCompanies(cIds);
            }}
            onRemoveItem={(e: DropdownItem[]) => {
              const cIds = e.map((e) => e.value);
              onChangeCompanies(cIds);
            }}
            id="userCompany"
          />
        )
      }
      permissionDropdownMenu={
        <MultiselectDropdown
          options={authorityList}
          avoidHighlightFirstOption
          title={permissionDropdownHeaderText}
          singleSelect={true}
          maxSelection={1}
          onSelectItem={(e: Authority[]) => onChangeAuthority(e[0])}
          onRemoveItem={() => onChangeAuthority({ value: '', text: '' })}
          id="userPermission"
        />
      }
      permissionDropdownMenuHeader={
        <Text type="style7" required>
          {permissionDropdownHeaderText}
        </Text>
      }
      userImage={<CircleImage size="medium" image={selectedPic} />}
      imageDescription={<Text type="style7">{profileImageText}</Text>}
      imageTypeDescription={<Text type="style6">{profileImageTypeText}</Text>}
      uploadBtn={
        <>
          <Button size="small" paddingSize="small" onClick={onFileSelectClick}>
            {uploadButtonText}
          </Button>
          <FileDrop onTargetClick={onTargetClick}>
            <></>
          </FileDrop>
          <input
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            id="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={onFileInputChange}
          />
        </>
      }
      phoneNumberInputField={
        <UnderlineInput
          titleText={phoneNumberInputTitle}
          onChange={onChangePhone}
          value={phone || ''}
          max={20}
        />
      }
      positionInputField={
        <UnderlineInput
          titleText={positionInputTitle}
          onChange={onChangePosition}
          value={position || ''}
        />
      }
      saveUserButton={
        <Button
          size="small"
          paddingSize="large"
          roundedLarge
          iconSize="large"
          icon="plus"
          disabled={!modalValidation}
          onClick={() => {
            dispatch(setUsersScrollbarPosition(undefined));
            upload(userForm);
          }}
          loadingSpinner={isLoading}
          id="userSubmitButton"
        >
          {newUserButtonText}
        </Button>
      }
    />
  );
};

export default AddUserModal;
