import React, { ReactElement } from 'react';

interface ErrorFeedbackProps extends React.InputHTMLAttributes<HTMLInputElement> {
  errorMessage: string | null;
}

const ErrorFeedback = ({ errorMessage }: ErrorFeedbackProps): ReactElement => {
  return (
    <div className="text-danger">
      <small>{errorMessage}</small>
    </div>
  );
};

export default ErrorFeedback;
