import '@/layouts/ProcessesTableLayout/style.scss';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { CreateScopeCSS } from '@/components/utils';
import Text from '@/components/Text';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import { ModalBox } from '@/components/ModalBox';
import ProcessCreateModal from '@/screens/ProcessesScreen/components/ProcessCreateModal';

import Tooltip from '@/components/Tooltip';
import { AppState } from '@/redux/store';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import { setModalOpen } from '@/redux/core/core.actions';

const scope = CreateScopeCSS('layouts-processes-table-header');
const titleClass = scope.and('title');

interface Props {
  processTitle: string;
  serial: number; // id of Process
  client?: string;
  company?: string;
  fromDate: string | null;
  toDate: string | null;
  stepsNotChecked?: boolean;
}

export const ProcessesTableHeader: FC<Props> = ({
  processTitle,
  serial,
  fromDate,
  toDate,
  client,
  company,
  stepsNotChecked,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dateFormat = t('common.dateFormat');

  const [showProcessModal, setShowProcessModal] = useState<boolean>(false);
  const workflowInProgressText = t('processesScreen.process.inProgress');
  const user = useSelector((state: AppState) => state.user.user);
  const AuthorisedEntities = [Authorities.Admin, Authorities.SuperAdmin, Authorities.JuniorUser];

  useEffect(() => {
    dispatch(setModalOpen(showProcessModal));
  }, [dispatch, showProcessModal]);

  return (
    <div className={scope}>
      <div className="container-fluid">
        <div className="row d-flex align-items-center text-nowrap pt-3 pl-3">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-3 col-6">
                <Text type="style34" className={titleClass}>
                  {processTitle} {stepsNotChecked}
                </Text>
              </div>
              <div className="col-lg-1 col-6 d-flex align-items-center justify-content-lg-start justify-content-end pr-3 pr-lg-0">
                <Text type="style6">#{serial}</Text>
              </div>
              <div className="col-lg-4 col-6 d-flex align-items-center">
                <Icon type="user" colorStyle="lightGray" className="d-flex" />
                <Text type="style6" className="ml-1 d-flex">
                  {client} {company ? `/ ${company}` : ''}
                </Text>
              </div>
              <div className="col-lg-2 col-6 d-flex align-items-center justify-content-end">
                <Icon type="calendar" colorStyle="lightGray" className="d-flex" />
                <Text type="style6" className="ml-1 d-flex">
                  {dayjs(fromDate).format(dateFormat)} - {dayjs(toDate).format(dateFormat)}
                </Text>
              </div>

              {AuthorisedEntities.includes(user?.authority as Authorities) && (
                <div className="col-lg-2 col-6 d-flex justify-content-end">
                  {stepsNotChecked ? (
                    <Button
                      size="small"
                      className="modifyButton"
                      iconSize="medium"
                      icon="pencil"
                      onClick={() => {
                        setShowProcessModal(true);
                      }}
                    >
                      <Text className="d-flex" type="style17">
                        {t('processesScreen.process.modification')}
                      </Text>
                    </Button>
                  ) : (
                    <Tooltip bubbleContent={workflowInProgressText} place="top">
                      <Button
                        size="small"
                        className="modifyButton"
                        iconSize="medium"
                        icon="pencil"
                        cursor={'none'}
                        disabled
                      >
                        <Text className="d-flex" type="style17">
                          {t('processesScreen.process.modification')}
                        </Text>
                      </Button>
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showProcessModal && (
        <ModalBox
          headerText={t('processesScreen.modal.editProcess')}
          show={showProcessModal}
          modalWidth="wide"
          onClickClose={() => setShowProcessModal(false)}
        >
          <ProcessCreateModal
            onClose={() => setShowProcessModal(false)}
            processId={serial}
            stepsNotChecked={stepsNotChecked}
          />
        </ModalBox>
      )}
    </div>
  );
};
