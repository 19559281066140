import { FC, ReactNode } from 'react';
import { CreateScopeCSS, cn } from '@/components/utils';
import '@/layouts/DocumentsContentLayout/style.scss';

const scope = CreateScopeCSS('layouts-documents-content-layout');

interface Props {
  downloadButton?: ReactNode;
  sendButton?: ReactNode;
  modifyButton?: ReactNode;
  deleteButton?: ReactNode;
  approveDocumentButton?: ReactNode;
  declineDocumentButton?: ReactNode;
}

export const DocumentsContentLayout: FC<Props> = ({
  downloadButton,
  sendButton,
  modifyButton,
  deleteButton,
  approveDocumentButton,
  declineDocumentButton,
}) => (
  <div className={cn(scope)}>
    <div className="row text-nowrap">
      <div className="col-lg-12">
        <div className="pb-4">
          <div className="d-flex justify-content-lg-around  flex-wrap align-items-center mt-lg-4">
            <div className="p-1">{downloadButton}</div>
            <div className="p-1">{approveDocumentButton}</div>
            <div className="p-1">{declineDocumentButton}</div>
            <div className="p-1">{sendButton}</div>
            <div className="p-1">{modifyButton}</div>
            <div className="p-1">{deleteButton}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
