import '@/screens/DashboardScreen/Sidebar/style.scss';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@/components/Card';
import CircleImage from '@/components/CircleImage';
import Text from '@/components/Text';
import { cn, CreateScopeCSS } from '@/components/utils';
import { SidebarFilter } from '@/screens/common/SidebarFilter';
import { useDashboardContactsData } from '@/client_v2/hooks/useDashboardContactsData';
import { createOnError } from '@/redux/message/message.callback';
import { IGetContactDataDashboard } from '@/client_v2/db/repositories/Company.repo';
import { AppState } from '@/redux/store';

export const contactCard = (
  index: number,
  data?: IGetContactDataDashboard,
  contentWidth?: string,
  type?: string,
) => {
  const imageUrl = data?.profileImageUrl
    ? `${process.env.REACT_APP_API_BASE_URL}${data?.profileImageUrl}`
    : '';

  return (
    <Card
      key={`${type}-${index}`}
      bgColorStyle="light"
      rounded
      paddingH="small"
      paddingV="small"
      className={`sidebarCard ${contentWidth}`}
    >
      <CircleImage
        image={imageUrl}
        smallText={data?.name
          .split('   ')
          .map((c) => c[0])
          .join('')
          .toUpperCase()}
        size="medium"
      />
      <Text className="d-flex justify-content-center text-nowrap" type="style5">
        {data?.name}
      </Text>
      <Text className="d-flex justify-content-center mb-1 text-nowrap" type="style14">
        {data?.position}
      </Text>
      <Text className="d-flex justify-content-center pb-2" type="style14">
        {data?.email}
      </Text>
      <Text className="d-flex justify-content-center" type="style14">
        {data?.phone}
      </Text>
    </Card>
  );
};

export const DashboardSidebar: FC = () => {
  const scope = CreateScopeCSS('screens-dashboard-screen-sidebar');
  const contentWidth = CreateScopeCSS(scope.and('contentWidth'));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.user);

  const selectedCompanyId = useSelector((state: AppState) => state.user.selectedCompanyId);
  const selectedClientId = useSelector((state: AppState) => state.user.selectedClientId);
  const [contactPersons, setContactPersons] = useState<IGetContactDataDashboard[]>();

  const [, tableData, trigger] = useDashboardContactsData({
    triggerOnce: {
      clientId: selectedClientId && selectedClientId,
      companyId: selectedCompanyId && selectedCompanyId,
    },
    renderOnUpdate: true,
    onError: createOnError(dispatch),
  });

  useEffect(() => {
    trigger({
      clientId: selectedClientId && selectedClientId,
      companyId: selectedCompanyId && selectedCompanyId,
    });
  }, [selectedClientId, selectedCompanyId, trigger, user?.user?.clients, user?.user?.companies]);

  useEffect(() => {
    const uniqueContacts: IGetContactDataDashboard[] = [];
    if (tableData) {
      [...tableData?.clientContacts, ...tableData?.companyContacts].map((item) => {
        if (!uniqueContacts.find((uItem) => uItem.id === item.id)) {
          uniqueContacts.push(item);
        }
      });
    }

    setContactPersons(uniqueContacts);
  }, [tableData, selectedCompanyId]);

  return (
    <div className={cn(scope, 'h-100 pb-3')}>
      <Card
        bgColorStyle="light"
        rounded
        paddingH="small"
        paddingV="small"
        className={`${contentWidth} sidebarFilterCard`}
      >
        <SidebarFilter title={t('sidebarFilter.businessAreas')} />
      </Card>

      {contactPersons &&
        contactPersons.map((contact, index) =>
          contactCard(index, contact, contentWidth, 'company'),
        )}
    </div>
  );
};
