import '@/components/CheckBox/style.scss';
import { FC, useEffect, useState } from 'react';
import Icon from '@/components/Icon';
import { cn, CreateScopeCSS } from '@/components/utils';
import Text from '@/components/Text';
import { IGetWorkflowMiles } from '@/client_v2/db/repositories/Workflow.repo';

const scope = CreateScopeCSS('components-navtab');

const statusMap = {
  done: 'done',
  failed: 'overdue',
  inProgress: 'in_progress',
  later: 'ready_to_start',
};

interface Props {
  tabs: IGetWorkflowMiles[];
  setTab: (value: number) => void;
  selectedTab: number | null;
  isSelectedProcess: boolean;
}

export const NavTab: FC<Props> = ({ tabs, setTab, selectedTab, isSelectedProcess }) => {
  const [isActiveTab, setIsActiveTab] = useState<number | null>(
    tabs[0].mileId && tabs[0] ? tabs[0].mileId : null,
  );

  useEffect(() => {
    if (selectedTab && isSelectedProcess) {
      setIsActiveTab(selectedTab);
      setTab(selectedTab);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ul className={cn('nav nav-tabs', scope)}>
      {tabs &&
        tabs.map((tab: IGetWorkflowMiles) => {
          return (
            <li className="nav-item" key={tab.mileId}>
              <a
                className={`d-flex nav-link bg-transparent
                ${isActiveTab && isActiveTab === tab.mileId ? 'active' : ''}
                `}
                onClick={() => {
                  setIsActiveTab(tab.mileId);
                  setTab(tab.mileId);
                }}
              >
                <Text type="style19">{tab.mileName}</Text>
                {tab.status === statusMap.done && (
                  <Icon type="checkCircle" colorStyle="success" className="ml-1" />
                )}
                {tab.status === statusMap.failed && (
                  <Icon type="xCircle" colorStyle="danger" className="ml-1" />
                )}
                {tab.status === statusMap.inProgress && (
                  <Icon type="skipForwardCircle" colorStyle="primary" className="ml-1" />
                )}
                {tab.status === statusMap.later && (
                  <Icon type="skipForwardCircle" colorStyle="primary" className="ml-1" />
                )}
              </a>
            </li>
          );
        })}
    </ul>
  );
};
