import { ISortState } from './sort.interfaces';
import {
  RESET_SORT_ACTION,
  SET_DOCUMENTS_SORT,
  SET_PROCESS_SORT,
  SortActionTypes,
} from './sort.type';

const defaultSortState: ISortState = {
  processSort: {
    name: '',
    direction: 'desc',
    field: 'createdAt',
  },
  documentSort: {
    name: '',
    direction: 'asc',
    field: 'companyName',
  },
};

export default (state = defaultSortState, action: SortActionTypes): ISortState => {
  switch (action.type) {
    case SET_PROCESS_SORT:
      return {
        ...state,
        processSort: action.payload,
      };
    case SET_DOCUMENTS_SORT:
      return {
        ...state,
        documentSort: action.payload,
      };
    case RESET_SORT_ACTION:
      return {
        ...state,
        processSort: { name: '', direction: 'desc', field: 'createdAt' },
        documentSort: { name: '', direction: 'desc', field: 'createdAt' },
      };
    default:
      return state;
  }
};
