import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IPollHandler } from '@/client_v2/util/createDataSync';
import { AppState } from '@/redux/store';

enum HookStates {
  INIT = 'init',
  TRIGGER = 'trigger',
  LOADING = 'loading',
  DONE = 'done',
  ERROR = 'error',
  SYNC = 'sync',
}

export function createDataHook<R, A = void>({
  polls,
  dataFactory,
}: {
  polls: IPollHandler[];
  dataFactory: (arg: A) => Promise<R>;
}) {
  return ({
    renderOnUpdate,
    triggerOnce,
    onError,
  }: {
    renderOnUpdate: boolean;
    triggerOnce: A extends void | undefined ? boolean : A;
    /**
     * The error: any argument must be any because the dataFactory function can throw any kin of error type
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError?(error?: any): void;
  }) => {
    const [state, setState] = useState<HookStates>(HookStates.INIT);
    const [triggerArg, setTriggerArg] = useState<A>(triggerOnce as A);
    const ref = useRef<R>(null!);
    const [hasTriggeredFirst, setHasTriggeredFirst] = useState<boolean>(true);

    const isModalOpen = useSelector((state: AppState) => state.core.isOpenModal);

    const trigger = useCallback(
      (arg: A extends void ? void : A) => {
        setTriggerArg(arg as A);
        setState(HookStates.TRIGGER);
      },
      [setState, setTriggerArg],
    );

    const sync = useCallback(
      (arg: A extends void ? void : A) => {
        setTriggerArg(arg as A);
        setState(HookStates.SYNC);
      },
      [setState, setTriggerArg],
    );

    useEffect(() => {
      switch (state) {
        case HookStates.INIT:
          setState(HookStates.LOADING);
          (async () => {
            await Promise.all(polls.map((poll) => poll.start()));
            setState(triggerArg ? HookStates.TRIGGER : HookStates.DONE);
          })();
          break;
        case HookStates.SYNC:
          setState(HookStates.LOADING);
          (async () => {
            await Promise.all(polls.map((poll) => poll.sync()));
            setState(triggerArg ? HookStates.TRIGGER : HookStates.DONE);
          })();
          break;
        case HookStates.TRIGGER:
          setState(HookStates.LOADING);
          (async () => {
            try {
              ref.current = await dataFactory(
                (typeof triggerArg === 'boolean' ? undefined : triggerArg) as A,
              );
              setState(HookStates.DONE);
            } catch (error) {
              setState(HookStates.ERROR);
              if (onError) onError(error);
              else throw error;
            }
          })();
          break;
        case HookStates.DONE:
          setHasTriggeredFirst(false);
          break;
        default:
          break;
      }
    }, [state, setState, ref, triggerArg, onError, setHasTriggeredFirst]);

    useEffect(() => {
      if (state === HookStates.INIT || !renderOnUpdate) return;
      const unsubscribers = polls.map((poll) =>
        poll.onUpdateEnd(() => {
          setState(HookStates.TRIGGER);
        }),
      );
      return () => unsubscribers.forEach((unsubscriber) => unsubscriber());
    }, [state, renderOnUpdate]);

    useEffect(() => {
      return () => {
        setState(HookStates.DONE);
      };
    }, []);

    const isLoading = (hasTriggeredFirst || !isModalOpen) && state !== HookStates.DONE;
    return [isLoading, ref.current, trigger, sync] as const;
  };
}
