import '@/components/MessageToast/style.scss';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CreateScopeCSS } from '@/components/utils';
import Icon from '@/components/Icon';
import { clearMessage } from '@/redux/message/message.action';
import { AppState } from '@/redux/store';

const scope = CreateScopeCSS('components-message-toast');
const messageWrapper = scope.and('messageWrapper');
const toastPosition = scope.and('messageWrapper');

/**
 *
 * @param children string or null as per the error/success message in the store.
 * @returns A toast message with the passed children as the error/success message.
 */
export const MessageToast: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { message, isError } = useSelector((state: AppState) => state.error);

  const errorTextHeader = t('error.generalError');
  const successTextHeader = t('error.success');

  const onClose = () => {
    dispatch(clearMessage());
  };

  useEffect(() => {
    const tid = setTimeout(() => {
      dispatch(clearMessage());
    }, 1000 * 10);
    return () => clearTimeout(tid);
  }, [message, dispatch]);

  return message ? (
    <div aria-live="polite" aria-atomic="true" className={`${messageWrapper}`}>
      <div className={`toast ${toastPosition} show`}>
        <div className="toast-header">
          <Icon
            colorStyle={isError ? 'danger' : 'success'}
            className="mr-3"
            type={isError ? 'alertCircle' : 'checkCircle'}
          />
          <strong className="mr-auto">{isError ? errorTextHeader : successTextHeader}</strong>
          <button
            type="button"
            onClick={onClose}
            className="ml-2 mb-1 close"
            data-dismiss="toast"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="toast-body">{message}</div>
      </div>
    </div>
  ) : null;
};
