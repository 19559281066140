import { Dispatch } from 'redux';
import client from '@/client';
import {
  GET_COMPANY_LIST_REQUEST,
  GET_COMPANY_LIST_SUCCESS,
  GET_COMPANY_LIST_FAILURE,
  DELETE_COMPANY_FAILURE,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_SUCCESS,
  EDIT_COMPANY_FAILURE,
  EDIT_COMPANY_REQUEST,
  EDIT_COMPANY_SUCCESS,
  SET_COLLAPSE_COMPANY_ID,
  SET_COMPANY_SCROLLBAR_POSITION,
} from '@/redux/companyManagement/companyManagement.types';
import RestClient from '@/client_v2/rest';
import { PatchCompany } from '@/client_v2/rest/types/PatchCompany';

export const getCompanyList = () => async (dispatch: Dispatch) => {
  dispatch({
    type: GET_COMPANY_LIST_REQUEST,
  });

  try {
    const res = await client.companyManagement.fetchCompanies();
    dispatch({
      type: GET_COMPANY_LIST_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: GET_COMPANY_LIST_FAILURE,
      error,
    });
  }
};
export const deleteCompany = (id: number) => async (dispatch: Dispatch) => {
  dispatch({
    type: DELETE_COMPANY_REQUEST,
  });

  try {
    await RestClient.Companies.delete(id);
    dispatch({
      type: DELETE_COMPANY_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DELETE_COMPANY_FAILURE,
      error,
    });
  }
};

export const editCompany = (company: PatchCompany) => async (dispatch: Dispatch) => {
  dispatch({
    type: EDIT_COMPANY_REQUEST,
  });

  try {
    await RestClient.Companies.patch(company);

    dispatch({
      type: EDIT_COMPANY_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EDIT_COMPANY_FAILURE,
      error,
    });
  }
};

export const setCollapseCompanyId = (id: number | null) => async (dispatch: Dispatch) =>
  dispatch({
    type: SET_COLLAPSE_COMPANY_ID,
    payload: id,
  });

export const setCompanyScrollbarPosition = (position: number | undefined) => (dispatch: Dispatch) =>
  dispatch({
    type: SET_COMPANY_SCROLLBAR_POSITION,
    payload: position,
  });
