import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { ClientsRes } from '@/client_v2/rest/types/ClientsRes';
import { API_CLIENTS_SEARCH } from '@/client_v2/rest/Clients/URLS';

export const getClientsByIds = (ids: number[]) =>
  axiosInstance.post<ClientsRes>(API_CLIENTS_SEARCH, {
    scopes: [
      {
        name: 'ids',
        parameters: [ids.toString()],
      },
    ],
  });
