import { getErrorMessage } from '@/resources/functions';
import { MessageState, CLEAR_MESSAGE, SET_ERROR, SET_SUCCESS } from './message.types';

const initialMessageState: MessageState = {
  message: null,
  isError: null,
};

/**
 * Shows a toast notification if there is an error/success message persist.
 *  All the error/success messages in the redux which should be shown in the error toast component MUST be passed as error in the dispatch.
 * The action is set to any, as the AppActionState includes phases where the error property is not presented.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (state = initialMessageState, action: any): MessageState => {
  if (action.error) {
    return {
      message: action?.error?.message,
      isError: true,
    };
  }

  if (action.type === CLEAR_MESSAGE) {
    return initialMessageState;
  }

  if (action.type === SET_ERROR) {
    return {
      message: getErrorMessage(action?.payload),
      isError: true,
    };
  }

  if (action.type === SET_SUCCESS) {
    return {
      message: action?.payload,
      isError: false,
    };
  }

  return state;
};
