import {
  UserActionTypes,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  BLOCK_USER_FAILURE,
  BLOCK_USER_REQUEST,
  BLOCK_USER_SUCCESS,
  UNBLOCK_USER_FAILURE,
  UNBLOCK_USER_REQUEST,
  UNBLOCK_USER_SUCCESS,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  SET_SELECTED_COMPANY_ID,
  SET_SELECTED_CLIENT_ID,
} from './user.types';
import { IuserState } from './user.interfaces';

const defaultUserState: IuserState = {
  isLoading: false,
  errorMessage: null,
  user: null,
  selectedCompanyId: null,
  selectedClientId: null,
  userCreated: null,
};

export default (state = defaultUserState, action: UserActionTypes): IuserState => {
  switch (action.type) {
    case GET_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        user: action.payload,
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error.message,
      };
    case DELETE_USER_REQUEST:
    case BLOCK_USER_REQUEST:
    case UNBLOCK_USER_REQUEST:
    case EDIT_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case DELETE_USER_SUCCESS:
    case BLOCK_USER_SUCCESS:
    case UNBLOCK_USER_SUCCESS:
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
      };
    case DELETE_USER_FAILURE:
    case BLOCK_USER_FAILURE:
    case UNBLOCK_USER_FAILURE:
    case EDIT_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error.message,
      };
    case CREATE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        userCreated: action.payload,
      };
    case CREATE_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error.message,
      };
    case SET_SELECTED_COMPANY_ID:
      return {
        ...state,
        selectedCompanyId: action.payload,
      };
    case SET_SELECTED_CLIENT_ID:
      return {
        ...state,
        selectedClientId: action.payload,
      };
    default:
      return state;
  }
};
