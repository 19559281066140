import { ICompaniesState } from '@/redux/companyManagement/companyManagement.interfaces';
import {
  CompaniesActionTypes,
  GET_COMPANY_LIST_REQUEST,
  GET_COMPANY_LIST_SUCCESS,
  GET_COMPANY_LIST_FAILURE,
  DELETE_COMPANY_FAILURE,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_SUCCESS,
  EDIT_COMPANY_FAILURE,
  EDIT_COMPANY_REQUEST,
  EDIT_COMPANY_SUCCESS,
  SET_COLLAPSE_COMPANY_ID,
  SET_COMPANY_SCROLLBAR_POSITION,
} from '@/redux/companyManagement/companyManagement.types';

const defaultCompaniesState: ICompaniesState = {
  isLoading: true,
  errorMessage: null,
  companiesData: null,
  collapseCompanyId: null,
  scrollbarPosition: undefined,
};

export default (state = defaultCompaniesState, action: CompaniesActionTypes): ICompaniesState => {
  switch (action.type) {
    case DELETE_COMPANY_REQUEST:
    case EDIT_COMPANY_REQUEST:
    case GET_COMPANY_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case GET_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        companiesData: action.payload,
      };
    case DELETE_COMPANY_FAILURE:
    case EDIT_COMPANY_FAILURE:
    case GET_COMPANY_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error.message,
      };
    case DELETE_COMPANY_SUCCESS:
    case EDIT_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
      };
    case SET_COLLAPSE_COMPANY_ID:
      return {
        ...state,
        collapseCompanyId: action.payload,
      };
    case SET_COMPANY_SCROLLBAR_POSITION:
      return {
        ...state,
        scrollbarPosition: action.payload,
      };
    default:
      return state;
  }
};
