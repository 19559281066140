import { ClientPoll } from '@/client_v2/polls/Client.poll';
import { ClientRepo } from '@/client_v2/db/repositories/Client.repo';
import { UserPoll } from '@/client_v2/polls/User.poll';
import { UserRepo } from '@/client_v2/db/repositories/User.repo';
import { createDataHook } from '@/client_v2/hooks/utils/createDataHook';
import { CompanyRepo } from '@/client_v2/db/repositories/Company.repo';

export const useModifyUserModalData = createDataHook({
  polls: [ClientPoll, UserPoll],
  dataFactory: async (arg: { userId: number }) => ({
    clients: await ClientRepo.query('get-add-or-modify-user-modal-data'),
    companies: await CompanyRepo.query('get-add-or-modify-user-modal-data'),
    authorities: [] as string[],
    user: await UserRepo.query('get-modify-user-modal-data', arg),
  }),
});
