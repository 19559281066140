import * as Endpoints from '@/client/endpoints';
import * as Interfaces from '@/client/userManagementInterfaces';
import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { IUsersResponse } from '@/client/types/IUsersResponse';

//USER MANAGEMENT CALLS
export class UserManagement {
  countStepsUser = async (user: number): Promise<Interfaces.ICountStepsUserResponse> => {
    const response = await axiosInstance.get(
      Endpoints.COUNT_STEPS_USER.replace('{user}', user.toString()),
    );
    return response.data;
  };

  fetchUsersSearch = async (
    fetchbody: Interfaces.IFetchUsersRequest,
  ): Promise<Interfaces.IFetchUserResponse> => {
    const response = await axiosInstance.post(Endpoints.FETCH_USERS_SEARCH, fetchbody);
    return response.data;
  };

  fetchUsers = async (): Promise<IUsersResponse> => {
    const response = await axiosInstance.get(Endpoints.FETCH_USERS);
    return response.data;
  };

  fetchUser = async (userId: number): Promise<Interfaces.IFetchUserResponse> => {
    const response = await axiosInstance.get(
      Endpoints.FETCH_USER.replace('{user}', userId.toString()),
    );
    return response.data;
  };

  putUser = async (userId: number, user: Interfaces.IUpdateUserRequestData) => {
    const response = await axiosInstance.put(
      Endpoints.UPDATE_USER.replace('{user}', userId.toString()),
      user,
    );
    return response.data;
  };

  patchUser = async (userId: number, user: Interfaces.IUpdateUserRequestData) => {
    const response = await axiosInstance.patch(
      Endpoints.UPDATE_USER.replace('{user}', userId.toString()),
      user,
    );
    return response.data;
  };

  deleteUser = async (userId: number): Promise<Interfaces.IDeleteUserResponse> => {
    const response = await axiosInstance.delete(
      Endpoints.DELETE_USER.replace('{user}', userId.toString()),
    );
    return response.data;
  };

  fetchSubordinatesSearch = async (): Promise<Interfaces.IFetchSubordinatesSearchResponse> => {
    //hianyzik a body
    const response = await axiosInstance.post(Endpoints.FETCH_SUBORDINATES_SEARCH);
    return response.data;
  };

  attachSubordinates = async (
    managerId: number,
    resources: Interfaces.IAttachSubordinateResponse,
  ) => {
    const response = await axiosInstance.post(
      Endpoints.ATTACH_SUBORDINATE.replace('{manager}', managerId.toString()),
      resources,
    );
    return response.data;
  };

  detachSubordinates = async (managerId: number) => {
    const response = await axiosInstance.delete(
      Endpoints.DETACH_SUBORDINATE.replace('{manager}', managerId.toString()),
    );
    return response.data;
  };

  fetchSubordinates = async (managerId: number): Promise<Interfaces.IFetchSubordinatesResponse> => {
    //hiányzik a body
    const response = await axiosInstance.get(
      Endpoints.FETCH_SUBORDINATES.replace('{manager}', managerId.toString()),
    );
    return response.data;
  };

  fetchManagersSearch = async (
    subordinateId: number,
  ): Promise<Interfaces.IFetchManagersSearchResponse> => {
    //hiányzik a body
    const response = await axiosInstance.post(
      Endpoints.FETCH_MANAGERS_SEARCH.replace('{subordinate}', subordinateId.toString()),
    );
    return response.data;
  };

  fetchManagers = async (subordinateId: number): Promise<Interfaces.IFetchManagersResponse> => {
    const response = await axiosInstance.get(
      Endpoints.FETCH_MANAGERS.replace('{subordinate}', subordinateId.toString()),
    );
    return response.data;
  };
}
