/* eslint-disable camelcase */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Card from '@/components/Card';
import Table from '@/components/Table';
import Text from '@/components/Text';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import { AppState } from '@/redux/store';
import { getUsers, setUsersScrollbarPosition } from '@/redux/users/users.actions';
import Icon from '@/components/Icon';
import { GetPropsType } from '@/types/GetPropType';
import { ModalBox } from '@/components/ModalBox';
import { blockUser, deleteUser, unblockUser } from '@/redux/user/user.actions';
import { sendForgotPassword } from '@/redux/auth/auth.actions';
import { useUserData } from '@/client_v2/hooks/useUserData';
import MessageToast from '@/components/MessageToast';
import EditUserModal from '@/screens/AccountSettings/Contents/EditUserModal';
import { IGetUsersData } from '@/client_v2/db/repositories/User.repo';
import { setError } from '@/redux/message/message.action';
import Button from '@/components/Button';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import URLS from '@/resources/constants/URLS';
import '@/styles/screens/AdminScreen/UsersScreen/index.scss';
import { resetDocumentFilter } from '@/redux/sortAndFilters/sortAndFilters.actions';
import { setModalOpen } from '@/redux/core/core.actions';
import { useClientsData } from '@/client_v2/hooks/useClientsData';

const useUsersContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.user.user);
  const { message } = useSelector((state: AppState) => state.error);
  const [showEditUserModal, setShowEditUserModal] = useState<boolean>(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState<boolean>(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState<boolean>(false);
  const [isLoading, data] = useUserData({
    renderOnUpdate: true,
    triggerOnce: true,
    onError: (err) => dispatch(setError(err.toString())),
  });

  useEffect(() => {
    dispatch(resetDocumentFilter());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setModalOpen(showEditUserModal));
  }, [dispatch, showEditUserModal]);

  const dateFormat = t('common.dateFormat');

  const [selectedUser, setSelectedUser] = useState<IGetUsersData | null>(null);

  const selectUser = (selectedId: number) => {
    const user = data.find(({ id }) => id === selectedId);
    setSelectedUser(user ? user : null);
  };

  const [, clientsData] = useClientsData({
    renderOnUpdate: true,
    triggerOnce: true,
    onError: (err) => dispatch(setError(err.toString())),
  });

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const renderDeleteUserModal = () => (
    <ModalBox
      headerText={t('common.areYouSure')}
      show={showDeleteUserModal}
      modalWidth="narrow"
      onClickClose={() => setShowDeleteUserModal(false)}
    >
      <Text type="style1">{t('accountSettings.contents.users.deleteUserModalText')}</Text>
      <div className="d-flex justify-content-around">
        <Button
          size="large"
          rounded
          className="my-4 mr-3"
          onClick={() => {
            dispatch(deleteUser(selectedUser!.id));
            setShowDeleteUserModal(!showDeleteUserModal);
          }}
          id="deleteUserConfirmation"
        >
          <Text className="px-2 py-1 d-flex" type="style24">
            {t('common.delete')}
          </Text>
        </Button>
        <Button
          size="large"
          rounded
          className="my-4 ml-3 btn-secondary"
          onClick={() => setShowDeleteUserModal(!showDeleteUserModal)}
        >
          <Text className="px-2 py-1 d-flex" type="style24">
            {t('common.cancel')}
          </Text>
        </Button>
      </div>
    </ModalBox>
  );

  const renderForgotPasswordModal = () => (
    <>
      <ModalBox
        headerText={t('companyScreen.confirmationPopup2')}
        show={showForgotPasswordModal}
        modalWidth="narrow"
        onClickClose={() => {
          setSelectedUser(null);
          setShowForgotPasswordModal(!showForgotPasswordModal);
        }}
      >
        <div className="col-12 mb-3">
          <Text type="style30">{t('accountSettings.contents.users.forgotPasswordModalText')}</Text>
        </div>
        <div className="d-flex justify-content-around">
          <Button
            size="large"
            rounded
            className="my-4 ml-4"
            onClick={() => {
              dispatch(sendForgotPassword(selectedUser!.email));
              setShowForgotPasswordModal(!showForgotPasswordModal);
            }}
          >
            <Text className="px-2 py-1 d-flex" type="style24">
              {t('accountSettings.contents.users.reset')}
            </Text>
          </Button>

          <Button
            size="large"
            rounded
            className="my-4 ml-4 btn-secondary"
            onClick={() => setShowForgotPasswordModal(!showForgotPasswordModal)}
          >
            <Text className="px-2 py-1 d-flex" type="style24">
              {t('common.cancel')}
            </Text>
          </Button>
        </div>
      </ModalBox>
    </>
  );

  const renderEditUserModal = () => (
    <>
      <ModalBox
        headerText={
          user?.authority === Authorities.Admin
            ? t('accountSettings.contents.users.showUser')
            : t('accountSettings.contents.users.editUser')
        }
        show={showEditUserModal}
        modalWidth="wide"
        onClickClose={() => {
          setSelectedUser(null);
          setShowEditUserModal(!showEditUserModal);
        }}
      >
        <EditUserModal
          isOpenedFromUsersPage
          userId={selectedUser!.id}
          close={() => setShowEditUserModal(false)}
        />
      </ModalBox>
    </>
  );

  const mappedUsers = useMemo(() => {
    const arr: (
      | string
      | {
          text: string;
          iconType: GetPropsType<typeof Icon>['type'];
          color: GetPropsType<typeof Icon>['colorStyle'];
          onClick(): void;
          isAuthority: boolean;
        }
    )[] = [];

    if (!isLoading)
      // eslint-disable-next-line camelcase
      data.forEach(({ name, email, authority, lastLogIn, id, isBlocked, clients }) => {
        arr.push(
          name,
          clientsData
            ? clientsData.clients
                .filter((clientData) => clients?.includes(clientData.id))
                .map((clientData) => clientData.name)
                .join(', ')
            : '',
          email,
          authority,
          lastLogIn
            ? dayjs(lastLogIn).format(dateFormat)
            : t('accountSettings.contents.users.noLastlogin'),
          {
            text:
              user?.authority === Authorities.Admin && authority === Authorities.SuperAdmin
                ? t('common.view')
                : t('common.edit'),
            iconType:
              user?.authority === Authorities.Admin && authority === Authorities.SuperAdmin
                ? 'user'
                : 'edit3',

            color: 'primary',
            onClick: () => {
              selectUser(id);
              setShowEditUserModal(true);
            },
            isAuthority:
              user?.authority === Authorities.Admin || user?.authority === Authorities.SuperAdmin,
          },
          {
            text: t(`common.${isBlocked ? 'unlock' : 'lock'}`),
            iconType: isBlocked ? 'lock' : 'unlock',
            color: isBlocked ? 'danger' : 'lightGray',
            onClick: () => dispatch(isBlocked ? unblockUser(id) : blockUser(id)),
            isAuthority:
              user?.authority === Authorities.Admin || user?.authority === Authorities.SuperAdmin,
          },
          {
            text: t('accountSettings.contents.users.passwordback'),
            iconType: 'key',
            color: 'lightGray',
            onClick: () => {
              selectUser(id);
              setShowForgotPasswordModal(!showForgotPasswordModal);
            },
            isAuthority:
              user?.authority === Authorities.Admin || user?.authority === Authorities.SuperAdmin,
          },
          {
            text: t('common.delete'),
            iconType: 'trash',
            color: 'danger',
            onClick: () => {
              selectUser(id);
              setShowDeleteUserModal(true);
            },
            isAuthority:
              user?.authority === Authorities.Admin || user?.authority === Authorities.SuperAdmin,
          },
        );
      });
    return arr;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  return {
    users: mappedUsers,
    isLoading,
    nameText: t('accountSettings.contents.users.name'),
    clientText: t('accountSettings.contents.users.client'),
    emailText: t('accountSettings.contents.users.email'),
    permissionText: t('accountSettings.contents.users.permission'),
    lastloginText: t('accountSettings.contents.users.lastlogin'),
    renderEditUserModal,
    showEditUserModal,
    renderDeleteUserModal,
    showDeleteUserModal,
    renderForgotPasswordModal,
    showForgotPasswordModal,
    message,
    dispatch,
  };
};

export const UsersContent: FC = () => {
  const {
    users,
    isLoading,
    clientText,
    emailText,
    lastloginText,
    nameText,
    permissionText,
    showEditUserModal,
    renderEditUserModal,
    renderDeleteUserModal,
    showDeleteUserModal,
    renderForgotPasswordModal,
    showForgotPasswordModal,
    message,
    dispatch,
  } = useUsersContent();

  const user = useSelector((state: AppState) => state.user.user);
  const navigate = useNavigate();

  const tableData = [
    {
      header: true,
      view: (
        <Text key="title-1" type="style34">
          {nameText}
        </Text>
      ),
    },
    {
      header: true,
      view: (
        <Text key="title-2" type="style34">
          {clientText}
        </Text>
      ),
    },
    {
      header: true,
      view: (
        <Text key="title-3" type="style34">
          {emailText}
        </Text>
      ),
    },
    {
      header: true,
      view: (
        <Text key="title-4" type="style34">
          {permissionText}
        </Text>
      ),
    },

    {
      header: true,
      view: <Text type="style34">{lastloginText}</Text>,
    },
    {
      header: true,
      view: <div />,
    },
    {
      header: true,
      view: <div />,
    },
    {
      header: true,
      view: <div />,
    },
    {
      header: true,
      view: <div />,
    },
    ...users.map((value, idx) => ({
      view:
        typeof value === 'string' ? (
          <Text type="style1" key={'text-' + idx}>
            {value}
          </Text>
        ) : (
          value &&
          value.isAuthority && (
            <div
              data-mdb-toggle="tooltip"
              key={'tooltip-' + idx}
              data-placement="top"
              title={value.text}
            >
              <Icon
                type={value.iconType}
                colorStyle={value.color}
                onClick={value.onClick}
                className="w-75"
              />
            </div>
          )
        ),
    })),
  ];

  useEffect(() => {
    if (user?.authority !== Authorities.Admin && user?.authority !== Authorities.SuperAdmin) {
      navigate(URLS.root.dashboard.toString());
    }
  }, [navigate, user?.authority]);

  const scrollDivRef = useRef<HTMLDivElement>(null);
  const scrollbarPosition = useSelector((state: AppState) => state.users.scrollbarPosition);

  useEffect(() => {
    if (!scrollbarPosition) {
      dispatch(setUsersScrollbarPosition(scrollDivRef.current?.scrollTop || 0));
    }
  }, [dispatch, scrollbarPosition]);

  useEffect(() => {
    if (scrollbarPosition) {
      scrollDivRef.current?.scrollTo(0, scrollbarPosition || 0);
    }
  }, [scrollbarPosition, isLoading]);

  return (
    <>
      <MessageToast>{message}</MessageToast>
      {showEditUserModal ? renderEditUserModal() : null}
      {showDeleteUserModal ? renderDeleteUserModal() : null}
      {showForgotPasswordModal ? renderForgotPasswordModal() : null}
      <LoadingPlaceholder showSpinner={true} isLoading={isLoading}>
        <div className="scope" ref={scrollDivRef}>
          <Card bgColorStyle="white" shadow rounded className="mb-5">
            <div className="scroll-tb">
              <Table column={9} data={tableData} />
            </div>
          </Card>
        </div>
      </LoadingPlaceholder>
    </>
  );
};
