import { FC } from 'react';
import { LoadingPlaceholder as View } from '@/components/LoadingPlaceholder/LoadingPlaceholder.view';
import { ComponentHook } from '@/types/ComponentHook';
import { useDinCSS } from '@/hooks/useDinCSS';

interface Props {
  isLoading?: boolean;
  width?: number | string;
  height?: number | string;
  showSpinner: boolean;
  className?: number | string;
}

export const useLoadingPlaceholder: ComponentHook<Props, typeof View, { isLoading: boolean }> = ({
  isLoading = true,
  width,
  height,
  showSpinner,
}) => {
  const [dynLoadingClassName, renderCSS] = useDinCSS();
  renderCSS({
    width: typeof width === 'number' ? `${width}px` : width,
    height: typeof height === 'number' ? `${height}px` : height,
    display: showSpinner ? 'flex' : 'none',
    className: '',
  });
  return {
    isLoading,
    dynLoadingClassName,
  };
};

export const LoadingPlaceholder: FC<Props> = ({ children, ...props }) => {
  const { isLoading, ...computedProps } = useLoadingPlaceholder(props);
  return <>{isLoading ? <View {...computedProps} /> : children}</>;
};
