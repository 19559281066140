import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { API_DOCUMENTS_SEND_APPROVAL } from '@/client_v2/rest/Documents/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';
import { DocumentRes } from '@/client_v2/rest/types/DocumentsRes';
import { DocumentPoll } from '@/client_v2/polls/Document.poll';

export const sendApprovalDocument = async (id: number) => {
  const res = await axiosInstance.post<DocumentRes>(createUrl(API_DOCUMENTS_SEND_APPROVAL), { id });
  await DocumentPoll.trigger();
  return res;
};
