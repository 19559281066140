import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppState } from '@/redux/store';
import DocumentContentLayout from '@/layouts/DocumentsContentLayout';
import Card from '@/components/Card';
import Button from '@/components/Button';
import Text from '@/components/Text';
import { DocumentListTable } from '@/screens/DocumentsScreen/Content/components/DocumentListTable/DocumentsListTable';
import { useDocumentData } from '@/client_v2/hooks/useDocumentData';
import { getFilteredBusinessAreas } from '@/resources/utils';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import { setError } from '@/redux/message/message.action';
import RestClient from '@/client_v2/rest';
import { createOnError } from '@/redux/message/message.callback';
import { useAsyncCallback } from '@/hooks/useAsyncCallback';
import { ModalBox } from '@/components/ModalBox';
import DocumentUploadModal from '@/screens/DocumentsScreen/components/DocumentUploadModal';
import DocumentTableContentLayout from '@/layouts/DocumentTableContentLayout';
import '@/styles/screens/DocumentScreen/index.scss';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import DeleteDocumentPopupLayout from '@/layouts/DeleteDocumentPopupLayout';
import { cn } from '@/components/utils';
import { setModalOpen } from '@/redux/core/core.actions';

const useDocumentScreenContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { sidebarFilters, documentFilters } = useSelector(
    (state: AppState) => state.sortAndFilters,
  );
  const { documentSort } = useSelector((state: AppState) => state.sort);
  const { searchWords } = useSelector((state: AppState) => state.documentSearch);
  const selectedCompanyId = useSelector((state: AppState) => state.user.selectedCompanyId);
  const selectedClientId = useSelector((state: AppState) => state.user.selectedClientId);
  const user = useSelector((state: AppState) => state.user.user);

  const getSelectedBusinessAreas = useCallback(() => {
    return getFilteredBusinessAreas(sidebarFilters);
  }, [sidebarFilters]);

  const [isLoading, data, trigger] = useDocumentData({
    triggerOnce: {
      businessAreaIds: getSelectedBusinessAreas(),
      sort: { field: 'companyName', direction: 'asc' },
      filters: documentFilters,
      currentUserId: user?.id,
      selectedCompanyId,
      selectedClientId,
    },
    renderOnUpdate: true,
    onError: (err) => dispatch(setError(err.toString())),
  });

  const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showSendPopup, setShowSendPopup] = useState(false);
  const [showApprovePopup, setShowApprovePopup] = useState(false);
  const [showDeclinePopup, setShowDeclinePopup] = useState(false);

  const [sendMode, setSendMode] = useState(false);
  const [adminApprovable, setAdminApprovable] = useState<number[]>([]);
  const [selectedApprovables, setSelectedApprovables] = useState<number[]>([]);
  const [selectedSendables, setSelectedSendables] = useState<number[]>([]);
  const [selectedIncorrect, setSelectedIncorrect] = useState(false);
  const [selectedIncorrectForApproval, setSelectedIncorrectForApproval] = useState(false);

  const juniorUser = user?.authority === Authorities.JuniorUser;
  const clientUser =
    user?.authority === Authorities.ClientUser1 || user?.authority === Authorities.ClientUser2;
  const adminUser = user?.authority === Authorities.Admin;
  const superAdminUser = user?.authority === Authorities.SuperAdmin;

  const headerText = t('upload.modifyHeaderText');
  const confirmRemove =
    selectedDocuments.length > 1
      ? t('docsContent.confirmRemoveMultiple')
      : t('docsContent.confirmRemove');
  const confirm = t('common.confirm');
  const cancel = t('common.cancel');
  const noSearchResultErrorText = t('docs.noSearchResult');
  const noFilterResultErrorText = t('docsContent.noFilteredDocument');
  const confirmApproveDocumentText =
    selectedDocuments.length > 1
      ? t('docsContent.confirmApproveMultipleDocumentText')
      : t('docsContent.confirmApproveDocumentText');
  const declineDocumentText =
    selectedDocuments.length > 1
      ? t('docsContent.declineMultipleDocumentText')
      : t('docsContent.declineDocumentText');

  let checkboxType: 'checked' | 'notChecked' | 'lock' | 'halfChecked' = 'notChecked';

  if (!isLoading) {
    if (selectedDocuments.length === data.length) {
      checkboxType = 'checked';
    } else if (selectedDocuments.length > 0) {
      checkboxType = 'halfChecked';
    } else {
      checkboxType = 'notChecked';
    }
  }

  useEffect(() => {
    dispatch(setModalOpen(showPopup));
  }, [dispatch, showPopup]);

  useEffect(() => {
    if (searchWords && searchWords!.length) {
      trigger({
        businessAreaIds: getSelectedBusinessAreas(),
        sort: documentSort,
        search: searchWords!,
        filters: documentFilters,
        currentUserId: user?.id,
        selectedCompanyId,
        selectedClientId,
      });
    } else {
      trigger({
        businessAreaIds: getSelectedBusinessAreas(),
        sort: documentSort,
        filters: documentFilters,
        currentUserId: user?.id,
        selectedCompanyId,
        selectedClientId,
      });
    }
  }, [
    searchWords,
    documentSort,
    trigger,
    getSelectedBusinessAreas,
    documentFilters,
    user?.id,
    selectedCompanyId,
    selectedClientId,
  ]);

  useEffect(() => {
    if (data) {
      setSelectedSendables(
        data
          .filter(
            (doc) =>
              selectedDocuments.includes(doc.id) &&
              doc.isApproved &&
              !doc.isWaitingForApproval &&
              !doc.isSent &&
              !doc.isDeclined &&
              !doc.isArchived,
          )
          .map(({ id }) => id),
      );

      setSelectedApprovables(
        data
          .filter(
            (doc) =>
              selectedDocuments.includes(doc.id) &&
              !doc.isApproved &&
              !doc.isWaitingForApproval &&
              !doc.isSent &&
              !doc.isArchived &&
              doc.approverId,
          )
          .map(({ id }) => id),
      );

      setAdminApprovable(
        data
          .filter(
            (doc) =>
              selectedDocuments.includes(doc.id) &&
              (adminUser || superAdminUser || clientUser) &&
              !doc.isApproved &&
              !doc.isSent &&
              !doc.isArchived &&
              !doc.isDeclined &&
              doc.approverId,
          )
          .map(({ id }) => id),
      );

      setSelectedIncorrect(
        data.some(
          (doc) =>
            selectedDocuments.includes(doc.id) &&
            (doc.isArchived || doc.isSent || doc.isWaitingForApproval),
        ),
      );
      setSelectedIncorrectForApproval(
        data.some((doc) => selectedDocuments.includes(doc.id) && (doc.isArchived || doc.isSent)),
      );
    }
  }, [
    data,
    selectedDocuments,
    setSelectedSendables,
    setSelectedApprovables,
    setSelectedIncorrect,
    setAdminApprovable,
    superAdminUser,
    adminUser,
    user?.id,
    clientUser,
  ]);

  useEffect(() => {
    setSendMode(!!(selectedSendables.length && !selectedApprovables.length));
  }, [selectedSendables, selectedApprovables]);

  const [sendDelete, deleteLoading] = useAsyncCallback(
    RestClient.Documents.delete,
    createOnError(dispatch),
    [dispatch],
  );

  const [sendApprove, sendDocApproveLoading] = useAsyncCallback(
    RestClient.Documents.approve,
    createOnError(dispatch),
    [dispatch],
  );

  const [sendDoc, sendDocLoading] = useAsyncCallback(
    RestClient.Documents.send,
    createOnError(dispatch),
    [dispatch],
  );

  const [sendDocApproval, sendDocApprovalLoading] = useAsyncCallback(
    RestClient.Documents.sendApproval,
    createOnError(dispatch),
    [dispatch],
  );
  const confirmSend =
    selectedSendables.length > 1
      ? t('docsContent.confirmSendMultiple')
      : t('docsContent.confirmSend');
  const confirmSendApproval =
    selectedApprovables.length > 1
      ? t('docsContent.confirmSendApprovalMultiple')
      : t('docsContent.confirmSendApproval');

  const noSearchResultError =
    searchWords && searchWords.length && !data?.length ? noSearchResultErrorText : undefined;

  const noFilterResultError =
    (sidebarFilters.some((filter) => !filter.isChecked) ||
      documentFilters.hasSelected ||
      documentFilters) &&
    !data?.length
      ? noFilterResultErrorText
      : undefined;

  const expandDocument = useSelector((state: AppState) => state.documents.expand);

  return {
    downloadButtonText: t('docsContent.downloadButton'),
    approveButtonText: t('docsContent.approveButton'),
    declineButtonText: t('docsContent.declineButton'),
    sendButtonText: t('docsContent.sendButton'),
    sendApprovalButtonText: t('docsContent.sendApprovalButton'),
    modifyButtonText: t('docsContent.modifyButton'),
    deleteButtonText: t('docsContent.deleteButton'),
    areYouSureText: t('common.areYouSure'),
    data,
    isLoading,
    selectedDocuments,
    setSelectedDocuments,
    selectedApprovables,
    selectedSendables,
    checkboxType,
    sendDelete,
    deleteLoading,
    sendDoc,
    sendDocLoading,
    headerText,
    confirmRemove,
    confirmSend,
    confirmSendApproval,
    cancel,
    confirm,
    showPopup,
    setShowPopup,
    setShowDeletePopup,
    showDeletePopup,
    noSearchResultErrorText,
    searchWords,
    showSendPopup,
    setShowSendPopup,
    sendMode,
    setSendMode,
    selectedIncorrect,
    sendDocApproval,
    sendDocApprovalLoading,
    sendDocApproveLoading,
    showApprovePopup,
    setShowApprovePopup,
    showDeclinePopup,
    setShowDeclinePopup,
    sendApprove,
    confirmApproveDocumentText,
    declineDocumentText,
    adminApprovable,
    selectedIncorrectForApproval,
    juniorUser,
    noFilterResultError,
    noSearchResultError,
    expandDocument,
    clientUser,
  };
};

export const DocumentScreenContent: FC = () => {
  const {
    downloadButtonText,
    sendButtonText,
    sendApprovalButtonText,
    modifyButtonText,
    deleteButtonText,
    data,
    isLoading,
    areYouSureText,
    checkboxType,
    selectedDocuments,
    setSelectedDocuments,
    selectedApprovables,
    selectedSendables,
    sendDelete,
    deleteLoading,
    sendDoc,
    sendDocLoading,
    headerText,
    confirmRemove,
    confirmSend,
    confirmSendApproval,
    cancel,
    confirm,
    showPopup,
    setShowPopup,
    setShowDeletePopup,
    showDeletePopup,
    showSendPopup,
    setShowSendPopup,
    sendMode,
    selectedIncorrect,
    sendDocApproval,
    sendDocApprovalLoading,
    sendDocApproveLoading,
    approveButtonText,
    declineButtonText,
    showApprovePopup,
    setShowApprovePopup,
    showDeclinePopup,
    setShowDeclinePopup,
    sendApprove,
    confirmApproveDocumentText,
    declineDocumentText,
    adminApprovable,
    selectedIncorrectForApproval,
    juniorUser,
    noFilterResultError,
    noSearchResultError,
    expandDocument,
    clientUser,
  } = useDocumentScreenContent();
  return (
    <>
      <LoadingPlaceholder
        showSpinner={true}
        isLoading={
          isLoading ||
          deleteLoading ||
          sendDocLoading ||
          sendDocApprovalLoading ||
          sendDocApproveLoading
        }
      >
        <div className={cn('pb-0 header-card', expandDocument && 'mt-4')}>
          <DocumentContentLayout
            downloadButton={
              <Button
                outline
                icon="download"
                iconSize="medium"
                onClick={() => RestClient.Documents.download(selectedDocuments)}
              >
                {downloadButtonText}
              </Button>
            }
            approveDocumentButton={
              <Button
                className={juniorUser || clientUser ? 'd-none' : ''}
                size="small"
                outline
                icon="check"
                iconSize="medium"
                disabled={!adminApprovable.length || selectedIncorrectForApproval}
                onClick={() => {
                  setShowApprovePopup(true);
                }}
              >
                {approveButtonText}
              </Button>
            }
            declineDocumentButton={
              <Button
                className={juniorUser || clientUser ? 'd-none' : ''}
                size="small"
                outline
                icon="x"
                iconSize="medium"
                disabled={!adminApprovable.length || selectedIncorrectForApproval}
                onClick={() => {
                  setShowDeclinePopup(true);
                }}
              >
                {declineButtonText}
              </Button>
            }
            sendButton={
              <Button
                size="small"
                outline
                icon="send"
                iconSize="medium"
                disabled={
                  !!(selectedApprovables.length && selectedSendables.length) ||
                  (!selectedApprovables.length && !selectedSendables.length) ||
                  selectedIncorrect
                }
                onClick={() => {
                  setShowSendPopup(true);
                }}
              >
                {sendMode ? sendButtonText : sendApprovalButtonText}
              </Button>
            }
            modifyButton={
              <Button
                size="small"
                outline
                icon="edit3"
                iconSize="medium"
                disabled={selectedDocuments.length !== 1}
                onClick={() => setShowPopup(true)}
              >
                {modifyButtonText}
              </Button>
            }
            deleteButton={
              <Button
                size="small"
                outline
                icon="trash"
                iconSize="medium"
                disabled={selectedDocuments.length === 0}
                onClick={() => {
                  setShowDeletePopup(true);
                }}
                id="documentDeleteButton"
              >
                {deleteButtonText}
              </Button>
            }
          />
        </div>

        <DocumentTableContentLayout
          table={
            <DocumentListTable
              data={data}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
              checkboxType={checkboxType}
            />
          }
          noSearchResult={
            noSearchResultError ? (
              <Card bgColorStyle="white" rounded className="d-flex justify-content-center h-100">
                <Text type="style7">{noSearchResultError || noFilterResultError}</Text>
              </Card>
            ) : (
              <div />
            )
          }
          isLoading
        />
      </LoadingPlaceholder>
      {showApprovePopup && (
        <ModalBox
          headerText={areYouSureText}
          show={showApprovePopup}
          modalWidth="narrow"
          onClickClose={() => setShowDeletePopup(false)}
        >
          <Text type="style1">{confirmApproveDocumentText}</Text>
          <div className="d-flex justify-content-around">
            <Button
              size="large"
              rounded
              className="my-4 mr-3"
              onClick={() => {
                if (adminApprovable.length) {
                  adminApprovable.map((doc) => sendApprove(doc, true!));
                }
                setShowApprovePopup(false);
              }}
              id="documentDeletionConfirmationButton"
            >
              <Text className="px-2 py-1 d-flex" type="style24">
                {confirm}
              </Text>
            </Button>
            <Button
              size="large"
              rounded
              className="my-4 ml-3 btn-secondary"
              onClick={() => setShowApprovePopup(false)}
            >
              <Text className="px-2 py-1 d-flex" type="style24">
                {cancel}
              </Text>
            </Button>
          </div>
        </ModalBox>
      )}
      {showDeclinePopup && (
        <ModalBox
          headerText={areYouSureText}
          show={showDeclinePopup}
          modalWidth="narrow"
          onClickClose={() => setShowDeletePopup(false)}
        >
          <Text type="style1">{declineDocumentText}</Text>
          <div className="d-flex justify-content-around">
            <Button
              size="large"
              rounded
              className="my-4 mr-3"
              onClick={() => {
                if (adminApprovable.length) {
                  adminApprovable.map((doc) => sendApprove(doc, false));
                }
                setShowDeclinePopup(false);
              }}
            >
              <Text className="px-2 py-1 d-flex" type="style24">
                {confirm}
              </Text>
            </Button>
            <Button
              size="large"
              rounded
              className="my-4 ml-3 btn-secondary"
              onClick={() => setShowDeclinePopup(false)}
            >
              <Text className="px-2 py-1 d-flex" type="style24">
                {cancel}
              </Text>
            </Button>
          </div>
        </ModalBox>
      )}
      {showDeletePopup && (
        <ModalBox
          headerText={areYouSureText}
          show={showDeletePopup}
          modalWidth="narrow"
          onClickClose={() => setShowDeletePopup(false)}
        >
          <DeleteDocumentPopupLayout
            deleteDocumentText={<Text type="style1">{confirmRemove}</Text>}
            confirmButton={
              <Button
                size="large"
                rounded
                onClick={() => {
                  selectedDocuments.map((doc) => sendDelete(doc));
                  setSelectedDocuments([]);
                  setShowDeletePopup(false);
                }}
              >
                <Text className="px-2 py-1 d-flex" type="style24">
                  {confirm}
                </Text>
              </Button>
            }
            cancelButton={
              <Button
                size="large"
                rounded
                className="btn-secondary"
                onClick={() => setShowDeletePopup(false)}
              >
                <Text className="px-2 py-1 d-flex" type="style24">
                  {cancel}
                </Text>
              </Button>
            }
          />
        </ModalBox>
      )}
      {showSendPopup && (
        <ModalBox
          headerText={areYouSureText}
          show={showSendPopup}
          modalWidth="narrow"
          onClickClose={() => setShowSendPopup(false)}
        >
          <Text type="style1">{sendMode ? confirmSend : confirmSendApproval}</Text>
          <div className="d-flex justify-content-around">
            <Button
              size="large"
              rounded
              className="my-4 mr-3"
              onClick={() => {
                if (sendMode) {
                  selectedSendables.map((doc) => sendDoc(doc));
                } else if (selectedApprovables.length) {
                  selectedApprovables.map((doc) => sendDocApproval(doc));
                }
                setSelectedDocuments([]);
                setShowSendPopup(false);
              }}
            >
              <Text className="px-2 py-1 d-flex" type="style24">
                {confirm}
              </Text>
            </Button>
            <Button
              size="large"
              rounded
              className="my-4 ml-3 btn-secondary"
              onClick={() => setShowSendPopup(false)}
            >
              <Text className="px-2 py-1 d-flex" type="style24">
                {cancel}
              </Text>
            </Button>
          </div>
        </ModalBox>
      )}
      {showPopup && (
        <ModalBox
          modalWidth="wide"
          headerText={headerText}
          show={showPopup}
          onClickClose={() => setShowPopup(false)}
        >
          <DocumentUploadModal
            close={async () => setShowPopup(false)}
            documentId={selectedDocuments[0]}
          />
        </ModalBox>
      )}
    </>
  );
};
