import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { DocumentsRes } from '@/client_v2/rest/types/DocumentsRes';
import { API_DOCUMENTS_SEARCH } from '@/client_v2/rest/Documents/URLS';

export const getDocumentsByIds = (ids: number[]) =>
  axiosInstance.post<DocumentsRes>(API_DOCUMENTS_SEARCH, {
    scopes: [
      {
        name: 'ids',
        parameters: [ids.toString()],
      },
    ],
  });
