import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { default as View } from '@/components/PageNavigator';
import Link from '@/components/Link';
import LanguageSelector from '@/components/LanguageSelector';
import CompanySelector from '@/components/CompanySelector';
import ClientSelector from '@/components/ClientSelector';
import { AppState } from '@/redux/store';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import { useClientListForSelectorData, useClientsData } from '@/client_v2/hooks/useClientsData';
import { useCompanyData } from '@/client_v2/hooks/useCompanyData';
import { createOnError } from '@/redux/message/message.callback';
import { useUserData } from '@/client_v2/hooks/useUserData';
import { useWorkflowStepData } from '@/client_v2/hooks/useWorkflowStepData';
import { setSelectedClientId } from '@/redux/user/user.actions';
import SyncButton from '@/components/SyncButton';
import { setError } from '@/redux/message/message.action';
import { useDocumentData } from '@/client_v2/hooks/useDocumentData';
import { getFilteredBusinessAreas } from '@/resources/utils';

const usePageNavigator = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [clientsLoading, , , clientSync] = useClientsData({
    triggerOnce: true,
    renderOnUpdate: true,
    onError: createOnError(dispatch),
  });

  const [companyLoading, , , companySync] = useCompanyData({
    renderOnUpdate: true,
    triggerOnce: true,
    onError: (err) => dispatch(setError(err.toString())),
  });

  const [usersLoading, , , usersSync] = useUserData({
    triggerOnce: true,
    renderOnUpdate: true,
    onError: createOnError(dispatch),
  });

  const [workflowsLoading, , , workflowsSync] = useWorkflowStepData({
    triggerOnce: true,
    renderOnUpdate: true,
    onError: createOnError(dispatch),
  });

  const user = useSelector((state: AppState) => state.user.user);

  const [doumentLoading, , , documentSync] = useDocumentData({
    triggerOnce: {
      businessAreaIds: getFilteredBusinessAreas([]),
      sort: { field: 'companyName', direction: 'asc' },
      filters: {},
      currentUserId: user?.id,
      selectedCompanyId: null,
      selectedClientId: null,
    },
    renderOnUpdate: true,
    onError: createOnError(dispatch),
  });

  const [hasSyncStarted, setHasSyncStarted] = useState<boolean>(false);

  const isSyncInProgress: boolean =
    clientsLoading || companyLoading || usersLoading || workflowsLoading || doumentLoading;

  useEffect(() => {
    if (hasSyncStarted && !isSyncInProgress) {
      setHasSyncStarted(false);
      window.location.reload();
    }
  }, [hasSyncStarted, setHasSyncStarted, isSyncInProgress]);

  const sync = useCallback(() => {
    setHasSyncStarted(true);
    clientSync();
    companySync();
    usersSync();
    workflowsSync();
    documentSync({
      businessAreaIds: getFilteredBusinessAreas([]),
      sort: { field: 'companyName', direction: 'asc' },
      filters: {},
      currentUserId: user?.id,
      selectedCompanyId: null,
      selectedClientId: null,
    });
  }, [clientSync, companySync, usersSync, workflowsSync, documentSync, user?.id]);

  const data = useMemo(() => {
    const paths = pathname.split('/').filter((path) => path !== '');
    paths.unshift('main');
    return paths.map((path, idx) => {
      const isFirst = idx === 0;
      const name = t(`nav.${path}`);
      const onClick = isFirst
        ? () => navigate('')
        : () => navigate(pathname.substr(0, pathname.indexOf(path) + path.length));
      return {
        name,
        onClick,
      };
    });
  }, [pathname, t, navigate]);
  return {
    data,
    sync,
    isSyncInProgress,
  };
};

const PageNavigator: FC = () => {
  const dispatch = useDispatch();
  const { data, sync, isSyncInProgress } = usePageNavigator();
  const expandDocument = useSelector((state: AppState) => state.documents.expand);
  const authority = useSelector((state: AppState) => state.user.user?.authority);
  const selectedClientId = useSelector((state: AppState) => state.user.selectedClientId);
  const canHaveMultipleClients =
    authority != Authorities.ClientUser1 && authority != Authorities.ClientUser2;

  const [, clients] = useClientListForSelectorData({
    triggerOnce: true,
    renderOnUpdate: true,
    onError: createOnError(dispatch),
  });

  useEffect(() => {
    dispatch(setSelectedClientId(null));
  }, [dispatch]);

  useEffect(() => {
    if (clients && !canHaveMultipleClients && !selectedClientId) {
      dispatch(setSelectedClientId(clients[0].clientId));
    }
  }, [clients, dispatch, selectedClientId, canHaveMultipleClients]);

  return (
    <>
      {expandDocument ? (
        <div className="py-3">{''}</div>
      ) : (
        <View
          languageSelector={<LanguageSelector />}
          clientSelector={canHaveMultipleClients && <ClientSelector />}
          companySelector={<CompanySelector />}
          syncButton={<SyncButton onClick={sync} loading={isSyncInProgress} />}
        >
          {data.map(({ name, onClick }, idx) => (
            <Link key={idx} defaultType="style28" onHoverType="style7" onClick={onClick}>
              {name}
            </Link>
          ))}
        </View>
      )}
    </>
  );
};

export default PageNavigator;
