import { FC, useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ClientSelector as View } from '@/components/ClientSelector/ClientSelector.view';
import { createOnError } from '@/redux/message/message.callback';
import { setSelectedClientId, setSelectedCompanyId } from '@/redux/user/user.actions';
import { AppState } from '@/redux/store';
import { useClientListForSelectorData } from '@/client_v2/hooks/useClientsData';

export const ClientSelector: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const allText = t('allClient');
  const allOptions = useMemo(() => ({ id: null, key: allText }), [allText]);

  const selectedClientId = useSelector((state: AppState) => state.user.selectedClientId);
  const selectedCompanyId = useSelector((state: AppState) => state.user.selectedCompanyId);

  useEffect(() => {
    dispatch(setSelectedClientId(null));
  }, [dispatch]);

  const [isLoading, clients] = useClientListForSelectorData({
    triggerOnce: true,
    renderOnUpdate: true,
    onError: createOnError(dispatch),
  });

  const dataclients = useMemo(
    () =>
      clients && [allOptions, ...clients.map((co) => ({ id: co.clientId, key: co.clientName }))],
    [clients, allOptions],
  );

  const selectedItem = useMemo(() => {
    if (!isLoading && clients) {
      const co = clients.find((item) => item.clientId === selectedClientId);
      if (!co) return null;
      return {
        id: co.clientId,
        key: co.clientName,
      };
    }
    return null;
  }, [clients, isLoading, selectedClientId]);

  useEffect(() => {
    setIsDropdownOpen(false);
  }, [selectedCompanyId]);

  const toggleDropdown = useCallback(
    () => setIsDropdownOpen(!isDropdownOpen),
    [setIsDropdownOpen, isDropdownOpen],
  );

  const onClick = useCallback(
    (item) => {
      dispatch(setSelectedClientId(item.id));
      dispatch(setSelectedCompanyId(null));
      toggleDropdown();
    },
    [toggleDropdown, dispatch],
  );

  return (
    dataclients && (
      <View
        selected={selectedClientId && selectedItem ? selectedItem : allOptions}
        isDropdownOpen={isDropdownOpen}
        toggleDropdown={toggleDropdown}
        data={dataclients && dataclients}
        onClick={onClick}
      />
    )
  );
};
