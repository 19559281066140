import { FC, ReactNode } from 'react';
import '@/layouts/CompanyContentCommentsLayout/style.scss';

interface Props {
  commentHeader: ReactNode;
  badges?: ReactNode;
}

export const CompanyContentCommentsLayout: FC<Props> = ({ commentHeader, badges }) => (
  <div className="container-fluid">
    <div className="row mt-3 mb-3 align-items-baseline text-nowrap">
      <div className="col-md-2 ml-2 d-flex align-items-center col-10">{commentHeader}</div>
      <div className="col-md-9 d-flex flex-wrap">{badges}</div>
    </div>
  </div>
);
