/* eslint-disable camelcase */
import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { PostActivityFeedsRequest } from '@/client_v2/rest/types/PostActivityFeeds';
import {
  IExportActivityFeedsParams,
  IRefreshActivityFeedsParams,
} from '@/redux/activityFeed/activityFeed.interfaces';
import {
  API_ACTIVITY_LOGS_SEARCH_URL,
  API_ACTIVITY_LOGS_EXPORT_URL,
} from '@/client_v2/rest/ActivityFeeds/URLS';

export const postActivityFeedsRefresh = ({
  startDate,
  endDate,
  sort,
}: IRefreshActivityFeedsParams) => {
  const filteringData: PostActivityFeedsRequest = {
    pagination_disabled: true,
    scopes: [
      {
        name: 'createdAtGte',
        parameters: [startDate!],
      },
      {
        name: 'createdAtLte',
        parameters: [endDate!],
      },
    ],
    ...(sort && {
      sort: [
        {
          field: 'created_at',
          direction: sort!,
        },
      ],
    }),
  };

  return axiosInstance.post(API_ACTIVITY_LOGS_SEARCH_URL, filteringData);
};

export const postActivityFeedsExport = (exportData?: IExportActivityFeedsParams) => {
  const filteringData: PostActivityFeedsRequest = exportData
    ? {
        pagination_disabled: true,
        scopes: [
          {
            name: 'createdAtGte',
            parameters: [exportData.startDate!],
          },
          {
            name: 'createdAtLte',
            parameters: [exportData.endDate!],
          },
        ],
        ...(exportData.sort
          ? {
              sort: [
                {
                  field: 'created_at',
                  direction: exportData.sort!,
                },
              ],
            }
          : null),
      }
    : {};

  return axiosInstance.post(API_ACTIVITY_LOGS_EXPORT_URL, filteringData);
};
