import '@/layouts/DeleteDocumentPopupLayout/style.scss';
import { FC, ReactNode } from 'react';

interface Props {
  deleteDocumentText?: ReactNode;
  confirmButton?: ReactNode;
  cancelButton?: ReactNode;
}

export const DeleteDocumentPopupLayout: FC<Props> = ({
  deleteDocumentText,
  confirmButton,
  cancelButton,
}) => (
  <div className="container-fluid">
    <div className="row d-flex justify-content-center">
      <div className="12">{deleteDocumentText}</div>
    </div>
    <div className="row mt-3 mb-3 d-flex justify-content-center">
      <div className="6 pr-4">{confirmButton}</div>
      <div className="6">{cancelButton}</div>
    </div>
  </div>
);
