import { FC } from 'react';
import '@/components/Table/TableItem/style.scss';
import { cn, CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('components-table-table-item');

interface Props {
  dynPosClassName: string;
  header?: boolean;
  isDark?: boolean;
  isHidden?: boolean;
}

export const TableItem: FC<Props> = ({ children, dynPosClassName, header, isDark, isHidden }) => (
  <div
    className={cn(
      scope,
      scope.and(isDark && 'dark'),
      scope.and(header && 'header'),
      dynPosClassName,
      scope.and(isHidden && 'hiddenItem'),
      'py-3',
      'px-2',
    )}
  >
    {children}
  </div>
);
