import React from 'react';
import '@/screens/ErrorScreen/style.scss';
import { useTranslation } from 'react-i18next';
import { cn, CreateScopeCSS } from '@/components/utils';

const ErrorScreen: React.FC = () => {
  const { t } = useTranslation();
  const errorMsg = t('error.pagePermissionError');
  const scope = CreateScopeCSS('error-screen');

  return (
    <div className={cn(scope)}>
      <p>{errorMsg}</p>
    </div>
  );
};

export default ErrorScreen;
