import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { PostWorkflow } from '@/client_v2/rest/types/PostWorkflow';
import { API_WORKFLOWS } from '@/client_v2/rest/Workflow/URLS';
import { WorkflowPoll } from '@/client_v2/polls/Workflow.poll';
import { WorkflowMilePoll } from '@/client_v2/polls/WorkflowMile.poll';
import { WorkflowStepPoll } from '@/client_v2/polls/WorkflowStep.poll';

export const postWorkflow = async (data: PostWorkflow) => {
  const res = await axiosInstance.post(API_WORKFLOWS, data);
  await WorkflowStepPoll.trigger();
  await WorkflowMilePoll.trigger();
  await WorkflowPoll.trigger();
  return res;
};
