import { InanoSQLInstance, nSQL } from '@nano-sql/core';
import { DB_NAME } from '@/client_v2/db/config';

export const READY_EVENT_TYPE = 'database_is_ready_to_use';

const db: InanoSQLInstance & { isReady?: boolean } = nSQL();

db.useDatabase(DB_NAME);
const handler = () => {
  db.off('ready', handler);
  db.isReady = true;
  window.dispatchEvent(new Event(READY_EVENT_TYPE));
};
db.on('ready', handler);

export default db;
