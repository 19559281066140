import * as Endpoints from '@/client/endpoints';
import * as Interfaces from '@/client/authInterfaces';
import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { IUserCredentials, IUserRegistration } from '@/redux/auth/auth.interfaces';

//AUTH related calls
export class Auth {
  login = async (userCredentials: IUserCredentials): Promise<Interfaces.ILoginResponse> => {
    const response = await axiosInstance.post(Endpoints.LOGIN, userCredentials);
    return response.data;
  };

  logout = async (): Promise<Interfaces.ILogoutResponse> => {
    const response = await axiosInstance.post(Endpoints.LOGOUT);
    return response.data;
  };

  register = async (user: IUserRegistration) => {
    const response = await axiosInstance.post(Endpoints.REGISTER, user);
    return response.data;
  };

  forgotPassword = async (
    user: Interfaces.IForgotPasswordRequest,
  ): Promise<Interfaces.IForgotPasswordResponse> => {
    const response = await axiosInstance.post(Endpoints.FORGOT_PASSWORD, user);
    return response.data;
  };

  resetPassword = async (
    user: Interfaces.IResetPasswordRequest,
  ): Promise<Interfaces.IForgotPasswordResponse> => {
    const response = await axiosInstance.post(Endpoints.RESET_PASSWORD, user);
    return response.data;
  };
}
