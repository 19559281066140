import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { ClientsRes } from '@/client_v2/rest/types/ClientsRes';
import { API_CLIENTS, DISABLE_PAGINATIONS_QUERY } from '@/client_v2/rest/Clients/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';

export const getClients = () =>
  axiosInstance.get<ClientsRes>(
    createUrl(API_CLIENTS, {
      queries: {
        [DISABLE_PAGINATIONS_QUERY]: '1',
      },
    }),
  );
