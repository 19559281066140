import i18n from 'i18next';
import { IGetBusinessAreaData } from '@/client_v2/db/repositories/BusinessArea.repo';
import {
  IPatchAddMile,
  IPatchAddStep,
  IPatchMile,
  IPatchStep,
} from '@/client_v2/rest/types/PatchWorkflow';
import { Mile } from '@/screens/ProcessesScreen/components/ProcessCreateModal';
import { IStepData } from '@/screens/ProcessesScreen/components/ProcessCreateModal/MileStones';

export const thousandsSeparators = (num: number) => {
  const numParts = num.toString().split('.');
  numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return numParts.join('.');
};

export const dateBeautifier = (date: Date, separator = '/') => {
  const month = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  const day = date.getDate() + 1 >= 10 ? date.getDate() + 1 : `0${date.getDate() + 1}`;
  return `${date.getFullYear()}${separator}${month}${separator}${day}`;
};

export const uniqueFilterItems = (array: unknown, key: string) => {
  if (Array.isArray(array) && array.length) {
    const ids: number[] = [];
    return array.filter((item) => {
      if (!ids.includes(item[key])) {
        ids.push(item[key]);
        return item;
      }
    });
  }
  return [];
};

export const getMileDataForPatch = (data: Mile[]) => {
  const addMiles: IPatchAddMile[] = [];
  const modifiedMiles: IPatchMile[] = [];
  data.map((item) => {
    if (item.id && item.id % 1 === 0) {
      const newSteps: IPatchAddStep[] = [];
      const modifiedSteps: IPatchStep[] = [];
      item.steps &&
        item.steps.map((step: IStepData) =>
          step.id && step.id % 1 === 0 ? modifiedSteps.push(step) : newSteps.push(step),
        );

      modifiedMiles.push({ ...item, addSteps: newSteps, steps: modifiedSteps });
    } else {
      addMiles.push(item);
    }
  });
  return { addMiles: addMiles, miles: modifiedMiles };
};

export const getBusinessAreasNiceName = (data: IGetBusinessAreaData[]) => {
  return data.map((item) => {
    return { ...item, name: i18n.t('sidebarFilter.' + item.name) };
  });
};

export const getErrorMessage = (message: string) => {
  if (message === 'Error: Request failed with status code 403') {
    return i18n.t('messageResponse.403');
  }
  return message;
};
