import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@/components/Card';
import { SidebarFilter } from '@/screens/common/SidebarFilter';

export const ProcessesSidebar: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Card bgColorStyle="light" rounded paddingH="small" paddingV="small">
        <SidebarFilter title={t('sidebarFilter.businessAreas')} />
      </Card>
    </>
  );
};
