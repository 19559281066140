import '@/components/CompanySelector/style.scss';

import { FC } from 'react';
import { cn, CreateScopeCSS } from '@/components/utils';
import CircleImage from '@/components/CircleImage';
import { BASE_URL } from '@/client/urls';

const scope = CreateScopeCSS('company-selector');
const item = scope.and('item');

interface Item {
  id: number | null;
  key: string;
  imgSrc: string | null;
}

interface Props {
  data: Item[] | null;
  isDropdownOpen: boolean;
  toggleDropdown: () => void;
  selected: Item | null;
  onClick: (item: Item) => void;
}

interface IButtonWithImg {
  onClick: () => void;
  imgSrc: string | null;
  label: string;
}

const ButtonWithImg: FC<IButtonWithImg> = ({ onClick, imgSrc, label }) => (
  <div className={cn(scope, item)}>
    <CircleImage
      size="small"
      color="secondary"
      smallText={label[0] && label[0].toLocaleUpperCase()}
      image={imgSrc ? BASE_URL + imgSrc : undefined}
    />
    <button
      className="btn btn-default col d-flex align-items-center"
      type="button"
      onClick={onClick}
    >
      {label}
    </button>
  </div>
);

export const CompanySelector: FC<Props> = (props) => (
  <div className={scope}>
    <div className="dropdown">
      <ButtonWithImg
        imgSrc={props.selected?.imgSrc ? props.selected.imgSrc : ''}
        onClick={props.toggleDropdown}
        label={props.selected?.key ? props.selected.key : ''}
      />
      <ul className={`dropdown-menu min-w-0 ${props.isDropdownOpen ? 'd-block' : 'd-none'}`}>
        {props.data &&
          props.data.map((item) => (
            <li key={item.key.toString()}>
              <ButtonWithImg
                imgSrc={item.imgSrc}
                label={item.key}
                onClick={() => props.onClick(item)}
              />
            </li>
          ))}
      </ul>
    </div>
  </div>
);
