import { Dispatch } from 'redux';
import { ICreateWorkflowRequest } from '@/client/workflowManagementInterfaces';
import client from '@/client';
import { IIsModified, ISearchWords } from './processes.interfaces';
import {
  CREATE_PROCESS_REQUEST,
  CREATE_PROCESS_SUCCESS,
  CREATE_PROCESS_FAILURE,
  SET_PROCESS_SEARCH_WORDS,
  DELETE_PROCESS_SEARCH_WORDS,
  SET_COLLAPSE_PROCESS_ID,
  SET_MEMORIZE_MILE_ID,
  SET_PROCESS_SCROLLBAR_POSITION,
  IS_PROCESS_MODIFIED,
} from './processes.types';

export const createProcess = (workflow: ICreateWorkflowRequest) => async (dispatch: Dispatch) => {
  dispatch({
    type: CREATE_PROCESS_REQUEST,
  });

  try {
    const res = await client.workflowManagement.createWorkflow(workflow);
    dispatch({
      type: CREATE_PROCESS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: CREATE_PROCESS_FAILURE,
      error,
    });
  }
};

export const setProcessSearchWords = (searchWords: ISearchWords) => (dispatch: Dispatch) =>
  dispatch({
    type: SET_PROCESS_SEARCH_WORDS,
    payload: searchWords.searchWords,
  });

export const deleteProcessSearchWords = () => (dispatch: Dispatch) =>
  dispatch({
    type: DELETE_PROCESS_SEARCH_WORDS,
  });

export const setCollapseProcessId = (id: number | null) => async (dispatch: Dispatch) => {
  dispatch({
    type: SET_COLLAPSE_PROCESS_ID,
    payload: id,
  });
};

export const setMemorizeSelectedMileId = (id: number | null) => async (dispatch: Dispatch) =>
  dispatch({
    type: SET_MEMORIZE_MILE_ID,
    payload: id,
  });

export const setProcessScrollbarPosition = (position: number | undefined) => (dispatch: Dispatch) =>
  dispatch({
    type: SET_PROCESS_SCROLLBAR_POSITION,
    payload: position,
  });

export const isProcessModified = (isModified: IIsModified) => (dispatch: Dispatch) =>
  dispatch({
    type: IS_PROCESS_MODIFIED,
    payload: isModified,
  });
