export interface MessageState {
  message: null | string;
  isError: boolean | null;
}

export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const SET_ERROR = 'SET_ERROR';
export const SET_SUCCESS = 'SET_SUCCESS';

export interface IclearMessageAction {
  type: typeof CLEAR_MESSAGE;
}

export interface IsetErrorAction {
  type: typeof SET_ERROR;
  payload: MessageState;
}

export interface IsetSuccessAction {
  type: typeof SET_SUCCESS;
  payload: MessageState;
}

export type MessageActions = IclearMessageAction | IsetErrorAction | IsetSuccessAction;
