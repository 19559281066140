import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '@/redux/store';
import RootRouter from '@/navigation/RootRouter';
import '@/styles/index.scss';
import '@/i18next';

const App: React.FC = () => {
  return (
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <RootRouter />
      </Provider>
    </PersistGate>
  );
};

export default App;
