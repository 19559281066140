import { UserPoll } from '@/client_v2/polls/User.poll';
import { axiosFormDataInstance } from '@/client_v2/rest/axios/axiosInstance';
import { PatchUser } from '@/client_v2/rest/types/PatchUser';
import { API_USERS_$ID, $ID_PARAM } from '@/client_v2/rest/Users/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';

export const patchUser = async ({ file, data }: PatchUser) => {
  const formData = new FormData();
  if (file) formData.set('file', file);
  formData.set('data', JSON.stringify(data));
  formData.append('_method', 'PATCH');
  const res = await axiosFormDataInstance.post(
    createUrl(API_USERS_$ID, {
      params: {
        [$ID_PARAM]: data.id.toString(),
      },
    }),
    formData,
  );
  await UserPoll.trigger();
  return res;
};
