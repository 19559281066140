import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { DocumentRes } from '@/client_v2/rest/types/DocumentsRes';
import { API_DOCUMENTS_$ID, $ID_PARAM } from '@/client_v2/rest/Documents/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';

export const getDocumentById = (id: number) =>
  axiosInstance.get<DocumentRes>(
    createUrl(API_DOCUMENTS_$ID, {
      params: {
        [$ID_PARAM]: id.toString(),
      },
    }),
  );
