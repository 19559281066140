import { FC, ReactNode } from 'react';
import '@/components/Table/style.scss';
import { cn, CreateScopeCSS } from '@/components/utils';
import TableItem from '@/components/Table/TableItem';
import { GetPropsType } from '@/types/GetPropType';
import { TableTypes } from '@/components/Table/Table';

const scope = CreateScopeCSS('components-table');
const rightAreaClass = scope.and('rightArea');

interface Props {
  header?: GetPropsType<typeof TableItem>[];
  body?: GetPropsType<typeof TableItem>[];
  dynColumnsClass?: string;
  dynAreaSizeClass?: string;
  rightArea?: ReactNode;
  type?: TableTypes;
  className?: string;
  id?: string;
}

export const Table: FC<Props> = ({
  body,
  header,
  dynColumnsClass,
  rightArea,
  dynAreaSizeClass,
  type,
  className,
  id,
}) => {
  return (
    <div className={cn(scope, dynColumnsClass, className)} id={id ? id : scope}>
      {header && header.map((headItem, idx) => <TableItem {...headItem} key={idx} type={type} />)}
      {body && body.map((childItem, idx) => <TableItem {...childItem} key={idx} type={type} />)}
      {rightArea ? <div className={cn(rightAreaClass, dynAreaSizeClass)}>{rightArea}</div> : null}
    </div>
  );
};
