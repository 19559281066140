import '@/components/ModalBox/style.scss';
import { AnimationEventHandler, FC, MouseEventHandler } from 'react';
import { createPortal } from 'react-dom';
import { cn, CreateScopeCSS } from '@/components/utils';
import Portal from '@/components/Portal';
import Icon from '@/components/Icon';
import Text from '@/components/Text';

const scope = CreateScopeCSS('components-modal-box');
const header = scope.and('header');
const blur = scope.and('blur');
const anim = scope.and('anim');
const close = header.and('close');
const content = scope.and('content');
const backgroundCover = scope.and('backgroundCover');

interface Props {
  headerText?: string;
  show?: boolean;
  onClickClose?: MouseEventHandler<HTMLSpanElement>;
  onAnimationEnd?: AnimationEventHandler<HTMLDivElement>;
  modalWidth?: 'narrow' | 'medium' | 'wide';
  id?: string;
}

export const ModalBox: FC<Props> = ({
  children,
  headerText,
  show,
  onAnimationEnd,
  onClickClose,
  modalWidth = 'medium',
  id,
}) => {
  const getModalWidth = () =>
    modalWidth === 'medium' ? 'col-6' : modalWidth === 'narrow' ? 'col-md-4 col-10' : 'col-8';
  return (
    <Portal x={0} y={0}>
      {createPortal(<div className={cn(blur, blur.and(show ? 'show' : 'hide'))} />, document.body)}
      <div className={anim.and(show ? 'show' : 'hide')} onAnimationEnd={onAnimationEnd}>
        <div className={backgroundCover}>
          <div
            className={scope + ' ' + getModalWidth()}
            id={id ? id : `modal-box-${headerText ? headerText : ''}`}
          >
            <div className={header}>
              <div>{headerText && <Text type="style33">{headerText}</Text>}</div>
              <div onClick={onClickClose}>
                <Icon type="x" colorStyle="darkGray" className={close} />
              </div>
            </div>
            <div className={content + 'p-0 px-md-4'}>{children}</div>
          </div>
        </div>
      </div>
    </Portal>
  );
};
