import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useDispatch } from 'react-redux';
import Button from '@/components/Button';
import '@/screens/DashboardScreen/Content/footer.scss';
import SVGLogo from '@/resources/SVG/SVGLogo';
import Text from '@/components/Text';
import { useBusinessAreaData } from '@/client_v2/hooks/useBusinesAreaData';
import { setError } from '@/redux/message/message.action';
import card1 from '@/resources/images/footer-card-bg-01.png';
import card2 from '@/resources/images/footer-card-bg-02.png';
import card3 from '@/resources/images/footer-card-bg-03.png';
import { OFFER_MAIL } from '@/resources/constants/shared';

export interface CarouselProps {
  infiniteLoop?: boolean;
  autoPlay?: boolean;
  showStatus?: boolean;
  showThumbs?: boolean;
  showIndicators?: boolean;
  showArrows?: boolean;
}

export const Footer: FC<CarouselProps> = ({
  infiniteLoop = false,
  autoPlay = false,
  showStatus = false,
  showThumbs = false,
  showIndicators = true,
  showArrows = false,
}) => {
  const dispatch = useDispatch();

  const sendEmail = () => {
    window.location.assign(OFFER_MAIL);
  };

  const [isLoading, businessAreaData] = useBusinessAreaData({
    triggerOnce: true,
    renderOnUpdate: false,
    onError: (err) => dispatch(setError(err.toString())),
  });

  const carouselImages = [card1, card2, card3];
  const carouselImagesOverlay = ['overlayColor1', 'overlayColor2', 'overlayColor3'];

  const { t } = useTranslation();
  return (
    <>
      {!isLoading && (
        <Carousel
          infiniteLoop={infiniteLoop}
          autoPlay={autoPlay}
          showStatus={showStatus}
          showThumbs={showThumbs}
          showIndicators={showIndicators}
          showArrows={showArrows}
        >
          {businessAreaData?.map((image) => {
            return (
              <div key={image.name} className="w-100">
                <div className={carouselImagesOverlay[Math.floor(Math.random() * 3)]}>
                  <img
                    className="image"
                    src={carouselImages[Math.floor(Math.random() * 3)]}
                    alt={image.name}
                  />
                </div>
                <div className="text text-left">
                  <div className="mt-2">
                    <Text className="font-weight-bold" type="style25">
                      {t(`sidebarFilter.${image.name}`)}
                    </Text>
                  </div>
                  <div className="text-left description text-wrap w-50">
                    <Text className="font-weight-bold" type="style38">
                      {t('dashboardScreen.footer.description')}
                    </Text>
                  </div>
                  <div className="mt-3">
                    <Button
                      outline
                      size="small"
                      className="mr-2 bg-light text-primary btn-outline-light"
                      iconSize="small"
                      icon="arrowRight"
                      rounded
                      onClick={() => sendEmail()}
                    >
                      {t('dashboardScreen.footer.button')}
                    </Button>
                  </div>
                </div>
                <div className="logo">
                  <SVGLogo width="128" height="80" />
                </div>
              </div>
            );
          })}
        </Carousel>
      )}
    </>
  );
};

export default Footer;
