import '@/components/LoadingPlaceholder/style.scss';
import { FC } from 'react';
import { cn, CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('components-loading-placeholder');
const spinner = scope.and('spinner');

interface Props {
  dynLoadingClassName: string;
}

export const LoadingPlaceholder: FC<Props> = ({ dynLoadingClassName, children, ...props }) => (
  <div className={cn(scope)}>
    <div className={cn(spinner, dynLoadingClassName)} {...props}>
      {children}
    </div>
  </div>
);
