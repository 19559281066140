import { FC, KeyboardEventHandler, useCallback, useState } from 'react';
import { UnderlineInput as View } from '@/components/Input/UnderlineInput/UnderlineInput.view';
import { ComponentHook } from '@/types/ComponentHook';
import { useStateFromProps } from '@/hooks/useStateFromProps';

interface Props {
  titleText?: string;
  value?: string;
  max?: number;
  onChange?(value: string): void;
  onBlur?(): void;
  onEnterKeyPressed?: KeyboardEventHandler<HTMLInputElement>;
  error?: boolean;
  required?: boolean;
  type?: 'text' | 'number';
  readOnly?: boolean;
  id?: string;
}

export const useUnderlineInput: ComponentHook<Props, typeof View> = ({
  value,
  titleText,
  max,
  onChange,
  onBlur: _onBlur,
  onEnterKeyPressed,
  error,
  type,
  id,
  readOnly,
  required,
}) => {
  const [focus, setFocus] = useState(false);
  const [inputText, setInputText] = useStateFromProps(value);

  const onChangeInputText = useCallback(
    (e) => {
      let currentValue: string = e.target.value;
      if (max && currentValue.length > max) currentValue = currentValue.substr(0, max);
      if (onChange) onChange(currentValue);
      setInputText(currentValue);
    },
    [setInputText, onChange, max],
  );

  const onFocus = useCallback(() => {
    setFocus(true);
  }, [setFocus]);

  const onBlur = useCallback(() => {
    setFocus(false);
    _onBlur && _onBlur();
  }, [setFocus, _onBlur]);

  const onKeyPress = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      if (e.key === 'Enter') {
        onEnterKeyPressed && onEnterKeyPressed(e);
      }
    },
    [onEnterKeyPressed],
  );

  return {
    onBlur,
    onFocus,
    focus,
    inputText,
    max,
    readOnly,
    titleText,
    onChangeInputText,
    onKeyPress,
    error,
    required,
    type,
    id,
  };
};

export const UnderlineInput: FC<Props> = (props) => {
  const computedProps = useUnderlineInput(props);
  return <View {...computedProps} />;
};
