import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { UserRes } from '@/client_v2/rest/types/UsersRes';
import { API_USERS_$ID, $ID_PARAM } from '@/client_v2/rest/Users/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';

export const getUserById = (id: number) =>
  axiosInstance.get<UserRes>(
    createUrl(API_USERS_$ID, {
      params: {
        [$ID_PARAM]: id.toString(),
      },
    }),
  );
