import '@/layouts/ActivityFeedHeader/style.scss';
import { FC, ReactNode } from 'react';

interface Props {
  activityFeedHeader: ReactNode;
  activityFeedDatePicker: ReactNode;
  refreshButton: ReactNode;
  downloadListButton: ReactNode;
  datePickerPlaceholder: ReactNode;
  syncButton?: ReactNode;
}

export const ActivityFeedHeader: FC<Props> = ({
  activityFeedHeader,
  activityFeedDatePicker,
  refreshButton,
  downloadListButton,
  datePickerPlaceholder,
  syncButton,
}) => (
  <div className="container-fluid my-4 py-3">
    <div className="row mt-4 ">
      <div className="col-lg-12 d-lg-flex justify-content-lg-start d-flex justify-content-center">
        {activityFeedHeader}
      </div>
    </div>
    <div className="row d-flex justify-content-center align-items-center">
      <div className="col-lg-2 col-1" />
      <div className="col-lg-3 col-12 mt-4">
        {activityFeedDatePicker}
        <div className="row">
          <div className="col-lg-2 position-absolute">{datePickerPlaceholder}</div>
        </div>
      </div>
      <div className="col-lg-1 col-4 mr-lg-0 mr-5 d-flex justify-content-end">{refreshButton}</div>
      <div className="col-lg-3 col-4 d-flex justify-content-start">{downloadListButton}</div>
      <div className="col-lg-2 col-1 justify-content-start">{syncButton}</div>
    </div>
  </div>
);
