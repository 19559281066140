import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { DocumentsRes } from '@/client_v2/rest/types/DocumentsRes';
import { API_DOCUMENTS, DISABLE_PAGINATIONS_QUERY } from '@/client_v2/rest/Documents/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';

export const getDocuments = () =>
  axiosInstance.get<DocumentsRes>(
    createUrl(API_DOCUMENTS, {
      queries: {
        [DISABLE_PAGINATIONS_QUERY]: '1',
      },
    }),
  );
