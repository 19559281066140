import { FC, useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CompanySelector as View } from '@/components/CompanySelector/CompanySelector.view';
import { useCompaniesListForSelectorData } from '@/client_v2/hooks/useCompanyData';
import { createOnError } from '@/redux/message/message.callback';
import { setSelectedClientId, setSelectedCompanyId } from '@/redux/user/user.actions';
import { AppState } from '@/redux/store';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';

export const CompanySelector: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const allText = t('allCompany');
  const allOptions = useMemo(() => ({ id: null, key: allText, imgSrc: null }), [allText]);

  const selectedClientId = useSelector((state: AppState) => state.user.selectedClientId);
  const selectedCompanyId = useSelector((state: AppState) => state.user.selectedCompanyId);
  const authority = useSelector((state: AppState) => state.user.user?.authority);

  const [isLoading, companies] = useCompaniesListForSelectorData({
    triggerOnce: true,
    renderOnUpdate: true,
    onError: createOnError(dispatch),
  });

  const dataCompanies = useMemo(
    () =>
      companies && [
        allOptions,
        ...companies
          .filter((co) => (selectedClientId ? selectedClientId === co.clientId : true))
          .map((co) => ({ id: co.id, key: co.name, imgSrc: co.profileImageUrl })),
      ],
    [companies, allOptions, selectedClientId],
  );

  useEffect(() => {
    if (
      companies &&
      (authority === Authorities.ClientUser1 || authority === Authorities.ClientUser2) &&
      companies.length === 1 &&
      !selectedCompanyId
    ) {
      dispatch(setSelectedCompanyId(companies[0].id));
      dispatch(setSelectedClientId(companies[0].clientId));
    }
  }, [authority, companies, dispatch, selectedCompanyId]);

  useEffect(() => {
    setIsDropdownOpen(false);
  }, [selectedCompanyId]);

  useEffect(() => {
    if (!selectedClientId && selectedCompanyId && authority === Authorities.SuperAdmin) {
      const co = companies?.find((c) => c.id === selectedCompanyId);
      dispatch(setSelectedClientId(co?.clientId ? co.clientId : null));
    }
  }, [selectedCompanyId, authority, selectedClientId, companies, dispatch]);

  const selectedCompanyItem = useMemo(() => {
    if (!isLoading && companies) {
      const co = companies.find((item) => item.id === selectedCompanyId);
      if (!co) return null;
      return {
        id: co.id,
        key: co.name,
        imgSrc: co.profileImageUrl,
      };
    }
    return null;
  }, [companies, isLoading, selectedCompanyId]);

  const toggleDropdown = useCallback(
    () => setIsDropdownOpen(!isDropdownOpen),
    [setIsDropdownOpen, isDropdownOpen],
  );

  const onClick = useCallback(
    (item) => {
      dispatch(setSelectedCompanyId(item.id));
      toggleDropdown();
    },
    [toggleDropdown, dispatch],
  );

  return (
    <>
      {dataCompanies && (
        <View
          selected={selectedCompanyId && selectedCompanyItem ? selectedCompanyItem : allOptions}
          isDropdownOpen={!!isDropdownOpen}
          toggleDropdown={toggleDropdown}
          data={dataCompanies && dataCompanies}
          onClick={onClick}
        />
      )}
    </>
  );
};
