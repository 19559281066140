import { FC, useCallback, useState, useMemo } from 'react';
import { ComponentHook } from '@/types/ComponentHook';
import { Link as View } from '@/components/Link/Link.view';
import { GetPropsType } from '@/types/GetPropType';

interface Props {
  onClick?(): void;
  onHoverType?: GetPropsType<typeof View>['type'];
  onActiveType?: GetPropsType<typeof View>['type'];
  defaultType: GetPropsType<typeof View>['type'];
  capitalization?: GetPropsType<typeof View>['capitalization'];
  active?: boolean;
  id?: string;
}

export const useLink: ComponentHook<Props, typeof View> = ({
  active,
  defaultType,
  onActiveType = defaultType,
  onHoverType = defaultType,
  ...props
}) => {
  const [state, setState] = useState<'hover' | 'none'>('none');
  const onMouseOver = useCallback(() => setState('hover'), [setState]);
  const onMouseOut = useCallback(() => setState('none'), [setState]);
  const type = useMemo(
    () => (active ? onActiveType : state === 'hover' ? onHoverType : defaultType),
    [active, onActiveType, state, onHoverType, defaultType],
  );
  return {
    ...props,
    onMouseOver,
    onMouseOut,
    type,
  };
};

export const Link: FC<Props> = ({ children, ...props }) => {
  const computedProps = useLink(props);
  return <View {...computedProps}>{children}</View>;
};
