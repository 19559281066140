/* eslint-disable @typescript-eslint/no-explicit-any */
import { Multiselect } from 'multiselect-react-dropdown';
import '@/components/MultiselectDropdown/style.scss';
import { FC, useCallback, useState } from 'react';
import { cn, CreateScopeCSS } from '@/components/utils';
import Icon from '@/components/Icon';
import Text from '@/components/Text';

const scope = CreateScopeCSS('components-multiselect-dropdown');

export interface Props {
  options: any;
  selectedOptions?: any;
  title: string;
  uppercase?: boolean;
  onSelectItem?: (selectedList: any, selectedOptions: any) => void;
  onRemoveItem?: (selectedList: any, selectedOptions: any) => void;
  maxSelection?: number;
  displayValue?: string;
  avoidHighlightFirstOption?: boolean;
  isDisabled?: boolean;
  showArrow?: boolean;
  closeOnSelect?: boolean;
  isError?: boolean;
  showIcon?: boolean;
  hidePlaceholder?: boolean;
  singleSelect?: boolean;
  label?: string;
  required?: boolean;
  id?: string;
  directRef?: React.RefObject<Multiselect>;
}

export const MultiselectDropdown: FC<Props> = ({
  maxSelection = 5,
  title,
  onSelectItem,
  onRemoveItem,
  showIcon,
  options,
  selectedOptions,
  displayValue = 'name',
  avoidHighlightFirstOption = false,
  isDisabled = false,
  showArrow = false,
  closeOnSelect = false,
  isError,
  hidePlaceholder = true,
  singleSelect = false,
  label,
  required,
  id,
  directRef,
  ...props
}) => {
  const [isSelectMoreHidden, setIsSelectMoreHidden] = useState<boolean>(false);

  const onSelect = useCallback(
    (selectedList: any, selectedOptions: any) => {
      selectedList.length >= maxSelection
        ? setIsSelectMoreHidden(true)
        : setIsSelectMoreHidden(false);
      if (onSelectItem) {
        onSelectItem(selectedList, selectedOptions);
      }
    },
    [onSelectItem, maxSelection],
  );

  const onRemove = useCallback(
    (selectedList: any, selectedOptions: any) => {
      selectedList.length >= maxSelection
        ? setIsSelectMoreHidden(true)
        : setIsSelectMoreHidden(false);
      if (onRemoveItem) {
        onRemoveItem(selectedList, selectedOptions);
      }
    },
    [onRemoveItem, maxSelection],
  );

  return (
    <div
      className={cn(
        scope,
        'd-flex flex-column align-items-start',
        maxSelection > 2 && !singleSelect ? 'text-wrap' : 'text-nowrap',
        isSelectMoreHidden ? '-hideSelectMoreInput' : '',
      )}
      {...props}
    >
      <Text type="style7" required={required}>
        {label}
      </Text>
      <div
        className={`d-flex align-items-center control mt-1 wideContainer ${
          isError ? '-multipleSelectError' : ''
        }`}
      >
        <Multiselect
          placeholder={title}
          options={options}
          selectedValues={selectedOptions}
          onSelect={onSelect}
          onRemove={onRemove}
          selectionLimit={maxSelection}
          displayValue={displayValue}
          avoidHighlightFirstOption={avoidHighlightFirstOption}
          disable={isDisabled}
          showArrow={showArrow}
          closeIcon="circle"
          closeOnSelect={closeOnSelect}
          hidePlaceholder={hidePlaceholder}
          singleSelect={singleSelect}
          id={id ? id : `multiselect-dropdown-${title}`}
          ref={directRef}
        />
        {(maxSelection === 1 || showIcon) && (
          <Icon
            colorStyle={!isError ? 'lightGray' : 'danger'}
            className="multiselectChevronDown"
            type="chevronDown"
          />
        )}
      </div>
    </div>
  );
};
