import { UserPoll } from '@/client_v2/polls/User.poll';
import { CompanyPoll } from '@/client_v2/polls/Company.poll';
import { CommentPoll } from '@/client_v2/polls/Comment.poll';
import { ClientPoll } from '@/client_v2/polls/Client.poll';
import { createDataHook } from '@/client_v2/hooks/utils/createDataHook';
import { CompanyRepo } from '@/client_v2/db/repositories/Company.repo';
import { ClientRepo } from '@/client_v2/db/repositories/Client.repo';

export const useDashboardContactsData = createDataHook({
  polls: [CompanyPoll, UserPoll, CommentPoll, ClientPoll],
  dataFactory: async (arg: { companyId: number | null; clientId: number | null }) => {
    return {
      clientContacts: await ClientRepo.query('get-client-contact-for-dashboard', arg),
      companyContacts: await CompanyRepo.query('get-company-contacts-for-dashboard', arg),
    };
  },
});
