import { ISort } from './sort.interfaces';

export const SET_PROCESS_SORT = 'SET_PROCESS_SORT';
export const SET_DOCUMENTS_SORT = 'SET_DOCUMENTS_SORT';
export const RESET_SORT_ACTION = 'RESET_SORT_ACTION';

export interface ISetProcessSortAction {
  type: typeof SET_PROCESS_SORT;
  payload: ISort;
}

export interface ISetDocumentSortAction {
  type: typeof SET_DOCUMENTS_SORT;
  payload: ISort;
}

export interface IResetSortAction {
  type: typeof RESET_SORT_ACTION;
}

export type SortActionTypes = ISetProcessSortAction | ISetDocumentSortAction | IResetSortAction;
