import { Dispatch } from 'redux';
import RestClient from '@/client_v2/rest';
import client from '@/client';
import { IGetUserByEmail } from '@/redux/user/user.interfaces';
import { PostUser } from '@/client_v2/rest/types/PostUser';
import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  UNBLOCK_USER_FAILURE,
  UNBLOCK_USER_REQUEST,
  UNBLOCK_USER_SUCCESS,
  EDIT_USER_FAILURE,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  DELETE_USER_REQUEST,
  DELETE_USER_FAILURE,
  DELETE_USER_SUCCESS,
  BLOCK_USER_REQUEST,
  BLOCK_USER_FAILURE,
  BLOCK_USER_SUCCESS,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  SET_SELECTED_COMPANY_ID,
  SET_SELECTED_CLIENT_ID,
} from '@/redux/user/user.types';
import { PatchUser } from '@/client_v2/rest/types/PatchUser';

export const setSelectedClientId = (id: number | null) => async (dispatch: Dispatch) => {
  dispatch({
    type: SET_SELECTED_CLIENT_ID,
    payload: id,
  });
};

export const setSelectedCompanyId = (id: number | null) => async (dispatch: Dispatch) => {
  dispatch({
    type: SET_SELECTED_COMPANY_ID,
    payload: id,
  });
};

export const getUserByEmail =
  ({ email }: IGetUserByEmail) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: GET_USER_REQUEST,
    });

    try {
      const {
        data: [user],
      } = await client.userManagement.fetchUsersSearch({
        scopes: [{ name: 'email', parameters: [email] }],
        sort: [],
      });
      dispatch({
        type: GET_USER_SUCCESS,
        payload: user,
      });

      user.clients &&
        dispatch({
          type: SET_SELECTED_CLIENT_ID,
          payload: user.clients,
        });
    } catch (error) {
      dispatch({
        type: GET_USER_FAILURE,
        error,
      });
    }
  };

export const deleteUser = (id: number) => async (dispatch: Dispatch) => {
  dispatch({
    type: DELETE_USER_REQUEST,
  });

  try {
    // Nothing to do here after success. The get user hook should update the screen
    await RestClient.Users.delete(id);
    dispatch({
      type: DELETE_USER_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAILURE,
      error,
    });
  }
};

export const blockUser = (id: number) => async (dispatch: Dispatch) => {
  dispatch({
    type: BLOCK_USER_REQUEST,
  });

  try {
    await RestClient.Users.block(id);
    dispatch({
      type: BLOCK_USER_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: BLOCK_USER_FAILURE,
      error,
    });
  }
};

export const unblockUser = (id: number) => async (dispatch: Dispatch) => {
  dispatch({
    type: UNBLOCK_USER_REQUEST,
  });

  try {
    await RestClient.Users.unblock(id);
    dispatch({
      type: UNBLOCK_USER_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: UNBLOCK_USER_FAILURE,
      error,
    });
  }
};

// eslint-disable-next-line
export const editUser = (user: PatchUser) => async (dispatch: Dispatch) => {
  dispatch({
    type: EDIT_USER_REQUEST,
  });

  try {
    //TODO: Use RestClient.Users.patch instead of RestClient.Users.edit
    //await RestClient.Users.edit(user);
    // eslint-disable-next-line
    console.error(
      'FILE: src/redux/user/user.actions.ts\n',
      'Use RestClient.Users.patch instead of RestClient.Users.edit',
    );

    dispatch({
      type: EDIT_USER_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EDIT_USER_FAILURE,
      error,
    });
  }
};

export const createUser = (user: PostUser) => async (dispatch: Dispatch) => {
  dispatch({
    type: CREATE_USER_REQUEST,
  });

  try {
    const res = await RestClient.Users.post(user);

    dispatch({
      type: CREATE_USER_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_USER_FAILURE,
      error,
    });
  }
};
