import { UserPoll } from '@/client_v2/polls/User.poll';
import { DocumentPoll } from '@/client_v2/polls/Document.poll';
import { CommentPoll } from '@/client_v2/polls/Comment.poll';
import { WorkflowStepPoll } from '@/client_v2/polls/WorkflowStep.poll';
import { WorkflowMilePoll } from '@/client_v2/polls/WorkflowMile.poll';
import { WorkflowPoll } from '@/client_v2/polls/Workflow.poll';
import { BusinessAreaPoll } from '@/client_v2/polls/BusinessArea.poll';
import { BusinessAreaRepo, IDashboardDataArg } from '@/client_v2/db/repositories/BusinessArea.repo';
import { createDataHook } from '@/client_v2/hooks/utils/createDataHook';

export const useDashboardData = createDataHook({
  polls: [
    WorkflowPoll,
    WorkflowMilePoll,
    CommentPoll,
    UserPoll,
    WorkflowStepPoll,
    DocumentPoll,
    BusinessAreaPoll,
  ],
  dataFactory: async (filterData: IDashboardDataArg) =>
    await BusinessAreaRepo.query('get-dashboard-data', filterData),
});
