import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import CheckBox from '@/components/CheckBox';
import Table from '@/components/Table';
import Text from '@/components/Text';
import DocumentIcon from '@/screens/DocumentsScreen/Content/components/DocumentIcon/DocumentIcon';
import {
  DocumentListTableProps,
  ListItem,
} from '@/screens/DocumentsScreen/Content/components/DocumentListTable/interfaces';
import { GetPropsType } from '@/types/GetPropType';
import Link from '@/components/Link';
import RestClient from '@/client_v2/rest';
import Badge from '@/components/Badge';
import { DocumentStatuses } from '@/resources/constants/shared';
import '@/styles/screens/DocumentScreen/index.scss';
import { AppState } from '@/redux/store';

export const DocumentListTable: FC<DocumentListTableProps> = ({
  data,
  checkboxType,
  selectedDocuments,
  setSelectedDocuments,
  scrollDivRef,
}) => {
  const { t } = useTranslation();
  const dateFormat = t('common.dateFormat');
  const headers = [
    'way',
    'status',
    'name',
    'type',
    'date',
    'client',
    'company',
    'approver',
    'comment',
    'labels',
  ];

  const user = useSelector((state: AppState) => state.user);

  const docsListTable = [
    {
      header: true,
      view: (
        <div className="d-flex justify-content-center align-items-center h-100 pb-2">
          <CheckBox
            type={checkboxType}
            onClick={() => {
              if (selectedDocuments.length === data.length) {
                setSelectedDocuments([]);
              } else {
                setSelectedDocuments(data.map(({ id }) => id));
              }
            }}
          />
        </div>
      ),
    },
    ...headers.map((item) => ({
      header: true,
      view: (
        <div className="pb-2">
          <Text type="style36">{t('docListTable.' + item)}</Text>
        </div>
      ),
    })),
  ];

  const createListItems = () => {
    const listArray: ListItem[] = [];

    data.map((item, index) => {
      const sent = item.isSent && !item.isArchived && DocumentStatuses.IS_SENT;
      const approved = item.isApproved && !item.isArchived && DocumentStatuses.IS_APPROVED;
      const archived = item.isArchived && DocumentStatuses.IS_ARCHIVED;
      const declined = !item.isArchived && item.isDeclined && DocumentStatuses.IS_DECLINED;
      const waitingForApprove =
        !item.isArchived &&
        !item.isApproved &&
        item.isWaitingForApproval &&
        DocumentStatuses.IS_WAITING_FOR_APPROVAL;
      const status =
        archived ||
        sent ||
        approved ||
        declined ||
        waitingForApprove ||
        DocumentStatuses.IS_UPLOADED;
      const date = item.updatedAt || item.createdAt;
      const displayDate = dayjs(date).format(dateFormat);
      listArray.push(
        {
          view: (
            <div className="d-flex justify-content-center align-items-center h-100">
              <CheckBox
                type={selectedDocuments.includes(item.id) ? 'checked' : 'notChecked'}
                onClick={() => {
                  if (selectedDocuments.includes(item.id)) {
                    setSelectedDocuments((arr) => arr.filter((id) => id !== item.id));
                  } else {
                    setSelectedDocuments((arr) => [...arr, item.id]);
                  }
                }}
                id={`documentCheck-${index}`}
              />
            </div>
          ),
        },
        {
          view: (
            <Text
              type="style1"
              className={`${
                item.isWaitingForApproval ||
                (item.downloadedBy !== user?.user?.id &&
                  user &&
                  user.user &&
                  item.targetUsers.includes(user.user.id))
                  ? 'font-weight-bold text-dark'
                  : ''
              }`}
            >
              {t(`documentDirection.${item.direction}`)}
            </Text>
          ),
        },
        {
          view: (
            <Text
              type="style1"
              className={`${
                item.isWaitingForApproval ||
                (item.downloadedBy !== user?.user?.id &&
                  user &&
                  user.user &&
                  item.targetUsers.includes(user.user.id))
                  ? 'font-weight-bold text-dark'
                  : ''
              }`}
            >
              {status && t('documentStatus.' + status)}
            </Text>
          ),
        },
        {
          view: (
            <div className="item-name">
              <Link defaultType="style20" onClick={() => RestClient.Documents.download([item.id])}>
                <div
                  data-mdb-toggle="tooltip"
                  key={`tooltip-${item.id}`}
                  data-placement="top"
                  title={t('common.download')}
                >
                  {item.name}
                </div>
              </Link>
            </div>
          ),
        },
        {
          view: <DocumentIcon type={item.extension as GetPropsType<typeof DocumentIcon>['type']} />,
        },
        {
          view: (
            <Text
              type="style1"
              className={`${
                item.isWaitingForApproval ||
                (item.downloadedBy !== user?.user?.id &&
                  user &&
                  user.user &&
                  item.targetUsers.includes(user.user.id))
                  ? 'font-weight-bold text-dark'
                  : ''
              }`}
            >
              {displayDate}
            </Text>
          ),
        },
        {
          view: (
            <div className="mh-100">
              <Text
                className={`${
                  item.isWaitingForApproval ||
                  (item.downloadedBy !== user?.user?.id &&
                    user &&
                    user.user &&
                    item.targetUsers.includes(user.user.id))
                    ? 'font-weight-bold text-dark'
                    : ''
                } text-nowrap`}
                type="style1"
              >
                {item.clientName}
              </Text>
            </div>
          ),
        },
        {
          view: (
            <div className="mh-100">
              <Text
                className={`${
                  item.isWaitingForApproval ||
                  (item.downloadedBy !== user?.user?.id &&
                    user &&
                    user.user &&
                    item.targetUsers.includes(user.user.id))
                    ? 'font-weight-bold text-dark'
                    : ''
                } text-nowrap`}
                type="style1"
              >
                {item.companyName}
              </Text>
            </div>
          ),
        },
        {
          view: (
            <div className="mh-100">
              <Text
                className={`${
                  item.isWaitingForApproval ||
                  (item.downloadedBy !== user?.user?.id &&
                    user &&
                    user.user &&
                    item.targetUsers.includes(user.user.id))
                    ? 'font-weight-bold text-dark'
                    : ''
                } text-nowrap`}
                type="style1"
              >
                {item.approverName}
              </Text>
            </div>
          ),
        },
        {
          view: (
            <div className="d-flex justify-content-start align-items-center h-100">
              {item.comments &&
                item.comments.map(({ comment, commentId }) => (
                  <div className="mr-1" key={`${commentId}-${comment}`}>
                    <Badge colorStyle="lightGray" size="small" cursor="none" content={comment} />
                  </div>
                ))}
            </div>
          ),
        },
        {
          view: (
            <div className="d-flex justify-content-start align-items-center h-100">
              {item.tags &&
                item.tags.map(({ name, tagId }) => (
                  <div className="mr-1" key={`${tagId}-${name}`}>
                    <Badge colorStyle="lightGray" size="small" cursor="none" content={name} />
                  </div>
                ))}
            </div>
          ),
        },
      );
    });

    return listArray;
  };

  return (
    <div ref={scrollDivRef}>
      <Table column={docsListTable.length} data={[...createListItems(), ...docsListTable]} />
    </div>
  );
};
