import '@/layouts/DocumentsHeaderLayout/style.scss';
import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '@/redux/store';
import { cn } from '@/components/utils';

interface Props {
  title?: ReactNode;
  searchBar?: ReactNode;
  uploadDocumentButton?: ReactNode;
  datePicker?: ReactNode;
  sortOption?: ReactNode;
  filterOption?: ReactNode;
  datePickerPlaceholder?: ReactNode;
}

export const DocumentsHeaderLayout: FC<Props> = ({
  title,
  searchBar,
  uploadDocumentButton,
  datePicker,
  sortOption,
  filterOption,
  datePickerPlaceholder,
}) => {
  const expandDocument = useSelector((state: AppState) => state.documents.expand);

  return (
    <div className={cn('container-fluid', expandDocument ? 'my-0' : 'my-4')}>
      <div className="row">
        <div className="col-md-12">
          <div className={cn(expandDocument ? 'd-none' : 'row')}>
            <div className="col-lg-12 d-flex justify-content-lg-start justify-content-center text-nowrap">
              {title}
            </div>
          </div>
          <div className="row mb-2 d-flex flex-wrap align-items-center">
            <div className="col-lg-2 col-md-12 mb-lg-0 mb-md-4 mb-4 d-flex justify-content-center">
              {uploadDocumentButton}
            </div>
            <div className="col-lg-4 col-md-12 text-nowrap">{searchBar}</div>
            <div className="col-lg-3 col-md-12 pt-4 text-nowrap">
              {datePicker}
              <div className="row">
                <div className="col-lg-4 position-absolute text-nowrap">
                  {datePickerPlaceholder}
                </div>
              </div>
            </div>

            <div className="col-lg-1 ml-lg-5 ml-0 col-6 d-flex justify-content-lg-end justify-content-center text-nowrap">
              {sortOption}
            </div>
            <div className="col-lg-1 col-6 d-flex justify-content-lg-start justify-content-center align-items-center text-nowrap">
              {filterOption}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
