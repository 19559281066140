import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncCallback } from '@/hooks/useAsyncCallback';
import RestClient from '@/client_v2/rest';
import { createOnError } from '@/redux/message/message.callback';
import CheckBox from '@/components/CheckBox';
import Text from '@/components/Text';
import {
  IGetWorkflowData,
  IGetWorkflowMiles,
  IGetWorkflowSteps,
} from '@/client_v2/db/repositories/Workflow.repo';
import { WorkflowStatuses } from '@/resources/constants/shared';
import { AppState } from '@/redux/store';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import {
  isProcessModified,
  setCollapseProcessId,
  setMemorizeSelectedMileId,
} from '@/redux/processes/processes.actions';

interface Props {
  givenData?: IGetWorkflowSteps[] | null;
  givenMile?: IGetWorkflowMiles;
  stepsLoading: boolean;
  statusType: string;
  stepId: number;
  setStepsStatusLoading: () => void;
  isArchived: boolean;
  processData: IGetWorkflowData;
}

const typeMap = {
  checked: 'checked',
  halfChecked: 'halfChecked',
  lock: 'lock',
  notChecked: 'notChecked',
} as const;

export const StepStatusCheckbox: FC<Props> = ({
  givenData,
  givenMile,
  stepsLoading,
  statusType,
  stepId,
  setStepsStatusLoading,
  isArchived,
  processData,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.user.user);

  const canModify =
    user?.authority == Authorities.Admin ||
    user?.authority == Authorities.SuperAdmin ||
    (user?.authority == Authorities.JuniorUser && processData.userId === user?.id);

  const [onCheckBoxClick, isStepsStatusChangeLoading] = useAsyncCallback(
    async (stepId) => {
      dispatch(isProcessModified({ isModified: false }));
      dispatch(setMemorizeSelectedMileId(givenMile?.mileId || null));

      const currentStep = givenData && givenData.find((value) => value.stepId === stepId);
      if (stepId && givenMile && currentStep && !isStepsStatusChangeLoading) {
        setStepsStatusLoading();
        const newStatus =
          currentStep.status !== WorkflowStatuses.DONE
            ? WorkflowStatuses.DONE
            : WorkflowStatuses.LATER;

        const workflowsMilesStatus = givenMile.steps.every(
          (step) =>
            (step.stepId === currentStep.stepId && newStatus == WorkflowStatuses.DONE) ||
            (step.stepId !== currentStep.stepId && step.status === WorkflowStatuses.DONE),
        );

        await RestClient.WorkflowsSteps.patch({
          id: [stepId],
          status: newStatus,
        });

        dispatch(setCollapseProcessId(processData.id));

        await RestClient.WorkflowsMiles.patch({
          id: givenMile.mileId,
          status: workflowsMilesStatus ? WorkflowStatuses.DONE : WorkflowStatuses.IN_PROGRESS,
        });
      }
    },
    createOnError(dispatch),
    [stepsLoading, givenData],
  );
  const isResponsibleUser = () => {
    return givenData?.find(
      (step) =>
        stepId === step.stepId &&
        step.responsibleUsers?.filter((responsibleUser) => responsibleUser.userId === user?.id)
          ?.length,
    );
  };

  return (
    <div className="user-select-none w-50">
      {isStepsStatusChangeLoading ? (
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
      ) : (
        <div>
          <div className="row">
            {!isArchived && (canModify || isResponsibleUser()) && (
              <div className="col-12 d-flex justify-content-center">
                <CheckBox
                  type={statusType === WorkflowStatuses.DONE ? typeMap.checked : typeMap.notChecked}
                  onClick={() => onCheckBoxClick(stepId)}
                  colorStyle="primary"
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <Text type="style10" className="ml-0">
                {!isArchived ? t('workflowStatus.' + statusType) : t('workflowStatus.archived')}
              </Text>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StepStatusCheckbox;
