import '@/screens/common/SidebarFilter/style.scss';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { cn, CreateScopeCSS } from '@/components/utils';
import CheckBox from '@/components/CheckBox';
import Text from '@/components/Text';
import * as filterActions from '@/redux/sortAndFilters/sortAndFilters.actions';
import { AppState } from '@/redux/store';
import { ISidebarFilter } from '@/redux/sortAndFilters/sortAndFilters.interfaces';
import { useBusinessAreaData } from '@/client_v2/hooks/useBusinesAreaData';
import { setError } from '@/redux/message/message.action';
import { useClientsData } from '@/client_v2/hooks/useClientsData';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import Tooltip from '@/components/Tooltip';

const scope = CreateScopeCSS('sidebar-filter');
const titleClass = scope.and('title');
const itemsClass = scope.and('items');

interface Props {
  title?: string;
}

const typeMap = {
  checked: 'checked',
  halfChecked: 'halfChecked',
  lock: 'lock',
  notChecked: 'notChecked',
} as const;

export const SidebarFilter: FC<Props> = ({ title }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const filters = useSelector((state: AppState) => state.sortAndFilters.sidebarFilters);
  const { isSidebarInitialized } = useSelector((state: AppState) => state.sortAndFilters);

  const [isFilterLoading, businessAreaData] = useBusinessAreaData({
    triggerOnce: true,
    renderOnUpdate: false,
    onError: (err) => dispatch(setError(err.toString())),
  });

  const [clientsLoading, clients] = useClientsData({
    renderOnUpdate: true,
    triggerOnce: true,
    onError: (err) => dispatch(setError(err.toString())),
  });

  useEffect(() => {
    if (!isFilterLoading && !isSidebarInitialized) {
      dispatch(filterActions.createSidebarFilterData(businessAreaData));
    }
  }, [dispatch, isFilterLoading, isSidebarInitialized, businessAreaData]);

  const getDefaultAllState = () => {
    const allChecked = filters.filter((item: ISidebarFilter) => item.isChecked);
    return allChecked.length === filters.length
      ? typeMap.checked
      : allChecked.length == 0
      ? typeMap.notChecked
      : typeMap.halfChecked;
  };

  const [allType, setAllType] = useState<keyof typeof typeMap>(getDefaultAllState());

  const setCheckType = (value: ISidebarFilter) => {
    if (value.isActive && filters) {
      const newFilters = filters.map((item: ISidebarFilter) =>
        item.id == value.id ? { ...item, isChecked: !value.isChecked } : item,
      );
      dispatch(filterActions.modifySidebarFilterData(newFilters));
    }
    setAllCheckboxType(false);
  };

  const onClickAll = (newState: boolean) => {
    const newFilters = filters.map((item: ISidebarFilter) => ({ ...item, isChecked: !newState }));
    dispatch(filterActions.modifySidebarFilterData(newFilters));
  };

  const getCheckBoxType = (isAllowed: boolean, isChecked: boolean) => {
    if (!isAllowed) {
      return typeMap.lock;
    }
    return isChecked ? typeMap.checked : typeMap.notChecked;
  };

  const setAllCheckboxType = (isHalf: boolean) => {
    const newState = isHalf
      ? allType != typeMap.checked
        ? typeMap.checked
        : typeMap.notChecked
      : typeMap.halfChecked;
    setAllType(newState);
  };

  const user = useSelector((state: AppState) => state.user.user);
  const selectedClientId = useSelector((state: AppState) => state.user.selectedClientId);

  user &&
    user.authority !== Authorities.SuperAdmin &&
    !clientsLoading &&
    filters.map((filter) => {
      const actualClient = clients.clients.find((client) => user?.clients?.includes(client.id));
      filter.isActive = actualClient!.activeBusinessAreas.includes(filter.id) || false;
    });

  user &&
    user.authority === Authorities.SuperAdmin &&
    !clientsLoading &&
    filters.forEach((filter) => {
      const selectedClient =
        clients && clients!.clients!.find((client) => client.id === selectedClientId);
      !selectedClient
        ? (filter.isActive = true)
        : (filter.isActive = selectedClient!.activeBusinessAreas.includes(filter.id) || false);
    });

  const tooltipMessage =
    user?.authority !== Authorities.SuperAdmin ? t('sidebarFilter.tooltipMessage') : '';

  return (
    <div className={scope}>
      <div className={cn(titleClass)}>
        <div>{title}</div>
        <div className="d-flex">
          <div className="mr-1">{t('sidebarFilter.all')}</div>
          <CheckBox
            type={allType}
            onClick={() => {
              setAllCheckboxType(true);
              onClickAll(!(allType != typeMap.checked));
            }}
          />
        </div>
      </div>
      <div className={itemsClass}>
        {filters &&
          filters.map((value: ISidebarFilter, index: number) => (
            <div className="dashboard-item" key={`sidebar-filter-${index}`}>
              <Text type="style4">{t('sidebarFilter.' + value.name)}</Text>
              <Tooltip bubbleContent={(!value.isActive && tooltipMessage) || ''} place={'right'}>
                <CheckBox
                  type={getCheckBoxType(value.isActive, value.isChecked)}
                  onClick={() => value.isActive && setCheckType(value)}
                />
              </Tooltip>
            </div>
          ))}
      </div>
    </div>
  );
};
