import '@/components/Notification/style.scss';
import { FC } from 'react';
import { CreateScopeCSS } from '@/components/utils';
import Text from '@/components/Text';

const scope = CreateScopeCSS('components-notification');

interface Props {
  unread?: number;
}

export const Notification: FC<Props> = ({ unread, ...Props }) => (
  <div className={`${scope} position-absolute`} {...Props}>
    <div className={`${scope}-centering-container`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="#df4758"
        stroke="white"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`${scope}-circle`}
      >
        <circle cx="12" cy="12" r="10" />
      </svg>
      <Text type="style24" className={`${scope}-unreads`}>
        {unread}
      </Text>
    </div>
  </div>
);
