import { FC } from 'react';
import Icon from '@/components/Icon';
import Text from '@/components/Text';

export interface DocumentIconProps {
  type: 'xlsx' | 'docx' | 'pdf' | 'txt' | 'pptx' | 'jpeg' | 'png' | 'jpg';
}

export const DocumentIcon: FC<DocumentIconProps> = ({ type = 'docx' }) => {
  return (
    <div className="d-flex">
      <Icon type="file" colorStyle={type} circle />
      <div className="ml-1">
        <Text type="style28">.{type}</Text>
      </div>
    </div>
  );
};

export default DocumentIcon;
