/* eslint-disable @typescript-eslint/no-explicit-any */
import '@/layouts/ProcessesTableLayout/style.scss';
import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '@/redux/store';
import { CreateScopeCSS } from '@/components/utils';
import { Table } from '@/components/Table';
import Text from '@/components/Text';
import Badge from '@/components/Badge';
import Button from '@/components/Button';
import { ModalBox } from '@/components/ModalBox';
import URLS from '@/resources/constants/URLS';
import UnderlineInput from '@/components/Input/UnderlineInput';
import {
  IGetWorkflowMiles,
  IGetWorkflowResponsibleUser,
  IGetWorkflowLastModifyUser,
  IGetWorkflowSteps,
  IGetWorkflowData,
} from '@/client_v2/db/repositories/Workflow.repo';
import RestClient from '@/client_v2/rest';
import { createOnError } from '@/redux/message/message.callback';
import { useWorkflowStepData } from '@/client_v2/hooks/useWorkflowStepData';
import useAddComment from '@/hooks/useAddComment';
import StepStatusCheckbox from '@/screens/ProcessesScreen/components/StepStatusCheckbox';
import PorcessAddCommentPopupLayout from '@/layouts/PorcessAddCommentPopupLayout';
import ErrorFeedback from '@/components/ErrorFeedback';

const scope = CreateScopeCSS('layouts-processes-table-content-layout');

interface Props {
  givenData: IGetWorkflowSteps[];
  givenMile?: IGetWorkflowMiles;
  stepId: number[];
  setStepsStatusLoading: () => void;
  onUploadComment: (
    stepId: number,
    newComments: string[],
    deletedComments?: { commentId: number; comment: string }[],
  ) => void;
  processData: IGetWorkflowData;
  mileData: IGetWorkflowMiles;
  getProcessScrollbar: () => void;
  isArchived: boolean;
}

const headers = [
  'step',
  'deadline',
  'responsibleUsers',
  'status',
  'modificated',
  'documents',
  'comments',
];

export const ProcessesTableContent: FC<Props> = ({
  givenData = null,
  givenMile,
  onUploadComment,
  processData,
  mileData,
  setStepsStatusLoading,
  getProcessScrollbar,
  isArchived,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    comments,
    setComments,
    comment,
    setCommentInput,
    addComment,
    removeComment,
    showEmptyCommentError,
    showSameCommentError,
  } = useAddComment();

  const [scrolledDown, setScrolledDown] = useState(false);
  const [showAddCommentPopup, setShowAddCommentPopup] = useState(false);
  const [processStatus, setProcessStatus] = useState<string>();
  const [editingStep, setEditingStep] = useState<number>();
  const dateFormat = t('common.dateFormat');
  const downloadText = t('common.download');
  const emptyCommentErrorMessage = t('error.emptyBadgeError');
  const sameCommentErrorMessage = t('error.sameBadgeError');
  const pleaseSelectACompanyText = t('upload.selectACompany');

  const { selectedClientId } = useSelector((state: AppState) => state.user);

  const [stepsLoading, stepData] = useWorkflowStepData({
    triggerOnce: true,
    renderOnUpdate: false,
    onError: createOnError(dispatch),
  });

  const getBadgeColumn = (title: string, documentId?: number, index?: number) => {
    return documentId ? (
      <span
        data-toggle="tooltip"
        data-placement="top"
        title={downloadText}
        key={`document-id-${index}`}
        onClick={() => {
          documentId && RestClient.Documents.download([documentId]);
        }}
      >
        <div className="pl-5 ml-2">
          <Badge content={title} colorStyle="lightBlue" size="small" />
        </div>
      </span>
    ) : (
      <div className="pl-5 ml-2">
        <Badge
          content={title}
          colorStyle="lightBlue"
          size="small"
          cursor="none"
          key={`comment-id-${index}`}
        />
      </div>
    );
  };

  const getListingButtons = (value: string, stepId: number) => {
    const currentStep = givenData && givenData.find((step) => step.stepId === stepId);
    return (
      <div className="subProcessStepButtons" key={`listing-buttons-${stepId}`}>
        {value === 'documents' && (
          <div
            data-mdb-toggle="tooltip"
            data-placement="top"
            title={!selectedClientId ? pleaseSelectACompanyText : ''}
          >
            <Button
              size="small"
              className="processStepButton "
              iconSize="medium"
              icon="uploadCloud"
              onClick={() => {
                navigate(URLS.root.documents.toString(), {
                  state: {
                    selectedWorkflow: {
                      selectedWorkflowStep: currentStep,
                      selectedProcessData: processData,
                      selectedMileData: mileData,
                    },
                    openModal: true,
                  },
                });
              }}
              disabled={!selectedClientId}
              cursor={!selectedClientId ? 'none' : 'pointer'}
            >
              <Text className="d-flex" type="style17">
                {t('processesScreen.process.table.buttons.upload')}
              </Text>
            </Button>
          </div>
        )}
        {value === 'comments' && (
          <Button
            size="small"
            className="processStepButton"
            iconSize="medium"
            icon="chat"
            onClick={() => {
              setShowAddCommentPopup(true);
              setEditingStep(stepId);
              const currentStep = givenData && givenData.find((step) => step.stepId === stepId);
              currentStep && currentStep.comments
                ? setComments(currentStep.comments)
                : setComments([]);
            }}
          >
            <Text className="d-flex" type="style17">
              {t('processesScreen.process.table.buttons.comment')}
            </Text>
          </Button>
        )}
        <Button
          size="small"
          className="processStepButton"
          iconSize="medium"
          icon="listUl"
          onClick={() => {
            setScrolledDown(!scrolledDown);
          }}
        >
          <Text className="d-flex" type="style17">
            {t('processesScreen.process.table.buttons.listing')}
          </Text>
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (stepsLoading) return;
    if (!stepData.map((item) => item.status)) return;
    stepData.map((item) => {
      setProcessStatus(item.status);
    });
  }, [stepsLoading, stepData, processStatus]);

  const getColumn = (
    subValue: string[],
    stepId: number,
    modifiedUser: IGetWorkflowLastModifyUser[],
  ) => {
    if (subValue[0] === 'documents' || subValue[0] === 'comments') {
      return [
        <div className="processesBadgets" key={stepId}>
          {subValue[1] &&
            Array.isArray(subValue[1]) &&
            subValue[1].map((value: any, index: number) => {
              return getBadgeColumn(
                value.fileName || value.comment,
                subValue[0] === 'documents' && value.documentId,
                index,
              );
            })}
        </div>,
        getListingButtons(subValue[0], stepId),
      ];
    }
    if (subValue[0] === 'status') {
      return (
        <StepStatusCheckbox
          givenData={givenData}
          givenMile={givenMile}
          stepsLoading={stepsLoading}
          statusType={subValue[1]}
          stepId={stepId}
          setStepsStatusLoading={setStepsStatusLoading}
          isArchived={isArchived}
          processData={processData}
        />
      );
    }
    if (subValue[0] === 'responsibleUsers') {
      return (
        <Text type="style10" className="text-wrap">
          {subValue[1] &&
            Array.isArray(subValue[1]) &&
            subValue[1].map(
              (item: IGetWorkflowResponsibleUser, key) =>
                item.userName + (key + 1 >= subValue[1].length ? '' : ', '),
            )}
        </Text>
      );
    }
    if (subValue[0] === 'deadline' && subValue[1]) {
      return <Text type="style10">{dayjs(subValue[1]).format(dateFormat)}</Text>;
    }
    if (subValue[0] === 'updatedAt' && subValue[1]) {
      return (
        <Text type="style10">
          <div>{dayjs(subValue[1]).format(dateFormat)}</div>
          <div>{modifiedUser.map((user: IGetWorkflowLastModifyUser) => user.userName)}</div>
        </Text>
      );
    }
    if (subValue[0] === 'stepName') {
      return <Text type="style10">{subValue[1]}</Text>;
    }
    return;
  };

  const getRow = (value: IGetWorkflowSteps, key: number) => {
    return Object.entries(value).map((subValue, columnNumber: number) => {
      if (subValue[0] === 'stepName') {
        columnNumber = 1;
      }
      if (subValue[0] === 'deadline') {
        columnNumber = 2;
      }
      if (subValue[0] === 'responsibleUsers') {
        columnNumber = 3;
      }
      if (subValue[0] === 'status') {
        columnNumber = 4;
      }
      if (subValue[0] === 'updatedAt') {
        columnNumber = 5;
      }
      if (subValue[0] === 'documents') {
        columnNumber = 6;
      }
      if (subValue[0] === 'comments') {
        columnNumber = 7;
      }
      if (subValue[0] === 'clientName') {
        columnNumber = 8;
      }
      return {
        row: key + 2,
        column: columnNumber,
        children: getColumn(subValue, value.stepId, value?.lastModifyUser),
      };
    });
  };

  useEffect(() => {
    getProcessScrollbar();
  }, [getProcessScrollbar, stepsLoading]);

  const saveCommentButton = () => {
    const currentStep =
      editingStep && givenData && givenData.find((step) => step.stepId === editingStep);

    const commentTextsToUpload =
      currentStep &&
      comments
        .filter(
          (newComment) =>
            !(currentStep.comments || []).some(
              (oldComment) => oldComment.comment === newComment.comment,
            ),
        )
        .map((newComment) => newComment.comment);

    const commentsToDelete =
      currentStep &&
      (currentStep.comments || []).filter(
        (oldComment) => !comments.some((newComment) => newComment.comment === oldComment.comment),
      );

    editingStep &&
      commentTextsToUpload &&
      commentsToDelete &&
      onUploadComment(editingStep, commentTextsToUpload, commentsToDelete);
    setEditingStep(undefined);

    setShowAddCommentPopup(false);
  };

  return (
    <>
      <div
        className={
          scope + ` d-flex align-items-center mt-3 pl-3 mb-4 ${scrolledDown ? 'scrolledDown' : ''}`
        }
      >
        {givenData && (
          <Table
            header={
              headers &&
              headers.map((value: string, i: number) => {
                return {
                  column: i + 1,
                  row: 1,
                  children: (
                    <div className="d-flex justify-content-around pr-5">
                      <Text type="style30" className="font-weight-bold">
                        {t('processesScreen.process.table.' + value)}
                      </Text>
                    </div>
                  ),
                  header: true,
                };
              })
            }
            body={
              givenData &&
              givenData
                .map((value: IGetWorkflowSteps, key: number) => {
                  return getRow(value, key);
                })
                .flat(1)
                .filter((item) => item)
            }
          />
        )}
      </div>
      {showAddCommentPopup && (
        <ModalBox
          headerText={t('processesScreen.process.manageComment')}
          show={showAddCommentPopup}
          onClickClose={() => {
            setShowAddCommentPopup(false);
            setEditingStep(undefined);
          }}
          modalWidth="medium"
        >
          <PorcessAddCommentPopupLayout
            badgeContainer={
              <>
                {comments.map((comment, index) => (
                  <div key={`commentPopupBadgeContainer-${index}`} className="pr-1 pt-1">
                    <Badge
                      content={comment.comment}
                      colorStyle="lightGray"
                      size="small"
                      selfClosing
                      onClick={() => removeComment(comment)}
                    />
                  </div>
                ))}
              </>
            }
            commentInput={
              <UnderlineInput
                onChange={setCommentInput}
                value={comment}
                titleText={t('processesScreen.process.addComment')}
                onEnterKeyPressed={addComment}
              />
            }
            addCommentButton={
              <Button size="small" onClick={addComment}>
                <Text type="style24">{t('processesScreen.process.addComment')}</Text>
              </Button>
            }
            errorField={
              <>
                {showEmptyCommentError && <ErrorFeedback errorMessage={emptyCommentErrorMessage} />}
                {showSameCommentError && <ErrorFeedback errorMessage={sameCommentErrorMessage} />}
              </>
            }
            saveCommentButton={
              <Button size="large" rounded onClick={() => saveCommentButton()}>
                <Text className="px-2 py-1 d-flex" type="style24">
                  {t('processesScreen.process.saveComment')}
                </Text>
              </Button>
            }
          />
        </ModalBox>
      )}
    </>
  );
};
