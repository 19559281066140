import { ISortAndFiltersState } from '@/redux/sortAndFilters/sortAndFilters.interfaces';
import { IlogoutRequest, LOGOUT_REQUEST } from '@/redux/auth/auth.types';
import {
  CREATE_SIDEBARFILTER,
  SidebarFilterActionTypes,
  MODIFY_SIDEBARFILTER,
  SET_PROCESS_FILTER,
  SET_DOCUMENT_FILTER,
  RESET_DOCUMENT_FILTER,
} from './sortAndFilters.types';

const defaultSidebarFilterState: ISortAndFiltersState = {
  isLoading: true,
  isSidebarInitialized: false,
  errorMessage: null,
  sidebarFilters: [],
  processFilters: {
    clientIds: [],
    companyIds: [],
    userIds: [],
    statuses: [],
    hasSelected: false,
    archived: ['notArchived'],
  },
  documentFilters: {
    clientIds: [],
    companyIds: [],
    statuses: [],
    approverId: [],
    direction: [],
    toDate: undefined,
    fromDate: undefined,
    hasSelected: false,
    stepIds: undefined,
  },
};

export default (
  state = defaultSidebarFilterState,
  action: SidebarFilterActionTypes | IlogoutRequest,
): ISortAndFiltersState => {
  switch (action.type) {
    case SET_PROCESS_FILTER:
      return {
        ...state,
        processFilters: action.payload,
      };
    case SET_DOCUMENT_FILTER:
      return {
        ...state,
        documentFilters: action.payload,
      };
    case RESET_DOCUMENT_FILTER:
      return {
        ...state,
        documentFilters: { stepIds: undefined },
      };
    case CREATE_SIDEBARFILTER:
      return {
        ...state,
        isSidebarInitialized: true,
        sidebarFilters: action.payload,
      };

    case MODIFY_SIDEBARFILTER:
      return {
        ...state,
        sidebarFilters: action.payload,
      };

    case LOGOUT_REQUEST:
      return {
        ...state,
        ...defaultSidebarFilterState,
      };
    default:
      return state;
  }
};
