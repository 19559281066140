import '@/layouts/HeaderLayout/style.scss';
import { FC, ReactNode } from 'react';
import Card from '@/components/Card';
import { cn, CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('layouts-header-layout');
const logoConatiner = scope.and('logoConatiner');
const appLogoContainer = logoConatiner.and('appLogoContainer');
const companyLogoContainer = logoConatiner.and('companyLogoContainer');
const linkContainer = scope.and('linkContainer');
const userContainer = scope.and('userContainer');
const image = userContainer.and('image');
const name = userContainer.and('name');
const exit = userContainer.and('exit');

interface HeaderLayoutProps {
  appLogo: ReactNode;
  companyLogo?: ReactNode;
  navLinks: ReactNode;
  userImage: ReactNode;
  userName: ReactNode;
  logout: ReactNode;
}

export const HeaderLayout: FC<HeaderLayoutProps> = ({
  companyLogo,
  appLogo,
  navLinks,
  userImage,
  userName,
  logout,
}) => (
  <Card rounded bgColorStyle="darkGray">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 ">
              <div className={logoConatiner}>
                <div
                  className={cn(
                    appLogoContainer,
                    'd-flex',
                    'justify-content-center',
                    'align-items-center',
                  )}
                >
                  {appLogo}
                </div>
                <div
                  className={cn(
                    companyLogoContainer,
                    'd-flex',
                    'justify-content-center',
                    'align-items-center',
                  )}
                >
                  {companyLogo}
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className={linkContainer}>{navLinks}</div>
            </div>
            <div className="col-md-4 pt-3 pb-3">
              <div className={userContainer}>
                <div className={image}>{userImage}</div>
                <div className={cn(name, 'text-nowrap, d-inline-block text-truncate')}>
                  {userName}
                </div>
                <div className={exit} id="logoutButton">
                  {logout}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Card>
);
