import { FC, ReactNode } from 'react';
import '@/layouts/CustomerContentTableLayout/style.scss';

interface Props {
  partnershipDate?: ReactNode;
  recommender?: ReactNode;
  company?: ReactNode;
  commentList?: ReactNode;
  branch?: ReactNode;
  active?: ReactNode;
  activationDate?: ReactNode;
  checkBoxData?: ReactNode;
  badgeContent?: ReactNode;
}

export const CustomerContentTableLayout: FC<Props> = ({
  partnershipDate,
  recommender,
  company,
  commentList,
  branch,
  active,
  activationDate,
  checkBoxData,
  badgeContent,
}) => (
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-12 mb-4">
        <div className="row text-nowrap">
          <div className="col-md-6 col-xs-12">
            <div className="row ml-1 ">
              <div className="col-md-12 d-flex flex-nowrap ">{partnershipDate}</div>
            </div>
            <div className="row ml-1 mt-4">
              <div className="col-md-12 d-flex flex-nowrap">{recommender}</div>
            </div>
            <div className="row ml-1 mt-4">
              <div className="col-md-12 d-flex flex-nowrap">{company}</div>
            </div>
            <div className="row ml-1 mt-4">
              <div className="col-md-12 d-flex flex-nowrap">{commentList}</div>
            </div>
            <div className="row ml-2 mt-3 mr-1">
              <div className="col-md-12">
                <div className="row mt-3">
                  <div className="col-12 d-flex flex-wrap ">{badgeContent}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="border-right" />
            <div className="col-md-1" />
          </div>
          <div className="col-md-5 col-11 ml-4 mb-4">
            <div className="row user-select-none">
              <div className="col-5">{branch}</div>
              <div className="col-2 ">{active}</div>
              <div className="col-5 d-flex justify-content-end  d-inline-block text-truncate">
                {activationDate}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12">
                <div className="border-bottom" />
              </div>
            </div>
            <div className="row mb-1 align-items-center user-select-none">
              <div className="col-12  ml-1 d-inline-block text-truncate">{checkBoxData}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
