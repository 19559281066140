/* eslint-disable camelcase */
import { axiosFormDataInstance } from '@/client_v2/rest/axios/axiosInstance';
import { PatchCompany } from '@/client_v2/rest/types/PatchCompany';
import { postComments } from '@/client_v2/rest/Comments/postComments';
import { API_COMPANIES_$ID, $ID_PARAM } from '@/client_v2/rest/Companies/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';
import { CompanyPoll } from '@/client_v2/polls/Company.poll';
import { deleteComment } from '@/client_v2/rest/Comments/deleteComment';

export const patchCompany = async (
  { file, data }: PatchCompany,
  newComments?: { comments: string[]; userId: number | null },
  deletedComments?: { commentId: number; comment: string }[],
) => {
  const formData = new FormData();
  if (file) formData.set('file', file);
  formData.set('data', JSON.stringify(data));
  formData.set('_method', 'PATCH');
  const res = await axiosFormDataInstance.post(
    createUrl(API_COMPANIES_$ID, {
      params: {
        [$ID_PARAM]: data.id.toString(),
      },
    }),
    formData,
  );
  if (newComments && newComments.comments && newComments.comments.length && newComments.userId) {
    await postComments({
      comments: newComments,
      entityType: 'companies',
      entityId: data.id,
    });
  }
  if (deletedComments && deletedComments.length) {
    await Promise.all(
      deletedComments.map((comment) =>
        deleteComment({
          commentId: comment.commentId,
          entityType: 'companies',
          entityId: data.id,
        }),
      ),
    );
  }

  await CompanyPoll.trigger();
  return res;
};
