import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { PollRes } from '@/client_v2/rest/types/PollRes';
import {
  API_BUSINESS_AREA_POLL_$TIMESTAMP,
  $TIMESTAMP_PARAM,
} from '@/client_v2/rest/BusinessAreas/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';

export const pollBusinessAreas = (timestamp: number) =>
  axiosInstance.get<PollRes>(
    createUrl(API_BUSINESS_AREA_POLL_$TIMESTAMP, {
      params: {
        [$TIMESTAMP_PARAM]: timestamp.toString(),
      },
    }),
  );
