export const API_DOCUMENTS = 'api/documents';
export const API_DOCUMENTS_$ID = 'api/documents/$id';
export const API_DOCUMENTS_POLL_$TIMESTAMP = 'api/documents/poll/$timestamp';
export const API_DOCUMENTS_SEARCH = 'api/documents/search';
export const API_DOCUMENTS_DOWNLOAD = 'api/documents/download';
export const API_DOCUMENTS_APPROVE = 'api/documents/$id/approve';
export const API_DOCUMENTS_SEND_APPROVAL = 'api/send-approval';
export const API_DOCUMENTS_SEND = 'api/documents/$id/send';

export const DISABLE_PAGINATIONS_QUERY = 'pagination_disabled';
export const IDS_QUERY = 'id[]';

export const $ID_PARAM = '$id';
export const $TIMESTAMP_PARAM = '$timestamp';
