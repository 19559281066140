import { InanoSQLConfig } from '@nano-sql/core/lib/interfaces';

export class File {
  public name!: string;
  public type!: string;

  constructor(value: string | File) {
    if (typeof value === 'string') {
      const splitted = value.split('.');
      if (splitted.length === 1) {
        this.name = value;
      } else {
        this.type = `.${splitted.pop()}`;
        this.name = splitted.join('.');
      }
    } else {
      this.type = value.type;
      this.name = value.name;
    }
  }

  toString() {
    if (this.type) return `${this.name}${this.type}`;
    return this.name;
  }
}

export const FileType: InanoSQLConfig['types'] = {
  file: {
    interfaceText: 'file',
    onSelect(value) {
      return new File(value);
    },
  },
};
