import { FC, useMemo, useState, useCallback } from 'react';
import HUFlag from '@/resources/images/lang-selector-flag-hu.svg';
import ENFlag from '@/resources/images/lang-selector-flag-en.svg';
import i18n from '@/i18next';
import { LanguageSelector as View } from './LanguageSelector.view';

export const LanguageSelector: FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [selectedLang, setSelectedLang] = useState<string>(
    i18n.language.substring(i18n.language.indexOf('-') + 1),
  );

  const FLAG_WITH_LABELS = [
    {
      key: 'EN',
      imgSrc: ENFlag,
    },
    {
      key: 'HU',
      imgSrc: HUFlag,
    },
  ];

  const selectedFlag = useMemo(
    () => FLAG_WITH_LABELS.find(({ key }) => key == selectedLang.toUpperCase()),
    // disableing linter, as it would require a const to be in the dependency array.
    // eslint-disable-next-line
    [selectedLang],
  );

  const flags = FLAG_WITH_LABELS.filter((flag) => flag.key !== i18n.language.toUpperCase());

  const toggleDropdown = useCallback(
    () => setIsDropdownOpen(!isDropdownOpen),
    [setIsDropdownOpen, isDropdownOpen],
  );

  const onFlagClick = useCallback(
    (key) => {
      i18n.changeLanguage(key.toLowerCase());
      setSelectedLang(key);
      toggleDropdown();
    },
    [toggleDropdown],
  );

  return (
    <View
      selectedFlag={selectedFlag ? selectedFlag : FLAG_WITH_LABELS[0]}
      isDropdownOpen={isDropdownOpen}
      toggleDropdown={toggleDropdown}
      flags={flags}
      onFlagClick={onFlagClick}
    />
  );
};
