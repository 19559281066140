import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import activityFeedReducer from '@/redux/activityFeed/activityFeed.reducer';
import { AppAction } from './apptypes';
import userReducers from './user/user.reducer';
import usersReducers from './users/users.reducer';
import authReducers from './auth/auth.reducer';
import documentSearchReducer from './documents/documents.search.reducer';
import clientsReducer from './clients/clients.reducer';
import errorReducer from './message/message.reducer';
import documentsReducer from './documents/documents.reducer';
import processesSearchReducer from './processes/processes.search.reducer';
import sortAndFiltersReducer from './sortAndFilters/sortAndFilters.reducer';
import companyManagementReducer from './companyManagement/companyManagement.reducer';
import processesReducer from './processes/processes.reducer';
import coreReducer from './core/core.reducer';
import sortReducer from './sort/sort.reducer';

declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'documentSearch',
    'clients',
    'processesSearch',
    'documents',
    'processes',
    'core',
    'sort',
  ],
};

export const rootReducer = combineReducers({
  user: userReducers,
  users: usersReducers,
  auth: authReducers,
  documentSearch: documentSearchReducer,
  activityFeeds: activityFeedReducer,
  clients: clientsReducer,
  sortAndFilters: sortAndFiltersReducer,
  companies: companyManagementReducer,
  error: errorReducer,
  documents: documentsReducer,
  processesSearch: processesSearchReducer,
  processes: processesReducer,
  core: coreReducer,
  sort: sortReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type AppState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, AppAction>;

export const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(applyMiddleware(thunk))
    : applyMiddleware(thunk),
);

export const persistor = persistStore(store);
