import * as Endpoints from '@/client/endpoints';
import * as Interfaces from '@/client/activityLogInterfaces';
import axiosInstance from '@/client_v2/rest/axios/axiosInstance';

//ACTIVITY-LOG CALLS
export class ActivityLog {
  activityLogSearch = async (
    fetchbody: Interfaces.IActivityLogsRequest,
  ): Promise<Interfaces.IActivityLogsResponse> => {
    const response = await axiosInstance.post(Endpoints.ACTIVITY_LOG_SEARCH, fetchbody);
    return response.data;
  };
  activityLogs = async (): Promise<Interfaces.IActivityLogsResponse> => {
    const response = await axiosInstance.get(Endpoints.ACTIVITY_LOGS);
    return response.data;
  };
  activityLog = async (): Promise<Interfaces.IActivityLogResponse> => {
    const response = await axiosInstance.get(Endpoints.ACTIVITY_LOG);
    return response.data;
  };
}
