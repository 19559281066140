import { FC, useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useWorkflowData } from '@/client_v2/hooks/useWorkflowData';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import ProcessesTableLayout from '@/layouts/ProcessesTableLayout';
import { AppState } from '@/redux/store';
import { getFilteredBusinessAreas } from '@/resources/utils';
import { setError } from '@/redux/message/message.action';
import { useAsyncCallback } from '@/hooks/useAsyncCallback';
import RestClient from '@/client_v2/rest';
import { createOnError } from '@/redux/message/message.callback';
import { useWorkflowStepData } from '@/client_v2/hooks/useWorkflowStepData';
import Card from '@/components/Card';
import Text from '@/components/Text';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';
import { useClientsData } from '@/client_v2/hooks/useClientsData';
import { IGetWorkflowData } from '@/client_v2/db/repositories/Workflow.repo';
import '@/styles/screens/ProcessScreen/index.scss';
import { setProcessScrollbarPosition } from '@/redux/processes/processes.actions';
import { resetDocumentFilter } from '@/redux/sortAndFilters/sortAndFilters.actions';
import { useAddWorkflowModalData } from '@/client_v2/hooks/useAddWorkflowModalData';

export const ProcessesContent: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sidebarFilters, processFilters } = useSelector((state: AppState) => state.sortAndFilters);
  const userId = useSelector((state: AppState) => state.user.user?.id);
  const { searchWords } = useSelector((state: AppState) => state.processesSearch);
  const { processSort } = useSelector((state: AppState) => state.sort);
  const [stepsStatusLoading, setStepsStatusLoading] = useState<boolean>(false);
  const [workflowData, setWorkflowData] = useState<IGetWorkflowData[]>([]);

  const scrollDivRef = useRef<HTMLDivElement>(null);
  const scrollbarPosition = useSelector((state: AppState) => state.processes.scrollbarPosition);
  const selectedCompanyId = useSelector((state: AppState) => state.user.selectedCompanyId);
  const selectedClientId = useSelector((state: AppState) => state.user.selectedClientId);

  const getSelectedBusinessAreas = useCallback(() => {
    return getFilteredBusinessAreas(sidebarFilters);
  }, [sidebarFilters]);

  const [stepsLoading, stepData] = useWorkflowStepData({
    triggerOnce: true,
    renderOnUpdate: false,
    onError: createOnError(dispatch),
  });

  const [isLoading, data, trigger] = useWorkflowData({
    renderOnUpdate: true,
    triggerOnce: {
      businessAreaIds: getSelectedBusinessAreas(),
      sort: { field: 'endDate', direction: 'asc' },
      ...(selectedCompanyId
        ? {
            selectedCompanyId: selectedCompanyId,
          }
        : {}),
      ...(selectedClientId
        ? {
            selectedClientId: selectedClientId,
          }
        : {}),
      filters: processFilters,
    },
    onError: (err) => dispatch(setError(err.toString())),
  });

  const [, clientData] = useAddWorkflowModalData({
    triggerOnce: true,
    renderOnUpdate: false,
  });

  useEffect(() => {
    dispatch(resetDocumentFilter());
  }, [dispatch]);

  const noSearchResultError =
    searchWords && searchWords.length && searchWords[0] !== '' && !data?.workflowData?.length
      ? t('processesScreen.noSearchResult')
      : undefined;
  const noFilterResultError =
    (sidebarFilters.some((filter) => !filter.isChecked) ||
      processFilters.hasSelected ||
      processFilters.archived?.length) &&
    !data?.workflowData?.length
      ? t('processesScreen.noFilterResult')
      : undefined;

  const [sendArchive, archiveLoading] = useAsyncCallback(
    RestClient.Workflows.patch,
    createOnError(dispatch),
    [dispatch],
  );

  const [sendStepUpdate, stepUpdateLoading] = useAsyncCallback(
    RestClient.WorkflowsSteps.patch,
    createOnError(dispatch),
    [dispatch],
  );

  useEffect(() => {
    if (searchWords && searchWords!.length) {
      trigger({
        businessAreaIds: getSelectedBusinessAreas(),
        sort: processSort,
        filters: processFilters,
        search: searchWords!,
        selectedCompanyId,
        selectedClientId,
      });
    } else {
      trigger({
        businessAreaIds: getSelectedBusinessAreas(),
        sort: processSort,
        filters: processFilters,
        selectedCompanyId,
        selectedClientId,
      });
    }
  }, [
    searchWords,
    trigger,
    getSelectedBusinessAreas,
    processSort,
    processFilters,
    selectedClientId,
    selectedCompanyId,
  ]);

  useEffect(() => {
    setStepsStatusLoading(false);
  }, [isLoading]);

  const user = useSelector((state: AppState) => state.user.user);

  const [clientsLoading, clients] = useClientsData({
    renderOnUpdate: true,
    triggerOnce: true,
    onError: (err) => dispatch(setError(err.toString())),
  });

  useEffect(() => {
    const workflowData: IGetWorkflowData[] = [];

    user &&
      user.authority !== Authorities.SuperAdmin &&
      !clientsLoading &&
      data?.workflowData?.map((filter) => {
        const actualClient = clients.clients.find((client) => user?.clients?.includes(client.id));

        const currentCompanyId = filter.companyId;
        const currentClientId = filter.client;

        const checkBusinessAreaId = actualClient?.activeBusinessAreas.includes(
          filter.businessAreaId,
        );
        if (
          (user?.clients?.includes(filter.client) &&
            checkBusinessAreaId &&
            !clientData.clientsAndCompanies.filter(
              (item) => item.id === currentClientId && item.companies.length,
            ).length) ||
          user.companies.includes(currentCompanyId)
        ) {
          workflowData.push(filter);
        }
      });

    user && user.authority !== Authorities.SuperAdmin
      ? setWorkflowData(workflowData)
      : setWorkflowData(data?.workflowData);
    // PREVENTING INFINITE LOOP IT SHOULD NOT UPDATE ON clientData.clientsAndCompanies CHANGES
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients, clientsLoading, data, user]);

  const onUploadComment = (
    stepId: number,
    newComments: string[],
    deletedComments?: { commentId: number; comment: string }[],
  ) =>
    sendStepUpdate(
      {
        id: [stepId],
      },
      {
        userId: userId!,
        comments: newComments,
      },
      deletedComments,
    );

  useEffect(() => {
    if (!scrollbarPosition) {
      dispatch(setProcessScrollbarPosition(scrollDivRef.current?.scrollTop || 0));
    }
  }, [dispatch, scrollbarPosition]);

  const getProcessScrollbar = useCallback(() => {
    if (scrollbarPosition) {
      scrollDivRef.current?.scrollTo(0, scrollbarPosition || 0);
    }
  }, [scrollbarPosition]);

  useEffect(() => {
    getProcessScrollbar();
  }, [getProcessScrollbar]);

  const isModified = useSelector((state: AppState) => state.processes.isModified);

  return (
    data && (
      <LoadingPlaceholder
        showSpinner={true}
        isLoading={
          isModified
            ? isLoading || stepsStatusLoading || stepsLoading || archiveLoading || stepUpdateLoading
            : false
        }
      >
        <div className="scope">
          <div className="scroll-tb" ref={scrollDivRef}>
            {data &&
              workflowData?.map((item, index) => (
                <ProcessesTableLayout
                  canArchive={
                    user?.authority == Authorities.Admin ||
                    user?.authority == Authorities.SuperAdmin ||
                    user?.authority == Authorities.ClientUser2
                  }
                  key={'ProcessesTable' + item.id}
                  processData={item}
                  processTitle={item.name}
                  serial={item.id}
                  client={
                    data.clients.find((value) =>
                      value.clientId == item.client ? value.clientName : '',
                    )?.clientName
                  }
                  company={
                    data.companies.find((value) =>
                      value.companyId == item.companyId ? value.companyName : '',
                    )?.companyName
                  }
                  fromDate={item.startDate ? item.startDate.toString() : null}
                  toDate={item.endDate ? item.endDate.toString() : null}
                  miles={item.miles}
                  onArchive={() =>
                    sendArchive({
                      id: item.id,
                      isArchived: !item.isArchived,
                    })
                  }
                  setStepsStatusLoading={() => setStepsStatusLoading(true)}
                  stepId={stepData && stepData.map((step) => step.id)}
                  onUploadComment={onUploadComment}
                  isArchived={item.isArchived}
                  getProcessScrollbar={getProcessScrollbar}
                  index={index}
                />
              ))}
            {(noSearchResultError || noFilterResultError) && (
              <Card bgColorStyle="white" rounded className="d-flex justify-content-center">
                <Text type="style7" className="p-5">
                  {noSearchResultError || noFilterResultError}
                </Text>
              </Card>
            )}
          </div>
        </div>
      </LoadingPlaceholder>
    )
  );
};
