import * as Endpoints from '@/client/endpoints';
import * as Interfaces from '@/client/tagManagementInterfaces';
import axiosInstance from '@/client_v2/rest/axios/axiosInstance';

//Tag management related calls
export class TagManagement {
  createTag = async (
    tagId: string,
    tag: Interfaces.ICreateTagRequest,
  ): Promise<Interfaces.ICreateTagResponse> => {
    const response = await axiosInstance.post(
      Endpoints.CREATE_TAG.replace('{tags}', tagId.toString()),
      tag,
    );
    return response.data;
  };

  putTag = async (
    tagId: number,
    tag: Interfaces.IUpdateTagRequest,
  ): Promise<Interfaces.IUpdateTagResponse> => {
    const response = await axiosInstance.put(
      Endpoints.UPDATE_TAG.replace('{tag}', tagId.toString()),
      tag,
    );
    return response.data;
  };
  patchTag = async (tagId: number, tag: Interfaces.IUpdateTagRequest) => {
    const response = await axiosInstance.patch(
      Endpoints.UPDATE_TAG.replace('{tag}', tagId.toString()),
      tag,
    );
    return response.data;
  };

  deleteTag = async (tagId: number) => {
    const response = await axiosInstance.delete(
      Endpoints.DELETE_TAG.replace('{tag}', tagId.toString()),
    );
    return response.data;
  };
}
