import { UserPoll } from '@/client_v2/polls/User.poll';
import { CompanyPoll } from '@/client_v2/polls/Company.poll';
import { CommentPoll } from '@/client_v2/polls/Comment.poll';
import { ClientPoll } from '@/client_v2/polls/Client.poll';
import { CompanyRepo } from '@/client_v2/db/repositories/Company.repo';
import { createDataHook } from '@/client_v2/hooks/utils/createDataHook';
import { UserRepo } from '@/client_v2/db/repositories/User.repo';
import { ClientRepo } from '@/client_v2/db/repositories/Client.repo';

export const useCompanyModalData = createDataHook({
  polls: [CompanyPoll, UserPoll, CommentPoll, ClientPoll],
  dataFactory: async (id?: number) => {
    if (typeof id !== 'undefined')
      return {
        company: await CompanyRepo.query('get-company-modal-data', { id }),
        contacts: await UserRepo.query('get-company-modal-data'),
        clients: await ClientRepo.query('get-company-modal-data'),
      };

    return {
      contacts: await UserRepo.query('get-company-modal-data'),
      clients: await ClientRepo.query('get-company-modal-data'),
    };
  },
});
