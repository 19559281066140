import { Dispatch } from 'redux';
import { ISort } from './sort.interfaces';
import { RESET_SORT_ACTION, SET_DOCUMENTS_SORT, SET_PROCESS_SORT } from './sort.type';

export const setProcessSortAction = (sort: ISort) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_PROCESS_SORT,
    payload: sort,
  });
};

export const setDocumentSortAction = (sort: ISort) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_DOCUMENTS_SORT,
    payload: sort,
  });
};

export const resetSortAction = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_SORT_ACTION,
  });
};
