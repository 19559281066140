export const API_COMMENTS = 'api/comments';
export const API_COMMENTS_$ID = 'api/comments/$id';
export const API_COMMENTS_POLL_$TIMESTAMP = 'api/comments/poll/$timestamp';
export const API_COMMENTS_$ENTITY_TIPE_$ENTITY_ID_COMMENTS = 'api/$entityType/$entityId/comments';
export const API_COMMENTS_$ENTITY_TIPE_$ENTITY_ID_COMMENTS_$COMMENT_ID =
  'api/$entityType/$entityId/comments/$commentId';
export const API_COMMENTS_SEARCH = 'api/comments/search';

export const DISABLE_PAGINATIONS_QUERY = 'pagination_disabled';

export const $ID_PARAM = '$id';
export const $TIMESTAMP_PARAM = '$timestamp';
export const $ENTITY_TIPE_PARAM = '$entityType';
export const $ENTITY_ID_PARAM = '$entityId';
export const $COMMENT_ID = '$commentId';
