import { ChangeEventHandler, FC, FocusEventHandler, KeyboardEventHandler } from 'react';
import '@/components/Input/UnderlineInput/style.scss';
import Text from '@/components/Text';
import { cn, CreateScopeCSS } from '@/components/utils';

const text = CreateScopeCSS('components-text');
const scope = CreateScopeCSS('components-input');
const line = scope.and('line');
const title = scope.and('title');
const input = scope.and('input');
const counter = scope.and('counter');
const hasError = scope.and('error');
const isRequired = scope.and('required');

interface Props {
  focus?: boolean;
  titleText?: string;
  inputText?: string;
  max?: number;
  onFocus: FocusEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
  onChangeInputText: ChangeEventHandler<HTMLInputElement>;
  onKeyPress: KeyboardEventHandler<HTMLInputElement>;
  error?: boolean;
  required?: boolean;
  type?: 'text' | 'number';
  id?: string;
  readOnly?: boolean;
}

// TODO refactor to have at least the default input properties
export const UnderlineInput: FC<Props> = ({
  titleText,
  inputText = '',
  id,
  type = 'text',
  readOnly = false,
  focus,
  max,
  onFocus,
  onBlur,
  onChangeInputText,
  onKeyPress,
  error,
  required,
}) => (
  <div className={cn(scope, error && hasError)}>
    {titleText && (
      <div
        className={cn(
          title,
          title.and((inputText || focus) && 'focusInput'),
          required && isRequired,
        )}
      >
        <Text type="style30">{titleText}</Text>
      </div>
    )}
    <div className={input}>
      <input
        disabled={readOnly}
        readOnly={readOnly}
        id={id ? id : `underline-input-${titleText ? titleText : 'default'}`}
        type={type}
        className={cn(text.and('style19'), error && 'is-invalid', 'form-control')}
        value={inputText}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChangeInputText}
        onKeyPress={onKeyPress}
      />
    </div>
    {max && (
      <div className={counter}>
        <Text type={inputText.length >= max ? 'style32' : 'style31'}>
          {inputText.length}/{max}
        </Text>
      </div>
    )}
    <div className={cn(line, readOnly ? 'disabled' : '')} />
    <div className={cn(line, line.and('active'), line.and(focus && 'focusInput'))} />
  </div>
);
