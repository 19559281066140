import '@/components/Text/style.scss';
import { FC } from 'react';
import { cn, CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('components-text');
const underLine = CreateScopeCSS(scope.and('underline'));
const isRequired = CreateScopeCSS(scope.and('required'));

type TextStyles =
  | 'style1'
  | 'style2'
  | 'style3'
  | 'style4'
  | 'style5'
  | 'style6'
  | 'style7'
  | 'style8'
  | 'style9'
  | 'style10'
  | 'style11'
  | 'style12'
  | 'style13'
  | 'style14'
  | 'style15'
  | 'style16'
  | 'style17'
  | 'style18'
  | 'style19'
  | 'style20'
  | 'style21'
  | 'style22'
  | 'style23'
  | 'style24'
  | 'style25'
  | 'style26'
  | 'style27'
  | 'style28'
  | 'style29'
  | 'style30'
  | 'style31'
  | 'style32'
  | 'style33'
  | 'style34'
  | 'style35'
  | 'style36'
  | 'style37'
  | 'style38'
  | 'style36Title';

export interface TextProps {
  capitalization?: 'uppercase' | 'lowercase' | 'capitalize';
  className?: string;
  type: TextStyles;
  underline?: boolean;
  required?: boolean;
  isLoading?: boolean;
}

export const Text: FC<TextProps> = ({
  type,
  children,
  capitalization,
  className,
  underline,
  required,
  isLoading = false,
}) => (
  <span
    className={cn(
      scope,
      scope.and(type),
      scope.and(capitalization!),
      className,
      underline && underLine,
      required && isRequired,
    )}
  >
    {isLoading ? <div className="spinner-border text-light" role="status" /> : children}
  </span>
);
