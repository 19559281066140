import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { CompaniesRes } from '@/client_v2/rest/types/CompaniesRes';
import { API_COMPANIES_SEARCH } from '@/client_v2/rest/Companies/URLS';

export const getCompaniesByIds = (ids: number[]) =>
  axiosInstance.post<CompaniesRes>(API_COMPANIES_SEARCH, {
    scopes: [
      {
        name: 'ids',
        parameters: [ids.toString()],
      },
    ],
  });
