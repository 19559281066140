import '@/layouts/ProcessCreateModalLayout/style.scss';
import { FC, ReactNode } from 'react';
import { cn, CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('layouts-process-create-modal-layout');
const line = scope.and('line');

export interface Milestone {
  milestoneNameInput: ReactNode;
  milestoneTable: ReactNode;
}
interface Props {
  nameInput: ReactNode;
  businessAreasInput: ReactNode;
  clientInput: ReactNode;
  companyInput: ReactNode;
  startInput: ReactNode;
  endInput: ReactNode;
  reminderInput: ReactNode;
  saveButton: ReactNode;
  saveAndNewButton: ReactNode;
  milestoneTitleText: ReactNode;
  milestoneSubtitleText: ReactNode;
  milestoneDropDown: ReactNode;
  milestoneStepsText: ReactNode;
  newMilestoneButton: ReactNode;
  showMilestones: boolean;
  milestones?: ReactNode;
  startDatePlaceholder?: ReactNode;
  endDatePlaceholder?: ReactNode;
}

export const ProcessCreateModalLayout: FC<Props> = ({
  nameInput,
  businessAreasInput,
  clientInput,
  companyInput,
  startInput,
  endInput,
  reminderInput,
  saveButton,
  saveAndNewButton,
  milestoneTitleText,
  milestoneSubtitleText,
  milestoneDropDown,
  newMilestoneButton,
  showMilestones,
  milestones,
  startDatePlaceholder,
  endDatePlaceholder,
}) => {
  return (
    <div className={cn(scope, 'mb-5 container-fluid')}>
      <div className="row position-relative">
        <div className="col-12 overflow-hidden">
          <div className={cn('row')}>
            <div className={cn('col-lg-6 col-12')}>{nameInput}</div>
          </div>
          <div className={cn('row', 'mt-lg-4 mt-0', 'align-items-center text-nowrap')}>
            <div className={cn('col-lg-4 col-md-6 col-sm-12 col-12 mt-lg-0 mt-4 mb-2 z-index-4')}>
              {clientInput}
            </div>
            <div className={cn('col-lg-4 col-md-6 col-sm-12 col-12 mt-lg-0 mt-4 mb-2 z-index-3')}>
              {companyInput}
            </div>
            <div className={cn('col-lg-4 col-md-6 col-sm-12 col-12 mt-lg-0 mt-4 mb-2 z-index-5')}>
              {businessAreasInput}
            </div>
            <div className={cn('col-lg-4 col-md-6 col-sm-12 col-12 mt-lg-0 mt-4', 'mt-3')}>
              {startInput}
              <div className="row">
                <div className="col-lg-12 position-absolute">{startDatePlaceholder}</div>
              </div>
            </div>
            <div className={cn('col-lg-4 col-md-6 col-sm-12 col-12 mt-lg-0 mt-4', 'mt-3')}>
              {endInput}
              <div className="row">
                <div className="col-lg-12 position-absolute">{endDatePlaceholder}</div>
              </div>
            </div>
            <div className={cn('col-lg-4 col-md-6 col-sm-12 col-12 mt-lg-0 mt-4', 'mt-3')}>
              {reminderInput}
            </div>
          </div>

          <div className={cn('row text-nowrap', 'mt-4', 'button-position', 'pb-3')}>
            <div className={cn('col-lg-4 col-12 mt-lg-0 mt-4 mr-5 d-flex justify-content-center')}>
              {saveButton}
            </div>
            <div className={cn('col-lg-4 col-12 mt-lg-0 mt-4 d-flex justify-content-center')}>
              {saveAndNewButton}
            </div>
          </div>
          <hr className={line} />
          <div className="row">
            <div className="col-6">{milestoneTitleText}</div>
            <div className={cn('col-6 float-right')}>{milestoneDropDown}</div>
          </div>
          <div className="row">
            <div className="col-12">{milestoneSubtitleText}</div>
          </div>
          {showMilestones && (
            <>
              <div className="row">
                <div className="col-12">{milestones}</div>
              </div>
              <div className="row mt-5">
                <div className="col-12 mt-5">{newMilestoneButton}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
