import { AxiosError } from 'axios';
import { IActivityFeed, IActivityFeedWithLinks } from '@/client_v2/rest/types/ActivityFeedRes';

export const REFRESH_ACTIVITY_TABLE_REQUEST = 'REFRESH_ACTIVITY_TABLE_REQUEST';
export const REFRESH_ACTIVITY_TABLE_SUCCESS = 'REFRESH_ACTIVITY_TABLE_SUCCESS';
export const REFRESH_ACTIVITY_TABLE_FAILURE = 'REFRESH_ACTIVITY_TABLE_FAILURE';
export const GET_ACTIVITY_TABLE_REQUEST = 'GET_ACTIVITY_TABLE_REQUEST';
export const GET_ACTIVITY_TABLE_FAILURE = 'GET_ACTIVITY_TABLE_FAILURE';
export const GET_ACTIVITY_TABLE_SUCCESS = 'GET_ACTIVITY_TABLE_SUCCESS';
export const EXPORT_ACTIVITY_TABLE_REQUEST = 'EXPORT_ACTIVITY_TABLE_REQUEST';
export const EXPORT_ACTIVITY_TABLE_FAILURE = 'EXPORT_ACTIVITY_TABLE_FAILURE';
export const EXPORT_ACTIVITY_TABLE_SUCCESS = 'EXPORT_ACTIVITY_TABLE_SUCCESS';

export const GET_MORE_ACTIVITY_REQUEST = 'GET_MORE_ACTIVITY_REQUEST';
export const GET_MORE_ACTIVITY_SUCCESS = 'GET_MORE_ACTIVITY_SUCCESS';
export const GET_MORE_ACTIVITY_FAILURE = 'GET_MORE_ACTIVITY_FAILURE';

export interface IRefreshActivityTableRequestAction {
  type: typeof REFRESH_ACTIVITY_TABLE_REQUEST;
}
export interface IRefreshActivityTableSuccessAction {
  type: typeof REFRESH_ACTIVITY_TABLE_SUCCESS;
  payload: IActivityFeed[];
}

export interface IRefreshActivityTableFailureAction {
  type: typeof REFRESH_ACTIVITY_TABLE_FAILURE;
  error: AxiosError;
}

export interface IGetActivityTableRequestAction {
  type: typeof GET_ACTIVITY_TABLE_REQUEST;
}
export interface IGetActivityTableSuccessAction {
  type: typeof GET_ACTIVITY_TABLE_SUCCESS;
  payload: {
    data: IActivityFeed[];
    nextLink: string;
  };
}

export interface IGetActivityTableFailureAction {
  type: typeof GET_ACTIVITY_TABLE_FAILURE;
  error: AxiosError;
}

export interface IExportActivityTableRequestAction {
  type: typeof EXPORT_ACTIVITY_TABLE_REQUEST;
}
export interface IExportActivityTableSuccessAction {
  type: typeof EXPORT_ACTIVITY_TABLE_SUCCESS;
  payload: string;
}

export interface IExportActivityTableFailureAction {
  type: typeof EXPORT_ACTIVITY_TABLE_FAILURE;
  error: AxiosError;
}

export interface ILoadMoreActivityTableRequestAction {
  type: typeof GET_MORE_ACTIVITY_REQUEST;
}
export interface ILoadMoreActivityTableSuccessAction {
  type: typeof GET_MORE_ACTIVITY_SUCCESS;
  payload: IActivityFeedWithLinks;
}
export interface ILoadMoreActivityTableFailureAction {
  type: typeof GET_MORE_ACTIVITY_FAILURE;
  error: AxiosError;
}

export type ActivityFeedActionTypes =
  | ILoadMoreActivityTableRequestAction
  | ILoadMoreActivityTableSuccessAction
  | ILoadMoreActivityTableFailureAction
  | IRefreshActivityTableSuccessAction
  | IRefreshActivityTableRequestAction
  | IRefreshActivityTableFailureAction
  | IGetActivityTableRequestAction
  | IGetActivityTableFailureAction
  | IGetActivityTableSuccessAction
  | IExportActivityTableRequestAction
  | IExportActivityTableSuccessAction
  | IExportActivityTableFailureAction;
