import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { PatchWorkflow } from '@/client_v2/rest/types/PatchWorkflow';
import { API_WORKFLOWS_$ID, $ID_PARAM } from '@/client_v2/rest/Workflow/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';
import { WorkflowPoll } from '@/client_v2/polls/Workflow.poll';
import { WorkflowMilePoll } from '@/client_v2/polls/WorkflowMile.poll';
import { WorkflowStepPoll } from '@/client_v2/polls/WorkflowStep.poll';
import { deleteWorkflowStepById } from '@/client_v2/rest/WorkflowStep/deleteWorkflowStepById';

export const patchWorkflow = async (data: PatchWorkflow, removeStepIds?: number[]) => {
  const res = await axiosInstance.patch(
    createUrl(API_WORKFLOWS_$ID, {
      params: {
        [$ID_PARAM]: data.id.toString(),
      },
    }),
    data,
  );
  await removeStepIds?.forEach((removeStepId) => {
    deleteWorkflowStepById(removeStepId);
  });
  await WorkflowStepPoll.trigger();
  await WorkflowMilePoll.trigger();
  await WorkflowPoll.trigger();
  return res;
};
