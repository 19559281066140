import '@/components/SyncButton/style.scss';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateScopeCSS } from '@/components/utils';
import Button from '@/components/Button';

const scope = CreateScopeCSS('components-sync-button');

interface Props {
  onClick?(): void;
  loading?: boolean;
  id?: string;
}

export const SyncButton: FC<Props> = ({ id, onClick, loading }) => {
  const { t } = useTranslation();

  return (
    <div className={scope} id={id ? id : 'sync-button'}>
      <Button
        paddingSize="small"
        size="medium"
        type="button"
        outline
        onClick={onClick}
        loadingSpinner={loading}
      >
        {t('common.sync')}
      </Button>
    </div>
  );
};
