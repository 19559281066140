import { ClientPoll } from '@/client_v2/polls/Client.poll';
import { CompanyPoll } from '@/client_v2/polls/Company.poll';
import { UserPoll } from '@/client_v2/polls/User.poll';
import { axiosFormDataInstance } from '@/client_v2/rest/axios/axiosInstance';
import { PostUser } from '@/client_v2/rest/types/PostUser';
import { API_USERS } from '@/client_v2/rest/Users/URLS';

export const postUser = async ({ file, data }: PostUser) => {
  const formData = new FormData();
  if (file) formData.set('file', file);
  formData.set('data', JSON.stringify(data));
  const res = await axiosFormDataInstance.post(API_USERS, formData);
  await UserPoll.trigger();
  data.clientIds && (await ClientPoll.trigger());
  data.companyIds && (await CompanyPoll.trigger());

  return res;
};
