import {
  IDocumentDateFilters,
  IDocumentFilters,
  IProcessFilters,
  ISidebarFilter,
} from './sortAndFilters.interfaces';

export const CREATE_SIDEBARFILTER = 'CREATE_SIDEBARFILTER';
export const MODIFY_SIDEBARFILTER = 'MODIFY_SIDEBARFILTER';
export const SET_PROCESS_FILTER = 'SET_PROCESS_FILTER';
export const SET_DOCUMENT_FILTER = 'SET_DOCUMENT_FILTER';
export const RESET_DOCUMENT_FILTER = 'RESET_DOCUMENT_FILTER';
export const SET_DOCUMENT_DATE_FILTER = 'SET_DOCUMENT_DATE_FILTER';

export interface ISetProcessFilterAction {
  type: typeof SET_PROCESS_FILTER;
  payload: IProcessFilters;
}
export interface ISetDocumentFilterAction {
  type: typeof SET_DOCUMENT_FILTER;
  payload: IDocumentFilters;
}
export interface IResetDocumentFilterAction {
  type: typeof RESET_DOCUMENT_FILTER;
  payload: IDocumentFilters;
}
export interface ISetDocumentDateFilterAction {
  type: typeof SET_DOCUMENT_DATE_FILTER;
  payload: IDocumentDateFilters;
}

export interface ICreateSidebarFilterAction {
  type: typeof CREATE_SIDEBARFILTER;
  payload: ISidebarFilter[];
}

export interface IModifySidebarFilterAction {
  type: typeof MODIFY_SIDEBARFILTER;
  payload: ISidebarFilter[];
}
export type SidebarFilterActionTypes =
  | ISetProcessFilterAction
  | ISetDocumentFilterAction
  | IModifySidebarFilterAction
  | ISetDocumentDateFilterAction
  | ICreateSidebarFilterAction
  | ICreateSidebarFilterAction
  | IResetDocumentFilterAction;
