import {
  ActivityFeedActionTypes,
  GET_ACTIVITY_TABLE_REQUEST,
  GET_ACTIVITY_TABLE_SUCCESS,
  GET_ACTIVITY_TABLE_FAILURE,
  REFRESH_ACTIVITY_TABLE_SUCCESS,
  REFRESH_ACTIVITY_TABLE_REQUEST,
  REFRESH_ACTIVITY_TABLE_FAILURE,
  EXPORT_ACTIVITY_TABLE_FAILURE,
  EXPORT_ACTIVITY_TABLE_REQUEST,
  EXPORT_ACTIVITY_TABLE_SUCCESS,
  GET_MORE_ACTIVITY_REQUEST,
  GET_MORE_ACTIVITY_SUCCESS,
  GET_MORE_ACTIVITY_FAILURE,
} from '@/redux/activityFeed/activityFeed.types';
import { IActivityFeedState } from '@/redux/activityFeed/activityFeed.interfaces';

const defaultActivityFeedState: IActivityFeedState = {
  data: [],
  isLoading: false,
  isExportLoading: false,
  errorMessage: null,
  fileExportData: null,
  nextLink: '',
};

export default (
  state = defaultActivityFeedState,
  action: ActivityFeedActionTypes,
): IActivityFeedState => {
  switch (action.type) {
    case GET_ACTIVITY_TABLE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isExportLoading: false,
        errorMessage: null,
      };
    case GET_ACTIVITY_TABLE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        errorMessage: null,
        isLoading: false,
        isExportLoading: false,
        nextLink: action.payload.nextLink,
      };
    case GET_ACTIVITY_TABLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isExportLoading: false,
        errorMessage: action.error.message,
      };
    case REFRESH_ACTIVITY_TABLE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isExportLoading: false,
        errorMessage: null,
      };
    case REFRESH_ACTIVITY_TABLE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        errorMessage: null,
        isLoading: false,
        isExportLoading: false,
      };
    case REFRESH_ACTIVITY_TABLE_FAILURE:
      return {
        ...state,
        data: [],
        isLoading: false,
        isExportLoading: false,
        errorMessage: action.error.message,
      };
    case EXPORT_ACTIVITY_TABLE_REQUEST:
      return {
        ...state,
        isLoading: false,
        isExportLoading: true,
        errorMessage: null,
      };
    case EXPORT_ACTIVITY_TABLE_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isLoading: false,
        isExportLoading: false,
        fileExportData: action.payload,
      };
    case EXPORT_ACTIVITY_TABLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isExportLoading: false,
        errorMessage: action.error.message,
        fileExportData: null,
      };
    case GET_MORE_ACTIVITY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_MORE_ACTIVITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error.message,
      };
    case GET_MORE_ACTIVITY_SUCCESS:
      return {
        ...state,
        data: [...state.data, ...action.payload.data],
        isLoading: false,
        nextLink: action.payload.links.next ? action.payload.links.next : '',
        errorMessage: '',
      };
    default:
      return state;
  }
};
