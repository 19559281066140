import { AxiosError } from 'axios';
import { ILoginError } from './auth.interfaces';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';

export const SEND_FORGOT_PASSWORD_REQUEST = 'SEND_FORGOT_PASSWORD_REQUEST';
export const SEND_FORGOT_PASSWORD_SUCCESS = 'SEND_FORGOT_PASSWORD_SUCCESS';
export const SEND_FORGOT_PASSWORD_FAILURE = 'SEND_FORGOT_PASSWORD_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export interface IresetPasswordRequest {
  type: typeof RESET_PASSWORD_REQUEST;
}

export interface IresetPasswordSuccess {
  type: typeof RESET_PASSWORD_SUCCESS;
}

export interface IresetPasswordFailure {
  type: typeof RESET_PASSWORD_FAILURE;
  error: AxiosError;
}
export interface IrefreshTokenRequest {
  type: typeof REFRESH_TOKEN_REQUEST;
}

export interface IrefreshTokenSuccess {
  type: typeof REFRESH_TOKEN_SUCCESS;
  payload: {
    token: string;
    // eslint-disable-next-line camelcase
    refresh_token: string;
  };
}

export interface IrefreshTokenFailure {
  type: typeof REFRESH_TOKEN_FAILURE;
}
export interface IlogoutRequest {
  type: typeof LOGOUT_REQUEST;
}

export interface IlogoutSuccess {
  type: typeof LOGOUT_SUCCESS;
}

export interface IlogoutFailure {
  type: typeof LOGOUT_FAILURE;
  error: AxiosError | Error;
}
export interface IsendForgotPasswordRequest {
  type: typeof SEND_FORGOT_PASSWORD_REQUEST;
}

export interface IsendForgotPasswordSuccess {
  type: typeof SEND_FORGOT_PASSWORD_SUCCESS;
}

export interface IsendForgotPasswordFailure {
  type: typeof SEND_FORGOT_PASSWORD_FAILURE;
  error: AxiosError;
}
export interface ILoginUserRequest {
  type: typeof LOGIN_REQUEST;
}

export interface ILoginUserSuccess {
  type: typeof LOGIN_SUCCESS;
  payload: {
    token: string;
    refreshToken: string;
    userId: number;
  };
}

export interface ILoginUserFailure {
  type: typeof LOGIN_FAILURE;
  error: ILoginError;
}

export interface IuserRegistrationRequest {
  type: typeof REGISTRATION_REQUEST;
}

export interface IuserRegistrationSuccess {
  type: typeof REGISTRATION_SUCCESS;
}

export interface IuserRegistrationFailure {
  type: typeof REGISTRATION_FAILURE;
}

export type AuthActionTypes =
  | IresetPasswordFailure
  | IresetPasswordRequest
  | IresetPasswordSuccess
  | IrefreshTokenFailure
  | IrefreshTokenRequest
  | IrefreshTokenSuccess
  | IlogoutFailure
  | IlogoutRequest
  | IlogoutSuccess
  | IsendForgotPasswordFailure
  | IsendForgotPasswordRequest
  | IsendForgotPasswordSuccess
  | ILoginUserFailure
  | ILoginUserRequest
  | ILoginUserSuccess
  | IuserRegistrationRequest
  | IuserRegistrationSuccess
  | IuserRegistrationFailure;
