/* eslint-disable camelcase */
import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { PostActivityFeedsRequest } from '@/client_v2/rest/types/PostActivityFeeds';
import { IExportActivityFeedsParams } from '@/redux/activityFeed/activityFeed.interfaces';
import { API_ACTIVITY_LOGS_EXPORT_URL } from '@/client_v2/rest/ActivityFeeds/URLS';

export const postActivityFeedsExport = ({
  startDate,
  endDate,
  sort,
}: IExportActivityFeedsParams) => {
  const filteringData: PostActivityFeedsRequest = {
    ...(startDate &&
      endDate && {
        pagination_disabled: true,
        scopes: [
          {
            name: 'createdAtGte',
            parameters: [startDate],
          },
          {
            name: 'createdAtLte',
            parameters: [endDate],
          },
        ],
      }),
    ...(sort && {
      sort: [
        {
          field: 'created_at',
          direction: sort!,
        },
      ],
    }),
  };

  return axiosInstance.post(API_ACTIVITY_LOGS_EXPORT_URL, filteringData);
};
