import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { WorkflowsRes } from '@/client_v2/rest/types/WorkflowsRes';
import { API_WORKFLOWS, DISABLE_PAGINATIONS_QUERY } from '@/client_v2/rest/Workflow/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';

export const getWorkflows = () =>
  axiosInstance.get<WorkflowsRes>(
    createUrl(API_WORKFLOWS, {
      queries: {
        [DISABLE_PAGINATIONS_QUERY]: '1',
      },
    }),
  );
