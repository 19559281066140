import { InanoSQLTableConfig } from '@nano-sql/core/lib/interfaces';
import { UserRepo } from '@/client_v2/db/repositories/User.repo';
import { BusinessAreaRepo } from '@/client_v2/db/repositories/BusinessArea.repo';
import { ClientRepo } from '@/client_v2/db/repositories/Client.repo';
import { CompanyRepo } from '@/client_v2/db/repositories/Company.repo';
import { DocumentRepo } from '@/client_v2/db/repositories/Document.repo';
import { TagRepo } from '@/client_v2/db/repositories/Tag.repo';
import { WorkflowRepo } from '@/client_v2/db/repositories/Workflow.repo';
import { WorkflowMileRepo } from '@/client_v2/db/repositories/WorkflowMile.repo';
import { WorkflowStepRepo } from '@/client_v2/db/repositories/WorkflowStep.repo';
import { CommentRepo } from '@/client_v2/db/repositories/Comment.repo';

export const DBTables: InanoSQLTableConfig[] = [
  BusinessAreaRepo.config,
  ClientRepo.config,
  CommentRepo.config,
  CompanyRepo.config,
  DocumentRepo.config,
  TagRepo.config,
  UserRepo.config,
  WorkflowRepo.config,
  WorkflowMileRepo.config,
  WorkflowStepRepo.config,
];
