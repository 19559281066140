import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { PatchClient } from '@/client_v2/rest/types/PatchClient';
import { postComments } from '@/client_v2/rest/Comments/postComments';
import { API_CLIENTS_$ID, $ID_PARAM } from '@/client_v2/rest/Clients/URLS';
import { createUrl } from '@/client_v2/rest/utils/createUrl';
import { ClientPoll } from '@/client_v2/polls/Client.poll';
import { deleteComment } from '@/client_v2/rest/Comments/deleteComment';

export const patchClient = async (
  data: PatchClient,
  newComments?: { comments: string[]; userId: number },
  deletedComments?: { commentId: number; comment: string }[],
) => {
  const res = await axiosInstance.patch<{ data: { id: number } }>(
    createUrl(API_CLIENTS_$ID, {
      params: {
        [$ID_PARAM]: data.id.toString(),
      },
    }),
    data,
  );
  if (newComments && newComments.comments && newComments.comments.length && newComments.userId) {
    await postComments({
      comments: newComments,
      entityType: 'clients',
      entityId: data.id,
    });
  }
  if (deletedComments && deletedComments.length) {
    await Promise.all(
      deletedComments.map((comment) =>
        deleteComment({
          commentId: comment.commentId,
          entityType: 'clients',
          entityId: data.id,
        }),
      ),
    );
  }

  await ClientPoll.trigger();
  return res;
};
