import { IClientsState } from '@/redux/clients/clients.interfaces';
import {
  ClientActionTypes,
  UPLOAD_NEW_CLIENT_SUCCESS,
  UPLOAD_NEW_CLIENT_REQUEST,
  UPLOAD_NEW_CLIENT_FAILURE,
  SET_COLLAPSE_CLIENT_ID,
  SET_CLIENT_SCROLLBAR_POSITION,
} from '@/redux/clients/clients.types';

const defaultClientsState: IClientsState = {
  isLoading: false,
  errorMessage: null,
  client: null,
  collapseClientId: null,
  scrollbarPosition: undefined,
};

export default (state = defaultClientsState, action: ClientActionTypes): IClientsState => {
  switch (action.type) {
    case UPLOAD_NEW_CLIENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case UPLOAD_NEW_CLIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        client: {
          id: action.payload.id,
          name: action.payload.name,
          taxId: action.payload.tax_id,
          startDate: action.payload.start_date,
          proposer: action.payload.proposer,
          deletedAt: action.payload.deleted_at,
          createdAt: action.payload.created_at,
          updatedAt: action.payload.updated_at,
          contactsIds: action.payload.contacts_ids,
          usersIds: action.payload.users_ids,
          companiesIds: action.payload.companies_ids,
          businessAreasIds: action.payload.business_areas_ids,
          commentsIds: action.payload.comments_ids,
        },
      };
    case UPLOAD_NEW_CLIENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error.message,
      };
    case SET_COLLAPSE_CLIENT_ID:
      return {
        ...state,
        collapseClientId: action.payload,
      };
    case SET_CLIENT_SCROLLBAR_POSITION:
      return {
        ...state,
        scrollbarPosition: action.payload,
      };
    default:
      return state;
  }
};
