import { IGetDocumentsData } from '@/client_v2/db/repositories/Document.repo';
import { IDocumentFilters } from '@/redux/sortAndFilters/sortAndFilters.interfaces';
import { DocumentStatuses } from '@/resources/constants/shared';

export const DocumentStatusCheck = (document: IGetDocumentsData, filters: IDocumentFilters) => {
  if (document.isArchived && filters.statuses?.includes(DocumentStatuses.IS_ARCHIVED)) {
    return true;
  } else {
    if (
      !document.isArchived &&
      document.isSent &&
      filters.statuses?.includes(DocumentStatuses.IS_SENT)
    ) {
      return true;
    }

    if (
      document.isApproved &&
      !document.isSent &&
      !document.isArchived &&
      filters.statuses?.includes(DocumentStatuses.IS_APPROVED)
    ) {
      return true;
    }
    if (
      !document.isApproved &&
      !document.isSent &&
      !document.isWaitingForApproval &&
      !document.isDeclined &&
      filters.statuses?.includes(DocumentStatuses.IS_UPLOADED)
    ) {
      return true;
    }

    if (
      !document.isApproved &&
      document.approverId &&
      document.isWaitingForApproval &&
      filters.statuses?.includes(DocumentStatuses.IS_WAITING_FOR_APPROVAL)
    ) {
      return true;
    }

    if (
      !document.isApproved &&
      !document.isSent &&
      document.isDeclined &&
      filters.statuses?.includes(DocumentStatuses.IS_DECLINED)
    ) {
      return true;
    }
  }
  return false;
};
