import '@/components/ClientSelector/style.scss';

import { FC } from 'react';
import { cn, CreateScopeCSS } from '@/components/utils';

const scope = CreateScopeCSS('client-selector');
const item = scope.and('item');

interface Item {
  id: number | null;
  key: string;
}

interface Props {
  data: Item[] | null;
  isDropdownOpen: boolean;
  toggleDropdown: () => void;
  selected: Item | null;
  onClick: (item: Item) => void;
}

interface IButtonWithImg {
  onClick: () => void;
  label: string;
}

const ButtonWithImg: FC<IButtonWithImg> = ({ onClick, label }) => (
  <div className={cn(scope, item)}>
    <button
      className="btn btn-default col d-flex align-items-center"
      type="button"
      onClick={onClick}
    >
      {label}
    </button>
  </div>
);

export const ClientSelector: FC<Props> = (props) => (
  <div className={scope}>
    <div className="dropdown">
      <ButtonWithImg
        onClick={props.toggleDropdown}
        label={props.selected?.key ? props.selected.key : ''}
      />
      <ul className={`dropdown-menu min-w-0 ${props.isDropdownOpen ? 'd-block' : 'd-none'}`}>
        {props.data &&
          props.data.map((item) => (
            <li key={item.key.toString()}>
              <ButtonWithImg label={item.key} onClick={() => props.onClick(item)} />
            </li>
          ))}
      </ul>
    </div>
  </div>
);
