import '@/layouts/ProcessesTableLayout/style.scss';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@/components/Button';
import Text from '@/components/Text';
import Icon from '@/components/Icon';
import { ModalBox } from '@/components/ModalBox';
import AddCompanyModal from '@/screens/AccountSettings/Contents/AddCompanyModal';
import AddUserModal from '@/screens/AccountSettings/Contents/AddUserModal';
import { ICommentCompaniesData } from '@/client_v2/db/repositories/Company.repo';
import CompanyContentHeaderLayout from '@/layouts/CompanyContentHeaderLayout';
import { setModalOpen } from '@/redux/core/core.actions';
import { AppState } from '@/redux/store';
import { Authorities } from '@/screens/AccountSettings/Contents/interfaces';

const useCompaniesContentHeader = () => {
  const { t } = useTranslation();
  const user = useSelector((state: AppState) => state.user.user);
  const isAdmin = user?.authority == Authorities.Admin || user?.authority == Authorities.SuperAdmin;

  const taxNumber = t('companyScreen.taxNumber');
  const modification = t('companyScreen.modification');
  const newUser = t('companyScreen.newUser');
  const confirmRemove = t('companyScreen.confirmRemove');
  const contactName = t('companyScreen.contactUser');
  const clientText = t('companyScreen.client');
  const confirm = t('common.confirm');
  const cancel = t('common.cancel');
  const areYouSure = t('common.areYouSure');
  const createNewUserModalHeader = t('accountSettings.header.users.newUser');

  return {
    isAdmin,
    taxNumber,
    modification,
    newUser,
    confirmRemove,
    confirm,
    cancel,
    areYouSure,
    contactName,
    createNewUserModalHeader,
    clientText,
  };
};

interface Props {
  companyId: number;
  clientId: number;
  companyName: string;
  contact: string[];
  taxId: string;
  clientName?: string;
  comments: ICommentCompaniesData[];
  onDelete: (id: number) => void;
  index: number;
}

export const CompanyContentHeader: FC<Props> = ({
  companyName,
  contact,
  taxId,
  companyId,
  clientId,
  clientName,
  onDelete,
  index,
}) => {
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showEditCompany, setShowEditCompany] = useState(false);
  const [showNewUser, setshowNewUser] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    isAdmin,
    taxNumber,
    modification,
    newUser,
    confirmRemove,
    areYouSure,
    confirm,
    cancel,
    contactName,
    createNewUserModalHeader,
    clientText,
  } = useCompaniesContentHeader();

  useEffect(() => {
    dispatch(setModalOpen(showEditCompany));
  }, [dispatch, showEditCompany]);

  useEffect(() => {
    dispatch(setModalOpen(showNewUser));
  }, [dispatch, showNewUser]);

  return (
    <>
      <CompanyContentHeaderLayout
        headerTitle={<Text type="style34">{companyName}</Text>}
        trashIcon={
          isAdmin && (
            <Icon
              type="trash"
              colorStyle="lightGray"
              className="companyScreenIcon d-flex mr-3"
              onClick={() => {
                setShowDeletePopup(true);
              }}
              id={`deleteIcon-${companyName}-${index}`}
            />
          )
        }
        newUserButton={
          isAdmin && (
            <Button
              outline
              size="medium"
              className="companyContentButton mr-2"
              iconSize="medium"
              icon="personPlus"
              rounded
              onClick={() => {
                setshowNewUser(true);
              }}
            >
              <Text className="font-weight-normal" type="style30">
                {newUser}
              </Text>
            </Button>
          )
        }
        modifyButton={
          isAdmin && (
            <Button
              outline
              size="medium"
              className="companyContentButton"
              iconSize="medium"
              icon="pencil"
              rounded
              onClick={() => setShowEditCompany(true)}
            >
              <Text className="font-weight-normal" type="style30">
                {modification}
              </Text>
            </Button>
          )
        }
        taxNumber={
          <>
            <Icon type="bookOpen" colorStyle="lightGray" />
            <Text className="d-flex ml-1 font-weight-bold" type="style28">
              {taxNumber}:
              <Text className="d-flex ml-1" type="style28">
                {taxId}
              </Text>
            </Text>
          </>
        }
        clientName={
          <>
            <Icon type="person" colorStyle="lightGray" />
            <Text className="d-flex ml-1 font-weight-bold" type="style28">
              {clientText}:
              <Text className="d-flex ml-1" type="style28">
                {clientName}
              </Text>
            </Text>
          </>
        }
        contactText={
          <>
            <Icon type="person" colorStyle="lightGray" />
            <Text className="font-weight-bold" type="style28">
              {contactName}:
            </Text>
            <Text className="ml-1 text-wrap" type="style28">
              {contact}
            </Text>
          </>
        }
      />
      {showDeletePopup && (
        <ModalBox
          headerText={areYouSure}
          show={showDeletePopup}
          modalWidth="narrow"
          onClickClose={() => setShowDeletePopup(false)}
        >
          <Text type="style1">{confirmRemove}</Text>
          <div className="d-flex justify-content-around">
            <Button
              size="large"
              rounded
              className="my-4 mr-3"
              onClick={() => {
                onDelete(companyId);
                setShowDeletePopup(false);
              }}
              id="companyDeletionConfirmation"
            >
              <Text className="px-2 py-1 d-flex" type="style24">
                {confirm}
              </Text>
            </Button>
            <Button
              size="large"
              rounded
              className="my-4 ml-3 btn-secondary"
              onClick={() => {
                setShowDeletePopup(false);
              }}
            >
              <Text className="px-2 py-1 d-flex" type="style24">
                {cancel}
              </Text>
            </Button>
          </div>
        </ModalBox>
      )}
      {showEditCompany && (
        <ModalBox
          headerText={t('accountSettings.contents.companies.modifyHeader', {
            COMPANY: companyName,
          })}
          show={showEditCompany}
          modalWidth="wide"
          onClickClose={() => setShowEditCompany(false)}
        >
          <AddCompanyModal companyId={companyId} close={() => setShowEditCompany(false)} />
        </ModalBox>
      )}

      {showNewUser && (
        <ModalBox
          headerText={createNewUserModalHeader}
          show={showNewUser}
          modalWidth="wide"
          onClickClose={() => setshowNewUser(false)}
        >
          <AddUserModal
            companyId={companyId}
            clientId={clientId}
            close={() => setshowNewUser(false)}
          />
        </ModalBox>
      )}
    </>
  );
};
