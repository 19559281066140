import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileDrop } from 'react-file-drop';
import Multiselect from 'multiselect-react-dropdown';
import Button from '@/components/Button';
import Text from '@/components/Text';
import UnderlineInput from '@/components/Input/UnderlineInput';
import CircleImage from '@/components/CircleImage';
import AddUserLayout from '@/layouts/AddUserLayout';
import { useClientsData } from '@/client_v2/hooks/useClientsData';
import { convertFileListToFileArr } from '@/resources/utils';
import { useModifyUserModalData } from '@/client_v2/hooks/useModifyUserModalData';
import MultiselectDropdown from '@/components/MultiselectDropdown';
import { setError } from '@/redux/message/message.action';
import { useAsyncCallback } from '@/hooks/useAsyncCallback';
import { createOnError } from '@/redux/message/message.callback';
import RestClient from '@/client_v2/rest';
import { AppState } from '@/redux/store';
import { setUsersScrollbarPosition } from '@/redux/users/users.actions';
import {
  Authority,
  Authorities,
  DropdownItem,
} from '@/screens/AccountSettings/Contents/interfaces';

interface Props {
  userId: number;
  close: () => void;
  isOpenedFromUsersPage: boolean;
}

interface companyFilter {
  name: string;
  value: number;
  clientId: number | null;
}

interface clientFilter {
  name: string;
  value: number;
}

const useEditUserModal = ({ userId, close }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const nameInputTitle = t('accountSettings.contents.users.name');
  const emailInputTitle = t('accountSettings.contents.users.email');
  const phoneNumberInputTitle = t('accountSettings.contents.users.phoneNumber');
  const positionInputTitle = t('accountSettings.contents.users.position');
  const selectClientDropdownHeader = t('accountSettings.contents.users.client');
  const selectClientDropdownText = t('accountSettings.contents.users.selectClient');
  const selectCompanyDropdownHeader = t('accountSettings.contents.users.company');
  const selectCompanyDropdownText = t('accountSettings.contents.users.selectCompany');
  const permissionDropdownHeader = t('accountSettings.contents.users.permission');
  const permissionDropdownHeaderText = t('accountSettings.contents.users.selectPermission');
  const uploadButtonText = t('upload.buttonText');
  const saveUserButtonText = t('accountSettings.contents.users.saveUser');
  const profileImageText = t('accountSettings.contents.users.profileImageDescription');
  const profileImageTypeText = t('accountSettings.contents.users.imageTypeDescription');

  const [showDropdwon, setShowDropdown] = useState(false);
  const [showAuthorities, setShowAuthorities] = useState(false);
  const [showClients, setShowClients] = useState(false);
  const [selectedPic, setSelectedPic] = useState('');
  const [selectedClientList, setSelectedClientList] = useState<clientFilter[] | undefined>();
  const [filteredCompanyList, setFilteredCompanyList] = useState<companyFilter[] | undefined>();
  const [companyList, setCompanyList] = useState<companyFilter[] | undefined>();
  const [clientList, setClientList] = useState<clientFilter[] | undefined>();
  const user = useSelector((state: AppState) => state.user.user);

  const [clientsLoading, clients] = useClientsData({
    renderOnUpdate: false,
    triggerOnce: true,
    onError: (err) => dispatch(setError(err.toString())),
  });

  const userAuthority = useSelector((state: AppState) => state.user.user?.authority);

  interface IUserData {
    name: string;
    email: string;
    authority: string | null;
    position: string;
    phone: string;
    clientIds?: number[];
    companyIds?: number[];
    file: File | null;
    profileImageUrl?: string;
  }

  const [userForm, setUserForm] = useState<IUserData>({
    name: '',
    clientIds: [],
    companyIds: [],
    email: '',
    phone: '',
    position: '',
    authority: '',
    file: null,
  });

  const onChangeName = (value: string) => {
    setUserForm({ ...userForm, name: value });
  };
  const onChangePhone = (value: string) => {
    setUserForm({ ...userForm, phone: value });
  };
  const onChangePosition = (value: string) => {
    setUserForm({ ...userForm, position: value });
  };
  const onChangeAuthority = (value: Authority) => {
    setFilteredCompanyList(filteredCompanyId([]));
    setUserForm({ ...userForm, authority: value.value, clientIds: [], companyIds: [] });
    multiselectRef.current?.resetSelectedValues();
  };

  const onChangeClients = (values: number[]) => {
    setUserForm({ ...userForm, clientIds: values, companyIds: [] });
  };

  const onChangeCompanies = (values: number[]) => {
    setUserForm({ ...userForm, companyIds: values });
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const file = convertFileListToFileArr(files!)[0];
    if (file) {
      setUserForm({ ...userForm, file });
      setSelectedPic(URL.createObjectURL(file));
    }
  };

  const onFileSelectClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const onTargetClick = () => {
    fileInputRef.current!.click();
  };

  const authorityList = [
    {
      name: t('authorities.admin'),
      value: Authorities.Admin,
    },
    userAuthority === Authorities.SuperAdmin && {
      name: t('authorities.superAdmin'),
      value: Authorities.SuperAdmin,
    },
    {
      name: t('authorities.juniorUser'),
      value: Authorities.JuniorUser,
    },
    {
      name: t('authorities.clientUser1'),
      value: Authorities.ClientUser1,
    },
    {
      name: t('authorities.clientUser2'),
      value: Authorities.ClientUser2,
    },
  ].filter(Boolean);

  const [editUser, userEditLoading] = useAsyncCallback(
    async () => {
      dispatch(setUsersScrollbarPosition(undefined));
      await RestClient.Users.patch({
        ...(userForm.file && {
          file: userForm.file,
        }),
        data: {
          id: userId,
          ...(userForm.name && {
            name: userForm.name,
          }),
          ...(userForm.authority && {
            authority: userForm.authority,
          }),
          ...(userForm.position && {
            position: userForm.position,
          }),
          ...(userForm.phone && {
            phone: userForm.phone,
          }),
          ...(userForm.clientIds && {
            clientIds: userForm.clientIds,
          }),
          ...(userForm.companyIds && {
            companyIds: userForm.companyIds,
          }),
        },
      });
      close();
    },
    createOnError(dispatch),
    [userId, close, userForm],
  );

  const modalValidation = userForm.name && userForm.authority && userForm.clientIds?.length;

  const [isLoading, getData] = useModifyUserModalData({
    triggerOnce: { userId: userId ? userId : 1 },
    renderOnUpdate: false,
    onError: (err) => dispatch(setError(err.toString())),
  });

  const filteredCompanyId = (currentClientIds: number[]) => {
    if (currentClientIds && companyList) {
      return companyList.filter(
        (item: companyFilter) => item.clientId && currentClientIds.includes(item.clientId),
      );
    } else {
      return currentClientIds ? companyList : [];
    }
  };

  const [allSelectedCompanies, setAllSelectedCompanies] = useState<number[]>([]);

  useEffect(() => {
    userForm?.companyIds?.length &&
      setAllSelectedCompanies(allSelectedCompanies.concat(userForm.companyIds));
    // must be disabled to prevent an infitine loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAllSelectedCompanies, userForm.companyIds]);

  const selectedCompanyOptions =
    allSelectedCompanies &&
    filteredCompanyList &&
    filteredCompanyList.filter(
      (filteredCompany: companyFilter) =>
        userForm.companyIds?.includes(filteredCompany.value) ||
        allSelectedCompanies.includes(filteredCompany.value),
    );

  const isSuperAdminUser =
    userAuthority != Authorities.SuperAdmin && userForm.authority === Authorities.SuperAdmin;

  useEffect(() => {
    const inneerClientList: clientFilter[] = getData?.clients
      ? getData?.clients!.map((client) => {
          return {
            name: client.clientName,
            value: client.clientId,
          };
        })
      : [];

    const innerCompanyList: companyFilter[] = getData?.companies
      ? getData.companies?.map((company) => {
          return {
            name: company.companyName,
            value: company.companyId,
            clientId: company.clientId,
          };
        })
      : [];
    setCompanyList(innerCompanyList);
    setClientList(inneerClientList);
  }, [getData?.clients, getData?.companies, setCompanyList]);

  useEffect(() => {
    if (userForm.clientIds) {
      setFilteredCompanyList(filteredCompanyId(userForm.clientIds));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData?.companies, userForm.clientIds]);

  useEffect(() => {
    if (!isLoading && getData && getData.user) {
      setUserForm({
        name: getData.user.name,
        email: getData.user.email,
        phone: getData.user.phone,
        authority: getData.user.authority,
        clientIds: getData.user.clients ? getData.user.clients : [],
        companyIds: getData.user.companies ? getData.user.companies : [],
        file: null,
        position: getData.user.position,
        profileImageUrl: getData.user.profileImageUrl,
      });
    }
  }, [getData, setUserForm, isLoading]);

  const canHaveMultipleClients =
    userForm.authority && !['client-user1', 'client-user2'].includes(userForm.authority);

  const multiselectRef = React.useRef<Multiselect>(null);

  return {
    nameInputTitle,
    emailInputTitle,
    phoneNumberInputTitle,
    positionInputTitle,
    selectClientDropdownHeader,
    selectClientDropdownText,
    selectCompanyDropdownHeader,
    selectCompanyDropdownText,
    permissionDropdownHeader,
    permissionDropdownHeaderText,
    uploadButtonText,
    saveUserButtonText,
    profileImageText,
    profileImageTypeText,
    showDropdwon,
    setShowDropdown,
    userAuthority,
    userForm,
    setUserForm,
    onChangeName,
    onFileSelectClick,
    fileInputRef,
    onChangePhone,
    onChangePosition,
    authorityList,
    showAuthorities,
    setShowAuthorities,
    onChangeAuthority,
    showClients,
    setShowClients,
    onChangeClients,
    onChangeCompanies,
    clientsLoading,
    dispatch,
    clients,
    onFileInputChange,
    onTargetClick,
    selectedPic,
    modalValidation,
    editUser,
    userEditLoading,
    canHaveMultipleClients,
    filteredCompanyList,
    setFilteredCompanyList,
    companyList,
    setCompanyList,
    multiselectRef,
    selectedClientList,
    setSelectedClientList,
    getData,
    filteredCompanyId,
    clientList,
    isSuperAdminUser,
    user,
    selectedCompanyOptions,
  };
};

export const EditUserModal: FC<Props> = ({ userId, close, isOpenedFromUsersPage }) => {
  const {
    nameInputTitle,
    emailInputTitle,
    phoneNumberInputTitle,
    positionInputTitle,
    selectClientDropdownHeader,
    selectClientDropdownText,
    selectCompanyDropdownHeader,
    selectCompanyDropdownText,
    permissionDropdownHeader,
    permissionDropdownHeaderText,
    uploadButtonText,
    saveUserButtonText,
    profileImageText,
    profileImageTypeText,
    userForm,
    onChangeName,
    fileInputRef,
    onFileSelectClick,
    onChangePhone,
    onChangePosition,
    authorityList,
    onFileInputChange,
    onTargetClick,
    selectedPic,
    modalValidation,
    editUser,
    userEditLoading,
    onChangeClients,
    onChangeCompanies,
    canHaveMultipleClients,
    filteredCompanyList,
    setFilteredCompanyList,
    multiselectRef,
    getData,
    filteredCompanyId,
    clientList,
    isSuperAdminUser,
    user,
    onChangeAuthority,
    selectedCompanyOptions,
  } = useEditUserModal({ userId, close, isOpenedFromUsersPage });

  const userClientsCheck = user?.clients?.some((clientId) => {
    return userForm?.clientIds?.includes(clientId);
  });

  const saveButtonShow =
    user?.authority === Authorities.SuperAdmin ||
    (user?.authority === Authorities.Admin && userForm?.clientIds && userClientsCheck);

  const { name, email, phone, position, authority } = userForm;
  return (
    <AddUserLayout
      image={!!userForm.file || !!userForm.profileImageUrl}
      nameInputField={
        <UnderlineInput
          titleText={nameInputTitle}
          onChange={onChangeName}
          value={name || ''}
          required
          readOnly={isSuperAdminUser}
        />
      }
      emailInputField={
        <>
          <UnderlineInput titleText={emailInputTitle} value={email || ''} readOnly />
        </>
      }
      clientDropdownMenuHeader={
        isOpenedFromUsersPage && (
          <Text type="style7" required>
            {selectClientDropdownHeader}
          </Text>
        )
      }
      clientDropdownMenu={
        isOpenedFromUsersPage &&
        getData &&
        getData.clients && (
          <MultiselectDropdown
            avoidHighlightFirstOption
            options={clientList}
            title={selectClientDropdownText}
            selectedOptions={
              clientList &&
              clientList.filter((filteredClient: clientFilter) =>
                userForm.clientIds?.includes(filteredClient.value),
              )
            }
            onSelectItem={(e: DropdownItem[]) => {
              onChangeClients(e.map((e) => e.value));
              setFilteredCompanyList(filteredCompanyId(e.map((e) => e.value)));
            }}
            singleSelect={!canHaveMultipleClients}
            maxSelection={99}
            onRemoveItem={(e: DropdownItem[]) => {
              onChangeClients(e.map((e) => e.value));
              setFilteredCompanyList(filteredCompanyId(e.map((e) => e.value)));
            }}
            directRef={multiselectRef}
            id="userClient"
          />
        )
      }
      companyDropdownMenuHeader={
        isOpenedFromUsersPage && <Text type="style7">{selectCompanyDropdownHeader}</Text>
      }
      companyDropdownMenu={
        isOpenedFromUsersPage &&
        getData &&
        getData.companies && (
          <MultiselectDropdown
            avoidHighlightFirstOption
            options={filteredCompanyList}
            selectedOptions={selectedCompanyOptions}
            title={selectCompanyDropdownText}
            maxSelection={99}
            onSelectItem={(e) => {
              const cIds = e.map((e: companyFilter) => e.value);
              onChangeCompanies(cIds);
            }}
            onRemoveItem={(e) => {
              const cIds = e.map((e: companyFilter) => e.value);
              onChangeCompanies(cIds);
            }}
            displayValue="name"
            isDisabled={isSuperAdminUser}
          />
        )
      }
      permissionDropdownMenu={
        <MultiselectDropdown
          options={authorityList}
          avoidHighlightFirstOption
          selectedOptions={authorityList.filter((auth) => auth && auth.value == authority)}
          title={permissionDropdownHeader}
          isDisabled={isSuperAdminUser}
          singleSelect={true}
          maxSelection={1}
          onSelectItem={(e: Authority[]) => onChangeAuthority(e[0])}
          onRemoveItem={() => onChangeAuthority({ value: '', text: '' })}
        />
      }
      permissionDropdownMenuHeader={
        <Text type="style7" required>
          {permissionDropdownHeaderText}
        </Text>
      }
      userImage={
        selectedPic ? (
          <CircleImage size="medium" image={selectedPic} />
        ) : (
          <CircleImage
            size="medium"
            image={`${process.env.REACT_APP_API_BASE_URL}${userForm.profileImageUrl}`}
          />
        )
      }
      imageDescription={<Text type="style7">{profileImageText}</Text>}
      imageTypeDescription={<Text type="style6">{profileImageTypeText}</Text>}
      uploadBtn={
        <>
          <Button
            size="small"
            paddingSize="small"
            onClick={onFileSelectClick}
            disabled={isSuperAdminUser}
          >
            {uploadButtonText}
          </Button>
          <FileDrop onTargetClick={onTargetClick}>
            <></>
          </FileDrop>
          <input
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            id="file"
            ref={fileInputRef}
            className="d-none"
            onChange={onFileInputChange}
          />
        </>
      }
      // TODO: phone number validation
      phoneNumberInputField={
        <UnderlineInput
          titleText={phoneNumberInputTitle}
          onChange={onChangePhone}
          value={phone || ''}
          readOnly={isSuperAdminUser}
        />
      }
      positionInputField={
        <UnderlineInput
          titleText={positionInputTitle}
          onChange={onChangePosition}
          value={position || ''}
          readOnly={isSuperAdminUser}
        />
      }
      saveUserButton={
        saveButtonShow && (
          <Button
            size="small"
            paddingSize="large"
            roundedLarge
            icon={'pencil'}
            disabled={!modalValidation}
            onClick={editUser}
          >
            <>
              {userEditLoading ? (
                <div className="spinner-border text-light" role="status" />
              ) : (
                saveUserButtonText
              )}
            </>
          </Button>
        )
      }
    />
  );
};

export default EditUserModal;
