import { Dispatch } from 'redux';
import { CLEAR_MESSAGE, SET_ERROR, SET_SUCCESS } from './message.types';

export const clearMessage = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_MESSAGE,
  });
};

export const setError = (message: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_ERROR,
    payload: message,
  });
};

export const setSuccess = (message: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SUCCESS,
    payload: message,
  });
};
