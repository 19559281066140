/* eslint-disable camelcase */
import { creatRepo } from '@/client_v2/db/utils/createRepo';
import { Comment } from '@/client_v2/rest/types/CommentsRes';

export const CommentRepo = creatRepo<Comment>({
  name: 'comment',
  model: {
    'id:number': { pk: true },
    'user_id:number': { default: -1 },
    'comment:string': {},
    'commentable_id:number': { default: -1 },
    'commentable_type:string': {},
    'created_at:date': {},
    'updated_at:date': {},
  },
}).make();
