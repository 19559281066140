import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Text from '@/components/Text';
import { AppState } from '@/redux/store';
import { expandDocumentTable } from '@/redux/documents/documents.actions';
import { resetSortAction } from '@/redux/sort/sort.actions';

export const DashboardHeader: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state: AppState) => state.user);

  useEffect(() => {
    dispatch(expandDocumentTable({ expand: false }));
    dispatch(resetSortAction());
  }, [dispatch]);

  return (
    <div className="p-4 m-4">
      {user?.name && (
        <Text type="style13">
          {t('dashboardScreen.hello')} {user.name}!
        </Text>
      )}
      <Text className="pt-2 d-flex " type="style25">
        {t('dashboardScreen.headerText')}
      </Text>
    </div>
  );
};
