import { FC } from 'react';
import { Tooltip as View } from '@/components/Tooltip/Tooltip.view';

interface Props {
  children?: React.ReactNode;
  bubbleContent?: string;
  place?: 'top' | 'right' | 'left';
}

export const Tooltip: FC<Props> = (props) => {
  return <View {...props} />;
};
