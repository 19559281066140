/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { InanoSQLInstance, InanoSQLTableConfig } from '@nano-sql/core/lib/interfaces';
import db, { READY_EVENT_TYPE } from '@/client_v2/db';

type QueryHandler = (table: () => InanoSQLInstance, args: any) => Promise<any>;
type SecundArg<FN> = FN extends (a: any, b: infer Arg) => any ? Arg : never;
type ReturnType<FN> = FN extends (...args: any[]) => infer R ? R : never;
type QueryRunner<QHS> = {
  query<N extends keyof QHS>(name: N, args: SecundArg<QHS[N]>): ReturnType<QHS[N]>;
  query<N extends keyof QHS>(name: N): ReturnType<QHS[N]>;
};
type QueryRepo<DTO> = {
  config: InanoSQLTableConfig;
  table(): InanoSQLInstance;
  save(item: Partial<DTO>): Promise<DTO>;
  delete(arg: number): Promise<DTO>;
  delete(arg: any[]): Promise<DTO>;
  delete(arg: (row: DTO, i?: number) => boolean): Promise<DTO>;
};
type QueryBuilder<DTO, Lib = {}> = {
  queryName<N extends keyof any>(
    name: N,
  ): {
    handler<QH extends QueryHandler>(
      qh: QH,
    ): QueryBuilder<
      DTO,
      Lib & {
        [key in N]: QH;
      }
    >;
  };
  make(): QueryRepo<DTO> & QueryRunner<Lib>;
};

export function creatRepo<DTO>(config: Omit<InanoSQLTableConfig, 'queries'>) {
  const table = () => db.selectTable(config.name);
  const queries: any = {};
  const loadingBuffer: any = [];

  const repo = {
    config,
    table,
    async save(data: Partial<DTO>) {
      if (db.isReady) {
        return (await table().query('upsert', data).exec()).pop()!;
      }
      return await new Promise((res) => {
        const handler = async () => {
          res((await table().query('upsert', data).exec()).pop()!);
        };
        loadingBuffer.push(handler);
      });
    },
    async delete(args: number | any[] | ((row: DTO, i?: number) => boolean)) {
      if (typeof args === 'number') args = ['id', '=', args];
      if (db.isReady) {
        return (
          await table()
            .query('delete')
            .where(args as any)
            .exec()
        ).pop()!;
      }
      return await new Promise((res) => {
        const handler = async () => {
          res(
            (
              await table()
                .query('delete')
                .where(args as any)
                .exec()
            ).pop()!,
          );
        };
        loadingBuffer.push(handler);
      });
    },
    query(name: string, args: any) {
      if (db.isReady) return queries[name](table, args);
      return new Promise((res) => {
        const handler = async () => {
          res(await queries[name](table, args));
        };
        loadingBuffer.push(handler);
      });
    },
  };

  const builder: QueryBuilder<DTO> = {
    queryName(name: string) {
      return {
        handler<QH extends QueryHandler>(qh: QH) {
          queries[name] = qh;
          return builder;
        },
      };
    },
    make() {
      return repo;
    },
  } as any;

  const isReadyHandler = () => {
    let handler: Function = null!;
    while ((handler = loadingBuffer.pop())) {
      handler();
    }
    window.removeEventListener(READY_EVENT_TYPE, isReadyHandler);
  };
  window.addEventListener(READY_EVENT_TYPE, isReadyHandler);

  return builder;
}
