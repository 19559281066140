import '@/layouts/ProcessesTableLayout/style.scss';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@/components/Card';
import NavTab from '@/components/NavTab';
import Button from '@/components/Button';
import Text from '@/components/Text';
import Collapse from '@/components/Collapse';
import URLS from '@/resources/constants/URLS';
import { ModalBox } from '@/components/ModalBox';
import { IGetWorkflowMiles, IGetWorkflowData } from '@/client_v2/db/repositories/Workflow.repo';
import { AppState } from '@/redux/store';
import {
  setCollapseProcessId,
  setMemorizeSelectedMileId,
} from '@/redux/processes/processes.actions';
import { cn } from '@/components/utils';
import { WorkflowStatuses } from '@/resources/constants/shared';
import { ProcessesTableHeader } from './components/Header';
import { ProcessesTableContent } from './components/Table';

interface Props {
  canArchive: boolean;
  processTitle: string;
  serial: number;
  miles: IGetWorkflowMiles[];
  client?: string;
  company?: string;
  fromDate: string | null;
  toDate: string | null;
  onArchive: () => void;
  stepId: number[];
  onUploadComment: (
    stepId: number,
    newComments: string[],
    deletedComments?: { commentId: number; comment: string }[],
  ) => void;
  isArchived: boolean;
  processData: IGetWorkflowData;
  setStepsStatusLoading: () => void;
  getProcessScrollbar: () => void;
  index: number;
}

export const ProcessesTableLayout: FC<Props> = ({
  canArchive,
  processTitle,
  serial,
  miles,
  fromDate,
  toDate,
  onArchive,
  client,
  company,
  stepId,
  isArchived,
  onUploadComment,
  processData,
  setStepsStatusLoading,
  getProcessScrollbar,
  index,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { collapseProcessId, memorizedMileId } = useSelector((state: AppState) => state.processes);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [clickTab, setClickTab] = useState(false);
  const [stepsNotChecked, setStepsNotChecked] = useState(true);
  const [tab, setTab] = useState<number>();
  const dateFormat = t('common.dateFormat');

  useEffect(() => {
    const tab =
      processData.id === collapseProcessId && memorizedMileId
        ? memorizedMileId
        : miles && miles[0] && miles[0].mileId;

    setTab(tab || undefined);
  }, [setTab, miles, processData.id, collapseProcessId, memorizedMileId]);

  const [steps, setSteps] = useState(
    miles &&
      miles.find((item: IGetWorkflowMiles) => {
        return item.mileId === tab;
      }),
  );

  useEffect(() => {
    const getSteps = miles && miles.find((item: IGetWorkflowMiles) => item.mileId === tab);
    getSteps && setSteps(getSteps);
  }, [tab, miles]);

  const expandDocument = useSelector((state: AppState) => state.documents.expand);

  useEffect(() => {
    setStepsNotChecked(true);
    miles.map((mile) => {
      mile.steps.map((step) => {
        if (step.status === WorkflowStatuses.DONE) {
          setStepsNotChecked(false);
        }
      });
    });
  }, [miles]);

  return (
    <div className={cn('mb-4', expandDocument && 'mt-4')}>
      <Card bgColorStyle={isArchived ? 'lighter' : 'white'} rounded shadow>
        <ProcessesTableHeader
          processTitle={processTitle}
          serial={serial}
          client={client}
          company={company}
          fromDate={fromDate && dayjs(fromDate).format(dateFormat)}
          toDate={toDate && dayjs(toDate).format(dateFormat)}
          stepsNotChecked={stepsNotChecked}
        />
        <hr className="my-2" />
        {miles && steps && (
          <div className="container-fluid">
            <div className="row px-4 pt-2 pb-4">
              <div className="col-md-10 col-12 d-flex text-nowrap">
                <NavTab
                  tabs={miles}
                  isSelectedProcess={collapseProcessId === serial}
                  selectedTab={memorizedMileId}
                  setTab={(value: number) => {
                    setClickTab(true);
                    setTab(value);
                    dispatch(setMemorizeSelectedMileId(value));
                    serial && dispatch(setCollapseProcessId(serial));
                  }}
                />
              </div>
              <div className="col-md-2 col-12">
                <div className="d-flex justify-content-end tabButtons">
                  <Button
                    size="small"
                    className="tabButton"
                    iconSize="medium"
                    icon="file"
                    onClick={() => navigate(URLS.root.documents.toString())}
                  >
                    <Text className="d-flex" type="style17">
                      {t('processesScreen.doc')}
                    </Text>
                  </Button>
                  {canArchive && (
                    <Button
                      size="small"
                      className="tabButton ml-2"
                      iconSize="medium"
                      icon="archive"
                      onClick={() => setShowArchiveModal(true)}
                      id={`archiveButton-${index}`}
                    >
                      <Text className="d-flex" type="style17" capitalization="capitalize">
                        {isArchived ? t('processesScreen.restore') : t('processesScreen.archive')}
                      </Text>
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="row processCollapse">
              <div className="col">
                <Collapse
                  isOpened={serial === collapseProcessId}
                  onClick={() => {
                    serial && dispatch(setCollapseProcessId(!clickTab ? serial : null));
                    setClickTab(false);
                  }}
                >
                  <div className="scope text-nowrap">
                    <div className="scroll-tb">
                      <ProcessesTableContent
                        key={`process-table-${stepId}`}
                        givenData={steps.steps}
                        givenMile={miles.find((item: IGetWorkflowMiles) => item.mileId === tab)}
                        stepId={stepId}
                        onUploadComment={onUploadComment}
                        mileData={steps}
                        processData={processData}
                        setStepsStatusLoading={setStepsStatusLoading}
                        getProcessScrollbar={getProcessScrollbar}
                        isArchived={isArchived}
                      />
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        )}
      </Card>
      {showArchiveModal && (
        <ModalBox
          headerText={t('common.areYouSure')}
          show={showArchiveModal}
          onClickClose={() => setShowArchiveModal(false)}
          modalWidth={'medium'}
        >
          <Text type="style1">
            {isArchived
              ? t('processesScreen.process.confirmRestore')
              : t('processesScreen.process.confirmArchive')}
          </Text>
          <div className="d-flex justify-content-around flex-wrap mt-lg-2 mt-0">
            <Button
              size="large"
              rounded
              className="mt-lg-4 mt-2 mb-3"
              onClick={() => {
                onArchive();
                setShowArchiveModal(false);
              }}
              id="confirmArchiveProcessButton"
            >
              <Text className="px-2 py-1 d-flex" type="style24">
                {t('processesScreen.process.confirmArchiveButton')}
              </Text>
            </Button>
            <Button
              size="large"
              rounded
              className="mt-lg-4 mt-2 ml-lg-3 ml-0 mb-3 btn-secondary"
              onClick={() => setShowArchiveModal(false)}
            >
              <Text className="px-2 py-1 d-flex" type="style24">
                {t('processesScreen.process.confirmArchiveButton2')}
              </Text>
            </Button>
          </div>
        </ModalBox>
      )}
    </div>
  );
};
