import axiosInstance from '@/client_v2/rest/axios/axiosInstance';
import { WorkflowMilesRes } from '@/client_v2/rest/types/WorkflowMilesRes';
import { API_WORKFLOWS_SEARCH } from '@/client_v2/rest/WorkflowMile/URLS';

export const getWorkflowMilesByIds = (ids: number[]) =>
  axiosInstance.post<WorkflowMilesRes>(API_WORKFLOWS_SEARCH, {
    scopes: [
      {
        name: 'ids',
        parameters: [ids.toString()],
      },
    ],
  });
